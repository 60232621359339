import { useState, useEffect } from 'react';
import CustomModal from '../../../components/modals/customModal';
import { IEvent, IStep } from '../../../models/interfaces/events.interface';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { MdModeEdit, MdLocationOn } from "react-icons/md";
import { DateHelper } from '../../../helpers/date-helper';
import { useTranslation } from 'react-i18next';
import useEventsApi from '../../../hooks/useEventsApi';
import { getEventInscriptionURL } from '../../../helpers/events-helper';
import useLoader from '../../../components/loader/useLoader';
import QuestionRow from '../../../questions/questionRow';
import SectorRow from '../../../sectors/sectorRow';
import EmailModal from '../../../components/modals/emailPreviewModal';
import { isHost, StepProps } from '../utils';
import i18n from '../../../../i18n';
import { useHistory } from 'react-router-dom';
import { IoArrowForwardOutline } from 'react-icons/io5';
import StepStatusChip from '../../../components/step-status-chip/stepStatusChip';
import EventCardModal from '../../../components/modals/eventCardPreviewModal';
import { SHOW_UNDO_BUTTON } from '../../../../constants';
import BatchRow from '../../batchRow';

export function stepSummary(props: StepProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { event } = props;
    const [allStepsComplete, setAllStepsComplete] = useState<boolean>(event?.steps?.slice(0, -1).every((step: IStep) => step.percentComplete === 100) || false);
    const [modifiedEvent, setModifiedEvent] = useState<any>();
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>();
    const [showSuccessModalDelete, setShowSuccessModalDelete] = useState<boolean>();
    const [goToEvents, setGoToEvents] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [inscriptionLink, setInscriptionLink] = useState<any>(event?.qrUrl);
    const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);
    const [loader, showLoader, hideLoader] = useLoader();
    const [showSectors, setShowSectors] = useState(false);
    const [showBatches, setShowBatches] = useState(false);
    const [batchesAvailability, setBatchesAvailability] = useState<any[]>([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [showEventPreviewModal, setShowEventPreviewModal] = useState<boolean>(false);
    const [isPublished, setIsPublished] = useState<boolean>(props.event?.status === 'PUBLISHED');
    const [html, setHtml] = useState<string | undefined>();
    const [userLoggedIsHost, setUserLoggedIsHost] = useState<boolean>(false);

    const [showEmailPreview, setShowEmailPreview] = useState(false);
    const [createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getSectorsAvailability,
        getInscriptionsBySector,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent,
        publishEvent] = useEventsApi();

    const handleEditSection = (sectionName: string) => {
        props.saveAndGoToStep(event, sectionName, true);
    }
    const handleToggleSectors = () => {
        setShowSectors(!showSectors);
    };
    const handleToggleBatches = () => {
        setShowBatches(!showBatches);
    }
    const handleCloseSuccessModal = (e: any) => {
        if (e) {
            navigator.clipboard.writeText(inscriptionLink)
                .then(() => {
                    setShowSuccessModal(false);
                    setShowLinkCopiedModal(true);
                });
        } else {
            setShowSuccessModal(false);
        }

        props.saveAndGoToStep(modifiedEvent, 'SUMMARY');
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
    };


    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    const handleEvent = async () => {
        showLoader();
        if (!!props.event) {
            setAllStepsComplete(props.event?.steps?.slice(0, -1).every((step: IStep) => step.percentComplete === 100) || false);
            setIsPublished(props.event?.status === 'PUBLISHED');
            const _inscriptionLink = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL) +
                getEventInscriptionURL(props.event);
            setInscriptionLink(_inscriptionLink);
            setUserLoggedIsHost(isHost(props.loggedUser, event));
            if (props.event.eventType === 'PUBLIC_EVENT' && props.event.hasPayment === true) {
                if (!!props.event._id) {
                    const response: any = await getBatchesAvailability(props.event._id);
                    if (!response.error && response?.batchesAvailability) {
                        setBatchesAvailability(response.batchesAvailability);
                    }
                }
            }
        }
        hideLoader();
    }


    const handlePublishEvent = async () => {
        try {
            showLoader();
            if (!!event && allStepsComplete) {
                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    publishedBy: props.loggedUser._id,
                    publishedDT: new Date()
                }
                if (!!evt && !!evt.steps) {
                    const stepIdx = evt.steps.findIndex(s => s.name === 'SUMMARY');
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = 100;
                    }
                }
                const response: any = await dispatch(publishEvent(evt));
                if (!!response && response['payload']['_id']) {
                    setIsPublished(true);
                    setModifiedEvent(response.payload);

                    const _inscriptionLink = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL) +
                        getEventInscriptionURL(evt);

                    if (_inscriptionLink) {
                        setInscriptionLink(_inscriptionLink);
                        setShowSuccessModal(true);
                    } else {
                        setErrorModalMessage(t('error.unexpected'));
                        setShowErrorModal(true);
                    }
                }
            }
        } catch (error) {
            console.error("Error al publicar el evento:", error);
        } finally {
            hideLoader();
        }


    };
    const handleUnPublishEvent = async () => {
        try {
            showLoader();
            if (!!event) {
                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    status: 'DRAFT',
                    publishedBy: null,
                    publishedDT: null
                };
                if (!!evt && !!evt.steps) {
                    const stepIdx = evt.steps.findIndex(s => s.name === 'SUMMARY');
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = 0;
                    }
                }


                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    hideLoader();

                    setIsPublished(false);
                    setModifiedEvent(response.payload);
                    setInscriptionLink(undefined);
                    props.saveAndGoToStep(response.payload, 'SUMMARY');
                    document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
                }
            }
        } catch (error) {
            console.error("Error al publicar el evento:", error);
        } finally {
            hideLoader();
        }


    };
    const handleCloseLinkCopiedModal = (e: any) => {
        setShowLinkCopiedModal(false);
    };
    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }
    const loadPreview = async () => {
        try {
            showLoader();
            if (!!props.event?._id) {
                const template = await getEventMailPreview(props.event?._id, 'reminder');
                if (!!template) {
                    setHtml(template);
                    setShowEmailPreview(true);
                }
            }
        } catch (e) {
            setErrorModalMessage(t('event.error-email-template'));
            setShowErrorModal(true);
        }
        finally {
            hideLoader();
        }
    }
    const onHidePreviewModal = () => {
        setShowEmailPreview(false);
    }
    const handleCloseDeleteModal = async (e: any) => {
        setShowDeleteModal(false);
        if (!!e) {
            if (!!event && !!event._id) {
                // await dispatch(deleteEvent({ id: eventId }));
                // history.push('/events');
                showLoader();
                const deletedObj: any = await dispatch(updateEvent({
                    _id: event._id,
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date(),
                    deletedBy: props.loggedUser._id,
                    deletedDT: new Date()
                }));
                hideLoader();

                if (deletedObj && deletedObj['payload']) {
                    if (deletedObj['payload']['removed'] == true || deletedObj['payload']['removed'] == 'true') {
                        if (deletedObj['payload']['inscriptions'] == true || deletedObj['payload']['inscriptions'] == 'true') {
                            setSuccessMessage(i18n.t('event.success-notifications-with-inscriptions'));
                            setGoToEvents(true);
                            setShowSuccessModalDelete(true);
                        } else {
                            setGoToEvents(true);
                            setSuccessMessage(i18n.t('event.success-delete'));
                            setShowSuccessModalDelete(true);
                        }
                    } else {
                        setErrorModalMessage(t('event.error-delete'));
                        setShowErrorModal(true);
                    }
                }
            }
        }

    };
    const handleCloseSuccessDeleteModal = (e: any) => {
        if (!!e) {
            if (goToEvents) {
                if (event?.status === 'PUBLISHED') {
                    history.push('/events');
                } else {
                    history.push('/draft-events');
                }
            }
        }
        setShowSuccessModal(false);
    };
    const handleShowPreview = () => {
        setShowEventPreviewModal(true);
    };

    return (
        <div>
            {loader}
            {!!event && <>
                <div className='summary-wrapper'>
                    <header className='row justify-content-between'>
                        <h1 className='m-0 page-title'>{t('event-summary')}</h1>
                        {
                            (props.editable && event.status === 'DRAFT') && (
                                <Button
                                    className="button-outline-primary medium btn btn-primary d-flex align-items-center"
                                    onClick={() => handleShowPreview()}
                                    aria-label={t('preview-event')}
                                >
                                    {t('preview-event')}
                                </Button>
                            )
                        }
                    </header>
                    <section className='section-wrapper'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                <h4 className='step-title align-self-center'>{t('step.INFO')}</h4>
                                {props.editable && (
                                    <StepStatusChip event={event} step={'INFO'} />
                                )}
                            </div>
                            {props.editable && (
                                <Button
                                    className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                    onClick={() => handleEditSection("INFO")}
                                    aria-label={t('event.edit')}
                                >
                                    <MdModeEdit />
                                </Button>
                            )}
                        </div>
                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className='d-flex flex-column align-items-baseline'>
                                    <label className='form-label input-label'>{t('event.name')}</label>
                                    <p className='margin-bottom-0'>{event?.name}</p>
                                </div>
                            </div>
                            <div className="">
                                <label className='form-label input-label'>{t('event.description')}</label>
                                <p className='margin-bottom-0'>{event?.description}</p>
                            </div>
                            <div className="step-location-info">
                                <label className='form-label input-label'>{t('place')}</label>
                                <div className='d-flex flex-row justify-content-start align-items-center'>
                                    <MdLocationOn color='#760098' />
                                    <a href={event.placeLink} target='_blank'>
                                        <p className='margin-bottom-0 text-main-color'>{event?.place}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                            <div className="">
                                <label className='form-label input-label'>{t('date-start')}</label>
                                <div>
                                    <span className=" date">
                                        {event?.date && DateHelper.dateAndDayLong(event?.date)}
                                    </span>{' '}
                                    -{' '}
                                    {`${event?.startHour}hs`}
                                </div>
                            </div>
                            <div className="">
                                <label className='form-label input-label'>{t('date-end')}</label>
                                <div>
                                    <span className="date">
                                        {event?.dateEnd && DateHelper.dateAndDayLong(event?.dateEnd)}
                                    </span>{' '}
                                    -{' '}
                                    {`${event?.endHour}hs`}
                                </div>
                            </div>

                            <div className="">
                                <label className='form-label input-label'>{t('date-limit-inscription')}</label>
                                <div>
                                    <span className="date">
                                        {event?.inscriptionLimitDT && DateHelper.dateAndDayLong(event?.inscriptionLimitDT)}
                                    </span>{' '}
                                    -{' '}
                                    {`${event?.inscriptionLimitHour} PM`}
                                </div>
                            </div>

                            <div className="">
                                <label className='form-label input-label'>{t('event.max-capacity')}</label>
                                <p className='margin-bottom-0'>{event?.maxCapacity}</p>
                            </div>
                        </div>
                    </section>
                    <section className='section-wrapper'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                <h4 className='step-title'>{t('step.PARTICIPANTS')}</h4>
                                {props.editable && (
                                    <StepStatusChip event={event} step={'PARTICIPANTS'} />
                                )}
                            </div>
                            {props.editable && (
                                <Button
                                    className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                    title={t('event.edit')}
                                    onClick={() => handleEditSection("PARTICIPANTS")}
                                >
                                    <MdModeEdit />
                                </Button>
                            )}
                        </div>
                        <div className='grid grid-cols-1 gap-4'>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className='d-flex flex-column align-items-baseline host-p'>
                                    <label className='form-label input-label'>{t('event.host')}</label>
                                    {event.hosts && (
                                        <p className='text-break d-flex row ml-0 mr-0 p-gap-03'>
                                            {event.hosts.map((host, idx) => {
                                                return <span key={host._id}>{`${host.name ?? ''} ${host.surname ?? ''} (${host.email})` + ((idx < (event.hosts?.length ?? 1) - 1) ? ', ' : '')}</span>;
                                            })
                                            }
                                        </p>
                                    )
                                    }
                                </div>
                            </div>
                            <div className="">
                                <label className='form-label input-label'>{t('event.planners')}</label>
                                <div>
                                    {event.planners && (
                                        <p className='text-break d-flex row ml-0 mr-0 p-gap-03'>
                                            {event.planners.map((planner, idx) => {
                                                return <span key={planner._id}>{`${planner.name ?? ''} ${planner.surname ?? ''} (${planner.email})` + ((idx < (event.planners?.length ?? 1) - 1) ? ', ' : '')}</span>;
                                            })
                                            }
                                        </p>
                                    )
                                    }
                                </div>
                            </div>
                            <div className="">
                                <label className='form-label input-label'>{t('event.admissions')}</label>
                                <div>
                                    {event.admissions && (
                                        <p className='text-break d-flex row ml-0 mr-0 p-gap-03'>
                                            {event.admissions.map((admission, idx) => {
                                                return <span key={admission._id}>{`${admission.name ?? ''} ${admission.surname ?? ''} (${admission.email})` + ((idx < (event.admissions?.length ?? 1) - 1) ? ', ' : '')}</span>;
                                            })
                                            }
                                        </p>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='section-wrapper'>
                        <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                    <h4 className='step-title'>{t('step.INSCRIPTIONS')}</h4>
                                    {props.editable && (
                                        <StepStatusChip event={event} step={'INSCRIPTIONS'} />
                                    )}
                                </div>
                                {props.editable && (
                                    <Button
                                        className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                        title={t('event.edit')}
                                        onClick={() => handleEditSection("INSCRIPTIONS")}
                                    >
                                        <MdModeEdit />
                                    </Button>
                                )}
                            </div>
                            {event?.eventType === 'PRIVATE_EVENT' && <div className='row section-h-row'>
                                <div className="col d-flex justify-content-between align-items-center col-md-4">
                                    <div className='d-flex flex-column align-items-baseline'>
                                        <label className='form-label input-label'>{t('event.inscription-type')}:</label>
                                        <p className='margin-bottom-0'>{event && typeof event.inscriptionType !== 'undefined' ? (event.inscriptionType === 'SINGLE_INSCRIPTION' ? t('event.inscription.SINGLE_INSCRIPTION') : t('event.inscription.GROUPAL_INSCRIPTION')) : t("step.UNDEFINED")}</p>
                                    </div>
                                </div>
                                {
                                    event && typeof event.inscriptionType !== 'undefined' && event.inscriptionType === 'GROUPAL_INSCRIPTION' && (
                                        <>
                                            <div className="col-4 d-grid">
                                                <label className='form-label input-label'>{t('event.companions-limit')}:</label>
                                                <p className='margin-bottom-0'>{event.companionsLimit}</p>
                                            </div>
                                        </>
                                    )
                                }
                            </div>}
                            <div className='row section-h-row'>
                                {
                                    event.eventType == 'PUBLIC_EVENT' ? (
                                        <div className="col d-flex flex-column align-items-baseline">
                                            <label className='form-label input-label'>{t('event.include-dob')}: </label>
                                            <p className='margin-bottom-0'>{event && typeof event.includeDOB !== 'undefined' ? (event.includeDOB ? t("confirmation.yes") : t("confirmation.no")) : t("step.UNDEFINED")}</p>
                                        </div>
                                    ) : (
                                        <div className="col  d-flex flex-column align-items-baseline">
                                            <label className='form-label input-label'>{t('event.include-menu')}: </label>
                                            <p className='margin-bottom-0'>{event && typeof event.includeMenu !== 'undefined' ? (event.includeMenu ? t("confirmation.yes") : t("confirmation.no")) : t("step.UNDEFINED")}</p>
                                        </div>
                                    )
                                }
                                <div className="col  d-flex flex-column align-items-baseline">
                                    <label className='form-label input-label'>{t('event.include-gender')}: </label>
                                    <p className='margin-bottom-0'>{event && typeof event.includeGender !== 'undefined' ? (event.includeGender ? t("confirmation.yes") : t("confirmation.no")) : t("step.UNDEFINED")}</p>
                                </div>
                                <div className="col  d-flex flex-column align-items-baseline">
                                    <label className='form-label input-label'>{t('event.include-identification')}: </label>
                                    <p className='margin-bottom-0'>{event && typeof event.includeIdentification !== 'undefined' ? (event.includeIdentification ? t("confirmation.yes") : t("confirmation.no")) : t("step.UNDEFINED")}</p>
                                </div>
                            </div>
                            {
                                !(event.eventType == 'PUBLIC_EVENT') && 
                                <div className='row section-h-row'>
                                    <div className="col col-md-4 d-flex flex-column align-items-baseline">
                                        <label className='form-label input-label'>{t('event.include-dob')}: </label>
                                        <p className='margin-bottom-0'>{event && typeof event.includeDOB !== 'undefined' ? (event.includeDOB ? t("confirmation.yes") : t("confirmation.no")) : t("step.UNDEFINED")}</p>
                                    </div>
                                    <div className="col col-md-4 d-flex flex-column align-items-baseline">
                                        <label className='form-label input-label'>{t('event.allows-waiting')}: </label>
                                        <p className='margin-bottom-0'>{event && typeof event.allowsWaiting !== 'undefined' ? (event.allowsWaiting ? t("confirmation.yes") : t("confirmation.no")) : t("step.UNDEFINED")}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                    <section className='section-wrapper'>
                        <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                    <h4 className='step-title'>{t('step.IMAGES')}</h4>
                                    {props.editable && (
                                        <StepStatusChip event={event} step={'IMAGES'} />
                                    )}
                                </div>
                                {props.editable && (
                                    <Button
                                        className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                        title={t('event.edit')}
                                        onClick={() => handleEditSection("IMAGES")}
                                    >
                                        <MdModeEdit />
                                    </Button>
                                )}
                            </div>
                            <div>
                                <div className={`d-flex justify-content-between align-items-center }`}>
                                    {
                                        event?.imageCardSrc || event?.imageSrc
                                            ? <img className={`summary-image mt-2 ${props.editable ? 'img-editable' : ''}`} src={event?.imageCardSrc || event?.imageSrc} />
                                            : <p className='margin-0'>{t("step.UNDEFINED")}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    {props.event?.eventType === 'PUBLIC_EVENT' &&
                        <section className='section-wrapper'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                    <h4 className='step-title'>{t('step.BATCHES')}</h4>
                                    {props.editable && (
                                        <StepStatusChip event={event} step={'BATCHES'} />
                                    )}
                                </div>
                                {props.editable && (
                                    <Button
                                        className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                        title={t('event.edit')}
                                        onClick={() => handleEditSection("BATCHES")}
                                    >
                                        <MdModeEdit />
                                    </Button>
                                )}
                            </div>
                            
                            <div className='row section-h-row'>
                                <div className="col  d-flex flex-column align-items-baseline">
                                    <label className='form-label input-label'>{t('event.time-to-buy')}: </label>
                                    <p className='margin-bottom-0'>{event && typeof event.timeToBuy !== 'undefined' ? event.timeToBuy : t("step.UNDEFINED")}</p>
                                </div>
                                <div className="col  d-flex flex-column align-items-baseline">
                                    <label className='form-label input-label'>{t('event.min-age')}: </label>
                                    <p className='margin-bottom-0'>{event && typeof event.minAge !== 'undefined' ? (event.minAge) + ' '+ t('years-old') : t("step.UNDEFINED")}</p>
                                </div>
                                
                            </div>
                           
                            <div>
                                <div className={`d-flex justify-content-between align-items-center batches-container }`}>
                                    <div className=' d-flex flex-column align-items-baseline mt-2'>
                                        <label className='form-label input-label m-0 mr-2'>{t('event.is-free')} </label>
                                        <div className='d-flex row m-0 align-items-center'>
                                            <p className='margin-bottom-0 align-row-p form-label mr-2 ml-0'>{event && event.hasPayment === true ? t("confirmation.no") : event.hasPayment === false ? t("confirmation.yes") : t("step.UNDEFINED")}</p>
                                            {event && event.hasPayment === true && <label className='form-label link-text-colored' onClick={handleToggleBatches}>
                                                {showBatches ? t('event.hide-batches') : t('event.show-batches')}
                                                <IoArrowForwardOutline />
                                            </label>}
                                        </div>

                                    </div>
                                </div>

                                {showBatches && event?.batches && event?.batches.length > 0 && (
                                    <div className='mt-3'>
                                        {event.batches.map((b, i) => (
                                            <div key={i}>
                                                {!b.deleted &&
                                                    <BatchRow
                                                        i={i}
                                                        batch={b}
                                                        otherBatches={event?.batches?.filter(x => x.number !== b.number) || []}
                                                        eventId={props.event?._id}
                                                        canEdit={false}
                                                        batchesAvailability={batchesAvailability}
                                                    ></BatchRow>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                        </section>
                    }
                    {
                        event.eventType == 'PRIVATE_EVENT' && (
                            <>
                                <section className='section-wrapper'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                            <h4 className='step-title'>{t('step.QUESTIONS')}</h4>
                                            {props.editable && (
                                                <StepStatusChip event={event} step={'QUESTIONS'} />
                                            )}
                                        </div>
                                        {props.editable && (
                                            <Button
                                                className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                                title={t('event.edit')}
                                                onClick={() => handleEditSection("QUESTIONS")}
                                            >
                                                <MdModeEdit />
                                            </Button>
                                        )}
                                    </div>

                                    {event?.hasQuestions !== 'yes' && (
                                        <div className={`d-flex justify-content-between align-items-center }`}>
                                            <div className='d-flex flex-column align-items-baseline'>
                                                <label className='form-label input-label'>{t('event.include-question')}: </label>
                                                <p className='margin-0 mb-0'>{event && event.hasQuestions === 'no' ? t("confirmation.no") : t("step.UNDEFINED")}</p>
                                            </div>
                                        </div>
                                    )}

                                    {event?.hasQuestions && (
                                        <div className='mt-3'>
                                            <>
                                                {event?.questions?.map((question, i) => (
                                                    <div key={i}>
                                                        <QuestionRow
                                                            i={i}
                                                            question={question}

                                                            canEdit={false}
                                                        ></QuestionRow>
                                                    </div>
                                                ))}
                                            </>
                                        </div>
                                    )}

                                </section>
                                <section className='section-wrapper'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                            <h4 className='step-title'>{t('step.SECTORS')}</h4>
                                            {props.editable && (
                                                <StepStatusChip event={event} step={'SECTORS'} />
                                            )}
                                        </div>
                                        {props.editable && (
                                            <Button
                                                className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                                title={t('event.edit')}
                                                onClick={() => handleEditSection("SECTORS")}
                                            >
                                                <MdModeEdit />
                                            </Button>
                                        )}
                                    </div>
                                    <div>
                                        <div className={`d-flex justify-content-between align-items-center sectors-container }`}>
                                            <div className=' d-flex flex-column align-items-baseline mt-2'>
                                                <label className='form-label input-label m-0 mr-2'>{t('event.include-sector')} </label>
                                                <div className='d-flex row m-0 align-items-center'>
                                                    <p className='margin-bottom-0 align-row-p form-label mr-2 ml-0'>{event && event.hasSectors === 'yes' ? t("confirmation.yes") : event.hasSectors === 'no' ? t("confirmation.no") : t("step.UNDEFINED")}</p>
                                                    {event && event.hasSectors === 'yes' && <label className='form-label link-text-colored' onClick={handleToggleSectors}>
                                                        {showSectors ? t('event.hide-sectors') : t('event.show-sectors')}
                                                        <IoArrowForwardOutline />
                                                    </label>}
                                                </div>

                                            </div>
                                        </div>

                                        {showSectors && event?.sectors && event?.sectors.length > 0 && (
                                            <div className='mt-3'>
                                                {event.sectors.map((b, i) => (
                                                    <div key={i}>
                                                        {!b.deleted && (
                                                            <SectorRow
                                                                i={i}
                                                                sector={b}
                                                                eventId={props.event?._id}
                                                                canEdit={false}
                                                                sectorsAvailability={[]}
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                </section>
                                <section className='section-wrapper'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                            <h4 className='step-title'>{t('step.FEEDBACK')}</h4>
                                            {!userLoggedIsHost && (
                                                <StepStatusChip event={event} step={'FEEDBACK'} />
                                            )}
                                        </div>
                                        {!userLoggedIsHost && (
                                            <Button
                                                className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                                title={t('event.edit')}
                                                onClick={() => handleEditSection("FEEDBACK")}
                                            >
                                                <MdModeEdit />
                                            </Button>
                                        )}
                                    </div>
                                    {event?.hasFeedbackQuestions === 'yes' && (
                                        <div className="d-flex flex-column flex-md-row">
                                            <div className=" d-flex flex-column align-items-baseline col-12 col-md-4 p-0">
                                                <label className='form-label input-label'>{t('date-limit-feedback')}: </label>
                                                <p className='margin-bottom-0'>{event.feedbackLimitDT ? DateHelper.dateAndDayLong(event.feedbackLimitDT) : ''}</p>
                                            </div>
                                            <div className=" d-flex flex-column align-items-baseline col-12 col-md-4 p-0">
                                                <label className='form-label input-label'>{t('time-limit-feedback')}: </label>
                                                <p className='margin-bottom-0'>{event.feedbackLimitHour}</p>
                                            </div>
                                        </div>
                                    )}
                                    <div className="d-flex flex-colum">
                                        {event?.hasFeedbackQuestions !== 'yes' && <div className="d-flex flex-column align-items-baseline col-4 p-0">
                                            <label className='form-label input-label'>{t('event.include-feedback-question')}: </label>
                                            <p className='margin-bottom-0'>{event && event.hasFeedbackQuestions === 'yes' ? t("confirmation.yes") : (event.hasFeedbackQuestions === 'no' ? t("confirmation.no") : t("step.UNDEFINED"))}</p>
                                        </div>}
                                    </div>
                                    {event?.hasFeedbackQuestions && (
                                        <div className='mt-3'>
                                            <>
                                                {event?.feedbackQuestions?.map((question, i) => (
                                                    <div className='' key={i}>
                                                        <QuestionRow
                                                            i={i}
                                                            question={question}
                                                            canEdit={false}
                                                        ></QuestionRow>
                                                    </div>
                                                ))}
                                            </>
                                        </div>
                                    )}
                                </section>
                            </>
                        )
                    }
                    <section className='section-wrapper'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex flex-column flex-md-row align-items-baseline align-md-items-center m-0'>
                                <h4 className='step-title'>{t('step.REMINDER')}</h4>
                                {props.editable && (
                                    <StepStatusChip event={event} step={'REMINDER'} />
                                )}
                            </div>
                            {props.editable && (
                                <Button
                                    className="d-md-flex rounded-button rounded-button-ok outline mr-md-2"
                                    title={t('event.edit')}
                                    onClick={() => handleEditSection("REMINDER")}
                                >
                                    <MdModeEdit />
                                </Button>
                            )}
                        </div>
                        <div>
                            <div className={`d-flex justify-content-between align-items-center sectors-container }`}>
                                <div className=' d-flex flex-column align-items-baseline'>
                                    <label className='form-label input-label'>{t('event.reminder')}: </label>
                                    <p className='margin-bottom-0 '>{event && event.hasReminder === 'yes' ? t("confirmation.yes") : event.hasReminder !== 'none' ? t("confirmation.no") : t("step.UNDEFINED")}</p>
                                    {!!event && event.hasReminder === 'yes' &&

                                        <label className='link-text-underlined' onClick={loadPreview}>{t('preview')}</label>}

                                </div>
                            </div>

                        </div>

                    </section>
                </div>
                {!isPublished && !!event && <div className="d-flex d-md-none justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                    <Button
                        onClick={(e: any) => setShowDeleteModal(true)}
                        className="button-cancel-outlined w-100"
                    >
                        {t('event.delete')}
                    </Button>
                </div>}
                {!isPublished && <div className="d-flex d-md-none justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                    <Button
                        disabled={!allStepsComplete} onClick={handlePublishEvent}
                        className="button-secondary btn button-primary w-100"
                    >
                        {t('event.publish')}
                    </Button>
                </div>}
                {(isPublished && SHOW_UNDO_BUTTON) && <div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                    <Button
                        onClick={handleUnPublishEvent}
                        className="button-ok btn btn-primary w-100"
                    >
                        {t('undo') + ' ' + t('event.publish')}
                    </Button>
                </div>}

                {showSuccessModal && (
                    <CustomModal
                        title={t('success.event-publish')}
                        message={t('success.event-create-copy-link')}
                        isShowing={showSuccessModal}
                        parentCallback={handleCloseSuccessModal}
                        type={'SUCCESS'}
                        buttonOK={t('copy-inscription-link')}
                        buttonCancel={t('close')}
                    />
                )}
                {!!event && showDeleteModal && (
                    <CustomModal
                        isShowing={showDeleteModal}
                        parentCallback={handleCloseDeleteModal}
                        message={event.inscriptions && event.inscriptions.length > 0 ? t('event.delete-message-with-inscriptions') : t('event.delete-message')}
                        title={t('event.delete-title')}
                        buttonCancel={t('cancel')}
                        buttonOK={t('delete')}
                        type="WARNING_DELETE"
                    />
                )}
                {showSuccessModalDelete && (
                    <CustomModal
                        isShowing={showSuccessModalDelete}
                        parentCallback={handleCloseSuccessDeleteModal}
                        title={t('success.modal-title')}
                        message={successMessage}
                        type="SUCCESS"
                        buttonOK={t('accept')}
                    />
                )}
                {showEmailPreview && <EmailModal html={html} title={t('event.reminder')} onHide={onHidePreviewModal} visible={showEmailPreview}></EmailModal>}

                {showLinkCopiedModal && (
                    <CustomModal
                        isShowing={showLinkCopiedModal}
                        parentCallback={handleCloseLinkCopiedModal}
                        message={t('link-copied-success-message')}
                        title={t('link-copied-success-title')}
                        type="SUCCESS"
                        buttonOK={t('accept')}
                    />
                )}
                {showErrorModal && (
                    <CustomModal
                        isShowing={showErrorModal}
                        parentCallback={handleCloseErrorModal}
                        message={errorModalMessage}
                        title={t('error.modal-title-oops')}
                        buttonOK={t('accept')}
                        type="ERROR"
                    />)}
                {showEventPreviewModal && (
                    <EventCardModal
                        event={event}
                        onHide={() => setShowEventPreviewModal(false)}
                        visible={showEventPreviewModal}
                    />)}

            </>}
        </div>
    )
}

export default stepSummary;