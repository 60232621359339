import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Tabs, Tab, Dropdown, Button } from 'react-bootstrap';
import { IoMdMore } from 'react-icons/io';
import useApi from '../../hooks/useApi';
import useEventsApi from '../../hooks/useEventsApi';
import CustomModal from '../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../components/loader/useLoader';
import { SHOW_SECTORS, SHOW_QUESTIONS } from '../../../constants';
import { TInscriptionStatus } from '../../models/types/types';
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import InscriptionsMenuReportDynamicPDF from '../../reports/menuReportDynamic';
import { getEventInscriptionURL, shortenUrl } from '../../helpers/events-helper';
import { exportAnswersToExcel, exportInscriptionsToExcel } from '../../helpers/excel-report-helper';
import SectorsManager from '../../sectors/sectorsManager';
import SectorsReportPDF from '../../reports/sectorsReport';
import QuestionsSummary from '../../questions/questionsSummary';
import InscriptionsManager from '../../inscriptions/manager/inscriptionsManager';
import { getFirstUncompletedStepName, getRemainingTimeAndDateStatus } from '../../events/form/utils';
import InscriptionLinkModal from '../../components/modals/inscriptionLinkModal';
import NoResults from '../../components/no-results/noResults';
import { IoArrowBackOutline } from 'react-icons/io5';

interface EventManagerProps {
	eventId: string, isEditable: boolean, status?: TInscriptionStatus, preventEdit?: boolean
}
export function EventManager(props: EventManagerProps) {
	const lang: string = localStorage.getItem("i18nextLng") || 'en';
	//const [key, setKey] = useState<any>(props.details ? 'info' : 'invitations');
	const [key, setKey] = useState<any>('invitations');
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [loader, showLoader, hideLoader] = useLoader();
	const { t, i18n } = useTranslation();
	const [eventId, setEventId] = useState<string | undefined>(undefined);
	const [event, setEvent] = useState<any | undefined>(undefined);
	const [hasSector, setHasSector] = useState<string | undefined>();
	const [hasQuestion, setHasQuestion] = useState<string | undefined>();
	const [hasFeedbackQuestion, setHasFeedbackQuestion] = useState<string | undefined>();
	const [inscriptionsBySector, setInscriptionsBySector] = useState<any[]>([]);
	const [inscriptionsAnswersReport, setInscriptionsAnswersReport] = useState<any>();
	const [inscriptionsFeedbackAnswersReport, setInscriptionsFeedbackAnswersReport] = useState<any>();
	// Date
	const [showCopyLinkModal, setShowCopyLinkModal] = useState<boolean>(false);
	
	const [includeMenu, setIncludeMenu] = useState<string>('no');
	
	const [editable, setEditable] = useState<boolean>(props.isEditable && !props.preventEdit);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showConfirmationReminderModal, setShowConfirmationReminderModal] = useState(false);
	const [showConfirmationFeedbackModal, setShowConfirmationFeedbackModal] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [notifyInscriptions, setNotifyInscriptions] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [validated, setValidated] = useState(false);
	const [isHost, setIsHost] = useState(undefined);
	const [goToEvents, setGoToEvents] = useState<boolean>(true);
	const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);
	const [isPastEvent, setIsPastEvent] = useState<boolean>(false);
	const [eventStatus, setEventStatus] = useState<string|undefined>();
	const [inscriptionLink, setInscriptionLink] = useState<any>(undefined);
	
	const dispatch = useDispatch();
	const history = useHistory();

	const today = new Date();
	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	let maxDate = new Date();

	maxDate.setDate(today.getDate() + 700);
	const [
		loggedUser,
	] = useApi();
	const [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
		editEventNotifyInscriptions,
		getBatchesAvailability,
		checkTicketsAvailability,
		getSectorsAvailability,
		getInscriptionsBySector,
		sendEventReminder,
		sendEventFeedback
	] = useEventsApi();

	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions,
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
		updateInscriptionSector,
		getPublicAcceptedInscriptions,
		getReportBilling,
		getInscriptionsAnswers,
		getInscriptionsFeedbackAnswers
	] = useInscriptionsApi();


	const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	
	
	const handleSendReminder = () => {
		if (!!eventId) {
			setShowConfirmationReminderModal(true);
		}

	}
	const handleSendFeedback = () => {
		if (!!eventId) {
			setShowConfirmationFeedbackModal(true);
		}

	}

	const onConfirmSendReminder = async (confirm: boolean) => {
		setShowConfirmationReminderModal(false);
		if (confirm) {
			sendReminder();
		}
	}
	const onConfirmSendFeedback = async (confirm: boolean) => {
		setShowConfirmationFeedbackModal(false);
		if (confirm) {
			sendFeedback();
		}
	}
	const sendFeedback = async () => {
		try {
			showLoader();
			if (!!eventId) {
				const email = await sendEventFeedback(eventId);
				if (!!email) {
					setGoToEvents(false);
					setSuccessMessage(i18n.t('event.success-email-feedback'));
					setShowSuccessModal(true);
				} else {
					setErrorModalMessage(t('event.error-email-feedback'));
					setShowErrorModal(true);
				}
			}
		}
		catch (e) {
			setErrorModalMessage(t('event.error-email-feeedback'));
			setShowErrorModal(true);
		}
		finally {
			hideLoader();
		}

	}
	const sendReminder = async () => {
		try {
			showLoader();
			if (!!eventId) {
				const email = await sendEventReminder(eventId);
				if (!!email) {
					setGoToEvents(false);
					setSuccessMessage(i18n.t('event.success-email-reminder'));
					setShowSuccessModal(true);
				} else {
					setErrorModalMessage(t('event.error-email-reminder'));
					setShowErrorModal(true);
				}
			}
		}
		catch (e) {
			setErrorModalMessage(t('event.error-email-reminder'));
			setShowErrorModal(true);
		}
		finally {
			hideLoader();
		}

	}

	
	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		history.goBack();
	};
	const handleCloseErrorModal = () => {
		setErrorModalMessage('');
		setShowErrorModal(false);
	}

	const handleCloseDeleteModal = async (e: any) => {
		setShowDeleteModal(false);
		if (!!e) {
			if (eventId) {
				// await dispatch(deleteEvent({ id: eventId }));
				// history.push('/events');
				showLoader();
				const deletedObj: any = await dispatch(updateEvent({
					_id: eventId,
					lastUpdatedBy: loggedUser._id,
					lastUpdatedDT: new Date(),
					deletedBy: loggedUser._id,
					deletedDT: new Date()
				}));
				hideLoader();

				if (deletedObj && deletedObj['payload']) {
					if (deletedObj['payload']['removed'] == true || deletedObj['payload']['removed'] == 'true') {
						if (deletedObj['payload']['inscriptions'] == true || deletedObj['payload']['inscriptions'] == 'true') {
							setSuccessMessage(i18n.t('event.success-notifications-with-inscriptions'));
							setGoToEvents(true);
							setShowSuccessModal(true);
						} else {
							setGoToEvents(true);
							setSuccessMessage(" ");
							setShowSuccessModal(true);
						}
					} else {
						setErrorModalMessage(t('event.error-delete'));
						setShowErrorModal(true);
					}
				}
			}
		}

	};

	const handleCloseSuccessModal = (e: any) => {
		if (!!e) {
			if (goToEvents) {
				if (successMessage != "") {
					history.push('/events');
				} else {
					//window.location.reload();
					history.push('/events');
				}
			}
		}
		setShowSuccessModal(false);
	};
	const handleCloseLinkCopiedModal = () => {
		setShowLinkCopiedModal(false);
	};
	useEffect(() => {
		handleEventId();
	}, [loggedUser]);

	useEffect(() => {
		handleKeyChange(key);
	}, [key, event]);

	const handleKeyChange = async (k: string) => {
		showLoader();
		
		if (k === 'invitations' && !!event) {
			// if (SHOW_SECTORS) {
			// 	const response: any = await getSectorsAvailability(props.eventId);
			// 	if (!response.error && response?.sectorsAvailability) {
			// 		setSectorsAvailability(response.sectorsAvailability);
			// 	}
			// }
		}
	
			if ( k === 'sectors' && SHOW_SECTORS) {
				const response = await getInscriptionsBySector(props.eventId);
				setInscriptionsBySector([]);

				setInscriptionsBySector(response);
			}
			if (k === 'questions' && SHOW_QUESTIONS) {
				const response = await getInscriptionsAnswers(props.eventId);
				setInscriptionsAnswersReport(undefined);

				setInscriptionsAnswersReport(response);
			}
			if (k === 'feedbackQuestions' && SHOW_QUESTIONS) {
				const response = await getInscriptionsFeedbackAnswers(props.eventId);
				setInscriptionsFeedbackAnswersReport(undefined);

				setInscriptionsFeedbackAnswersReport(response);
			}
		
		hideLoader();
	};

	const handleEventId = async () => {
		showLoader();
		if (loggedUser && isLoggedUser && !event) {

			const ev: any = await getEventById(props.eventId);
			if (ev && ev['_id']) {
				// if (ev.eventType === 'PUBLIC_EVENT') {
				// 	location.href = window.location.href.replace('/events/', '/events-public/');
				// }
				setEvent(ev);
				
				setEventId(ev['_id']);
				const _inscriptionLink = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL) +
					getEventInscriptionURL(ev);
				const shortURL = await shortenUrl(_inscriptionLink);
				setInscriptionLink(
					shortURL
				);
				setIncludeMenu(ev.includeMenu === true ? 'yes' : 'no');
				
				let sectorsCap = 0;
				if (!!ev.sectors) {
					sectorsCap = ev.sectors?.reduce((acumulador: any, actual: any) => acumulador + (actual.capacity || 0), 0);
				}
				
				setHasSector((ev.sectors?.length > 0 && SHOW_SECTORS) ? "yes" : "no");
				setHasQuestion((ev.questions?.length > 0 && SHOW_QUESTIONS) ? "yes" : "no");
				setHasFeedbackQuestion((ev.feedbackQuestions?.length > 0 && SHOW_QUESTIONS) ? "yes" : "no");
				
				const isHost_ =
					loggedUser['isHost'] ||
					(!loggedUser['isOrganizer'] &&
						ev.planners.filter((x: any) => x._id == loggedUser["_id"]).length == 0 &&
						ev.hosts.filter((x: any) => x._id == loggedUser['_id'])
							.length > 0);
				await setIsHost(isHost_);
				await setEditable(props.isEditable && !isHost_ && !props.preventEdit);
				const rt = getRemainingTimeAndDateStatus(ev);
				setIsPastEvent(rt.dateStatus === "ended");
				setEventStatus(ev.status === "DRAFT" ? "DRAFT" : 
					((rt.dateStatus === "ended") ? 'ENDED' : 
						(rt.dateStatus === "now" ? 'NOW': 
							ev.status)));
			}
		}
		hideLoader();
	};
	
	const loadReportInscriptions = async () => {
		if (!!event && !!event._id) {
			return await getReportInscriptions(event._id);
		}
	};
	

	const loadReportMenuDynamic = async (attendeed: boolean) => {
		if (!!event && !!event._id) {
			return await getReportMenuDynamic(event._id, attendeed);
		}
	};
	const loadReportPastEventInscriptions = async () => {
		if (!!event && !!event._id) {
			return await getReportPastEventInscriptions(event._id);
		}
	};
	
	const handleOnReportSectors = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			const response = await getInscriptionsBySector(event._id);
			if (!!response && response.length > 0) {
				await SectorsReportPDF(event, response);

			} else {
				setErrorModalMessage(t('event.error-report-sector'));
				setShowErrorModal(true);
			}
			hideLoader();
		}
	}

	const handleOnExportFeedbackQuestions = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			if (!!event && !!event._id) {
				const response = await getInscriptionsFeedbackAnswers(event._id);
				if (!!response && response.report.length > 0) {
					exportAnswersToExcel(response, event, 'FEEDBACK');
				} else {
					setErrorModalMessage(t('event.error-report-question-feedback'));
					setShowErrorModal(true);
				}
			}
			hideLoader();
		}
	}

	const handleOnExportQuestions = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			if (!!event && !!event._id) {
				const response = await getInscriptionsAnswers(event._id);
				if (!!response && response.report.length > 0) {
					exportAnswersToExcel(response, event, 'QUESTIONS');
				} else {
					setErrorModalMessage(t('event.error-report-question-feedback'));
					setShowErrorModal(true);
				}
			}
			hideLoader();
		}
	}

	const handleOnMenuReport = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			const reportMenuDynamic = await loadReportMenuDynamic(!props.isEditable);
			await InscriptionsMenuReportDynamicPDF(event, reportMenuDynamic);

			hideLoader();
		}
	}
	const goTo = (route: string) => {
		history.push(route);
	}
	
	const handleCopyLink = async (e: any) => {
		e.stopPropagation();
		e.preventDefault();

		navigator.clipboard.writeText(
			inscriptionLink
		).then(() => {
			if (props.isEditable || (isHost && props.isEditable)) {
				setShowLinkCopiedModal(true);
			}
		})
	};

	const handleOnExport = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            const reportInscriptionsResult = !isPastEvent ? await loadReportInscriptions() : await loadReportPastEventInscriptions();
            if (!!event) {
                exportInscriptionsToExcel(event, reportInscriptionsResult, isPastEvent);
            }
            hideLoader();
        }
    }
	const goToEditEvent = ()=>{
		if (!!event &&!!event._id) {
			if (event.eventType === 'PRIVATE_EVENT') {
				if (event.steps?.length > 0) {
					if (event.status === 'PUBLISHED') {
						history.push('/events/edit-draft/' + event._id + '/INFO');
					} else {
						history.push('/events/edit-draft/' + event._id + '/' + getFirstUncompletedStepName(event.steps));

					}
				} else {
					history.push('/events/edit/' + event._id + '/true');
				}

			} else {
				//history.push('/events-public/edit/' + props.event._id + '/true');
				history.push('/events/edit-public-draft/' + event._id + '/' + getFirstUncompletedStepName(event.steps));
			}

		}
	}

	const handleViewEvent = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		if (event && event._id) {

			if (event.eventType === 'PRIVATE_EVENT') {
				history.push('/events/edit-draft/' + event._id + '/SUMMARY');
			} else {
				history.push('/events/edit-public-draft/' + event._id + '/SUMMARY');
			}
		}
	};
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{loader}
			{(!isLoggedUser) && <Redirect to="/login" />}
			{event && event.deletedBy && <Redirect to="/events" />}
			{showCopyLinkModal && event &&
                <InscriptionLinkModal
                    event={event}
                    onHide={(e: boolean) => { setShowCopyLinkModal(false); setShowLinkCopiedModal(e); }}
                    visible={showCopyLinkModal}
                ></InscriptionLinkModal>}
			{showConfirmationReminderModal && (
				<CustomModal
					buttonCancel={t('confirmation.no')}
					buttonOK={t('confirmation.yes')}
					isShowing={showConfirmationReminderModal}
					title={t('confirm')}
					message={t('event.send-reminder-confirmation')}
					parentCallback={onConfirmSendReminder}
					type="WARNING_EDIT"
				></CustomModal>
			)}
			{showConfirmationFeedbackModal && (
				<CustomModal
					buttonCancel={t('confirmation.no')}
					buttonOK={t('confirmation.yes')}
					isShowing={showConfirmationFeedbackModal}
					title={t('confirm')}
					message={t('event.send-feedback-confirmation')}
					parentCallback={onConfirmSendFeedback}
					type="WARNING_EDIT"
				></CustomModal>
			)}
			{isLoggedUser &&
				loggedUser &&
				!!event &&
				isHost != undefined && (
					<div className="dashboard-content form">
						<div className='container-box' >
							<div className='container-box-content d-flex justify-content-between align-items-center'>
								<div className='d-flex '>
									<div
										className="button-back mr-2"
										onClick={(e: any) => handleOnCancel(e)}
									>
										<IoArrowBackOutline />
									</div>
									<div className='ml-4 mr-4'>
										<div className='banner-title d-flex align-items-center'><p className='one-line'>{t('event.manage-inscriptions')}</p></div>
										<div className='banner-subtitle'>{event.name}</div>
									</div>

								</div>
								<div className='d-flex row align-items-center justify-content-end'>
									{!!event && eventStatus === "DRAFT" && <div className="d-none d-md-flex justify-content-center justify-content-md-end align-items-center">
										<Button
											// disabled={!allStepsComplete || isDirty} onClick={handlePublishEvent}
											className="button button-primary medium w-100"
										>
											{t('event.publish')}
										</Button>
									</div>}
									{!!event &&
									<Dropdown>
										<Dropdown.Toggle bsPrefix="event-edit-menu" className="only-icon black">
											<IoMdMore size={28} />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{!isPastEvent && eventStatus !== "DRAFT" && (editable ? <Dropdown.Item onClick={(e) => goToEditEvent()}>{t('event.edit')}</Dropdown.Item> : <Dropdown.Item onClick={(e) => handleViewEvent(e)}>{t('event.view')}</Dropdown.Item>)}
											{!isPastEvent && eventStatus !== "NOW" && <Dropdown.Item onClick={(e: any) => setShowCopyLinkModal(true)}>{t('event.inscription-link')}</Dropdown.Item>}
											{event.includeMenu && <Dropdown.Item onClick={(e: any) => handleOnMenuReport()}>{t('event.edit-print-menu')}</Dropdown.Item>}
											{event.hasSectors === 'yes' && SHOW_SECTORS && <Dropdown.Item onClick={(e: any) => handleOnReportSectors()}>{t('event.sector.print')}</Dropdown.Item>}
											<Dropdown.Item onClick={(e: any) => handleOnExport()}>{t('event.export-list')}</Dropdown.Item>
											{event.hasQuestions === 'yes' && SHOW_QUESTIONS && <Dropdown.Item onClick={(e: any) => handleOnExportQuestions()}>{t('event.question.export-answers')}</Dropdown.Item>}
											{!!event.inscriptions && !isPastEvent && event.inscriptions.filter((x: any) => x.inscriptionStatus === 'ACCEPTED').length > 0 && <Dropdown.Item onClick={(e: any) => handleSendReminder()}>{t('event.send-reminder')}</Dropdown.Item>}
											{!!event.inscriptions && event.hasFeedbackQuestions === 'yes' && <Dropdown.Item onClick={(e: any) => handleSendFeedback()}>{t('event.send-feedback-link')}</Dropdown.Item>}
											{editable && <Dropdown.Item onClick={(e: any) => setShowDeleteModal(true)}>{t('event.delete')}</Dropdown.Item>}
										</Dropdown.Menu>
									</Dropdown>}
								</div>
							</div>
						</div>
						<Form
							className="form-container event-manager"
							noValidate
							validated={validated}
							action="/events"
						>
							<div className="form-info-container mt-5">
								<Tabs
									defaultActiveKey={'invitations'}
									id="event-tabs"
									transition={false}
									className="custom-tabs"
									activeKey={key}
									onSelect={(k) => setKey(k)}
								>
									
									<Tab
										className="custom-tab"
										eventKey="invitations"
										title={t('invites')}
									>
										{key == 'invitations'
											&& (
												<InscriptionsManager
												event={event}
													isPublic={event.eventType === 'PUBLIC_EVENT'}
													includeMenu={event.includeMenu}
													eventId={event._id}
													canEdit={props.isEditable}
													inscriptionType={event.inscriptionType}
													allInscriptions={event.inscriptions}
													statusTab={props.status}
													eventType={event.eventType}
													eventMaxCapacity={event.maxCapacity}
													includeSector={hasSector === 'yes' && SHOW_SECTORS}
												/>
											)}
									</Tab>
									{hasSector === 'yes' && SHOW_SECTORS && <Tab
										className="custom-tab"
										eventKey="sectors"
										title={t('event.sectors')}
									>
										{key == 'sectors' && !!inscriptionsBySector && inscriptionsBySector.length > 0
											? (
												<SectorsManager eventId={event._id}
													inscriptionsBySector={inscriptionsBySector}
													includeGender={event.includeGender}
													includeDOB={event.includeDOB}
													includeMenu={includeMenu === 'yes'}></SectorsManager>

											)
											:<div className="align-items-center">
												<NoResults text={t('inscriptions-not-found')} />
											</div>
										}
									</Tab>}
									{hasQuestion === 'yes' && SHOW_QUESTIONS && <Tab
										className="custom-tab"
										eventKey="questions"
										title={t('event.questions')}
									>
										{key == 'questions' && event.inscriptions?.length > 0 && !!event.questions && event.questions.length > 0 && !!inscriptionsAnswersReport
											? (
												<QuestionsSummary eventId={event._id}
													event={event}
													questions={event.questions}
													data={inscriptionsAnswersReport}
													includeMenu={includeMenu === 'yes'}></QuestionsSummary>

											)
											: <div className="align-items-center">
												<NoResults text={t('inscriptions-not-found')} />
											</div>
										}
									</Tab>}
									{/* TODO feedback tab */}
									{/* hasFeedbackQuestion === 'yes' && !editable && SHOW_QUESTIONS && <Tab
										className="custom-tab"
										eventKey="feedbackQuestions"
										title={t('event.feedback-questions')}
									>
										{key == 'feedbackQuestions' && !!event.feedbackQuestions && event.feedbackQuestions.length > 0 && !!inscriptionsFeedbackAnswersReport
											&& (
												<QuestionsSummary eventId={event._id}
													questions={event.feedbackQuestions}
													event={event}
													data={inscriptionsFeedbackAnswersReport}
													includeMenu={false}></QuestionsSummary>

											)}
									</Tab>*/}
								</Tabs>
							</div>
						</Form>



						{showDeleteModal && (
							<CustomModal
								isShowing={showDeleteModal}
								parentCallback={handleCloseDeleteModal}
								message={event.inscriptions && event.inscriptions.length > 0 ? t('event.delete-message-with-inscriptions') : t('event.delete-message')}
								title={t('event.delete-title')}
								buttonCancel={t('cancel')}
								buttonOK={t('delete')}
								type="WARNING_DELETE"
							/>
						)}
						{showSuccessModal && !goToEvents && (
							<CustomModal
								isShowing={showSuccessModal}
								parentCallback={handleCloseSuccessModal}
								title={successMessage}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}
						{showSuccessModal && goToEvents === true && (
							<CustomModal
								isShowing={showSuccessModal}
								parentCallback={handleCloseSuccessModal}
								message={successMessage}
								title={(successMessage != '' && !notifyInscriptions) ? t('event.success-delete') : t('success.event-update')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}
						{showLinkCopiedModal && (
							<CustomModal
								isShowing={showLinkCopiedModal}
								parentCallback={handleCloseLinkCopiedModal}
								message={t('link-copied-success-message')}
								title={t('link-copied-success-title')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}

						{showErrorModal && (
							<CustomModal
								isShowing={showErrorModal}
								parentCallback={handleCloseErrorModal}
								message={errorModalMessage}
								title={t('error.modal-title-oops')}
								buttonOK={t('accept')}
								type="ERROR"
							/>
						)}
						
						
					</div>
				)}
		</>
	);
}

export default EventManager;
