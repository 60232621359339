import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';


import useEventsApi from '../../../hooks/useEventsApi';

import CustomModal from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/loader/useLoader';
import { IEvent } from '../../../models/interfaces/events.interface';
import { StepProps, getBooleanValue, getSelectSingleOptions, getYesNoValue } from '../utils';
import { EVENT_COMPANIONS_MAX, EVENT_COMPANIONS_MIN, INSCRIPTION_TYPES, YES_NO } from '../../../../constants';
import { TCommunicationType, TInscriptionType } from '../../../models/types/types';

export function StepInscriptions(props: StepProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();


    const [inscriptionType, setInscriptionType] = useState<TInscriptionType | undefined>();
    const [companionsLimit, setCompanionsLimit] = useState<number | undefined>(undefined);
    const [minEventCompanionsLimit, setMinEventCompanionsLimit] = useState<number>(1);

    const [includeMenu, setIncludeMenu] = useState<string | undefined>();

    const [allowsWaiting, setAllowsWaiting] = useState<string | undefined>();
    const [includeGender, setIncludeGender] = useState<string | undefined>();
    const [includeDOB, setIncludeDOB] = useState<string | undefined>();
    const [communicationType, setCommunicationType] = useState<TCommunicationType>('EMAIL');

    const [includeIdentification, setIncludeIdentification] = useState<string | undefined>();

    const inscriptionTypes = getSelectSingleOptions(INSCRIPTION_TYPES, 'event.inscription.');
    const includeMenuOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
    const includeIdentificationOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
    const includeGenderOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
    const includeDOBOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
    const allowsWaitingOptions = getSelectSingleOptions(YES_NO, 'confirmation.');

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const [isDirty, setIsDirty] = useState(false);

    const dispatch = useDispatch();
    const [
        createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getInscriptionsAvailability,
        getInscriptionsBySector,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent
    ] = useEventsApi();


    let subStepsTotal = props.event?.eventType === 'PUBLIC_EVENT' ? 2 : 6;

    const validateAll = (form: any) => {
        return (
            form.checkValidity());
    };

    const getInscriptionType = (): TInscriptionType | undefined => {
        if (props.event?.eventType === 'PUBLIC_EVENT') {
            if (props.event?.hasPayment === false) {
                return 'SINGLE_INSCRIPTION';
            } else if (!!props.event?.batches && props.event.batches.length > 0) {
                return props.event?.batches?.filter(b => !b.deleted && !!b.batchLimit && b.batchLimit > 1).length > 0 ? 'GROUPAL_INSCRIPTION' : 'SINGLE_INSCRIPTION';
            } else {
                return 'SINGLE_INSCRIPTION';
            }
        } else {
            return inscriptionType;
        }

    }
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            try {
                showLoader();

                const auxInscType = getInscriptionType();
                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    inscriptionType: auxInscType,
                    companionsLimit: companionsLimit,
                    includeMenu: getBooleanValue(includeMenu),
                    communication: communicationType,
                    includeGender: getBooleanValue(includeGender),
                    includeDOB: getBooleanValue(includeDOB),
                    includeIdentification: getBooleanValue(includeIdentification),
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date(),
                    allowsWaiting: getBooleanValue(allowsWaiting)
                };
                let subSteps = 0;

                if (!!auxInscType && props.event?.eventType === 'PRIVATE_EVENT') {
                    subSteps += 1;
                    if (auxInscType === 'GROUPAL_INSCRIPTION') {

                        subStepsTotal = 7;
                        if (!!companionsLimit) {
                            subSteps += 1;
                        }
                    }
                }

                if (!(props.event?.eventType === 'PUBLIC_EVENT') && !!includeMenu) {
                    subSteps += 1;
                }

                if (!(props.event?.eventType === 'PUBLIC_EVENT') && !!includeDOB) {
                    subSteps += 1;
                }

                if (!!includeGender) {
                    subSteps += 1;
                }

                if (!(props.event?.eventType === 'PUBLIC_EVENT') && !!allowsWaiting) {
                    subSteps += 1;
                }

                if (!!includeIdentification) {
                    subSteps += 1;
                }

                if (!!evt.steps) {
                    const stepIdx = evt.steps?.findIndex(s => s.name === 'INSCRIPTIONS');
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = (subSteps / subStepsTotal) * 100;
                    }
                }
                const stepIdx = evt.steps?.findIndex(s => s.name === 'INSCRIPTIONS');
                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    hideLoader();
                    props.saveAndGoToStep(response.payload, 'IMAGES');

                } else {
                    hideLoader();
                    console.log('fallo el edit draft insc')
                }

            }
            catch (error: any) {
                setErrorModalMessage(t('event.error-delete'));
                setShowErrorModal(true);
            } finally {
                hideLoader();
            }
        }
        if (isDirty) {
            setValidated(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    useEffect(() => {

        props.notifyIsDirty(isDirty);

    }, [isDirty]);


    const handleEvent = async () => {
        showLoader();
        if (props.loggedUser && !!props.event) {

            setInscriptionType(props.event.inscriptionType);
            if (props.event.companionsLimit) {
                setCompanionsLimit(props.event.companionsLimit);

            }
            setMinEventCompanionsLimit(!!props.event?.inscriptions && props.event.inscriptions.length > 0 ? (props.event.companionsLimit || EVENT_COMPANIONS_MIN) : EVENT_COMPANIONS_MIN);

            setIncludeMenu(getYesNoValue(props.event.includeMenu));
            setIncludeDOB(getYesNoValue(props.event.includeDOB));
            setAllowsWaiting(getYesNoValue(props.event.allowsWaiting));
            setIncludeIdentification(getYesNoValue(props.event.includeIdentification));
            setIncludeGender(getYesNoValue(props.event.includeGender));

        }
        hideLoader();
    };

    const handleOnSelectInscriptionType = (event: any) => {
        const r = !!inscriptionTypes ? inscriptionTypes.find((x) => x.name == event.name) : undefined;
        if (!!r && r.name) {
            setInscriptionType(r.name);
        } else {
            setInscriptionType(undefined);
        }
        setIsDirty(true);
    };
    const handleOnChangeCompanionsLimit = (e: any) => {
        setCompanionsLimit(e?.target.value);
        setIsDirty(true);
    };
    const handleIncludeMenuChange = (e: any) => {
        setIncludeMenu(e.name);
        setIsDirty(true);
    }

    const handleIncludeGenderChange = (e: any) => {
        setIncludeGender(e.name);
        setIsDirty(true);
    }

    const handleIncludeDOBChange = (e: any) => {
        setIncludeDOB(e.name);
        setIsDirty(true);
    }
    const handleAllowsWaitingChange = (e: any) => {
        setAllowsWaiting(e.name);
        setIsDirty(true);
    }
    const handleIncludeIdentificationChange = (e: any) => {
        setIncludeIdentification(e.name);
        setIsDirty(true);
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loader}
            {!!props.event && inscriptionTypes &&
                <Form
                    className="form-container m-0 p-0 step-container"
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    action="/events"
                >


                    <div className="form-info-container pr-0 pt-0 pb-0 ">
                        <div className='d-flex flex-column m-0 mt-2 mt-md-0 p-0'>
                            <h3 className='w-100 step-information-title mb-2'>
                                {t('event.step-info.inscriptions.title')}
                            </h3>
                            <p className='step-information-body' dangerouslySetInnerHTML={{ __html: t('event.step-info.inscriptions.text') }}></p>
                        </div>
                        <Form.Group>
                            {props.event.eventType === 'PRIVATE_EVENT' &&

                                <div className="d-flex row">

                                    <div className="col-md-6">

                                        <Form.Label className="input-label">
                                            {`${t(
                                                'event.inscription-type'
                                            )}`}
                                        </Form.Label>
                                        <Select
                                            required
                                            isDisabled={!props.editable || (!!props.event?.inscriptions && props.event.inscriptions.length > 0)}
                                            isInvalid={validated && !inscriptionType}
                                            className={validated && !inscriptionType ? 'select-control invalid' : (validated && !!inscriptionType ? "select-control valid" : "select-control")}
                                            placeholder={t('select')}
                                            options={inscriptionTypes}
                                            value={inscriptionTypes.find(x => x.name === inscriptionType)}
                                            onChange={handleOnSelectInscriptionType}
                                        />
                                        {validated && !inscriptionType && <Form.Control.Feedback type="invalid" className='custom-error'>
                                            {!inscriptionType && `${t('error.required')}`}
                                        </Form.Control.Feedback>}

                                    </div>
                                    <div className={inscriptionType === 'GROUPAL_INSCRIPTION' ? "col-md-6" : "d-none"}>
                                        <Form.Label className="input-label">
                                            {`${t(
                                                'event.companions-limit'
                                            )}`}
                                        </Form.Label>
                                        <Form.Control
                                            disabled={!props.editable}
                                            required={inscriptionType === 'GROUPAL_INSCRIPTION'}
                                            name="companionsLimit"
                                            type="number"
                                            min={minEventCompanionsLimit}
                                            isInvalid={!!companionsLimit && companionsLimit < minEventCompanionsLimit}
                                            max={EVENT_COMPANIONS_MAX}
                                            defaultValue={props.event?.companionsLimit}
                                            onChange={handleOnChangeCompanionsLimit}
                                        ></Form.Control>
                                        {validated && !!companionsLimit && <Form.Control.Feedback type="invalid">
                                            {`${t(
                                                'event.companions-limit-value', { min: minEventCompanionsLimit, max: EVENT_COMPANIONS_MAX }
                                            )}`}
                                        </Form.Control.Feedback>}
                                        {validated && !companionsLimit && <Form.Control.Feedback type="invalid" className='custom-error'>
                                            {!companionsLimit && `${t('error.required')}`}
                                        </Form.Control.Feedback>}
                                    </div>

                                </div>
                            }
                            <div className="d-flex row">


                                {
                                    !(props.event?.eventType === 'PUBLIC_EVENT') && <div className="col-md-6">
                                        <Form.Label className="input-label">
                                            {`${t('event.include-menu')}`}
                                        </Form.Label>
                                        <Select
                                            required
                                            isDisabled={!props.editable || (!!props.event?.inscriptions && props.event.inscriptions.length > 0)}
                                            isInvalid={validated && !includeMenu}
                                            className={validated && !includeMenu ? "select-control invalid" : (validated && !!includeMenu ? "select-control valid" : "select-control")}
                                            placeholder={t('select')}
                                            options={includeMenuOptions}
                                            value={includeMenuOptions.find(x => x.name === includeMenu)}
                                            onChange={handleIncludeMenuChange}
                                        />
                                        {validated && !includeMenu && <Form.Control.Feedback type="invalid" className='custom-error'>
                                            {!includeMenu && `${t('error.required')}`}
                                        </Form.Control.Feedback>}
                                    </div>
                                }

                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.include-identification')}`}
                                    </Form.Label>
                                    <Select
                                        required
                                        isDisabled={!props.editable || (!!props.event?.inscriptions && props.event.inscriptions.length > 0)}
                                        isInvalid={validated && !includeIdentification}
                                        className={validated && !includeIdentification ? "select-control invalid" : (validated && !!includeIdentification ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={includeIdentificationOptions}
                                        value={includeIdentificationOptions.find(x => x.name === includeIdentification)}
                                        onChange={handleIncludeIdentificationChange}
                                    />
                                    {validated && !includeIdentification && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!includeIdentification && `${t('error.required')}`}
                                    </Form.Control.Feedback>}
                                </div>
                            </div>
                            <div className="d-flex row">
                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.include-gender')}`}
                                    </Form.Label>
                                    <Select
                                        isDisabled={!props.editable || (!!props.event?.inscriptions && props.event.inscriptions.length > 0)}
                                        isInvalid={validated && !includeGender}
                                        className={validated && !includeGender ? "select-control invalid" : (validated && !!includeGender ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={includeGenderOptions}
                                        value={includeGenderOptions.find(x => x.name === includeGender)}
                                        onChange={handleIncludeGenderChange}
                                    />
                                    {validated && !includeGender && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!includeGender && `${t('error.required')}`}
                                    </Form.Control.Feedback>}
                                </div>

                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.include-dob')}`}
                                    </Form.Label>
                                    <Select
                                        isDisabled={props.event?.eventType === 'PUBLIC_EVENT' || !props.editable || (!!props.event?.inscriptions && props.event.inscriptions.length > 0)}
                                        isInvalid={validated && !includeDOB}
                                        className={validated && !includeDOB ? "select-control invalid" : (validated && !!includeDOB ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={includeDOBOptions}
                                        value={includeDOBOptions.find(x => x.name === includeDOB)}
                                        onChange={handleIncludeDOBChange}
                                    />
                                    {validated && !includeDOB && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!includeDOB && `${t('error.required')}`}
                                    </Form.Control.Feedback>}
                                </div>
                            </div>
                            {
                                !(props.event?.eventType === 'PUBLIC_EVENT') && <div className="d-flex row">
                                    <div className="col-md-6">
                                        <Form.Label className="input-label">
                                            {`${t('event.allows-waiting')}`}
                                        </Form.Label>
                                        <Select
                                            isDisabled={!props.editable || (!!props.event?.inscriptions && props.event.inscriptions.length > 0)}
                                            isInvalid={validated && !allowsWaiting}
                                            className={validated && !allowsWaiting ? "select-control invalid" : (validated && !!allowsWaiting ? "select-control valid" : "select-control")}
                                            placeholder={t('select')}
                                            options={allowsWaitingOptions}
                                            value={allowsWaitingOptions.find(x => x.name === allowsWaiting)}
                                            onChange={handleAllowsWaitingChange}
                                        />
                                        {validated && !allowsWaiting && <Form.Control.Feedback type="invalid" className='custom-error'>
                                            {!allowsWaiting && `${t('error.required')}`}
                                        </Form.Control.Feedback>}
                                    </div>

                                    <div className="col-md-6">

                                    </div>
                                </div>
                            }
                        </Form.Group>

                    </div>
                    {/* botones */}
                    <div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                        <Button
                            disabled={!isDirty}
                            className="button-outline-primary w-100 w-md-auto text-transform-none"
                            type="submit">
                            {
                                props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                            }
                        </Button>
                    </div>
                </Form>

            }
            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}







        </>
    );
}

export default StepInscriptions;
