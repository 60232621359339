import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomModal from '../components/modals/customModal';
import { FaCircle } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useUsersApi from '../hooks/useUsersApi';
import { DateHelper } from '../helpers/date-helper';
import './users.scss';
interface UserRowProps {
    user: any,
    isAdmin: boolean
}
export function UserRow(props: UserRowProps) {

    const [
        deleteUser,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        deleteUserByOrganizer,
    ] = useUsersApi();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const handleCloseDeleteModal = async (e: any) => {
        if (e) {
            if (props.user && props.user._id) {
                if (props.isAdmin) {
                    const response: any = await dispatch(deleteUser({ id: props.user._id }));
                    if (response && response['payload']) {
                        window.location.reload();
                    } else {
                        setShowWarningModal(true);
                    }
                }
                else {
                    const _loggedUserId: any = localStorage.getItem('loggedUserId');
                    const response: any = await dispatch(deleteUserByOrganizer({ id: props.user._id, loggedUserId: _loggedUserId }));
                    if (response && response['payload']) {
                        window.location.reload();
                    } else {
                        setShowWarningModal(true);
                    }
                }
            }
        }
        setShowDeleteModal(false);
    };

    const handleCloseWarningModal = async () => {
        setShowWarningModal(false);
    };


    const handleEditUser = () => {
        if (props.user.isEditable){
            history.push('/user/edit/' + props.user._id);
        }
    };
    return (
        <>
            <tr className={(props.user.isEditable ? 'cursor-pointer': 'cursor-not-allowed') +' user-row single'}
              onClick={handleEditUser}
            >
                <td className='image-col'>
                    <div
                        className=
                        'table-card-image m-0 ' >
                        <img src={!!props.user.avatar ? props.user.avatar : ('/assets/' + (!props.user.gender ? 'DEFAULT' : props.user.gender) + '.png')} className='table-card-image rounded-image m-0 ' />
                        <div className="dot-container-table img"><FaCircle className={props.user.status == "ACTIVE" ? "active-dot mr-1" : "inactive-dot mr-1"} /></div>
                    </div>
                </td>
                <td>
                    <div className='inscription-name'>{props.user.name} {props.user.surname}</div>
                    <div className='hide-not-medium inscription-companions'>
                        {t('role.' + props.user.highestRoleOrganizer.roleName)}
                        {!props.user.isOrganizer && ' - ' + props.user.highestRoleOrganizer.organizer}
                    </div>

                    <div className='inscription-email'>{props.user.email}</div>
                    {(props.user.group || props.user.dob) && <div className='hide-not-medium inscription-email'>

                        {(props.user.dob ? t('age-group.' + DateHelper.getGroup(props.user.dob)) + " - " + DateHelper.calculateAge(new Date(props.user.dob), t) :
                            t('age-group.' + props.user.group))}

                    </div>}
                    {(props.user.gender) && <div className='hide-not-medium inscription-email'>

                        {(t('gender.' + props.user.gender))}

                    </div>}
                </td>
                <td className='hide-medium inscription-companions'>
                    {t('role.' + props.user.highestRoleOrganizer.roleName)}
                </td>

                <td className='hide-medium inscription-companions'>
                    {!props.user.isOrganizer && props.user.highestRoleOrganizer.organizer}
                </td>


                <td className='hide-medium inscription-companions'>

                    {(props.user.group || props.user.dob) && (props.user.dob ? t('age-group.' + DateHelper.getGroup(props.user.dob)) + " - " + DateHelper.calculateAge(new Date(props.user.dob), t) :
                        t('age-group.' + props.user.group))}

                </td>
                <td className='hide-medium inscription-companions'>

                    {(props.user.gender) && (t('gender.' + props.user.gender))}

                </td>
                <td className='image-col'>
                    {props.user.isEditable && (
                        <Button
                            className="rounded-button rounded-button-ok outline"
                            title={t('user.edit')}
                          
                        >
                            <MdEdit />
                        </Button>
                    )}
                </td>

            </tr>

            {showDeleteModal && (
                <CustomModal
                    isShowing={showDeleteModal}
                    parentCallback={handleCloseDeleteModal}
                    message={t('user.delete-message')}
                    type="WARNING_DELETE"
                    title={t('user.delete-title')}
                    buttonCancel={t('cancel')}
                    buttonOK={t('delete')}
                />
            )}

            {showWarningModal && (
                <CustomModal
                    title={t('warning.modal-title')}
                    isShowing={showWarningModal}
                    parentCallback={handleCloseWarningModal}
                    message={t('user.delete-user-event-message')}
                    type="WARNING_DELETE"
                    buttonOK={t('accept')}
                />
            )}
        </>
    );
}

export default UserRow;
