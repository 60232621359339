import { useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FiImage } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import './dropzone.scss';
import ImageCropper from './imageCropper';
import { BsTrashFill } from 'react-icons/bs';

interface DropzoneProps {
  className: string;
  onChangeStatus: (files: File[] | null) => void;
  value?: any;
  setShowErrorModal: Function;
  setErrorModalMessage: Function;
  squared?: boolean;
}

export function Dropzone(props: DropzoneProps) {
  const [image, setImage] = useState(false);
  const [image_file, setImageFile] = useState("");
  const [files, setFiles] = useState<any[]>([]);
  
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [finalImage, setFinalImage] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const squared = props.squared === false ? false : true;
  const thumbs = files.map((file: any, i) => (
    <img key={i} src={file?.preview} className="upload-image" alt={`Thumbnail ${i}`} />
  ));

  const handleDeleteImage = () => {
    setFiles([]);
    setImage(false);
  //  setImageFile("");
    props.onChangeStatus(null);
  };

  const handleCloseCropperModal = () => {
    if (!!fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setShowCropperModal(false);
  };

  
  const handleButtonClick = () => {
    setFinalImage("");
    if (!!fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageFile(reader.result as any);
    };
    if (!!files && files.length > 0) {
      reader.readAsDataURL(files[0]);
      setShowCropperModal(true);
    }
    
  };

  const onChangeStatusImage = async (e: any) => {
    props.onChangeStatus(e);
    setImage(true);
  }


  return (
    <div>
      {(!image && !props.value) ? (
        <div className={props.className}>
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={onChange}
            style={{ position: "absolute", width: 1, height: 1, overflow: "hidden" }}
            tabIndex={-1}
            ref={fileInputRef}
          />
          <Button
            className="custom-btn-transparent"
            onClick={() => handleButtonClick()}
          >  
            <div className="text-center">
              <FiImage className="img-icon" />
              <div className="label clear text-center" dangerouslySetInnerHTML={{ __html: t('img-dropzone-msg')}}></div>
            </div>
          </Button>
        </div>
      ) : (
        <div className="dropzone-container">
          {!props.value && files && files.length > 0 && thumbs}
          {finalImage && <img src={typeof finalImage === 'string' ? finalImage : props.value?.preview} className={squared ? "upload-image" : "upload-image not-squared"}  alt="Uploaded" />}
          {!!props.value && !finalImage && <img src={typeof props.value === 'string' ? props.value : props.value?.preview} className={squared ? "upload-image" : "upload-image not-squared"}  alt="Uploaded" />}
          <Button
            className="rounded-button rounded-button-ok outline delete dropzone-card-button btn btn-primary"
            onClick={() => handleDeleteImage()}
          >
            <BsTrashFill />
          </Button>
        </div>
      )}
      {/* Agregar el componente ImageCropper dentro del modal */}
      <Modal show={showCropperModal} onHide={handleCloseCropperModal}>
        <Modal.Body>
          <ImageCropper squared={squared}
          imageFile={image_file} setCloseModal={handleCloseCropperModal} 
          finalImage={finalImage} setFinalImage={setFinalImage} onChangeStatus={onChangeStatusImage} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Dropzone;
