import { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import { IoIosArrowBack, IoMdPerson } from 'react-icons/io';
import useEventsApi from '../../hooks/useEventsApi';
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useLoader from '../../components/loader/useLoader';
import {
	INSCRIPTIONS_PAGE_SIZE, SHOW_SECTORS,
} from '../../../constants';
import Paginator from '../../components/paginator/paginator';
import { Button, Form, Table } from 'react-bootstrap';
import { IoArrowBackOutline, IoCheckmarkSharp, IoClose, IoSearch } from 'react-icons/io5';
import { FaRegClock, FaUnlock } from 'react-icons/fa';
import { IoQrCodeOutline } from 'react-icons/io5';
import { getCanAttendee } from '../../helpers/inscription-helper';
import NoResults from '../../components/no-results/noResults';
import { TInscriptionStatus } from '../../models/types/types';
import CustomModal from '../../components/modals/customModal';
import { IEvent } from '../../models/interfaces/events.interface';
import { InscriptionSingleRow } from '../../inscriptions/manager/inscriptionSingleRow';
import { InscriptionAdmissionSingleRow } from '../../inscriptions/manager/inscriptionAdmissionSingleRow';
import { InscriptionAdmissionGroupalRow } from '../../inscriptions/manager/inscriptionAdmissionGroupalRow';
interface EventAttendanceProps {
	pEventId: string,
	status?: string
}
export function EventAttendance(props: EventAttendanceProps) {
	const [loader, showLoader, hideLoader] = useLoader();
	const [loggedUser] = useApi();
	const [, , , , , getEventById] = useEventsApi();
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		getAttendeedInscriptions
	] = useInscriptionsApi();

	const { t } = useTranslation();
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');

	const [inscriptions, setInscriptions] = useState<any[] | undefined>(undefined);
	const [inscriptionsPage, setInscriptionsPage] = useState(0);
	const [inscriptionsTotal, setInscriptionsTotal] = useState(0);
	const [filterNameEmail, setFilterNameEmail] = useState('none');
	const [filterStatus, setFilterStatus] = useState<boolean>(props.status === 'ATTENDEED');
	const [filterToAttendee, setFilterToAttendee] = useState<boolean>(props.status !== 'REJECTED');
	const [attendeeds, setAttendeeds] = useState(0);
	const [notAttendeeds, setNotAttendeeds] = useState(0);
	const [rejected, setRejected] = useState(0);
	const [canAttendee, setCanAttendee] = useState(false);
	const [isPast, setIsPast] = useState(false);
	const [isSingle, setIsSingle] = useState<boolean>(false);
	const [statuses, setStatuses] = useState<TInscriptionStatus[]>(['ACCEPTED', 'PENDING', 'PENDING_EMAIL_CONFIRMATION']);
	const [currentTab, setCurrentTab] = useState<string>(props.status ? props.status : 'NOT_ATTENDEED');
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [inscription, setInscription] = useState<any>(undefined);
	const [successModalMessage, setSuccessModalMessage] = useState<string>('');
	const [event, setEvent] = useState<IEvent | undefined>(undefined);
	const loadInscriptions = async (pageNumber: number, name: string, status: boolean, toAttendee: boolean) => {
		const _Inscriptions = await getAdmissionInscriptions(
			props.pEventId,
			pageNumber,
			name ? name || 'none' : 'none',
			status,
			toAttendee
		);
		if (!!_Inscriptions) {
			setInscriptionsTotal(_Inscriptions.total);
			setAttendeeds(_Inscriptions.attendeedsPerson);
			setNotAttendeeds(_Inscriptions.noAttendeedsPerson);
			setRejected(_Inscriptions.rejectedPerson);

			if (_Inscriptions.event && _Inscriptions.event != null) {
				const attendable = getCanAttendee(_Inscriptions.event);
				setCanAttendee(attendable['canAttendee']);
				setIsPast(attendable['isPast']);
				setIsSingle(_Inscriptions.event.inscriptionType === 'SINGLE_INSCRIPTION');
				setEvent(_Inscriptions.event);
			}
			return _Inscriptions.inscriptions;
		}
		return [];
	};

	const filterInscriptions = (pStatus: any, pInscriptions: any[]) => {
		if (pInscriptions)
			return pInscriptions.filter((x) => x.inscriptionStatus != pStatus);
	};
	const handleQR = () => {
		history.push('/scanQR/' + props.pEventId);
	};
	const handleEventId = async () => {
		if (loggedUser && !inscriptions) {
			showLoader();
			const ins = await loadInscriptions(0, filterNameEmail, filterStatus, filterToAttendee);
			setInscriptions(ins);
			hideLoader();
		}
	};
	const getSuccessModalMessage = (insc: any, ids: string[] | undefined) => {
		const hasSectors = insc.event?.sectors?.length > 0;
		if (!!!ids || insc.companions?.length === 0) {
			return insc.name + ' ' + insc.surname + (hasSectors ? ' - ' + (insc.sector?.name || t('event.sector.no-sector')) : '');
		} else {
			let msj = insc.name + ' ' + insc.surname + (hasSectors ? ' - ' + (insc.sector?.name || t('event.sector.no-sector')) : '');
			insc.companions.forEach((companion: any) => {
				if (ids.indexOf(companion._id) >= 0) {
					msj += '<br>' + companion.name + ' ' + companion.surname + (hasSectors ? ' - ' + (companion.sector?.name || t('event.sector.no-sector')) : '');
				}
			});
			return msj;
		}
	}
	const onAtendee = async (insc: any, ids: string[] | undefined) => {
		showLoader();
		setInscription(insc);
		if (!!insc) {
			setSuccessModalMessage(getSuccessModalMessage(insc, ids));
			setShowSuccessModal(true);
		}


		setInscriptionsPage(0);
		setFilterNameEmail('none');
		setFilterStatus(false);
		const ins = await loadInscriptions(0, 'none', false, true);
		setInscriptions([]);
		setInscriptions(ins);
		hideLoader();
	};
	useEffect(() => {
		handleEventId();
	}, [loggedUser]);

	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		//history.goBack();
		history.push('/events');
	};
	const onFilterNameEmailChange = (e: any) => {
		setFilterNameEmail(e.target.value);
	};
	const onSubmit = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		performSearch();
	}
	const performSearch = async (status?: boolean, toAttendee?: boolean, _textFilter?: string) => {
		showLoader();
		setInscriptionsPage(0);
		const ins = await loadInscriptions(0, _textFilter ? _textFilter : filterNameEmail, status != undefined ? status : filterStatus, toAttendee != undefined ? toAttendee : filterToAttendee);

		setInscriptions([]);
		setInscriptions(ins);
		hideLoader();
	};
	const history = useHistory();

	const handleOnSelectInscriptionStatus = async (status: boolean, toAttendee: boolean) => {
		setFilterStatus(status);
		setFilterToAttendee(toAttendee);
		if (!status && toAttendee) { // aceptados y pendientes
			setCurrentTab('NOT_ATTENDEED');
			setStatuses(['ACCEPTED', 'PENDING', 'PENDING_EMAIL_CONFIRMATION']);
		} else if (!status && !toAttendee) { // rechazados
			setCurrentTab('REJECTED');
			setStatuses(['REJECTED', 'DENIED']);
		} else { // ingresados
			setCurrentTab('ATTENDEED');
			setStatuses(['ATTENDEED']);
		}
		await performSearch(status, toAttendee);
	};
	const handlePrev = async () => {
		showLoader();
		const pending = await loadInscriptions(inscriptionsPage - 1, filterNameEmail, filterStatus, filterToAttendee);
		if (pending && pending.length > 0) {
			setInscriptions([]);
			setInscriptions(pending);
		}
		setInscriptionsPage(inscriptionsPage - 1);
		hideLoader();
	};
	const handleNext = async () => {
		showLoader();
		const pending = await loadInscriptions(inscriptionsPage + 1, filterNameEmail, filterStatus, filterToAttendee);
		if (pending && pending.length > 0) {
			setInscriptions([]);
			setInscriptions(pending);
		}
		setInscriptionsPage(inscriptionsPage + 1);
		hideLoader();
	};

	const clearSearchFilter = async () => {
		setFilterNameEmail('none');
		await performSearch(undefined, undefined, 'none');
	}
	const goToEditAdmin = () => {
		//history.push('/events/edit/' + props.pEventId + '/false/PENDING/attendance');
		history.push('/event-manager/' + props.pEventId + '/PENDING/attendance');
	}
	const handleCloseSuccessModal = (e: any) => {
		setInscription(undefined);
		setShowSuccessModal(false);
	};
	return (
		<>
			{loader}
			{!isLoggedUser && <Redirect to="/login" />}
			{/* message={inscription.name + ' ' + inscription.surname  +(inscription.event?.sectors?.length > 0 ? ' - '+ (inscription.sector?.name || t('event.sector.no-sector')): '')} */}
			{showSuccessModal && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					title={t('inscription.attendee-title')}
					message={successModalMessage}

					warningMessage={!!inscription.warningMessage ? inscription.warningMessage : null}
					type="SUCCESS"
					buttonOK={t('accept')}
				/>
			)}
			{isLoggedUser && loggedUser && (
				<>
					<div className="dashboard-content form">
						<div className='container-box'>
							<div className='container-box-content d-flex justify-content-between align-items-center'>
								<div className='d-flex '>
									<div
										className="button-back mr-2"
										onClick={(e: any) => handleOnCancel(e)}
									>
										<IoArrowBackOutline />
									</div>
									<div className='ml-4 mr-4'>
										<div className='banner-title d-flex align-items-center'><p className='one-line'>{t('admission')}</p></div>
										<div className='banner-subtitle'>{event?.name}</div>
									</div>

								</div>
								<div className='d-flex row align-items-center justify-content-end'>
									{!!loggedUser && loggedUser.isOrganizer && <div>
										<Button className='rounded-button rounded-button-ok'
											onClick={goToEditAdmin}>
											<FaUnlock></FaUnlock>
										</Button>
									</div>}
									<div><Button
										className="qr-button ml-3"
										title={t('scan-QR')}
										onClick={handleQR}
									>
										<IoQrCodeOutline /><span> {t('scan-QR')}</span>
									</Button></div>
								</div>
							</div>
						</div>
						<div className="form-container col-xl-8 mt-5">
							<div className="row pb-4 pt-3 col-12 m-0 pr-0 pl-0 mt-x-5">
								<Form
									onSubmit={onSubmit}
									className="w-100"
								>
									<div className="row justify-content-between align-items-center w-100 m-0">
										<div className="row col-12 p-0 m-0">
											{/* <div className="col-12 col-lg-6 p-0 mb-4 mb-md-0"> */}
											<div className="col-12 col-lg-6 row m-0 p-0 ">
												<div className=
													{(currentTab === 'NOT_ATTENDEED') ? "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer selected PENDING" : "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer"}
													onClick={() => { handleOnSelectInscriptionStatus(false, true) }}>
													<div className="custom-checkbox inscription-checkbox checkbox-rounded disabled mr-2">
														<FaRegClock />
													</div>
													<div>
														<div className="inscription-counter">
															{notAttendeeds}
															<IoMdPerson className="person-icon"></IoMdPerson>
														</div>
														<div className="inscription-counter-status">
															{t(
																'pending'
															)}
														</div>
													</div>
												</div>
												<div className={(currentTab === 'ATTENDEED') ? "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer selected ATTENDEED" : "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer"}
													onClick={() => { handleOnSelectInscriptionStatus(true, true) }}>

													<div className="custom-checkbox inscription-checkbox checkbox-rounded successful mr-2">
														<IoCheckmarkSharp />
													</div>
													<div>
														<div className="inscription-counter">
															{attendeeds}
															<IoMdPerson className="person-icon"></IoMdPerson>
														</div>
														<div className="inscription-counter-status">
															{t('inscription.attendeeds')}
														</div>
													</div>
												</div>
												{event?.eventType === "PRIVATE_EVENT" && <div className={(currentTab === 'REJECTED') ? "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer selected REJECTED" : "inscription-counter-box align-items-center row p-2 m-0 cursor-pointer"}
													onClick={() => { handleOnSelectInscriptionStatus(false, false) }}>

													<div className="custom-checkbox inscription-checkbox checkbox-rounded rejected mr-2">
														<IoClose />
													</div>
													<div>
														<div className="inscription-counter">
															{rejected}
															<IoMdPerson className="person-icon"></IoMdPerson>
														</div>
														<div className="inscription-counter-status">
															{t('rejected')}
														</div>
													</div>
												</div>}
											</div>
											{/* </div> */}
											<div className="col-12 col-lg-6 row m-0 p-0 mt-3 mt-lg-0 align-items-center justify-content-lg-end ">
												<div className="row col-12 m-0 p-0 align-items-center search-input">
													<Form.Group className="w-100">
														<Form.Control
															type="text"
															name="filterNameEmail"
															value={filterNameEmail != 'none' ? filterNameEmail : ''}
															placeholder={t('inscription.search')}
															onChange={onFilterNameEmailChange} />
													</Form.Group>
													<div className="clear-search" onClick={clearSearchFilter}>
														<IoClose />
													</div>
													<div className="input-button-search">
														<Button className="rounded-button rounded-button-ok" type="submit" >
															<IoSearch />
														</Button>
													</div>
												</div>

											</div>


										</div>

									</div>
								</Form>
							</div>
							<div className="d-flex row pb-5 col-12 m-0 pr-0 pl-0">
								<div className="w-100">
									{inscriptions && inscriptions.length == 0 && (
										<div className="align-items-center">
											<NoResults text={i18next.t('inscriptions-not-found')} />
											{/* <NoResults text={i18next.t('events-not-found')} /> */}
										</div>
									)}

									{currentTab === 'NOT_ATTENDEED' && !isSingle && inscriptions && !loader &&
										inscriptions.length > 0 &&
										<Table borderless responsive>
											<tbody>

												{
													inscriptions.map((inscription, i) => (
														<InscriptionAdmissionGroupalRow
															inscription={inscription}
															onAtendee={onAtendee}
															key={i}
															statuses={statuses}
															canAttendee={canAttendee}
															loggedUserId={loggedUser['_id']}
															isFinished={isPast}
															includeSector={inscription.event?.sectors?.length > 0 && SHOW_SECTORS}
														/>

													))}
											</tbody>
										</Table>
									}
									{currentTab === 'NOT_ATTENDEED' && isSingle && inscriptions &&
										inscriptions.length > 0 && !loader &&
										<Table borderless responsive
										>
											<tbody>

												{
													inscriptions.map((inscription, i) => (

														<InscriptionAdmissionSingleRow
															inscription={inscription}
															onAtendee={onAtendee}
															key={i}
															statuses={statuses}
															canAttendee={canAttendee}
															loggedUserId={loggedUser['_id']}
															isFinished={isPast}
															includeSector={inscription.event?.sectors?.length > 0 && SHOW_SECTORS}
														/>

													))}
											</tbody>
										</Table>
									}
									{(currentTab !== 'NOT_ATTENDEED') && inscriptions &&
										inscriptions.length > 0 && !loader &&
										<Table borderless responsive className={currentTab}>
											<tbody>

												{inscriptions.map((inscription, i) => (
													<InscriptionSingleRow
														includeMenu={false} // TODO
														key={i}
														inscription={inscription}
														includeSector={inscription.event?.hasSectors == 'yes' && SHOW_SECTORS}
														canEdit={false}
														type={currentTab === 'REJECTED' ? 'DENIED' : 'ATTENDEED'}
														statuses={[]}
														onUndoInscription={() => { }}
														selectAll={() => { }}
														disableSelectAll={() => { }}
													/>

												))}
											</tbody>
										</Table>
									}
									{inscriptions &&
										inscriptions.length > 0 && (
											<Paginator
												currentPage={inscriptionsPage}
												totalPages={Math.ceil(
													inscriptionsTotal /
													INSCRIPTIONS_PAGE_SIZE
												)}
												onPrevious={handlePrev}
												onNext={handleNext}
											></Paginator>
										)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default EventAttendance;
