import React, { useEffect } from 'react';
import { IBatch } from '../models/interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import { DateHelper } from '../helpers/date-helper';
import { Dropdown } from 'react-bootstrap';
import { IoMdMore } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { hasEnded, hasPreviousBatch, isForSale, isScheduled, isSoldOut } from '../helpers/batch-helper';

interface BatchRowProps {
    batch: IBatch;
    otherBatches: IBatch[];
    i: number;
    handleDeleteBatch?: any;
    handleEditBatch?: any;
    canEdit: boolean;
    eventId?: string;
    batchesAvailability?: any[];
}

export function BatchRow(props: BatchRowProps) {
    const { t, i18n } = useTranslation();
    const [sold, setSold] = React.useState(0);
    let notAvailableQty = 0;
    let availableQty = props.batch.qty || 0;
    const prevBatch: IBatch | undefined = props.otherBatches.find(b => b.number === props.batch.batchEndedNumber);

    useEffect(() => {
        if (props.batchesAvailability && !!props.batch._id) {
            const mybatchAvailability: any = props.batchesAvailability.find((x: any) => x.batchId === props.batch._id);
            setSold(mybatchAvailability?.sold);
            notAvailableQty = mybatchAvailability?.sold;
        }
    }, [sold, props])

    if (!!props.eventId && !!props.batch._id) {
        if (!!props.batchesAvailability) {
            // const batchesAvailability: any[] = props.batchesAvailability;
            // const mybatchAvailability: any = batchesAvailability.find((x: any)=> x.batchId === props.batch._id);
            // if (!!prevBatch && previousBatchIsExpired(props.batch, props.otherBatches)){
            //     const previousBatchAvailability: any = batchesAvailability.find((x: any)=> x.batchId === prevBatch._id);
            //     availableQty = (props.batch.qty + previousBatchAvailability.qty);
            //setBatchesAvailability(response.batchesAvailability);

            // if (!!prevBatch && hasPreviousBatch(props.batch)){
            //     const previousBatchAvailability: any = batchesAvailability.find((x: any)=> x.batchId === prevBatch._id);
            //     availableQty = (props.batch.qty + previousBatchAvailability.qty);
            // }
            notAvailableQty = sold

        }
    }

    //     if (!!props.eventId && !!props.batch._id) {

    //         if (!!props.batchesAvailability) {
    //             const batchesAvailability: any[] = props.batchesAvailability;
    //             const mybatchAvailability: any = batchesAvailability.find((x: any)=> x.batchId === props.batch._id);
    //             if (!!prevBatch && previousBatchIsExpired(props.batch, props.otherBatches)){
    //                 const previousBatchAvailability: any = batchesAvailability.find((x: any)=> x.batchId === prevBatch._id);
    //                 setAvailableQty(props.batch.qty + previousBatchAvailability.qty);
    //             } else {
    //                 setNotAvailableQty((props.batch.qty || 0) - mybatchAvailability.qty);
    //             }
    //             //setBatchesAvailability(response.batchesAvailability);
    //         }
    //     }
    // }
    // useEffect(() => {
    //     handleEffect();
    // }, [props.batch]);
    return (
        <>
            <div key={props.i} className="batch-row mb-2">
                <div className='row m-0 p-0 ml-1 mr-1 justify-content-between align-items-start no-wrap'>
                    <div className='d-flex flex-column align-items-start justify-content-center'>
                        <div className='batch-title'>{props.batch.name}{' - '}{props.batch.currency} {props.batch.currency ? '$' : ''} {props.batch.price}</div>
                        <div className="card-subtitle align-items-center row p-0 m-0 mt-1">
                            {(isForSale(props.batch, props.otherBatches, props.batchesAvailability) || isScheduled(props.batch, props.otherBatches, props.batchesAvailability)) && <FaCircle className={isForSale(props.batch, props.otherBatches, props.batchesAvailability) ? 'active-dot medium mr-2' : 'warning-dot medium mr-2'}></FaCircle>}
                            <div>
                                {isForSale(props.batch, props.otherBatches, props.batchesAvailability) ? t('event.batch.for-sale') : (isScheduled(props.batch, props.otherBatches, props.batchesAvailability) ? t('event.batch.scheduled') : '')}
                            </div>
                            <FaCircle className={'regular-dot small ml-1 mr-1'}></FaCircle>
                            {(isForSale(props.batch, props.otherBatches, props.batchesAvailability) && !isScheduled(props.batch, props.otherBatches, props.batchesAvailability)) && <div>{t('sales-end-on', { date: DateHelper.longDate2(props.batch.dateEnd || ''), time: props.batch.endHour })}</div>}
                            {!hasPreviousBatch(props.batch) && (isScheduled(props.batch, props.otherBatches, props.batchesAvailability)) && <div>{t('sales-start-on', { date: DateHelper.longDate2(hasPreviousBatch(props.batch) ? prevBatch?.dateEnd || '' : props.batch.date || ''), time: hasPreviousBatch(props.batch) ? prevBatch?.endHour : props.batch.startHour })} </div>}
                            {hasPreviousBatch(props.batch) && isScheduled(props.batch, props.otherBatches, props.batchesAvailability) && <div>{t('event.batch.batch-available-after', { batch: prevBatch?.name })}</div>}
                            {hasEnded(props.batch) && !isSoldOut(props.batch, props.batchesAvailability) && <div>{t('finalized-sales')}</div>}
                            {isSoldOut(props.batch, props.batchesAvailability) && <div>{t('sold-out')}</div>}

                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <div className='batch-title'>{notAvailableQty}/{availableQty}</div>
                        {props.canEdit && <Dropdown>
                            <Dropdown.Toggle bsPrefix="batch-edit-menu" className="batch-edit-menu">
                                <IoMdMore size={28} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {props.i > 0 && notAvailableQty === 0 && <Dropdown.Item onClick={() => props.handleDeleteBatch(props.batch)}>{t('delete')}</Dropdown.Item>}
                                <Dropdown.Item onClick={() => props.handleEditBatch(props.batch, props.i)}>{t('edit')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BatchRow;

