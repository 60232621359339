import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../components/footer/footer';
import PaymentSummary from '../checkout/paymentSummary';
import { IBatch, IEvent, IOrder } from '../models/interfaces/events.interface';
import useEventsApi from '../hooks/useEventsApi';
import useLoader from '../components/loader/useLoader';
import { useTranslation } from 'react-i18next';
import BatchPreview from './batchPreview';
import { Form, Button } from 'react-bootstrap';
import CustomModal from '../components/modals/customModal';
import { useHistory } from 'react-router';
import EventSummary from '../events/eventSummary';
import { DateHelper } from '../helpers/date-helper';
import { isPastOrNow } from '../helpers/inscription-helper';
import useApi from '../hooks/useApi';
import PublicCardEvent from '../events/card-event/public-card-event';

interface TicketsSelectionNewProps {
	eventId: string
}

export function TicketsSelectionNew(props: TicketsSelectionNewProps) {

	const [event, setEvent] = useState<IEvent | undefined>(undefined);
	const [loggedUser] = useApi();
	const [ticketQties, setTicketQties] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [loader, showLoader, hideLoader] = useLoader();
	const [, , , , , getEventById, , getBatchesAvailability, checkTicketsAvailability] = useEventsApi();
	const [batchesAvailability, setBatchesAvailability] = useState<any[]>([]);
	const [validated, setValidated] = useState(false);
	const dispatch = useDispatch();
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

	const [eventError, setEventError] = useState<string | null>(null);
	const history = useHistory();

	const { t, i18n } = useTranslation();

	useEffect(() => {
		showLoader();
		handleEffect();
	}, [batchesAvailability]);

	const canBuy = (event: IEvent) => {

		const deprecatedBatches = event.batches?.filter(x => cannotInscribe(x));
		return deprecatedBatches?.length !== event.batches?.length;
	}
	const cannotInscribe = (batch: any) => {
		if (batch?.dateEnd && batch?.endHour) {
			return DateHelper.daysFromToday(batch?.dateEnd) < 0 ||
				(DateHelper.daysFromToday(batch.dateEnd) === 0 &&
					isPastOrNow(batch.endHour));
		}
		return true;

	}
	const handleEffect = async () => {
		try {
			if (!event) {
				//showLoader();
				await loadBatches(false);
			}
		}
		catch (e) {
			console.error(e);
		} finally {
			hideLoader();
		}
	}

	const handleOnChangeTicketQty = (batch: any, operation: string) => {
		let newQty = operation === "+" ? ticketQties[batch._id] + 1 : ticketQties[batch._id] - 1;
		if (newQty >= 0 && (!batch.batchLimit || newQty <= batch.batchLimit)) {
			setTicketQties({
				...ticketQties,
				[batch._id]: newQty
			});
		}
	};


	const handleCloseErrorModal = async() => {
		setShowErrorModal(false);
		await loadBatches(true);
	};

	const loadBatches = async (resetEvent: boolean) => {
		try {
		showLoader();
		if(resetEvent){
			setEvent(undefined);
		}
		const ev: any = await getEventById(props.eventId);
		if (canBuy(ev)) {
			setShowErrorMessage(false);
			if (ev && ev['_id']) {
				setEvent(ev);
				let initialTicketsQties: { [key: string]: any } = {};
				ev.batches.forEach((b: { _id: string; }) => initialTicketsQties[b._id] = 0);
				setTicketQties(initialTicketsQties);
			}
			const response: any = await getBatchesAvailability(props.eventId);
			if (!response.error && response?.batchesAvailability) {
				setBatchesAvailability(response.batchesAvailability);
			}
		} else {
			setEventError('past-event');
			setShowErrorMessage(true);
		}
	} catch(e){

		console.log(e);
	} finally {
		hideLoader();
	}
	}
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		const qties = getTotalQties();

		if (qties > 0) {
			setLoading(true);
			const order: IOrder = {
				batchesQties: ticketQties,
				loggedUserId: !!loggedUser ? loggedUser['_id'] : undefined
			};
			const response: any = await dispatch(checkTicketsAvailability({ id: props.eventId, order: order }));
			if (response?.payload?.available) {
				console.log(response);
				//Aca hay que tomar el response.payload.inscription para enviar en el redirect al nuevo form de inscriptions a definir
				history.push('/inscribe-public/' + response.payload.inscription._id);
			} else {
				console.log(response);
				setErrorMessage(t('tickets-selection.error.no-available-tickets'));
				setShowErrorModal(true);
			}
			setLoading(false);
		}
		setValidated(true);
	};

	const goToLanding = () => {
		history.push('/');
	}
	/* const getDefaultBatch = (ev: IEvent, batchesAv: any[]) => {
		for (let b of batchesAv) {
			if (b.qty > 0 && ev?.batches) {
				const batch = ev?.batches?.filter((batch: any) => b.batchId === batch._id);
				return batch[0];
			}
		}
		return undefined;
	} */

	const getTotalQties = () => {
		let total = 0;
		for (let id in ticketQties) {
			total += ticketQties[id];
		}
		return total;
	}

	return (
		<>
			{loader}
			{!event && showErrorMessage && (
				<div className="dashboard-content error m-0 p-0">
					<div className="form-container pb-0 col-xl-8">
						<div className="form-static d-flex flex-column justify-content-around align-items-center">
							<div className="d-flex justify-content-center mt-5">
								<div className="logo white cursor-pointer" onClick={goToLanding}></div>
							</div>
							<div className="d-flex flex-column align-items-center justify-content-center text-center new-inscription-box p-lg-5">
								<div className="warning-error"></div>
								<div className="mt-2 inscription-title-bold text-center"
									dangerouslySetInnerHTML={{ __html: t('event.error.' + eventError) }}>
								</div>
							</div>
							<div className="d-flex footer-container">
								<Footer white={true}></Footer>
							</div>
						</div>
					</div>
				</div>
			)}
			{!showErrorMessage && !!event && !!event.batches && !!batchesAvailability && batchesAvailability.length > 0 && <div className="dashboard-content tickets-selection tickets-selection-container p-0 m-0 pt-5">
				<div className="form-container col-xl-10">
					<div className="row m-0 p-0 align-items-stretch">
						<div className="col-12 col-lg-6 p-0">
							<div className='d-flex flex-column align-items-center m-1 h-100 p-3 p-lg-5'>
								<div className="d-flex mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
									<div className="logo white cursor-pointer" onClick={goToLanding}></div>
								</div>
								<PublicCardEvent
									event={event}
									isInscription
									isHost
									showHosts
									isAllowedToEdit={false}
									showOrganizer
									includeDescription
								></PublicCardEvent>
							</div>
						</div>

						<div className="col-12 col-lg-6 p-2 pt-4 pb-4 p-md-5  tickets-selection tickets-selection-box d-flex flex-column">
							<div className="d-flex w-100 justify-content-start mb-1 mt-2">
								<h2 className="d-flex inscription-title w-100 justify-content-center align-items-center">
									{t("inscription.tickets")}
								</h2>
							</div>
							<PaymentSummary ticketQties={ticketQties} batches={event.batches} />
							<Form
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
								className='mt-2 mt-md-4 d-flex h-100'
							>
								<div className="w-100 justify-content-between d-flex flex-column p-3">
									<div className="w-100 d-flex flex-column flex-lg-row">
										<div className="p-0 col-lg-12 d-flex flex-column align-items-center justify-content-start h-100" >
											<h5 className="my-2 mb-3 w-100">{t("tickets-selection.select-tickets")}</h5>
											{/* <h6 className="my-2 mb-3 w-100">{t("tickets-selection.type")}</h6> */}
											{!!event && event?.batches?.map((b: IBatch, i) => {
												//const batchAv = batchesAvailability.filter((bAv: any) => bAv.batchId === b._id);
												const ticketQty = b._id ? ticketQties[b._id] : 0;
												return (
													<BatchPreview key={i} batch={b} 
													otherBatches={!!event?.batches ? event?.batches?.filter(x => x.number !== b.number) : []}
													ticketsQty={ticketQty} batchesAvailability={batchesAvailability} handleOnChangeTicketQty={handleOnChangeTicketQty} disableBatch={loading} />
												)
											})}
											<hr className='mb-0'></hr>
										</div>
									</div>
									<div className='mb-2'>
										<Button className="btn btn-primary button-primary button-ok w-100"
											disabled={getTotalQties() <= 0}
											type="submit">
											{loading ? (
												<div className="spinner-border text-light" role="status">
													<span className="sr-only">Loading...</span>
												</div>
											) : t('tickets-selection.check-availability')}
										</Button>
									</div>
								</div>
							</Form>
						</div>
					</div>
					<div className='mt-5 w-100'>
						<Footer white={true} hideGala={true}></Footer>
					</div>
				</div>
				{showErrorModal && (
					<CustomModal
						isShowing={showErrorModal}
						parentCallback={handleCloseErrorModal}
						message={errorMessage}
						title={t('error.modal-title-oops')}
						type="ERROR"
						buttonOK={t('accept')}
					/>
				)}
			</div>}
		</>
	);
}

export default TicketsSelectionNew;