import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import './inscriptions.scss';
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import { useDispatch } from 'react-redux';
import useLoader from '../../components/loader/useLoader';
import CustomModal from '../../components/modals/customModal';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import { FaCircle } from 'react-icons/fa';
import { TInscriptionStatus } from '../../models/types/types';
import { DateHelper } from '../../helpers/date-helper';
import { IoCheckmarkSharp } from 'react-icons/io5';
interface InscriptionAdmissionSingleRowProps {
    inscription: IInscription,
    onAtendee: any,
    loggedUserId: string,
    canAttendee: boolean,
    isFinished: boolean,
    statuses: TInscriptionStatus[],
    includeSector: boolean
}
export function InscriptionAdmissionSingleRow(
    props: InscriptionAdmissionSingleRowProps
) {
    const [loader, showLoader, hideLoader] = useLoader();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [currentInscription, setCurrentInscription] = useState<IInscription>(props.inscription);
    const [inscriptionToAccept, setInscriptionToAccept] = useState<IInscription | undefined>(undefined);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [previousStatus, setPreviousStatus] = useState<any>(
        props.inscription.inscriptionStatus
    );
    const [createInscription,
        getInscription,
        getAllInscriptions,
        getAcceptedInscriptions,
        getDeniedInscriptions,
        getPendingInscriptions,
        updateInscriptionStatus,
        updateStatusMultiple,
        confirmEmail,
        resendInscriptionEmail,
        getAdmissionInscriptions,
        generateQR,
        getInscriptionById,
        resendQREmail,
        getInscriptionByEmail,
        getInscriptionsByCriteria,
        getAttendeedInscriptions,
        sendDeniedEmail,
        getAttendeedInscriptionsFromMain,
    ] = useInscriptionsApi();

    const statusesToAttendee: TInscriptionStatus[] = ['ACCEPTED', 'PENDING', 'PENDING_EMAIL_CONFIRMATION'];
    const deniedStatuses: TInscriptionStatus[] = ['DENIED', 'REJECTED'];

    const selectSingleInscription = (e: any, inscription: IInscription) => {
        if (inscription.inscriptionStatus !== 'ATTENDEED' &&
            !!inscription.inscriptionStatus &&
            !deniedStatuses.includes(inscription.inscriptionStatus) &&
            props.statuses.includes(inscription.inscriptionStatus)) {
            if (props.canAttendee) {
                e.stopPropagation();
                e.preventDefault();
                setInscriptionToAccept(inscription);
                setShowConfirmationModal(true);
            } else {
                setShowErrorModal(true);
            }
        }
    };

    const handleCloseErrorModal = (e: any) => {
        setShowErrorModal(false);
        if (!!errorMessage) {
            setErrorMessage(undefined);
            props.onAtendee();
        }

    };
    const handleAtendee = async (e: any) => {
        if (!e) {
            setChecked(false);
            setShowConfirmationModal(false);
            setInscriptionToAccept(undefined);
        } else {
            setShowConfirmationModal(false);
            showLoader();
            try {
                if (!!inscriptionToAccept) {

                    let ids = [];

                    if (!!inscriptionToAccept?.inscriptionStatus && statusesToAttendee.includes(inscriptionToAccept?.inscriptionStatus)) {
                        ids.push(inscriptionToAccept._id);
                    }
                    if (!!inscriptionToAccept._id) {
                        const alreadyAttendeed = await getAttendeedInscriptionsFromMain(inscriptionToAccept._id, false);
                        if (!!alreadyAttendeed) {
                            let attendeedNames: string = '';
                            for (const attendeedInscription of alreadyAttendeed) {
                                if (ids.includes(attendeedInscription._id)) {
                                    attendeedNames += (attendeedInscription.name || attendeedInscription.user?.name) + ' ' +
                                        (attendeedInscription.surname || attendeedInscription.user?.surname);

                                }
                            }
                            if (attendeedNames != '') {
                                setErrorMessage(t('error.inscription-already-attendeed', { names: attendeedNames }));
                                setShowErrorModal(true);
                                return;
                            }
                        }
                        const response: any = await dispatch(
                            updateStatusMultiple({
                                inscriptionStatus: 'ATTENDEED',
                                ids: ids,
                                loggedUserId: props.loggedUserId,
                            })
                        );
                        if (response) {
                            props.onAtendee(inscriptionToAccept);
                        }
                    }

                }
            } catch (e) {
                console.error(e);
            } finally {
                hideLoader();
                setInscriptionToAccept(undefined);
                setChecked(false);
            }
        }
    };
    const getConfirmationMessage = () => {
        if (!inscriptionToAccept) {
            return '';
        }

        let userName;
        if (!!inscriptionToAccept?.inscriptionStatus && statusesToAttendee.includes(inscriptionToAccept?.inscriptionStatus) && !!inscriptionToAccept.event) {
            userName = (inscriptionToAccept.name || inscriptionToAccept.user?.name) + ' ' + (inscriptionToAccept.surname || inscriptionToAccept.user?.surname);//+
            //	(!!inscriptionToAccept.event.sectors && inscriptionToAccept.event?.sectors?.length > 0 ? ' - '+ (inscriptionToAccept.sector?.name || t('event.sector.no-sector')): '');
        }


        return (!!inscriptionToAccept && !!userName) ? i18n.t('confirmation.inscription-atendee', {
            user: userName
        }) : '';

    };
    const getBatchName = (name: string | undefined) => {
        if (name === 'FREE_BATCH') {
            return t(`event.batch.type.${name}`);
        }
        return name;
    }
    return (
        <>
            {loader}
            {showConfirmationModal && (
                <CustomModal
                    buttonCancel={t('cancel')}
                    buttonOK={t('confirm')}
                    isShowing={showConfirmationModal}
                    message={getConfirmationMessage()}
                    parentCallback={handleAtendee}
                    type="WARNING_EDIT"
                ></CustomModal>
            )}
            {showErrorModal && (
                <CustomModal
                    buttonOK={t('accept')}
                    isShowing={showErrorModal}
                    title={t('error.modal-title-oops')}
                    message={errorMessage ? errorMessage :
                        (props.isFinished
                            ? t('error.event-ended')
                            : t('error.event-not-started'))
                    }
                    parentCallback={handleCloseErrorModal}
                    type="ERROR"
                ></CustomModal>
            )}
            {!!currentInscription && !!currentInscription.event &&

                <tr key={currentInscription._id} className='inscription-row'>
                    {/* Action */}
                    <td onClick={(e) => selectSingleInscription(e, currentInscription)} className='cursor-pointer text-center'>
                        {props.canAttendee &&
                            !props.statuses.includes('ATTENDEED') &&
                            !!currentInscription.inscriptionStatus && !['REJECTED', 'DENIED'].includes(currentInscription.inscriptionStatus) &&
                            props.statuses.includes(currentInscription.inscriptionStatus) &&
                            <div
                                className={
                                    currentInscription.inscriptionStatus == 'ATTENDEED'
                                        ? 'custom-checkbox inscription-checkbox checkbox-rounded successful'
                                        : (checked || currentInscription._id === inscriptionToAccept?._id)
                                            ? 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded checked'
                                            : 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded'
                                }
                            >
                                {<IoCheckmarkSharp />}
                            </div>
                        }

                    </td>
                    {/* Name  */}
                    <td>
                        <div className="inscription-name d-flex align-items-center">
                            <FaCircle className={currentInscription.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!currentInscription.user ? "inactive-dot mr-1" : "d-none")} />
                            {currentInscription.name || currentInscription.user?.name}{' '}
                            {currentInscription.surname || currentInscription.user?.surname}
                        </div>
                        {currentInscription.event?.includeIdentification && !!currentInscription.identificationNumber && !!currentInscription.identificationNumber
                            && <div className="inscription-email">
                                {currentInscription.identificationType + ' ' + currentInscription.identificationNumber}
                            </div>}
                        {(currentInscription.email || currentInscription.user?.email) &&
                            <div className="inscription-email">
                                {currentInscription.email || currentInscription.user?.email}
                            </div>}
                        {/* Mobile */}
                        <div className='hide-not-medium'>
                            {props.includeSector && currentInscription.event.hasSectors == "yes" &&
                                <div className="inscription-companions">
                                    {currentInscription.sector?.name || t('event.sector.no-sector')}

                                </div>
                            }
                            {/* Rango etareo y Genero */}
                            {(currentInscription.event.includeDOB || currentInscription.event.includeGender) &&
                                <div className="inscription-companions">
                                    {currentInscription.event.includeDOB && t('age-group.' + (currentInscription.group))}
                                    {currentInscription.event.includeDOB && currentInscription.event.includeGender && ' - '}
                                    {currentInscription.event.includeGender && t('gender.' + (currentInscription.gender))}

                                </div>
                            }
                        </div>
                    </td>
                    {/* Sector */}
                    {currentInscription.event?.hasSectors == "yes" &&
                        <td className='hide-medium'>
                            <div className="inscription-companions">
                                {currentInscription.sector?.name || t('event.sector.no-sector')}

                            </div>
                        </td>
                    }
                    {/* Rango etareo */}
                    {currentInscription.event.includeDOB &&
                        <td className="inscription-companions hide-medium">
                            <div>{t('age-group.' + (currentInscription.group))}</div>


                        </td>
                    }
                    {/* Genero */}
                    {currentInscription.event.includeGender &&
                        <td className="inscription-companions hide-medium">
                            <div>{t('gender.' + (currentInscription.gender))}</div>
                        </td>
                    }
                    {/* Status */}
                    {/* Status */}
                    {!!currentInscription.inscriptionStatus &&
                        <td>
                            {(currentInscription.event.eventType === 'PRIVATE_EVENT' || currentInscription.inscriptionStatus == 'ATTENDEED') && <div className="p-0 m-0 d-flex align-items-center justify-content-start justify-content-md-end">
                                <div className={'mr-2 status-tag ' + currentInscription.inscriptionStatus}>{t('inscription.status-tag.' + currentInscription.inscriptionStatus)}</div>
                            </div >}
                            <div className='inscription-email small hide-not-medium'>
                                {(currentInscription.event.eventType === 'PUBLIC_EVENT' && currentInscription.inscriptionStatus != 'ATTENDEED') &&
                                    // <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
                                    <>
                                        {currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
                                            {'$ ' + currentInscription.batch?.price}
                                            {currentInscription.payment?.cardBrand && (
                                                <span className="d-none d-md-inline">
                                                    {' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
                                                </span>
                                            )}
                                        </div>) : null}
                                        {currentInscription.payment?.cardBrand && (
                                            <div className="d-md-none small mt-1 inscription-companions small mt-1">
                                                {currentInscription.payment?.cardBrand?.toUpperCase() +
                                                    '-' +
                                                    currentInscription.payment.cardLast4}
                                            </div>
                                        )}
                                        <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
                                        <div className="inscription-email small mt-1">
                                            {currentInscription.acceptedDT
                                                ? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
                                                : ''}
                                        </div></>
                                }
                                {(currentInscription.event.eventType === 'PRIVATE_EVENT' || currentInscription.inscriptionStatus == 'ATTENDEED') &&
                                    <>
                                        {(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
                                            currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
                                                (currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
                                        <br></br>
                                        <span className="smaller">
                                            {(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
                                                currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
                                        </span>

                                    </>}
                            </div>
                        </td>
                    }

                    {!!currentInscription?.inscriptionStatus &&
                        <td className="inscription-email small hide-medium">
                            {(currentInscription.event.eventType === 'PUBLIC_EVENT' && currentInscription.inscriptionStatus != 'ATTENDEED') &&
                                <>
                                    {/* <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div> */}
                                    {currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
                                        {'$ ' + currentInscription.batch?.price}
                                        {currentInscription.payment?.cardBrand && (
                                            <span className="d-none d-md-inline">
                                                {' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
                                            </span>
                                        )}
                                    </div>) : null}
                                    {currentInscription.payment?.cardBrand && (
                                        <div className="d-md-none small mt-1 inscription-companions small mt-1">
                                            {currentInscription.payment?.cardBrand?.toUpperCase() +
                                                '-' +
                                                currentInscription.payment.cardLast4}
                                        </div>
                                    )}
                                    <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
                                    <div className="inscription-email small mt-1">
                                        {currentInscription.acceptedDT
                                            ? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
                                            : ''}
                                    </div>
                                </>
                            }
                            {(currentInscription.event.eventType !== 'PUBLIC_EVENT' || currentInscription.inscriptionStatus === 'ATTENDEED') &&
                                <>
                                    {(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
                                        currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
                                            (currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
                                    <br></br>
                                    <span className="smaller">
                                        {(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
                                            currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
                                    </span>
                                </>}


                        </td>
                    }
                </tr>}

        </>
    );
}
