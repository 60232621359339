import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DateHelper } from '../helpers/date-helper';
import { checkInscriptionsEveryStatus, checkInscriptionsSomeStatus, isPastOrNow } from '../helpers/inscription-helper';
import CustomModal from '../components/modals/customModal';
import useLoader from '../components/loader/useLoader';
import { dispatch } from 'd3';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { useDispatch } from 'react-redux';
import { IEvent } from '../models/interfaces/events.interface';
import { MdHourglassEmpty, MdClose, MdCheck } from 'react-icons/md';
import InscriptionQrModal from '../components/modals/inscriptionQrModal';
import { HiSearch } from 'react-icons/hi';
import useEventsApi from '../hooks/useEventsApi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BiPencil } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import { RiAddBoxLine } from 'react-icons/ri';
import { TInscriptionStatus } from '../models/types/types';

interface InscriptionSummaryNewProps {
	canChangeStatus: boolean;
	isPrivateFree: boolean;
	refreshInscription: any;
	inscription: IInscription;
	event: IEvent;
	noShadow?: boolean,
	semiTransparentBg?: boolean;
	hideButtons?: boolean;
	secondaryHeader?: boolean;
}

const InscriptionSummaryNew = (props: InscriptionSummaryNewProps) => {
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions,
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
	] = useInscriptionsApi();
	const [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
	] = useEventsApi();
	const [loader, showLoader, hideLoader] = useLoader();
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
		false
	);

	const [
		inscriptionToQRModal,
		setInscriptionToQRModal,
	] = useState<IInscription>(props.inscription);
	const [qrModalVisible, setQrModalVisible] = useState(false);
	const [inscriptionToPending, setInscriptionToPending] = useState<
		IInscription | undefined
	>(undefined);
	const [inscriptionToWaiting, setInscriptionToWaiting] = useState<
		IInscription | undefined
	>(undefined);
	const [
		showConfirmationModalPending,
		setShowConfirmationModalPending,
	] = useState(false);
	const [
		showConfirmationModalWaiting,
		setShowConfirmationModalWaiting,
	] = useState(false);
	const [inscriptionToReject, setInscriptionToReject] = useState<
		IInscription | undefined
	>(undefined);
	const [
		showConfirmationModalReject,
		setShowConfirmationModalReject,
	] = useState(false);

	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();

	const history = useHistory();
	const goToTickets = (e: any) => {
		if (!!props.inscription) {
			history.push('/inscribe-create/' + props.inscription?.event?._id);
		}
	};

	const cannotInscribe = (ev: any) => {
		return (
			DateHelper.daysFromToday(ev['inscriptionLimitDT']) < 0 ||
			(DateHelper.daysFromToday(ev['inscriptionLimitDT']) === 0 &&
				isPastOrNow(ev.inscriptionLimitHour))
		);
	};

	const rejectInscriptions = async (rejectOne: boolean) => {
		showLoader();
		if (!!props.inscription) {
			
			const statusToChange: (TInscriptionStatus | undefined)[] = ["PENDING", "ACCEPTED", "WAITING"];
			const inscriptionsToReject: IInscription[] = [];

			if(rejectOne && inscriptionToReject) {
				inscriptionsToReject.push(inscriptionToReject); 
			} else if (!rejectOne) {
				if(statusToChange.includes(props.inscription.inscriptionStatus)) inscriptionsToReject.push(props.inscription);
				if(props.inscription.companions) {
					props.inscription.companions
					.filter((c:IInscription) => 
						statusToChange.includes(c.inscriptionStatus))
					.map(c => inscriptionsToReject.push(c));
				}
			}
			const inscriptionsIdsToSendEmails: Set<string | undefined> = new Set(inscriptionsToReject.map(
				(y: IInscription) => {
					return y.email ? y._id : y.parentInscription;
				}
			));

			if (inscriptionsToReject.length > 0) {
				const inscriptionsIds = inscriptionsToReject.map((y:IInscription) => {
					return y._id;
				});

				const response: any = await dispatch(
					updateStatusMultiple({
						inscriptionStatus: 'REJECTED',
						ids: inscriptionsIds,
						loggedUserId: '0',
					})
				);
				// TODO se puede mejorar al igual que aceptar inscripciones por lote
				if (response) {
					inscriptionsIdsToSendEmails.forEach(async (id) =>
						await sendRejectedEmail(id)
					)
					
					await props.refreshInscription();
				}
			}
		}
		hideLoader();
	};

	const onCloseConfirmationModal = async (e: any) => {
		if (e) {
			setShowConfirmationModal(false);
			await rejectInscriptions(false);
		} else {
			setShowConfirmationModal(false);
		}
	};

	const onCloseConfirmationModalPending = async (e: any) => {
		if (e) {
			setShowConfirmationModalPending(false);
			if (!!props.event?._id) {
				showLoader();
				const event: any = await getEventById(props.event._id);
				hideLoader();
				if (!!event && !!event.maxCapacity && !!event.inscriptions) {
					const capacityExceeded =
						event.inscriptions?.filter(
							(x: IInscription) =>
								x.inscriptionStatus === 'WAITING' ||
								x.inscriptionStatus === 'PENDING' ||
								x.inscriptionStatus === 'ACCEPTED'
						).length >= event.maxCapacity;
					if (!capacityExceeded) {
						await performMoveToPendingInscription();
					} else {
						setShowErrorModal(true);
					}
				}
			}
		} else {
			setShowConfirmationModalPending(false);
		}
	};

	const onCloseConfirmationModalWaiting = async (e: any) => {
		if (e) {
			setShowConfirmationModalWaiting(false);
			await performMoveToWaitingInscription();
		} else {
			setShowConfirmationModalWaiting(false);
		}
	};

	const performMoveToPendingInscription = async () => {
		showLoader();
		if (!!inscriptionToPending) {
			let inscriptionsToPending = [inscriptionToPending];
			let inscriptionsIdsToSendEmails = !!inscriptionToPending.parentInscription
				? [inscriptionToPending.parentInscription]
				: [inscriptionToPending._id];

			if (inscriptionsToPending.length > 0) {
				const inscriptionsIds = inscriptionsToPending.map((y) => {
					return y._id;
				});

				const response: any = await dispatch(
					updateStatusMultiple({
						inscriptionStatus: 'PENDING',
						ids: inscriptionsIds,
						loggedUserId: '0',
					})
				);
				// TODO se puede mejorar al igual que aceptar inscripciones por lote
				if (response) {
					if (!!props.refreshInscription) {
						await props.refreshInscription();
					}
				}
			}
		}
		hideLoader();
	};
	const performMoveToWaitingInscription = async () => {
		showLoader();
		if (!!inscriptionToWaiting) {
			let inscriptionsToWaiting = [inscriptionToWaiting];
			let inscriptionsIdsToSendEmails = !!inscriptionToWaiting.parentInscription
				? [inscriptionToWaiting.parentInscription]
				: [inscriptionToWaiting._id];

			if (inscriptionsToWaiting.length > 0) {
				const inscriptionsIds = inscriptionsToWaiting.map((y) => {
					return y._id;
				});

				const response: any = await dispatch(
					updateStatusMultiple({
						inscriptionStatus: 'WAITING',
						ids: inscriptionsIds,
						loggedUserId: '0',
					})
				);
				// TODO se puede mejorar al igual que aceptar inscripciones por lote
				if (response) {
					if (!!props.refreshInscription) {
						await props.refreshInscription();
					}
				}
			}
		}
		hideLoader();
	};

	const confirmReject = () => {
		setShowConfirmationModal(true);
	};

	const getConfirmationMessage = () => {
		if (
			!!props.inscription &&
			!!props.inscription.companions &&
			props.inscription.companions?.length > 0
		) {
			return t('confirmation.confirm-reject-plural');
		} else {
			return t('confirmation.confirm-reject');
		}
	};

	const openQRModal = (e: any, inscripted: IInscription) => {
		e.preventDefault();
		e.stopPropagation();
		setInscriptionToQRModal(inscripted);
		setQrModalVisible(true);
	};

	const confirmQr = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setShowConfirmationModal(true);
	};

	const rejectInscription = (insc: IInscription | undefined) => {
		if (!!insc) {
			setInscriptionToReject(insc);
			setShowConfirmationModalReject(true);
		}
	};

	const moveToPending = async (insc: IInscription | undefined) => {
		if (!!props.event?._id) {
			showLoader();
			const event: any = await getEventById(props.event._id);
			hideLoader();
			if (
				!!insc &&
				!!event &&
				!!event.maxCapacity &&
				!!event.inscriptions
			) {
				const capacityExceeded =
					event.inscriptions?.filter(
						(x: IInscription) =>
							x.inscriptionStatus === 'WAITING' ||
							x.inscriptionStatus === 'PENDING' ||
							x.inscriptionStatus === 'ACCEPTED'
					).length >= event.maxCapacity;

				if (capacityExceeded) {
					if (!event.allowsWaiting) {
						setShowErrorModal(true);
					} else {
						setInscriptionToWaiting(insc);
						setShowConfirmationModalWaiting(true);
						//TODO	agregar a la lista de espera
					}
				} else {
					setInscriptionToPending(insc);
					setShowConfirmationModalPending(true);
				}
			}
		}
	};

	const onCloseConfirmationModalReject = async (e: any) => {
		if (e) {
			setShowConfirmationModalReject(false);
			await rejectInscriptions(true);
		} else {
			setShowConfirmationModalReject(false);
		}
	};

	const editInscription = (e: any) => {
		if (!!props.inscription) {
			history.push('/inscribe-editing/' + props.inscription?._id);
		}
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const getSuccessCheckEmailMessage = () => {
		const isGroupal =
			props.inscription.companions &&
			props.inscription.companions.length > 0;
		const companions = props.inscription.companions || [];
		const companionsNames = isGroupal
			? companions.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
			  })
			: null;
		const companionsEmails = isGroupal
			? companions.filter((x) => !!x.email)
			: [];

		if (isGroupal && companionsEmails?.length > 0 && props.event.hosts) {
			return props.event.hosts?.length > 1
				? i18n.t(
						'success.inscription-check-groupal-no-confirmation-hosts'
				  )
				: i18n.t('success.inscription-check-groupal-no-confirmation');
		}
		if (props.event.hosts)
			return props.event.hosts?.length > 1
				? i18n.t(
						'success.inscription-check-single-no-confirmation-hosts'
				  )
				: i18n.t('success.inscription-check-single-no-confirmation');
	};

	const returnInscripteds = () => {
		const listInscripteds = [props.inscription];
		props.inscription?.companions?.map((companion) => {
			listInscripteds.push(companion);
		});

		return listInscripteds.map((inscripted, index) => {
			return (
				<div
					className={`w-100 d-flex flex-row inscription-container mb-3 p-2 ${
						inscripted.inscriptionStatus === 'DENIED' && 'disabled'
					}`}
					key={index}
				>
					<div className="d-flex align-items-center mr-3 inscription-icon-container">
						{!!inscripted?.qrImage &&
						inscripted.inscriptionStatus === 'ACCEPTED' ? (
							<div
								className="d-flex align-items-center justify-content-center position-relative cursor-pointer"
								onClick={(e) => {
									openQRModal(e, inscripted);
								}}
							>
								<img
									className="inscription-qr-summ qr"
									src={inscripted.qrImage}
								/>
								<div className="position-absolute search-icon-container top-50 start-50 translate-middle rounded-circle p-2 d-flex align-items-center justify-content-center">
									<HiSearch className="text-white" />
								</div>
							</div>
						) : inscripted.inscriptionStatus === 'PENDING' ? (
							<span className="inscription-qr-summ d-flex align-items-center justify-content-center pending-icon-container">
								<div className="w-50 h-50">
									<MdHourglassEmpty className="w-100 h-100" />
								</div>
							</span>
						) : inscripted.inscriptionStatus === 'ATTENDEED' ? (
							<span className="inscription-qr-summ d-flex align-items-center justify-content-center attendeed-icon-container">
								<div className="w-50 h-50">
									<MdCheck className="w-100 h-100" />
								</div>
							</span>
						) : inscripted.inscriptionStatus === 'REJECTED' ? (
							<span className="inscription-qr-summ d-flex align-items-center justify-content-center rejected-icon-container">
								<div className="w-50 h-50">
									<MdClose className="w-100 h-100" />
								</div>
							</span>
						) : inscripted.inscriptionStatus === 'WAITING' ? (
							<span className="inscription-qr-summ d-flex align-items-center justify-content-center waiting-icon-container">
								<div className="w-50 h-50">
									<AiOutlineClockCircle className="w-100 h-100" />
								</div>
							</span>
						) : (
							<span className="inscription-qr-summ d-flex align-items-center justify-content-center denied-icon-container">
								<div className="w-50 h-50">
									<MdClose className="w-100 h-100" />
								</div>
							</span>
						)}
					</div>
					<div className="d-flex flex-column w-100 overflow-hidden">
						<div className="d-flex flex-column">
							<div className="d-inline-block text-truncate inscription-name-summ m-0 w-100">
								{(inscripted?.name || inscripted?.user?.name) +
									' ' +
									(inscripted?.surname ||
										inscripted?.user?.surname)}
							</div>

							<label className="d-inline-block text-truncate inscription-email-summ m-0 w-100">
								{!!inscripted?.email ? (
									inscripted.email
								) : (
									<div className="invisible">1</div>
								)}
							</label>
						</div>
						<div className="d-flex flex-wrap justify-content-between h-100 position-relative">
							<div className="d-flex align-items-center">
								<span
									className={`status-tag m-0 mr-1 
										${inscripted?.inscriptionStatus} 
										${inscripted.inscriptionStatus !== 'PENDING' && 'mr-5 '}`}
								>
									{t(
										'inscription.status.' +
											inscripted?.inscriptionStatus
									)}
								</span>
							</div>

							{/* {props.canChangeStatus &&
								props.isPrivateFree &&
								inscripted.inscriptionStatus === 'REJECTED' && (
									<div className="button-container ml-auto align-self-end">
										<button
											className="pl-2 pr-2 button-ok small btn btn-primary"
											onClick={() =>
												moveToPending(inscripted)
											}
										>
											{t('reinscript')}
										</button>
									</div>
								)} */}
							{props.canChangeStatus &&
								props.isPrivateFree &&
								inscripted?.inscriptionStatus !== 'DENIED' &&
								inscripted?.inscriptionStatus !==
									'REJECTED' && (
									<div className="button-container ml-auto align-self-end">
										<button
											className="pl-2 pr-2 button-delete small btn btn-primary"
											onClick={() =>
												rejectInscription(inscripted)
											}
										>
											{t('reject')}
										</button>
									</div>
								)}
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<>
			{loader}
			{!!props.inscription && (
				<div className="w-100 p-lg-0 position-relative h-100">
					{
						props.secondaryHeader ?(
							<div className="col-12 p-0">
								<h5 className="w-100 mb-3 mt-4">{t('inscription.tickets')}</h5>
							</div>
						) : (
							<div className="w-100 d-flex flex-column align-items-center text-center">
								<div className="star-check" />
								<h3 className="inscription-title w-100">
									{t('inscription.details-title')}
								</h3>
							</div>
						)
					}
					{checkInscriptionsSomeStatus(props.inscription ,"PENDING") && <h3 className='inscription-card-subtitle w-100 text-center'>{getSuccessCheckEmailMessage()}</h3>}

					<div className="mt-3 mb-5">{returnInscripteds()}</div>

					{
						!props.hideButtons && (
							<div className="d-flex flex-column flex-lg-row justify-content-between align-items-center inscription-buttons-container w-100">
								{!cannotInscribe(props.event) &&
									props.event.eventType === 'PRIVATE_EVENT' &&
									((!!props.inscription.companions &&
										props.event.companionsLimit &&
										props.inscription.companions?.length <
											props.event?.companionsLimit) ||
										((!!props.event.questions &&
											props.event.questions.length > 0) || checkInscriptionsSomeStatus(props.inscription, "REJECTED")) || props.event.includeMenu) && !checkInscriptionsEveryStatus(props.inscription, "DENIED") && (
										<Button
											className="button-main d-flex flex-row align-items-center justify-content-center w-100 m-2"
											onClick={editInscription}
										>
											<BiPencil className="mr-1" />
											{t('inscription.edit')}
										</Button>
									)}

								{!cannotInscribe(props.event) &&
									props.event.eventType === 'PRIVATE_EVENT' &&
									(
										checkInscriptionsSomeStatus(props.inscription, "PENDING") || 
										checkInscriptionsSomeStatus(props.inscription, "WAITING") || 
										checkInscriptionsSomeStatus(props.inscription, "ACCEPTED")
									) && (
										<Button
											className="button-cancel-inscription d-flex flex-row align-items-center justify-content-center w-100 m-2"
											onClick={confirmReject}
										>
											<IoClose className="mr-1" />
											{t('inscription.reject-inscription')}
										</Button>
									)}
								{!cannotInscribe(props.event) &&  (
									<Button
										className="button-outline-primary d-flex flex-row align-items-center justify-content-center w-100 m-2"
										onClick={goToTickets}
									>
										<RiAddBoxLine className="mr-1" />
										{t('new-inscription')}
									</Button>
								)}
							</div>
						) 
					}
				</div>
			)}

			{showConfirmationModal && (
				<CustomModal
					isShowing={showConfirmationModal}
					parentCallback={onCloseConfirmationModal}
					message={getConfirmationMessage()}
					buttonCancel={t('cancel')}
					buttonOK={t('accept')}
					type="WARNING_EDIT"
				/>
			)}

			{showConfirmationModalReject && (
				<CustomModal
					isShowing={showConfirmationModalReject}
					parentCallback={onCloseConfirmationModalReject}
					message={t('confirmation.confirm-reject')}
					buttonCancel={t('cancel')}
					buttonOK={t('accept')}
					type="WARNING_EDIT"
				/>
			)}

			{showConfirmationModalPending && (
				<CustomModal
					isShowing={showConfirmationModalPending}
					parentCallback={onCloseConfirmationModalPending}
					message={t('confirmation.confirm-reinscript')}
					buttonCancel={t('cancel')}
					buttonOK={t('accept')}
					type="WARNING_EDIT"
				/>
			)}
			{showConfirmationModalWaiting && (
				<CustomModal
					isShowing={showConfirmationModalWaiting}
					parentCallback={onCloseConfirmationModalWaiting}
					message={t('confirmation.inscription-waiting-single')}
					buttonCancel={t('cancel')}
					buttonOK={t('accept')}
					type="WARNING_EDIT"
				/>
			)}

			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={t('event.error.max-capacity-inscripted')}
					type="ERROR"
					buttonOK={t('accept')}
					title={t('error.modal-title-oops')}
				/>
			)}
			{qrModalVisible && !!props.inscription && (
				<InscriptionQrModal
					visible={qrModalVisible}
					inscription={inscriptionToQRModal}
					onHide={() => setQrModalVisible(false)}
					hideResendButton={true}
					resendQRFn={confirmQr}
					hideCompanions={true}
					event={props.event}
				></InscriptionQrModal>
			)}
		</>
	);
};

export default InscriptionSummaryNew;
