import { useHistory, Redirect } from 'react-router-dom';

import useApi from '../hooks/useApi';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import NoResults from '../components/no-results/noResults';
import i18next from 'i18next';
import { useState, useEffect } from 'react';
import Paginator from '../components/paginator/paginator';
import { CONTACTS_PAGE_SIZE } from '../../constants';
import CustomModal from '../components/modals/customModal';
import { IoClose, IoSearch } from 'react-icons/io5';
import useContactsApi from '../hooks/useContactsApi';
import { IContact } from '../models/interfaces/contacts.interface';
import { ContactRow } from './contactRow';

export function ContactList() {
	const [contacts, setContacts] = useState<IContact[] | undefined>();
	const [contactsPage, setContactsPage] = useState(0);
	const [contactsTotal, setContactsTotal] = useState(0);
	const [loader, showLoader, hideLoader] = useLoader();
	const [
		loggedUser,
	] = useApi();
	const [createContact, updateContact, findContacts] = useContactsApi();


	const [textFilter, setTextFilter] = useState('');
	const [showErrorReportModal, setShowErrorReportModal] = useState(false);

	const [contacted, setContacted] = useState<boolean>(false);

	const { t, i18n } = useTranslation();

	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');

	useEffect(() => {
		handleDataLoad();
	}, [loggedUser]);

	const handleDataLoad = async () => {
		if (loggedUser) {
			showLoader();
			const usrs = await loadContactList(0, false);
			setContacts(usrs);
			hideLoader();
		}


	};

	const loadContactList = async (pageNumber: number, _cted: boolean, reset?: boolean) => {
		const _contacts: any =
			await findContacts(pageNumber, reset ? '' : textFilter, _cted ? 'true' : 'false');

		if (!!_contacts) {
			setContactsTotal(_contacts['total']);
			return _contacts['contacts'];
		}
		return [];
	};

	const handlePrev = async () => {
		showLoader();
		const pending = await loadContactList(contactsPage - 1, contacted);
		if (pending && pending.length > 0) {
			setContacts(undefined);
			setContacts(pending);
		}
		setContactsPage(contactsPage - 1);
		hideLoader();
	};
	const handleNext = async () => {
		showLoader();
		const pending = await loadContactList(contactsPage + 1, contacted);
		if (pending && pending.length > 0) {
			setContacts(undefined);
			setContacts(pending);
		}
		setContactsPage(contactsPage + 1);
		hideLoader();
	};

	const handleTextFilterChange = (e: any) => {
		setTextFilter(e.target.value);
	};

	const clearSearchFilter = async () => {
		setTextFilter('');
		await handleApplyFilters(contacted, true);
	}
	const handleKeyDown = async (e: any) => {
		if (e.keyCode == 13) {
			await handleApplyFilters(contacted);
		}
	};
	const handleApplyFilters = async (ct: boolean, reset?: boolean) => {
		if (loggedUser) {
			showLoader();
			const filteredUsrs = await loadContactList(0, ct, reset);
			setContacts(filteredUsrs);
			setContactsPage(0);
			hideLoader();
		}
	};


	const handleCloseErrorReportModal = () => {
		setShowErrorReportModal(false);
	}

	const onCheckedFilterChange = (val: boolean) => {
		setContacted(val);
		handleApplyFilters(val);
	}
	const clickSearch = async () => {
		await handleApplyFilters(contacted);
	}

	const history = useHistory();

	return (
		<>
			{loader}
			{!isLoggedUser && <Redirect to="/login" />}
			{isLoggedUser && loggedUser && loggedUser['role'] && (
				<>

					{loggedUser['role']['roleLevel'] === 32 ? (
						<div className="dashboard-content form">

							<div className="form-container col-md-10">

								<div className='d-flex align-items-md-center justify-content-between flex-column flex-md-row'>
									<div className="col-md-4 p-0">

										<div className="row col-12 m-0 p-0  align-items-center search-input user-events">
											<input
												className="form-control"
												type="text"
												name="filterNameEmail"
												value={textFilter != 'none' ? textFilter : ''}
												placeholder={t('community-filters.text-filter')}
												onKeyDown={handleKeyDown}
												onChange={handleTextFilterChange}
											/>
											<div className="clear-search" onClick={clearSearchFilter}>
												<IoClose />
											</div>
											<div className="input-button-search">
												<Button className="rounded-button rounded-button-ok" onClick={clickSearch} >
													<IoSearch />
												</Button>
											</div>
										</div>
									</div>
									<div className='d-flex mt-2 mt-md-0 justify-content-end'>
										<div className="d-flex">
											<div onClick={() => onCheckedFilterChange(true)} className={"filter-button left d-flex align-items-center justify-content-center w-auto pr-2 pl-2 " + (contacted ? "selected " : "")} id="contacted">
												{t("contacted")}
											</div>
											<div onClick={() => onCheckedFilterChange(false)} className={"filter-button right d-flex align-items-center justify-content-center w-auto pr-2 pl-2 " + (!contacted ? "selected " : "")} id="not-contacted">
												{t("not-contacted")}
											</div>
										</div>

									</div>
								</div>

								{!!contacts &&
									<Table className='contact-table mt-3 w-100' responsive striped borderless>
										<thead className="w-100">
											<tr>
												<th className="">{t('user.name')}</th>
												<th className="">{t('user.email')}</th>
												<th className="">{t('contact-form.message')}</th>

												<th className="">{t('created')}</th>
												<th className="">{t('contacted')}</th>

											</tr>
										</thead>
										<tbody>
											{contacts?.map((contact: IContact, i) => (
												<ContactRow contact={contact} key={contact._id} onContacted={clearSearchFilter}></ContactRow>
											))}
										</tbody>


									</Table>

								}
								{contacts && contacts.length == 0 && (
									<div className="w-100 align-items-center">
										<NoResults
											text={i18next.t(
												'contacts-not-found'
											)} />
									</div>
								)}

								{contacts &&
									contacts.length > 0 && (
										<Paginator
											currentPage={contactsPage}
											totalPages={Math.ceil(
												contactsTotal /
												CONTACTS_PAGE_SIZE
											)}
											onPrevious={handlePrev}
											onNext={handleNext}
										></Paginator>
									)}
							</div>

							{showErrorReportModal && (
								<CustomModal
									isShowing={showErrorReportModal}
									parentCallback={handleCloseErrorReportModal}
									message={t('event.error-delete-report')}
									title={t('error.modal-title-oops')}
									buttonOK={t('accept')}
									type="ERROR"
								/>
							)}
						</div>
					) : (
						<Redirect to="dashboard"></Redirect>
					)}
				</>
			)}
		</>
	);
}

export default ContactList;