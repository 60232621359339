import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IUser } from '../models/interfaces/users.interface';
import { IContact } from '../models/interfaces/contacts.interface';
import { CONTACTS_PAGE_SIZE } from '../../constants';
import useUsersApi from './useUsersApi';
const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;


export default () => {
	const dispatch = useDispatch();
    const [, , , logoutUser] = useUsersApi();
	const token = localStorage.getItem('token');
	const tokenAuth = {
		headers: { Authorization: `Bearer ${token}` },
	};


	const createContact = createAsyncThunk(
		'createContact',
		async (contact: IContact) => {
			try {
				const response: any = await fetch(API_URL + '/api/contacts/email', {
                    method: 'POST',
                    body: JSON.stringify(contact),
                    headers: { 'Content-type': 'application/json' },
                    credentials: 'omit',
                    mode: 'cors'
                });
				try {
					
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail create Contact');
				throw err;
			}
		}
	);

	const updateContact = createAsyncThunk(
		'updateContact',
		async (contact: IContact) => {
			try {
				const response: any = await fetch(API_URL + '/api/contacts/' + contact._id,
					{
						method: 'PATCH',
						body: JSON.stringify(contact),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail updateUser');
				console.log(err);
			}
		}
	);

    const findContacts = async (
		page: number,
		text?: string,
		contacted?: string,
		pageSize?: number,
	) => {
		const filterText = text && text !== '' ? text : 'none';
        const contactedText = contacted && contacted !=='' ? contacted : 'none';
		const perPage = typeof pageSize === 'number' && pageSize >= 0 ? pageSize : CONTACTS_PAGE_SIZE;

		try {
			const response: any = await fetch(API_URL + '/api/contacts/findContacts/'
                 + filterText + '/' 
                 + contactedText + '/'
                 + page +'/'
                 + perPage
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				if (response.status !== 200 ){
					return undefined;
				}
				const data = await response.json();
				if (!!data){
					return data;
				}
				return undefined;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail FINDCONTACTS');
			console.log(err);
		}
	};
	
	return [
		createContact,
        updateContact,
        findContacts
	] as const;
};
