import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../globalStates';
import useUsersApi from '../../hooks/useUsersApi';

import { useTranslation } from 'react-i18next';
import useLoader from '../../components/loader/useLoader';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import CustomModal from '../../components/modals/customModal';
import Footer from '../../components/footer/footer';

import { NAME_MAX_LENGTH, SHOW_SIGN_UP, SURNAME_MAX_LENGTH, YES_NO } from '../../../constants';
import { Validator } from '../../helpers/validators';
import './signup.scss';
import { IUser } from '../../models/interfaces/users.interface';
import useCaptchaApi from '../../hooks/useCaptchaApi';
import ReCAPTCHA from 'react-google-recaptcha';
import useApi from '../../hooks/useApi';
import { Redirect } from 'react-router-dom';
import { getSelectSingleOptions } from '../../events/form/utils';

export function SignUpForm() {
    const captchaRef = useRef(null);
    const [checkToken] = useCaptchaApi();
    const [validCaptcha, setValidCaptcha] = useState<boolean>(false);
    const [strError, setStrError] = useState('');
    const [authState, setAuthState] = useContext(AuthContext);
    const [loader, showLoader, hideLoader] = useLoader();
    const { t } = useTranslation();
    const [loggedUser,
        getLoggedUser,
        getUserById,
        getMyUsers,
        getUsers,
        getCreatableRoles,
        getMyHosts,
        getMyAdmissions,
        getMyPlanners,
        getOrganizer,
        getMyEvents,
        getAllOrganizers,
        getAllVisibleRoles,
        getAllRoles,
        uploadImage,
        getCommunityFilteredUsers,
        getMyPastEvents,
        getPublicEvents,
        getCreatablePublicRoles] = useApi();
    const [deleteUser,
        createUser,
        updateUser,
        logoutUser,
        getUserByEmail,
        getRoleByName,
        updateUserPassword,
        changeUserPassword,
        getSendResetPasswordEmail,
        deleteUserByOrganizer,
        createPublicUser] = useUsersApi();

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [emailAlreadyInscripted, setEmailAlreadyInscripted] = useState(false);
    const [emailNotAllowed, setEmailNotAllowed] = useState(false);
    const [isParticipant, setIsParticipant] = useState(false);
    const [emailErrorFormat, setEmailErrorFormat] = useState(false);
    const [repeatEmailErrorFormat, setRepeatEmailErrorFormat] = useState(false);
    const yesNoOptions = getSelectSingleOptions(YES_NO, 'profile-type.');
    //const [isPersonOrganizer, setIsPersonOrganizer] = useState<boolean>();
    const [isOrganizer, setIsOrganizer] = useState<string>();
    const validateAll = (form: any) => {
        clearAllValidations();
        const isValidEmail = !checkIsInvalidEmail(email);
        setEmailErrorFormat(!isValidEmail);
        const isValidRepeatEmail = !checkIsInvalidEmail(repeatEmail);
        setRepeatEmailErrorFormat(!isValidRepeatEmail);

        return (
            !!form && form.checkValidity() &&
            !!isOrganizer &&
            !Validator.name(name) &&
            ((isOrganizer === 'no' && !Validator.name(lastName)) || (isOrganizer === 'yes')) &&
            isValidEmail &&
            isValidRepeatEmail &&
            !emailAlreadyInscripted &&
            !emailErrorFormat &&
            !emailNotAllowed &&
            email?.toLowerCase() == repeatEmail?.toLowerCase()


        );
    };

    const validateCaptcha = async () => {
        try {
            setValidCaptcha(false);
            if (!!captchaRef.current) {
                const token = Object(captchaRef.current).getValue();
                const r: any = await dispatch(checkToken(token));
                if (!!r && !!r['payload'] && r.payload === true) {
                    setValidCaptcha(true);
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };
    const handleOnSelectYesNo = (event: any) => {
        const val = event.name;
        setIsOrganizer(val);
    };
    const handleSubmit = async (e: any) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            const form = e.currentTarget;


            const isValidForm = validateAll(form);
            if (isValidForm) {
                showLoader();
                const roles = await getCreatablePublicRoles();
                if (!roles || (roles?.statusCode != undefined && roles?.statusCode !== 200)) {
                    setStrError(t('error.unexpected'));
                    setShowErrorModal(true);
                } else {
                    const role = roles.find((r: any) => r.name === 'USER_ORGANIZER');
                    console.log(role)
                    const roleOrganizers = {
                        roleId: role._id,
                        roleName: role.name,
                        roleLevel: role.level,
                        organizer: name
                    };
                    const usr: IUser = {
                        name,
                        surname: lastName,
                        email: email,
                        status: 'INACTIVE',
                        rolesOrganizers: [roleOrganizers],
                        createdDT: new Date(),
                        lastUpdatedDT: new Date(),
                        isPersonOrganizer: isOrganizer == 'no'
                    }
                    
                    const response: any = await dispatch(createPublicUser(usr));

                    if (response['payload'] && response['payload']['_id']) {
                        setStrError('');

                        hideLoader();
                        setShowSuccessModal(true);
                    } else {
                        hideLoader();
                        // Ya existe el usuario, pero para otro Organizer
                        if (response['payload'] && response['payload']['statusCode'] == 418 && response['payload']['message'] && response['payload']['message'].length > 0) {

                            setStrError(t('error.user.alreadyRegistered'));
                            setShowErrorModal(true);


                        } else {
                            if (response['payload'] && response['payload']['message']) {
                                setStrError(
                                    t('error.' + response['payload']['message'])
                                );
                                setShowErrorModal(true);
                            } else if (
                                response['error'] &&
                                response['error']['message']
                            ) {
                                if (response['error']['message'].indexOf('409') >= 0) {
                                    setStrError(t('error.user.alreadyRegistered'));
                                    setShowErrorModal(true);
                                } else {
                                    setStrError(t('error.unexpected'));
                                    setShowErrorModal(true);
                                }
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            console.log(e);
            setStrError(t('error.unexpected'));
            setShowErrorModal(true);
        } finally {
            hideLoader();

            setValidated(true);
        }

    };

    const handleOnLogout = async () => {
        const result: any = await dispatch(logoutUser());
        if (result) {
            await setAuthState(null);
        }
    };
    const handleCloseSuccessModal = async (e: any) => {
        if (e) {
            await handleOnLogout();
            history.push('/login');
        }
        setShowSuccessModal(false);
    };
    const handleCloseErrorModal = (e: any) => {
        setShowErrorModal(false);
    };
    const history = useHistory();
    const goToLanding = () => {
        history.push('/');
    }

    const EMAIL_REGEX = new RegExp(
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const [repeatEmail, setRepeatEmail] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(undefined);
    const handleOnCreateName = (event: any) => {
        setName(event.target.value);
    };
    const handleOnCreateLastName = (event: any) => {
        setLastName(event.target.value);
    };
    const clearAllValidations = () => {
        setIsParticipant(false);
        setEmailNotAllowed(false);
        setEmailAlreadyInscripted(false);
        setEmailErrorFormat(false);
        setRepeatEmailErrorFormat(false);
    };

    const handleOnCreateEmail = async (event: any) => {
        clearAllValidations();
        setEmail(event.target.value);

        const isValidEmail = !checkIsInvalidEmail(event.target.value);
        setEmailErrorFormat(!isValidEmail);
    };

    const checkIsInvalidEmail = (pEmail: any) => {
        return !EMAIL_REGEX.test(pEmail);
    };
    const equalsEmails = (
        email1: string | undefined,
        email2: string | undefined
    ) => {
        if (
            (email1 == undefined || email1 == null || email1.trim() == '') &&
            (email2 == undefined || email2 == null || email2.trim() == '')
        ) {
            return true;
        }
        return email1?.toLowerCase() === email2?.toLowerCase();
    };
    const preventPaste = (e: any) => {
        e.preventDefault();
        return false;
    };
    const handleOnCreateEmailRepeat = async (event: any) => {
        clearAllValidations();
        setRepeatEmail(event.target.value);
        const isValidEmail = !checkIsInvalidEmail(email);
        setEmailErrorFormat(!isValidEmail);
        const isValidRepeatEmail = !checkIsInvalidEmail(event.target.value);
        setRepeatEmailErrorFormat(!isValidRepeatEmail);
    };
    return (
        <>
            {loader}
            {showSuccessModal && (
                <CustomModal
                    isShowing={showSuccessModal}
                    parentCallback={handleCloseSuccessModal}
                    message={t('user.register-success')}
                    title={t('user.register-success-title')}
                    buttonOK={t('Continue')}
                    type="SUCCESS"
                />
            )}
            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={
                        strError != ''
                            ? strError

                            : t('error.user-create')
                    }
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}

            {!SHOW_SIGN_UP && <Redirect to="/login" />}
            {SHOW_SIGN_UP && <div className="row w-100 login-container">

                <div className="col-12 p-0 m-0 d-flex justify-content-center">
                    <div className="d-flex flex-column justify-content-center align-items-center signup-form">
                        <div className="d-flex row mt-3 flex-column logo-container mb-4 justify-content-center align-items-center">
                            <div className="logo white cursor-pointer" onClick={goToLanding}></div>
                            <div className='inscription-secondary-title'>
                                {t("user.register")}
                            </div>
                        </div>
                        <div className='login-box w-100'>
                            <Form
                                noValidate
                                validated={validated}

                                onSubmit={handleSubmit}>
                                {/* Es organización? */}
                                <Form.Group className='col-12 m-0 p-0'>

                                    <Form.Label className="input-label">{t('user.is-organizer')}</Form.Label>
                                    <Select
                                        required

                                        isInvalid={validated && !isOrganizer}
                                        className={
                                            validated && !isOrganizer ? "select-control invalid" : (validated && !!isOrganizer ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={yesNoOptions}
                                        value={yesNoOptions.find(x => x.value === isOrganizer)}
                                        onChange={handleOnSelectYesNo}
                                    />
                                    {validated && !isOrganizer && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!isOrganizer && `${t('error.required')}`}
                                    </Form.Control.Feedback>}

                                </Form.Group>
                                {/* nombre y apellido  */}
                                <div className="col-12 m-0 p-0 row">
                                    <div className={(isOrganizer === 'no' ? "col-md-6 " : "col-md-12 ") + "m-0 p-0 pr-md-1"}>
                                        <Form.Group>
                                            <Form.Label className="input-label">{`${t(
                                                'user.name'
                                            )}*`}</Form.Label>
                                            <Form.Control
                                                disabled={!isOrganizer}
                                                type="text"
                                                name="name"
                                                autoComplete="on"
                                                placeholder={t(
                                                    'user.name'
                                                )}
                                                onChange={handleOnCreateName}
                                                maxLength={NAME_MAX_LENGTH}
                                                isInvalid={
                                                    validated &&
                                                    Validator.name(name)
                                                }
                                                required
                                            />
                                            {validated && (
                                                <Form.Control.Feedback type="invalid">
                                                    {!name &&
                                                        `${t(
                                                            'error.required'
                                                        )}`}
                                                    {!!name &&
                                                        `${t(
                                                            'error.invalid'
                                                        )}`}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </div>
                                    {
                                        isOrganizer === 'no' &&
                                        <div className="col-md-6 m-0 p-0 pl-md-1">
                                            <Form.Group id="main-inscription-surname">
                                                <Form.Label className="input-label">{`${t(
                                                    'user.lastname'
                                                )}*`}</Form.Label>
                                                <Form.Control
                                                    required={isOrganizer === 'no'}
                                                    disabled={!isOrganizer}
                                                    autoComplete="on"
                                                    maxLength={
                                                        SURNAME_MAX_LENGTH
                                                    }
                                                    isInvalid={
                                                        validated &&
                                                        Validator.name(
                                                            lastName
                                                        )
                                                    }
                                                    type="text"
                                                    name="lastName"
                                                    placeholder={t(
                                                        'user.lastname'
                                                    )}
                                                    onChange={
                                                        handleOnCreateLastName
                                                    }
                                                />
                                                {validated && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {!lastName &&
                                                            `${t(
                                                                'error.required'
                                                            )}`}
                                                        {!!lastName &&
                                                            `${t(
                                                                'error.invalid'
                                                            )}`}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </div>
                                    }
                                </div>

                                {/* email       */}
                                <div className="col-12 m-0 p-0 row">
                                    <div className="col-md-6 m-0 p-0 pr-md-1">
                                        <Form.Group id="main-inscription-email">
                                            <Form.Label className="input-label">{`${t(
                                                'user.email'
                                            )}*`}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                autoComplete="none"
                                                disabled={!isOrganizer}
                                                //className={isLoading ? 'loading' : ''}
                                                isInvalid={
                                                    emailAlreadyInscripted ||
                                                    isParticipant ||
                                                    emailErrorFormat ||
                                                    emailNotAllowed
                                                }
                                                required
                                                placeholder={t(
                                                    'user.email'
                                                )}
                                                onChange={
                                                    handleOnCreateEmail
                                                }
                                                maxLength={70}
                                                contentEditable={
                                                    true
                                                }
                                            />
                                            {email &&
                                                !emailAlreadyInscripted &&
                                                !isParticipant &&
                                                !emailNotAllowed &&
                                                emailErrorFormat && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.email-format'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}

                                            {email &&
                                                emailAlreadyInscripted &&
                                                !emailErrorFormat &&
                                                !isParticipant &&
                                                !emailNotAllowed && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.user.alreadyRegistered'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}

                                            {email &&
                                                !emailAlreadyInscripted &&
                                                !emailErrorFormat &&
                                                isParticipant &&
                                                !emailNotAllowed && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.email-is-participant'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}

                                            {email &&
                                                !emailAlreadyInscripted &&
                                                !emailErrorFormat &&
                                                !isParticipant &&
                                                emailNotAllowed && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.email-not-allowed'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}

                                            {!email && validated && (
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className="custom-error"
                                                >
                                                    {`${t(
                                                        'error.email-required'
                                                    )}`}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 m-0 p-0 pl-md-1">
                                        <Form.Group id="main-inscription-repeatEmail">
                                            <Form.Label className="input-label">{`${t(
                                                'user.repeat-email'
                                            )}*`}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="repeatEmail"
                                                required
                                                disabled={!isOrganizer}
                                                autoComplete="none"
                                                maxLength={70}
                                                isInvalid={
                                                    !equalsEmails(
                                                        email,
                                                        repeatEmail
                                                    ) ||
                                                    emailAlreadyInscripted ||
                                                    isParticipant ||
                                                    emailNotAllowed ||
                                                    repeatEmailErrorFormat
                                                }
                                                placeholder={t(
                                                    'user.repeat-email'
                                                )}
                                                onChange={
                                                    handleOnCreateEmailRepeat
                                                }
                                                onCopy={preventPaste}
                                                onDrag={preventPaste}
                                                onDrop={preventPaste}
                                                onPaste={preventPaste}
                                                contentEditable={
                                                    true
                                                }
                                            />
                                            {repeatEmail &&
                                                !emailAlreadyInscripted &&
                                                !isParticipant &&
                                                !emailNotAllowed &&
                                                email == repeatEmail &&
                                                repeatEmailErrorFormat && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.email-format'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}
                                            {repeatEmail &&
                                                !repeatEmailErrorFormat &&
                                                !equalsEmails(
                                                    email,
                                                    repeatEmail
                                                ) && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.repeat-email'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}
                                            {repeatEmail &&
                                                !repeatEmailErrorFormat &&
                                                email == repeatEmail &&
                                                emailAlreadyInscripted &&
                                                !isParticipant &&
                                                !emailNotAllowed && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.user.alreadyRegistered'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}
                                            {repeatEmail &&
                                                !repeatEmailErrorFormat &&
                                                email == repeatEmail &&
                                                !emailAlreadyInscripted &&
                                                isParticipant &&
                                                !emailNotAllowed && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.email-is-participant'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}
                                            {repeatEmail &&
                                                !repeatEmailErrorFormat &&
                                                email == repeatEmail &&
                                                !emailAlreadyInscripted &&
                                                !isParticipant &&
                                                emailNotAllowed && (
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="custom-error"
                                                    >
                                                        {`${t(
                                                            'error.email-not-allowed'
                                                        )}`}
                                                    </Form.Control.Feedback>
                                                )}
                                            {validated && !repeatEmail && (
                                                <Form.Control.Feedback
                                                    type="invalid"
                                                    className="custom-error"
                                                >
                                                    {`${t(
                                                        'error.email-required'
                                                    )}`}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="mt-5 mb-3">
                                    <ReCAPTCHA
                                        sitekey={
                                            process.env
                                                .REACT_APP_SITE_KEY_CAPTCHA
                                        }
                                        ref={captchaRef}
                                        onChange={() => {
                                            validateCaptcha()
                                        }}
                                    />
                                </div>
                                <div className="mt-3">

                                    <Button
                                        disabled={!validCaptcha || !isOrganizer}
                                        className="button-primary w-100 text-transform-none" type="submit">
                                        {t('user.register')}
                                    </Button>

                                </div>



                            </Form>
                        </div>
                        <div className="d-flex align-items-center mt-5 justify-content-center footer-container">
                            <Footer white={true} hideGala={true}></Footer>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default SignUpForm;
