import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { Button } from 'react-bootstrap';
import useUsersApi from '../hooks/useUsersApi';
import useEventsApi from '../hooks/useEventsApi';
import CustomModal from '../components/modals/customModal';
import i18next from 'i18next';
import Moment from 'moment';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { NewCompanion } from './newCompanion';
import useLoader from '../components/loader/useLoader';
import { DNI_MAX_LENGTH, genderOptions, IDENTIFICATION_TYPES, MENU_CONDITIONS_TYPES, MENU_TYPES, NAME_MAX_LENGTH, SURNAME_MAX_LENGTH } from '../../constants';
import Footer from '../components/footer/footer';
import { ICompanion, IInscription, InscriptionModel } from '../models/interfaces/inscriptions.interface';
import { IUser } from '../models/interfaces/users.interface';
import { IEvent } from '../models/interfaces/events.interface';
import { isPastOrNow } from '../helpers/inscription-helper';
import { TGender, TGroup, TIdentificationType } from '../models/types/types';
import Select from 'react-select';
import { Validator } from '../helpers/validators';
import { DateHelper } from '../helpers/date-helper';
import CustomDatePicker from '../components/custom-date-picker/custom-date-picker';
import { getEventImageURL } from '../helpers/events-helper';
import Timer from '../components/timer/timer';
import ReCAPTCHA from "react-google-recaptcha";
import useCaptchaApi from '../hooks/useCaptchaApi';
import PublicCardEvent from '../events/card-event/public-card-event';
interface CreatePublicInscriptionProps {
	inscriptionId: string
}
export function CreatePublicInscription(props: CreatePublicInscriptionProps) {
	const captchaRef = useRef(null);
	const [loader, showLoader, hideLoader] = useLoader();
	const lang: string = localStorage.getItem("i18nextLng") || 'en';
	Moment.locale(lang);
	const [myForm, setMyForm] = useState(undefined);
	const { t, i18n } = useTranslation();
	const [isGroupal, setIsGroupal] = useState<boolean>(false);
	const [event, setEvent] = useState<IEvent | undefined>(undefined);
	const [eventStringDate, setEventStringDate] = useState<string>('');
	const [eventStringDateEnd, setEventStringDateEnd] = useState<string>('');
	const [name, setName] = useState<string | undefined>(undefined);
	const [includeMenu, setIncludeMenu] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [includeIdentification, setIncludeIdentification] = useState<boolean>();
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [hosts, setHosts] = useState<string | undefined>(undefined);
	const [bgImg, setBgImg] = useState<string>('/assets/DEFAULT.png');
	const EMAIL_REGEX = new RegExp(
		/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
	);
	const [repeatEmail, setRepeatEmail] = useState<string | undefined>(undefined);
	const [lastName, setLastName] = useState<string | undefined>(undefined);
	const [identificationNumber, setIdentificationNumber] = useState<string | undefined>(undefined);
	const [identificationType, setIdentificationType] = useState<TIdentificationType | undefined>("DNI");
	const [role, setRole] = useState<any>(undefined);
	const [dataToSave, setDataToSave] = useState<any>({});
	const [checkToken] = useCaptchaApi();
	const [
		,
		createUser,
		updateUser,
		,
		getUserByEmail,
		getRoleByName,
	] = useUsersApi();
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions, updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches
	] = useInscriptionsApi();
	const [validated, setValidated] = useState(false);
	const [companionValidated, setCompanionValidated] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>('Error');
	const [companions, setCompanions] = useState<ICompanion[]>([]);
	const [companionsExisting, setCompanionsExisting] = useState<IUser[]>([]);
	const [emailAlreadyInscripted, setEmailAlreadyInscripted] = useState(false);
	const [idAlreadyInscripted, setIdAlreadyInscripted] = useState(false);
	const [isParticipant, setIsParticipant] = useState(false);
	const [isParticipantId, setIsParticipantId] = useState(false);
	const [emailNotAllowed, setEmailNotAllowed] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingID, setIsLoadingID] = useState(false);
	const [emailErrorFormat, setEmailErrorFormat] = useState(false);
	const [repeatEmailErrorFormat, setRepeatEmailErrorFormat] = useState(false);
	const [eventError, setEventError] = useState<string | null>(null);
	const [checked, setChecked] = useState(true);
	const [termsAndConditions, setTermsAndConditions] = useState(false);
	const [ageGroup, setAgeGroup] = useState<TGroup | undefined>(undefined);
	const [gender, setGender] = useState<TGender | undefined>(undefined);
	const [menu, setMenu] = useState<string>('NORMAL');
	const [menuCondition, setMenuCondition] = useState<string>('NONE');
	const [menuOther, setMenuOther] = useState<string | undefined>();
	const [dob, setDOB] = useState<Date>();
	const [maxDate, setMaxDate] = useState<Date>(new Date());
	const [editable, setEditable] = useState(false);
	const [inscription, setInscription] = useState<IInscription>();
	const [allFree, setAllFree] = useState<boolean>(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const today = new Date();
	let minDate = new Date();
	minDate.setFullYear(today.getFullYear() - 110);
	const getOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const menuOptions = getOptions(MENU_TYPES, 'event.menu.');
	const _genderOptions = getOptions(genderOptions, 'gender.');
	const identificationTypesOptions = getOptions(IDENTIFICATION_TYPES, 'identification-type.');
	const menuConditionOptions = getOptions(MENU_CONDITIONS_TYPES, 'event.menu-condition.');
	const handleOnGenderChange = (e: any) => {
		const g = genderOptions.find((x: any) => x.name === e.name);
		if (!!g && g.name) {
			setGender(g.name);
		} else {
			setGender(undefined);
		}
	};
	const handleOnIdentificationTypeChange = (e: any) => {
		const g = identificationTypesOptions.find((x: any) => x.name === e.name);
		if (!!g && g.name) {
			setIdentificationType(g.name);
		} else {
			setIdentificationType(undefined);
		}
	};
	const handleOnCreateDOB = (_date: Date) => {
		setDOB(_date);
		if (!!_date && _date >= minDate && _date <= maxDate) {
			setAgeGroup(DateHelper.getGroup(_date));
		} else {
			setAgeGroup(undefined);
		}
	};
	const handleOnMenuChange = (e: any) => {
		const g = MENU_TYPES.find((x: any) => x.name === e.name);
		if (!!g && g.name) {
			setMenu(g.name);
		} else {
			setMenu('NORMAL');
		}
	};
	const handleOnMenuConditionChange = (e: any) => {
		const g = MENU_CONDITIONS_TYPES.find((x: any) => x.name === e.name);
		if (g && g.name) {
			setMenuCondition(g.name);
		} else {
			setMenuCondition('NONE');
		}
		if (g?.name !== 'OTHER') {
			setMenuOther(undefined);
		}
	};
	const handleOtherChange = (e: any) => {
		setMenuOther(e.target.value);
	}
	const cannotInscribe = (i: any) => {
		if (!!i && i?.batch?.dateEnd && i?.batch?.endHour) {
			return DateHelper.daysFromToday(i?.batch?.dateEnd) < 0 ||
				(DateHelper.daysFromToday(i?.batch.dateEnd) === 0 &&
					isPastOrNow(i?.batch.endHour));
		}
		return true;

	}
	const handleEffect = async () => {
		if (!inscription) {
			const insc: any = await getInscriptionByIdWithBatches(props.inscriptionId);
			if (!!insc) {
				if (insc.inscriptionStatus !== 'RESERVED' && insc.inscriptionStatus !== 'PENDING') {
					history.push('/tickets/' + insc.event._id);
				} else {
					//	TODO: revisar todos los companions a ver si son free ono
					const allFree = insc.batch.price === 0 && insc.batch.name === 'FREE_BATCH';
					setAllFree(allFree);
					setInscription(insc);

					if (insc.inscriptionStatus === 'PENDING') {
						setName(insc.name);
						setLastName(insc.surname);
						setDOB(DateHelper.getDateWithoutTZ(insc.dob));

						setGender(insc.gender);

						setEmail(insc.email);
						setRepeatEmail(insc.email);
						setIsEditing(true);
						setTermsAndConditions(true);

					}


					for (let n = 0; n < insc.companions.length; n++) {
						insc.companions[n].number = n + 1;
					}

					setCompanions(insc.companions);

					//const ev: any = await getEventById(props.pEventId);
					const ev: any = insc.event;
					if (ev && ev['_id']) {
						if (ev['deletedBy'] != null) {
							setEventError('no-event');
						} else {
							if (cannotInscribe(insc)) {
								// TODO: ver hasta que hora dejo que se inscriban
								setEventError('past-event');
							} else {
								setEvent(ev);
								const minAge = ev.minAge;
								let maxD = new Date();
								maxD.setFullYear(today.getFullYear() - minAge);
								setMaxDate(maxD);
								setBgImg(getEventImageURL(ev));
								setEventStringDate(ev.date.toString().substring(0, 10));
								setEventStringDateEnd(ev.dateEnd.toString().substring(0, 10));
								setIncludeMenu(ev.includeMenu);
								setIncludeIdentification(ev.includeIdentification);
								if (insc.inscriptionStatus === 'PENDING') {
									if (ev.includeIdentification) {
										setIdentificationNumber(insc.identificationNumber);
										setIdentificationType(insc.identificationType);
									}
									if (ev.includeMenu) {
										setMenu(insc.menu);
										setMenuCondition(insc.menuCondition);
										setMenuOther(insc.menuOther);
									}
								}
								const hosts = ev['hosts'].map((h: any) => {
									return (
										' ' +
										h.name +
										(h.surname ? ' ' + h.surname + ' ' : '')
									);
								});
								setHosts(hosts);


								setIsGroupal(
									!!insc.companions && insc.companions.length > 0
								);
							}
						}
					}
					else {
						setEventError('no-event')
					}
				}

			}
		}
		if (!role) {
			const _role = await getRoleByName('USER_GUEST');
			if (_role && _role['_id']) {
				setRole(_role);
			}
		}
		hideLoader();
	};
	useEffect(() => {
		showLoader();
		handleEffect();
	}, [inscription]);
	useEffect(() => {
		setEditable(true);
	}, []);

	const handleCloseSuccessModal = () => {
		//setShowSuccessModal(false);
		window.location.reload();
	};
	const handleCloseErrorModal = () => {
		setShowErrorModal(false);
	};
	const handleOnCreateName = (event: any) => {
		setName(event.target.value);
	};

	const handleOnCreateLastName = (event: any) => {
		setLastName(event.target.value);
	};
	const handleOnCreateIdentificationNumber = async (event: any) => {
		clearIDNumberValidations();
		setIdentificationNumber(event.target.value);
		const isInvalidID = Validator.identificationNumber(identificationNumber);
		if (!isInvalidID) {
			await checkAlreadyInscriptedIdentificationNumber(event.target.value);
		}
	};
	const handleOnCreateEmail = async (event: any) => {
		clearAllValidations();
		setEmail(event.target.value);

		const isValidEmail = !checkIsInvalidEmail(event.target.value);
		setEmailErrorFormat(!isValidEmail);
		// if (isValidEmail) {
		// 	await checkAlreadyInscriptedEmail(event.target.value);
		// }
	};

	const checkIsInvalidEmail = (pEmail: any) => {
		return !EMAIL_REGEX.test(pEmail);
	};

	const handleOnCreateEmailRepeat = async (event: any) => {
		clearAllValidations();
		setRepeatEmail(event.target.value);
		const isValidEmail = !checkIsInvalidEmail(email);
		setEmailErrorFormat(!isValidEmail);
		const isValidRepeatEmail = !checkIsInvalidEmail(event.target.value);
		setRepeatEmailErrorFormat(!isValidRepeatEmail);
	};
	const preventPaste = (e: any) => {
		e.preventDefault();
		return false;
	};

	const handleDeleteCompanion = async (c: any) => {
		const idx = companions.indexOf(c);
		//companions.findIndex(x => x.email == c.email && x.name == c.name && x.surname == c.surname && !x.deleted);
		companions[idx]['deleted'] = true;
		const aux = companions.filter(x => x !== c);
		setCompanions(aux);
	};
	const checkAlreadyInscriptedIdentificationNumber = async (pIdentificationNumber: any) => {
		if (includeIdentification) {
			setIsLoadingID(true);
			setIdAlreadyInscripted(false);
			try {
				const _alreadyInscripted = await getInscriptionByIdentificationNumber(
					pIdentificationNumber,
					event?._id
				);
				setIsLoadingID(false);
				if (_alreadyInscripted) {
					setIdAlreadyInscripted(true);
					setErrorMessage(
						i18next.t('error.id-already-used', {
							id: pIdentificationNumber
						}));
					setShowErrorModal(true);

				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoadingID(false);
			}
		}

	};
	const checkCompanion = async (companion: ICompanion, _roleOrganizer: any) => {

		const existingUsr = await checkExistingUser(companion.email);
		const isAdminOrOrganizer = existingUsr ? existingUsr.rolesOrganizers.filter((x: any) => x.roleLevel > 8).length > 0 : false;
		if (isAdminOrOrganizer) {
			companion.emailNotAllowed = true;
			companion.repeatEmail = undefined;
			return false;
		} else {
			companion.emailNotAllowed = false;
			let userId = existingUsr ? existingUsr['_id'] : null;
			if (existingUsr) {
				let arr = companionsExisting;
				arr.push(existingUsr);
				setCompanionsExisting(arr);
			}
			if (!userId) {
				const _user: IUser = {
					name: companion.name,
					surname: companion.surname,
					email: companion.email,
					rolesOrganizers: [_roleOrganizer],
					group: companion.group,
					createdDT: new Date(),
					lastUpdatedDT: new Date(),
					dob: DateHelper.saveDateWithoutTZ(companion.dob),
					gender: companion.gender,
					status: 'ACTIVE'
				};

				if (includeMenu && !!companion.menu && !!companion.menuCondition) {
					_user.menu = companion.menu;
					_user.menuCondition = companion.menuCondition;
				}
				if (includeIdentification && !!companion.identificationNumber && !!companion.identificationType) {
					_user.identificationNumber = companion.identificationNumber;
					_user.identificationType = companion.identificationType;
				}
				const newUser = await createGuestUser(_user, _roleOrganizer);
				userId = newUser;
				if (!newUser) {
					return false;
				}
			}
			if (userId && !isEditing) {
				const alreadyInscripted = await checkExistingInscription(userId);
				if (alreadyInscripted) {
					companion.alreadyInscripted = true;
					companion.repeatEmail = undefined;
					return false;
				} else {
					companion.user = userId;
					return true;
				}
			}
			if (isEditing) {
				return true;
			}
		}

	};
	const checkCompanionUsersNotAlreadyInscripted = async (_roleOrganizer: any) => {
		if (isEditing) {
			return true;
		}
		const companionUsers = companions.filter(
			(x: any) => x.email && x.email != '' && !x.deleted
		);
		if (companionUsers && companionUsers.length > 0) {
			let incorrect = 0;
			for (const user of companionUsers) {
				const response: any = await checkCompanion(user, _roleOrganizer);
				if (response == false) {
					incorrect++;
				}
			}
			return incorrect == 0;
		} else {
			return true;
		}
	};
	const updateExistingCompanions = async (_roleOrganizer: any) => {
		if (companionsExisting && companionsExisting.length > 0) {
			let incorrect = 0;
			for (const companionExistingUser of companionsExisting) {
				const userToUpdate: IUser = companionExistingUser; //majooooo
				const data = companions.find(x => x.email === companionExistingUser.email);
				let shouldUpdate = false;
				if (!!data) {
					if (!!data.dob && userToUpdate.dob != data.dob) {
						shouldUpdate = true;
						userToUpdate.dob = data.dob;
						userToUpdate.group = DateHelper.getGroup(data.dob);
					}
					if (!!data.gender && userToUpdate.gender != data.gender) {
						shouldUpdate = true;
						userToUpdate.gender = data.gender;
					}

					if (includeMenu && !!data.menu && userToUpdate.menu != data.menu) {
						shouldUpdate = true;
						userToUpdate.menu = data.menu;

					}
					if (includeMenu && !!data.menuCondition && userToUpdate.menuCondition != data.menuCondition) {
						shouldUpdate = true;
						userToUpdate.menuCondition = data.menuCondition;
					}

					if (includeIdentification && userToUpdate.identificationNumber != data.identificationNumber) {
						shouldUpdate = true;
						userToUpdate.identificationNumber = data.identificationNumber;
					}
					if (includeIdentification && userToUpdate.identificationType != data.identificationType) {
						shouldUpdate = true;
						userToUpdate.identificationType = data.identificationType;
					}
				}
				const response: any = await updateExistingUser(userToUpdate, _roleOrganizer, shouldUpdate);
				if (response == false) {
					incorrect++;
				}
			}
			return incorrect == 0;
		}
		return true;
	};

	const updateExistingUser = async (existingUser: any, _roleOrganizer: any, shouldUpdate: boolean) => {
		let rolesOrganizers = existingUser['rolesOrganizers'];
		let roleOrganizer = rolesOrganizers.find(
			(x: any) => x.organizerId == event?.organizer?._id
		);
		let _shouldUpdate = shouldUpdate;
		// Check if the user already exists for the current organizer
		if (!roleOrganizer || roleOrganizer.length == 0) {
			_shouldUpdate = true;
			existingUser['rolesOrganizers'].push(_roleOrganizer);
		}
		if (_shouldUpdate) {
			const userToUpdate: IUser = {
				_id: existingUser._id,
				lastUpdatedDT: new Date(),
				lastUpdatedBy: existingUser._id,
				gender: existingUser.gender,
				dob: DateHelper.saveDateWithoutTZ(existingUser.dob),
				group: DateHelper.getGroup(existingUser.dob),
				rolesOrganizers: existingUser.rolesOrganizers,
				status: 'ACTIVE'
			}
			if (!roleOrganizer || roleOrganizer.length == 0) {
				userToUpdate.rolesOrganizers = existingUser['rolesOrganizers'];
			}
			if (includeMenu) {
				userToUpdate.menu = existingUser.menu;
				userToUpdate.menuCondition = existingUser.menuCondition;
			}
			if (includeIdentification) {
				userToUpdate.identificationNumber = existingUser.identificationNumber;
				userToUpdate.identificationType = existingUser.identificationType;
			}

			// If not exists for the current organizer, add GUEST to the organizer
			const updatedUser: any = await dispatch(
				updateUser(userToUpdate)
			);
			if (
				!updatedUser ||
				!updatedUser['payload'] ||
				!updatedUser['payload']['_id']
			) {
				return false;
			} else {
				return true;
			}
		}
		return true;
	};
	const checkExistingUser = async (email: string | undefined) => {
		const existingUser = await getUserByEmail(email);
		// Check if the user already exists
		if (existingUser && existingUser['_id']) {
			return existingUser;
		} else {
			return null;
		}
	};
	const checkAlreadyInscriptedEmail = async (pEmail: any) => {
		if (isEditing) {
			setEmailAlreadyInscripted(false);
			return;
		}
		setIsLoading(true);

		try {
			const alreadyInscripted = await getInscriptionByEmail(
				pEmail,
				event?._id
			);
			setIsLoading(false);
			if (!!alreadyInscripted && alreadyInscripted.inscriptionStatus !== 'EXPIRED') {
				setEmailAlreadyInscripted(true);
				setErrorMessage(
					i18next.t('error.email-already-used', {
						email: pEmail
					}));
				setShowErrorModal(true);

			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}

	};
	const checkExistingInscription = async (userId: string) => {
		if (isEditing) {
			return false;
		}
		const alreadyInscripted: IInscription | undefined = await getInscription(userId, event?._id);
		return !!alreadyInscripted && !!alreadyInscripted['_id'] && alreadyInscripted.inscriptionStatus !== 'EXPIRED';
	};
	const createGuestUser = async (user: IUser, _roleOrganizer: any) => {
		const createdUser: any = await dispatch(createUser(user));
		if (createdUser['payload'] && createdUser['payload']['_id']) {
			return createdUser['payload']['_id'];
		} else {
			return null;
		}
	};
	const equalsEmails = (email1: string | undefined, email2: string | undefined) => {
		if ((email1 == undefined || email1 == null || email1.trim() == "") &&
			(email2 == undefined || email2 == null || email2.trim() == "")) {
			return true;
		}
		return email1?.toLowerCase() === email2?.toLowerCase();
	}
	const checkCompanionEmails = () => {
		const emailDiff = companions.filter(
			//(x: any) => x.email != x.repeatEmail && !x.deleted
			(x: any) => !equalsEmails(x.email, x.repeatEmail) && !x.deleted
		);
		return emailDiff && emailDiff.length == 0;
	};

	const checkDifferentEmailAndIDs = () => {
		const companionUsers = companions.filter(
			(x: any) => x.email && x.email != '' && !x.deleted
		);
		companions.map((user) => {
			user['sameEmail'] = false;
			user['sameEmailCompanion'] = false;
			user['sameID'] = false;
			user['sameIDCompanion'] = false;
			user['alreadyInscripted'] = false;
			user['isParticipant'] = false;
			user['isParticipantID'] = false;
			user['emailNotAllowed'] = false;
		});
		let repeatedEmailsOrIds = 0;
		if (includeIdentification) {
			const _companions = companions.filter(
				(x: any) => !x.deleted
			);
			_companions.forEach((u) => {
				const sameID = includeIdentification ? (u.identificationNumber === identificationNumber) : false;

				const _otherUsers = [..._companions];
				const idx = _otherUsers.indexOf(u);
				_otherUsers.splice(idx, 1);

				const sameIDCompanion = includeIdentification ? _otherUsers.filter(
					x => x.identificationNumber === u.identificationNumber
				) : [];
				u['sameIDCompanion'] = sameIDCompanion && sameIDCompanion.length > 0;
				u['sameID'] = sameID;
				u['isParticipantID'] = isPartOfTheEventById(u.identificationNumber);

				repeatedEmailsOrIds += sameID ? 1 : 0 + sameIDCompanion.length;
			});
		}
		companionUsers.reverse().forEach((user) => {
			const sameEmailMain = equalsEmails(user.email, email);//user.email?.toLowerCase() === email?.toLowerCase();

			const otherUsers = [...companionUsers];
			const idx = otherUsers.indexOf(user);
			otherUsers.splice(idx, 1);

			const sameEmailCompanion = otherUsers.filter(
				x => equalsEmails(x.email, user.email)
			);

			user['sameEmailCompanion'] = sameEmailCompanion && sameEmailCompanion.length > 0;
			user['isParticipant'] = isPartOfTheEvent(user.email);
			user['sameEmail'] = sameEmailMain;
			repeatedEmailsOrIds += sameEmailMain ? 1 : 0 + sameEmailCompanion.length;
		});
		setCompanionValidated(true);
		return repeatedEmailsOrIds == 0;
	};
	const clearIDNumberValidations = () => {
		setIsParticipantId(false);
		setIdAlreadyInscripted(false);
	}
	const clearAllValidations = async () => {
		setIsParticipant(false);
		setEmailNotAllowed(false);
		setEmailAlreadyInscripted(false);
		setCompanionValidated(false);
		setEmailErrorFormat(false);
		setRepeatEmailErrorFormat(false);
	};
	const isPartOfTheEvent = (pEmail: any) => {
		return (
			(!!event?.hosts && event?.hosts?.filter((x) => x.email === pEmail)?.length > 0) ||
			(!!event?.admissions && event?.admissions?.filter((x: IUser) => x.email === pEmail)?.length > 0) ||
			(!!event?.planners && event?.planners?.filter((x: IUser) => x.email === pEmail)?.length > 0) ||
			event?.organizer?.email == pEmail
		);
	};
	const isPartOfTheEventById = (identificationNumber: any) => {
		return (
			(!!event?.hosts && event?.hosts?.filter((x) => x.identificationNumber === identificationNumber)?.length > 0) ||
			(!!event?.admissions && event?.admissions?.filter((x: IUser) => x.identificationNumber === identificationNumber)?.length > 0) ||
			(!!event?.planners && event?.planners?.filter((x: IUser) => x.identificationNumber === identificationNumber)?.length > 0) ||
			event?.organizer?.identificationNumber == identificationNumber
		);
	};
	const hasError = (companion: any) => {
		const err = (
			// validated &&
			(companion.alreadyInscripted ||
				companion.sameEmail ||
				companion.isParticipant ||
				companion.emailNotAllowed ||
				companion.sameEmailCompanion ||
				(includeIdentification && (companion.isParticipantID || companion.alreadyInscriptedID || companion.sameID || companion.sameIDCompanion)) ||
				!companion.name || Validator.name(companion.name) ||
				!companion.surname || Validator.name(companion.surname) ||
				!companion.dob || (!!companion.dob && (companion.dob > maxDate)) ||
				!companion.gender ||
				(includeIdentification && (!companion.identificationNumber || Validator.identificationNumber(companion.identificationNumber))) ||
				(includeIdentification && !companion.identificationType) ||
				!equalsEmails(companion.email, companion.repeatEmail)
			)
		);
		return err;
	};
	const checkCompanionsValidity = () => {
		const err = companions.filter(
			//(x: any) => x.email != x.repeatEmail && !x.deleted
			(x: any) => hasError(x)
		);
		return !err || err?.length === 0;
	}
	const checkIsNotParticipantId = () => {
		if (includeIdentification && !!identificationNumber) {
			if (isPartOfTheEventById(identificationNumber)) {
				setIsParticipantId(true);
				setErrorMessage(
					i18next.t('error.is-participant-id', {
						id: identificationNumber,
						eventName: event?.name,
					})
				);
				setShowErrorModal(true);
				return false;
			} else {
				setIsParticipantId(false);
				return true;
			}
		} else {
			return true;
		}
	};
	const checkIsNotParticipant = () => {
		if (isPartOfTheEvent(email)) {
			setIsParticipant(true);
			setErrorMessage(
				i18next.t('error.is-participant', {
					email: email,
					eventName: event?.name,
				})
			);
			setShowErrorModal(true);
			return false;
		} else {
			setIsParticipant(false);
			return true;
		}
	};

	const handleCheckTermsAndConditions = () => {
		setTermsAndConditions(!termsAndConditions);
	};
	const handleConfirmationModal = async (e: any) => {
		setShowConfirmationModal(false);
		if (e) {
			await handleOnSaveChanges(myForm);
		}
	};
	const handleOnSaveChanges = async (form: any) => {
		// e.preventDefault();
		// const form = e.currentTarget;
		showLoader();
		// TODO MAJO VER SI HACERLO POR FRONT O POR BACK AL COMPANION TO SAVE
		const today = new Date();
		const _companionsToSave: ICompanion[] = companions.filter((x: any) => !x.deleted).map((c) => {
			c.inscriptionStatus = 'PENDING';
			c.lastUpdatedBy = dataToSave['userId'];
			c.lastUpdatedDT = today;
			return new InscriptionModel(c);
		});

		const _inscription: any = {
			user: dataToSave['userId'],
			_id: props.inscriptionId,
			companions: _companionsToSave,// companions.filter((x: any) => !x.deleted),
			attend: checked,
			name: name,
			surname: lastName,
			email: email,
			group: ageGroup,
			menu: includeMenu ? menu : undefined,
			menuCondition: includeMenu ? menuCondition : undefined,
			menuOther: includeMenu ? menuOther : undefined,
			gender: event?.includeGender ? gender : undefined,
			dob: event?.includeDOB ? DateHelper.saveDateWithoutTZ(dob) : undefined	,
			identificationNumber: includeIdentification ? identificationNumber : undefined,
			identificationType: includeIdentification ? identificationType : undefined,
			inscriptionStatus: 'PENDING',
			lastUpdatedBy: dataToSave['userId'],
			lastUpdatedDT: today,
		};


		const response: any = event?.hasPayment ? await dispatch(updateInscriptionAndCompanions(_inscription)) : await dispatch(updateInscriptionAndCompanionsAndSendQR({ inscription: _inscription, event: event }));
		if (response && response['payload'] && response['payload']['_id']) {
			if (dataToSave['existingUser']) {
				const userToUpdate: IUser = dataToSave['existingUser'];
				let shouldUpdate = false;
				if (!!dob && userToUpdate.dob != dob) {
					shouldUpdate = true;
					userToUpdate.dob = dob;
					userToUpdate.group = DateHelper.getGroup(dob);
				}
				if (!!gender && userToUpdate.gender != gender) {
					shouldUpdate = true;
					userToUpdate.gender = gender;
				}

				if (includeMenu && !!menu && userToUpdate.menu != menu) {
					shouldUpdate = true;
					userToUpdate.menu = menu;

				}
				if (includeMenu && !!menuCondition && userToUpdate.menuCondition != menuCondition) {
					shouldUpdate = true;
					userToUpdate.menuCondition = menuCondition;
				}
				if (includeIdentification && userToUpdate.identificationNumber != identificationNumber) {
					shouldUpdate = true;
					userToUpdate.identificationNumber = identificationNumber;
				}
				if (includeIdentification && userToUpdate.identificationType != identificationType) {
					shouldUpdate = true;
					userToUpdate.identificationType = identificationType;
				}
				const res = await updateExistingUser(
					userToUpdate,
					dataToSave['roleOrganizer'],
					shouldUpdate
				);
				if (!res) {
					setErrorMessage(i18next.t('error.inscription'));
					setShowErrorModal(true);
				} else {
					const r = await updateExistingCompanions(
						dataToSave['roleOrganizer']
					);
					if (!r) {
						// setErrorMessage(
						// 	'updateExistingCompanions'
						// );
						setErrorMessage(i18next.t('error.inscription'));
						setShowErrorModal(true);
					} else {
						//setShowSuccessModal(true);
						if (event?.hasPayment) {
							history.push('/checkout/' + props.inscriptionId);
						} else {
							history.push('/checkout/' + props.inscriptionId + '/success');
						}

					}
				}
			} else {
				//setShowSuccessModal(true);
				if (event?.hasPayment) {
					history.push('/checkout/' + props.inscriptionId);
				} else {
					history.push('/checkout/' + props.inscriptionId + '/success');
				}
			}
			//
		}
		hideLoader();
		// } else {
		// 	hideLoader();
		// }
		// setValidated(true);
	};
	const validateCaptcha = async () => {
		try {
			if (!!captchaRef.current) {
				const token = ((Object)(captchaRef.current)).getValue();
				((Object)(captchaRef.current)).reset();
				const r: any = await dispatch(checkToken(token));
				if (!!r && !!r['payload'] && r.payload === true) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		}
		catch (e) {
			return false;
		}
	}
	const handleOnSubmit = async (e: any) => {
		e.preventDefault();
		const form = e.currentTarget;
		setMyForm(form);
		const ev: any = null;
		// await getEventById(props.pEventId);
		if (!!ev && cannotInscribe(ev)) {
			setEventError('past-event');
			setEvent(undefined);
			return;
		} else {
			clearAllValidations();
			const isValidEmail = !checkIsInvalidEmail(email);
			setEmailErrorFormat(!isValidEmail);
			const isValidRepeatEmail = !checkIsInvalidEmail(repeatEmail);
			setRepeatEmailErrorFormat(!isValidRepeatEmail);
			if (!!form && form.checkValidity() &&
				!Validator.name(name) &&
				!Validator.name(lastName) &&
				(!includeIdentification ||
					(includeIdentification && !!identificationNumber && !Validator.identificationNumber(identificationNumber) && !!identificationType && !idAlreadyInscripted && !isParticipantId)) &&
				((event?.includeGender && !!gender) || !event?.includeGender) &&
				((!!dob && (dob < maxDate) && (!event?.hasPayment || !!event?.includeDOB)) || (!event?.includeDOB && !!event?.hasPayment)) &&
				checkIsNotParticipantId() &&
				checkIsNotParticipant() &&
				checkCompanionEmails() &&
				checkDifferentEmailAndIDs() &&
				checkCompanionsValidity() &&
				isValidEmail && isValidRepeatEmail &&
				!emailAlreadyInscripted && !emailErrorFormat && !emailNotAllowed &&
				email == repeatEmail &&
				!!role && await validateCaptcha()
			) {
				const _roleOrganizer = {
					roleId: role._id,
					roleName: role.name,
					roleLevel: role.level,
					organizer: event?.organizer?.name,
					organizerId: event?.organizer?._id,
				};
				showLoader();
				const existingUser = await checkExistingUser(email);
				let userId = existingUser ? existingUser._id : null;

				const isAdminOrOrganizer = existingUser ? existingUser.rolesOrganizers.filter((x: any) => x.roleLevel > 8).length > 0 : false;
				if (isAdminOrOrganizer) {
					hideLoader();
					setEmailNotAllowed(true);
					setErrorMessage(i18next.t('error.inscription-admin-or-organizer', { email: email }));
					setShowErrorModal(true);
				} else {
					setEmailNotAllowed(false);
					let isNew = !userId || userId == null;
					if (!userId) {
						// If the user does not exists, create the user
						const user: IUser = {
							name: name,
							surname: lastName,
							email: email,
							gender: gender,
							rolesOrganizers: [_roleOrganizer],
							group: ageGroup,
							createdDT: new Date(),
							lastUpdatedDT: new Date(),
							dob: DateHelper.saveDateWithoutTZ(dob),
							status: 'ACTIVE'
						};
						if (includeMenu && !!menu && !!menuCondition) {
							user.menu = menu;
							user.menuCondition = menuCondition;
						}
						if (includeIdentification && !!identificationNumber && !!identificationType) {
							user.identificationNumber = identificationNumber;
							user.identificationType = identificationType;
						}
						const newUser = await createGuestUser(user, _roleOrganizer);
						userId = newUser;
						if (!userId) {
							hideLoader();
							setErrorMessage(i18next.t('error.inscription'));
							setShowErrorModal(true);
						}
					}
					if (userId && userId != null) {
						let alreadyInscripted = false;
						if (!isNew && !isEditing) {
							alreadyInscripted = await checkExistingInscription(userId);
						}
						if (alreadyInscripted) {
							hideLoader();
							setEmailAlreadyInscripted(true);
							setErrorMessage(
								i18next.t('error.already-inscripted', {
									email: email,
									eventName: event?.name,
								})
							);
							setShowErrorModal(true);

						} else {
							setEmailAlreadyInscripted(false);

							const someParticipants =
								companions.filter(
									(x: any) => x.isParticipant == true && !x.deleted
								).length > 0;
							if (someParticipants) {
								hideLoader();
								let emails = companions
									.filter((y) => y.isParticipant && !y.deleted)
									.map((x: any) => {
										return ' ' + x.email;
									});
								setErrorMessage(
									i18next.t('error.is-participant', {
										email: emails,
										eventName: event?.name,
									})
								);
								setShowErrorModal(true);
							} else {
								const companionsChecked = await checkCompanionUsersNotAlreadyInscripted(
									_roleOrganizer
								);
								if (companionsChecked) {
									hideLoader();
									setDataToSave({
										userId: userId,
										existingUser: existingUser,
										roleOrganizer: _roleOrganizer,
									});

									if (!isEditing) {
										setShowConfirmationModal(true);
									} else {
										await handleOnSaveChanges(myForm);
									}

								} else {
									const someNotAllowed = companions.filter(
										(x: any) => x.emailNotAllowed == true && !x.deleted
									).length > 0;
									if (someNotAllowed) {
										hideLoader();
										let emails = companions
											.filter((y) => y.emailNotAllowed && !y.deleted)
											.map((x: any) => {
												return ' ' + x.email;
											});
										setErrorMessage(
											i18next.t('error.inscription-admin-or-organizer', {
												email: emails
											})
										);
										setShowErrorModal(true);
									} else {
										hideLoader();
										let emails = companions
											.filter((y) => y.alreadyInscripted)
											.map((x: any) => {
												return ' ' + x.email;
											});
										setErrorMessage(
											i18next.t('error.already-inscripted', {
												email: emails,
												eventName: event?.name,
											})
										);

										setShowErrorModal(true);
									}
								}
							}

						}
					} else {
						hideLoader();
						setErrorMessage(i18next.t('error.inscription'));
						setShowErrorModal(true);
					}
				}
			}
			setValidated(true);
		}
	};

	const getConfirmationMessage = () => {
		const usr = '<br>' +
			(name != null ? name : '') +
			' ' +
			(lastName != null ? lastName : '') +
			(email && email != '' ? ' (<i>' + email + '</i>)' : '');

		const companionsNames = isGroupal ? companions
			.filter((y) => !y.deleted)
			.map((x: any) => {
				return x.name && x.surname
					? '<br>' +
					x.name +
					' ' +
					x.surname +
					(x.email && x.email != ''
						? ' (<i>' + x.email + '</i>)'
						: '')
					: '';
			}) : null;

		if (!checked) {
			if (isGroupal && companionsNames && companionsNames.length > 0) {
				return i18n.t('confirmation.inscription-no-attend-groupal', {
					email: usr,
					eventName: event?.name,
					companions: companionsNames
				});
			}
			return i18n.t('confirmation.inscription-no-attend', {
				email: usr,
				eventName: event?.name,
			});
		} else {
			if (isGroupal && companionsNames && companionsNames.length > 0) {
				return i18n.t('confirmation.inscription-groupal', {
					email: usr,
					eventName: event?.name,
					companions: companionsNames,
				});
			}
			return i18n.t('confirmation.inscription-single', {
				email: usr,
				eventName: event?.name,
			});
		}

	};
	const getSuccessMessage = () => {
		const usr = "<br>" +
			(name != null ? name : '') +
			' ' +
			(lastName != null ? lastName : '') +
			(email && email != '' ? ' (<i>' + email + '</i>)' : '');
		const companionsNames = isGroupal ? companions
			.filter((y) => !y.deleted)
			.map((x: any) => {
				return (
					'<br>' +
					x.name +
					' ' +
					x.surname +
					(x.email && x.email != ''
						? ' (<i>' + x.email + '</i>)'
						: '')
				);
			}) : null;
		if (!checked) {
			if (isGroupal && companionsNames && companionsNames.length > 0) {
				return i18n.t('success.inscription-no-attend-groupal', {
					email: usr,
					eventName: event?.name,
					companions: companionsNames,
				});
			}

			return i18n.t('success.inscription-no-attend', {
				email: usr,
				eventName: event?.name,
			});
		} else {
			if (isGroupal && companionsNames && companionsNames.length > 0) {
				return i18n.t('success.inscription-groupal', {
					email: usr,
					eventName: event?.name,
					companions: companionsNames,
				});
			}

			return i18n.t('success.inscription-single', {
				email: usr,
				eventName: event?.name,
			});
		}
	};

	const getSuccessCheckEmailMessage = () => {
		const companionsNames = isGroupal ? companions
			.filter((y) => !y.deleted)
			.map((x: any) => {
				return (
					'<br>' +
					x.name +
					' ' +
					x.surname +
					(x.email && x.email != ''
						? ' (<i>' + x.email + '</i>)'
						: '')
				);
			}) : null;
		const companionsEmails = isGroupal ? companions.filter((x) => !!x.email) : [];
		if (checked) {
			if (isGroupal && companionsEmails?.length > 0) {
				return i18n.t('success.inscription-check-groupal');
			}
			return i18n.t('success.inscription-check-single');
		}
	};
	const goToLanding = () => {
		history.push('/');
	}
	const goToTickets = (e: any) => {
		if (!!inscription) {
			history.push('/tickets/' + inscription?.event?._id);
		}
	};

	return (
		<>
			{loader}
			{!event && eventError && (
				<div className="dashboard-content error m-0 p-0">
					<div className="form-container pb-0 col-xl-8">
						<div className="form-static d-flex flex-column justify-content-around align-items-center">
							<div className="d-flex justify-content-center mt-5">
								<div className="logo white cursor-pointer" onClick={goToLanding}></div>
							</div>
							<div className="d-flex flex-column align-items-center justify-content-center text-center new-inscription-box p-lg-5">
								<div className="warning-error"></div>
								<div className="mt-2 inscription-title-bold text-center"
									dangerouslySetInnerHTML={{ __html: t('event.error.' + eventError) }}>
								</div>
							</div>
							<div className="d-flex footer-container">
								<Footer white={true}></Footer>
							</div>
						</div>
					</div>
				</div>
			)}
			{showSuccessModal && (
				<div className="dashboard-content new-inscription m-0">
					<div className="form-container pb-0 col-xl-8">
						<div className="form-static d-flex flex-column justify-content-around align-items-center">
							<div className="d-flex justify-content-center">
								<div className="logo white mt-4 cursor-pointer" onClick={goToLanding}></div>
							</div>
							<div className="d-flex flex-column align-items-center justify-content-center text-center new-inscription-box">
								<div className={'success'}></div>
								<div className="mt-2 inscription-subtitle text-center"
									dangerouslySetInnerHTML={{
										__html: getSuccessMessage(),
									}}>

								</div>
								{checked &&
									<div className="mt-5 inscription-check-email-subtitle text-center">
										<p className='m-2'>
											{getSuccessCheckEmailMessage()}
										</p>
									</div>
								}
								<Button
									className="button-ok mt-5 mb-3"
									onClick={() => handleCloseSuccessModal()}
								>
									{t('new-inscription')}
								</Button>
							</div>
							<div className="d-flex footer-container">
								<Footer white={true}></Footer>
							</div>
						</div>
					</div>
				</div>
			)}
			{!showSuccessModal && event && (
				<div className="dashboard-content new-inscription p-0 m-0 pt-5">
					<Form
						noValidate
						validated={validated}
						className="form-container col-xl-10"
						onSubmit={handleOnSubmit}
					>
						<div className='row m-0 p-0 align-items-stretch'>
							<div className='col-12 col-lg-6 d-flex flex-column align-items-center p-lg-5'>
								<div
									className="d-flex row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
									<div className="logo white cursor-pointer" onClick={goToLanding}></div>
								</div>
								<PublicCardEvent
									event={event}
									isInscription
									isHost
									showHosts
									isAllowedToEdit={false}
									showOrganizer
									includeDescription
								></PublicCardEvent>
								{/* <EventSummary event={event} customClass="col-lg-9"></EventSummary> */}
							</div>
							<div className='col-12 col-lg-6 p-4 p-lg-5 tickets-selection tickets-selection-box d new-inscription-box-flex flex-column'>
								<Timer expirationDT={inscription?.expirationDT} expirationHr={inscription?.expirationHr} customClass="col-lg-9" timeoutCallback={goToTickets} />
								<div>
									<h2 className='d-flex inscription-title w-100 justify-content-center align-items-center'>{t('inscription.title-public')}</h2>
								</div>
								{!!inscription && <div className='bottom col-12 p-0 d-flex flex-column justify-content-between'>
									{inscription?.batch?.name !== 'FREE_BATCH' &&
										<div className="mb-1 inscription-sub-subtitle">
											{inscription.batch?.name}
										</div>}
									<div className='col-12 m-0 p-0 row'>
										<div className='col-md-6 m-0 p-0 pr-md-1'>
											<Form.Group id="main-inscription-name">
												<Form.Label className="input-label">{`${t(
													'user.name'
												)}*`}</Form.Label>
												<Form.Control
													type="text"
													name="name"
													defaultValue={inscription.name}
													autoComplete="on"
													placeholder={t('user.name')}
													onChange={handleOnCreateName}
													maxLength={NAME_MAX_LENGTH}
													isInvalid={validated && Validator.name(name)}
													required
												/>
												{validated && <Form.Control.Feedback type="invalid">
													{!name && `${t('error.required')}`}
													{!!name && `${t('error.invalid')}`}
												</Form.Control.Feedback>}
											</Form.Group>
										</div>
										<div className='col-md-6 m-0 p-0 pl-md-1'>
											<Form.Group id="main-inscription-surname">
												<Form.Label className="input-label">{`${t(
													'user.lastname'
												)}*`}</Form.Label>
												<Form.Control
													required
													autoComplete="on"
													defaultValue={inscription.surname}
													maxLength={SURNAME_MAX_LENGTH}
													isInvalid={validated && Validator.name(lastName)}
													type="text"
													name="lastName"
													placeholder={t('user.lastname')}
													onChange={handleOnCreateLastName}
												/>
												{validated && <Form.Control.Feedback type="invalid">
													{!lastName && `${t('error.required')}`}
													{!!lastName && `${t('error.invalid')}`}
												</Form.Control.Feedback>}
											</Form.Group>
										</div>
									</div>
									{includeIdentification &&
										<div className='col-12 m-0 p-0 row'>
											<div className='col-md-6 m-0 p-0 pr-md-1'>
												<Form.Group id="main-inscription-identification-type">
													<Form.Label className="input-label">{`${t(
														'identification-type-label'
													)}*`}</Form.Label>
													<Select
														className={validated && !identificationType ? 'select-control invalid' : (validated && !!identificationType ? "select-control valid" : "select-control")}
														placeholder={t('select')}
														options={identificationTypesOptions}
														value={identificationTypesOptions.find(x => x.name === inscription.identificationType)}
														isInvalid={validated && !identificationType}
														required
														onChange={handleOnIdentificationTypeChange}
													/>
													{validated && !identificationType && <Form.Control.Feedback type="invalid" className='custom-error'>
														{`${t('error.required')}`}
													</Form.Control.Feedback>}
												</Form.Group>
											</div>
											<div className='col-md-6 m-0 p-0 pl-md-1'>
												<Form.Group id="main-inscription-identificationNumber">
													<Form.Label className="input-label">{`${t(
														'identification-number'
													)}*`}</Form.Label>
													<Form.Control
														required
														autoComplete='none'
														maxLength={DNI_MAX_LENGTH}
														isInvalid={idAlreadyInscripted || isParticipantId || (validated && Validator.identificationNumber(identificationNumber))}
														type="text"
														defaultValue={inscription.identificationNumber}
														name="identificationNumber"
														placeholder={t('identification-placeholder')}
														onChange={handleOnCreateIdentificationNumber}
														className={isLoadingID ? 'loading' : ''}
													/>
													<Form.Control.Feedback type="invalid">
														{!identificationNumber && validated && `${t('error.required')}`}
														{!!identificationNumber && !idAlreadyInscripted && !isParticipantId && `${t('error.invalid')}`}
														{!!identificationNumber && idAlreadyInscripted && !isParticipantId && `${t('error.id-already-inscripted')}`}
														{!!identificationNumber && !idAlreadyInscripted && isParticipantId && `${t('error.id-is-participant')}`}
													</Form.Control.Feedback>
												</Form.Group>
											</div>
										</div>
									}
									<div className='col-12 m-0 p-0 row'>

										<div className='col-md-6 m-0 p-0 pr-md-1'>
											<Form.Group id="main-inscription-dob">
												<Form.Label className="input-label">
													{`${t('user.dob')}${(event.includeDOB || !event.hasPayment) ? '*' : ''}`}
												</Form.Label>
												<CustomDatePicker
													isInvalid={(!event?.hasPayment || !!event?.includeDOB) && ((validated && !dob) || !!dob && (dob > maxDate))}
													isValid={!(!event?.hasPayment || !!event?.includeDOB) && ((validated && !dob) || !!dob && (dob > maxDate))}
													maxDate={maxDate}
													minDate={minDate}
													selected={dob || inscription.dob}
													onCustomDateInputChange={handleOnCreateDOB}></CustomDatePicker>
											</Form.Group>
											{(!event?.hasPayment || !!event?.includeDOB) && validated && !dob && <Form.Control.Feedback type="invalid" className="custom-error">
												{`${t('error.required')}`}
											</Form.Control.Feedback>}
											{(!event?.hasPayment || !!event?.includeDOB) && !!dob && (dob > maxDate) && <Form.Control.Feedback type="invalid" className="custom-error">
												{`${t('error.min-age', { age: event.minAge })}`}
											</Form.Control.Feedback>}
										</div>
										{
											event.includeGender && (
												<div className='col-md-6 m-0 p-0 pl-md-1'>
													<Form.Group id="main-inscription-gender">
														<Form.Label className="input-label">{`${t(
															'user.gender'
														)}*`}</Form.Label>
														<Select
															className={validated && !gender ? 'select-control invalid' : (validated && !!gender ? "select-control valid" : "select-control")}
															placeholder={t('select')}
															options={_genderOptions}
															value={_genderOptions.find(x => x.name === inscription.gender)}
															isInvalid={validated && !gender}
															required
															onChange={handleOnGenderChange}
														/>
														{validated && !gender && <Form.Control.Feedback type="invalid" className='custom-error'>
															{`${t('error.required')}`}
														</Form.Control.Feedback>}
													</Form.Group>
												</div>
											)
										}
									</div>
									<div className='col-12 m-0 p-0 row'>
										<div className='col-md-6 m-0 p-0 pr-md-1'>
											<Form.Group id="main-inscription-email">
												<Form.Label className="input-label">{`${t(
													'user.email'
												)}*`}</Form.Label>
												<Form.Control
													type="text"
													name="email"
													autoComplete="none"
													//	className={isLoading ? 'loading' : ''}
													isInvalid={
														!isEditing && (
															emailAlreadyInscripted ||
															isParticipant ||
															emailErrorFormat ||
															emailNotAllowed)
													}
													required
													placeholder={t('user.email')}
													onChange={handleOnCreateEmail}
													maxLength={70}
													contentEditable={editable}
													defaultValue={inscription.email}
													disabled={isEditing}
												/>
												{email &&
													!emailAlreadyInscripted &&
													!isParticipant && !emailNotAllowed &&
													emailErrorFormat &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{`${t('error.email-format')}`}
													</Form.Control.Feedback>
												}

												{email &&
													emailAlreadyInscripted &&
													!emailErrorFormat &&
													!isParticipant && !emailNotAllowed &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{
															`${t(
																'error.email-already-inscripted'
															)}`
														}
													</Form.Control.Feedback>

												}

												{email &&
													!emailAlreadyInscripted &&
													!emailErrorFormat &&
													isParticipant && !emailNotAllowed &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{
															`${t(
																'error.email-is-participant'
															)}`
														}
													</Form.Control.Feedback>
												}

												{email &&
													!emailAlreadyInscripted &&
													!emailErrorFormat &&
													!isParticipant && emailNotAllowed &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{
															`${t(
																'error.email-not-allowed'
															)}`
														}
													</Form.Control.Feedback>}

												{!email && validated &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{
															`${t('error.email-required')}`
														}
													</Form.Control.Feedback>
												}
											</Form.Group>
										</div>
										<div className='col-md-6 m-0 p-0 pl-md-1'>
											<Form.Group id="main-inscription-repeatEmail">
												<Form.Label className="input-label">{`${t(
													'user.repeat-email'
												)}*`}</Form.Label>
												<Form.Control
													type="email"
													name="repeatEmail"
													required
													autoComplete="none"
													maxLength={70}
													isInvalid={
														!isEditing && (
															!equalsEmails(email, repeatEmail) ||
															emailAlreadyInscripted ||
															isParticipant || emailNotAllowed ||
															repeatEmailErrorFormat)
													}
													placeholder={t('user.repeat-email')}
													onChange={handleOnCreateEmailRepeat}
													onCopy={preventPaste}
													onDrag={preventPaste}
													onDrop={preventPaste}
													onPaste={preventPaste}
													contentEditable={editable}
													defaultValue={inscription.email}
													disabled={isEditing}
												/>
												{repeatEmail &&
													!emailAlreadyInscripted &&
													!isParticipant && !emailNotAllowed &&
													email == repeatEmail &&
													repeatEmailErrorFormat &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{`${t('error.email-format')}`}
													</Form.Control.Feedback>
												}
												{repeatEmail &&
													!repeatEmailErrorFormat &&
													!equalsEmails(email, repeatEmail) &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{
															`${t('error.repeat-email')}`
														}
													</Form.Control.Feedback>
												}
												{repeatEmail &&
													!repeatEmailErrorFormat &&
													email == repeatEmail &&
													emailAlreadyInscripted &&
													!isParticipant && !emailNotAllowed &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{
															`${t(
																'error.email-already-inscripted'
															)}`
														}
													</Form.Control.Feedback>
												}
												{repeatEmail &&
													!repeatEmailErrorFormat &&
													email == repeatEmail &&
													!emailAlreadyInscripted &&
													isParticipant && !emailNotAllowed &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{`${t(
															'error.email-is-participant'
														)}`}
													</Form.Control.Feedback>
												}
												{repeatEmail &&
													!repeatEmailErrorFormat &&
													email == repeatEmail &&
													!emailAlreadyInscripted &&
													!isParticipant && emailNotAllowed &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{
															`${t(
																'error.email-not-allowed'
															)}`
														}
													</Form.Control.Feedback>
												}
												{validated && !repeatEmail &&
													<Form.Control.Feedback type="invalid" className='custom-error'>
														{
															`${t('error.email-required')}`
														}
													</Form.Control.Feedback>
												}
											</Form.Group>
										</div>
									</div>
									{includeMenu && <div className='col-12 m-0 p-0 row'>
										<div className='col-md-6 p-0 pr-md-1'>
											<Form.Group id="main-inscription-menu">
												<Form.Label className="input-label">{`${t(
													'event.menu-label'
												)}*`}</Form.Label>
												<Select
													className={validated && !!menu ? "select-control valid" : "select-control"}
													placeholder={t('select')}
													options={menuOptions}
													value={menuOptions.find(x => x.name === menu)}
													onChange={handleOnMenuChange}
												/>
											</Form.Group>
										</div>
										<div className='col-md-6 p-0 pl-md-1'>
											<Form.Group id="main-inscription-menu">
												<Form.Label className="input-label">{`${t(
													'event.menu-condition-label'
												)}*`}</Form.Label>
												<Select
													className={validated && !!menuCondition ? "select-control valid" : "select-control"}
													placeholder={t('select')}
													options={menuConditionOptions}
													value={menuConditionOptions.find(x => x.name === menuCondition)}
													onChange={handleOnMenuConditionChange}
												/>
											</Form.Group>
										</div>
										{menuCondition === 'OTHER' && <div className='col-md-6 p-0 pr-md-1'>
											<Form.Group id="main-inscription-menu">
												<Form.Label className="input-label">{`${t(
													'event.menu-condition-label-other'
												)}*`}</Form.Label>
												<Form.Control
													onChange={handleOtherChange}
													type="text"
													name="otherMenu"
													maxLength={100}
													autoComplete="none"
													placeholder={t('event.menu-condition-label-other')}
													required
												/>
												<Form.Control.Feedback type="invalid">
													{`${t('error.required')}`}
												</Form.Control.Feedback>
											</Form.Group>
										</div>}
									</div>}

									{isGroupal ? (
										<div className="mt-5 mb-3">
											{companions && companions.length > 0 && (
												<>
													{companions.map((companion, index) => (
														<div key={index}>
															{
																<NewCompanion
																	minAge={event.minAge}
																	maxDOB={maxDate}
																	hideDelete={true}
																	isEditing={isEditing}
																	includeMenu={includeMenu}
																	includeIdentification={includeIdentification}
																	isValidated={
																		companionValidated
																		|| validated
																	}
																	comp={companion}
																	deleteCompanion={
																		handleDeleteCompanion
																	}
																	eventId={event?._id}
																></NewCompanion>
															}
														</div>
													))}
												</>
											)}
										</div>
									) : <div className="mt-5 mb-3" />}

									{!isEditing && <div className='col-12 m-0 p-0 mb-4 d-flex align-items-center'>
										<div
											onClick={handleCheckTermsAndConditions}
											className={
												termsAndConditions
													? 'custom-checkbox cursor-pointer inscription-checkbox terms-check checked'
													: 'custom-checkbox cursor-pointer inscription-checkbox terms-check'
											}
										>
											{termsAndConditions && <IoCheckmarkSharp />}
										</div>
										<div
											className="ml-2 mr-2 inscription-terms-and-conditions"
											dangerouslySetInnerHTML={{
												__html: t('inscription.terms-and-policy', {
													linkTerms: "/termsAndConditions",
													linkPolicy: "/privacyPolicy"
												})
											}}
										>
										</div>
									</div>}
									<div className='mb-4'>
										<ReCAPTCHA
											sitekey={process.env.REACT_APP_SITE_KEY_CAPTCHA}
											ref={captchaRef}
										/>
									</div>
									<div className="mt-5 p-0 pb-0 d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-end">

										<Button
											className="btn btn-primary button-ok w-100 button-primary"
											type="submit"
											disabled={!termsAndConditions}
										>
											{allFree ? 'FINALIZAR' : t('next')}
										</Button>
									</div>
								</div>}
							</div>
						</div>
						<div className="d-flex align-items-center mt-4 justify-content-center footer-container">
							<Footer white={true} hideGala={true}></Footer>
						</div>
					</Form>

					{
						showConfirmationModal && (
							<CustomModal
								isShowing={showConfirmationModal}
								parentCallback={handleConfirmationModal}
								message={getConfirmationMessage()}
								type={checked ? 'WARNING_EDIT' : 'WARNING_DELETE'}
								buttonCancel={t('cancel')}
								buttonOK={t('confirm')}
							/>
						)
					}
					{
						showErrorModal && (
							<CustomModal
								isShowing={showErrorModal}
								parentCallback={handleCloseErrorModal}
								message={errorMessage}
								title={t('error.modal-title-oops')}
								type="ERROR"
								buttonOK={t('accept')}
							/>
						)
					}
				</div >
			)
			}

		</>
	);
}
