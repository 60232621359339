import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdUndo, IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { IoCheckmarkSharp } from 'react-icons/io5';
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import { useDispatch } from 'react-redux';
import { RiMailSendLine } from 'react-icons/ri';
import useLoader from '../../components/loader/useLoader';
import CustomModal from '../../components/modals/customModal';
import { IoQrCodeOutline } from 'react-icons/io5';
import { customStyles, getInscriptionQtyString, getInscriptionQtyStringTag } from '../../helpers/inscription-helper';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import { FaCircle } from 'react-icons/fa';
import { TInscriptionStatus } from '../../models/types/types';
import { DateHelper } from '../../helpers/date-helper';
import Select from 'react-select';
import { ISector } from '../../models/interfaces/events.interface';
import QRModal from '../../components/modals/qrModal';
import useEventsApi from '../../hooks/useEventsApi';
import './inscriptions.scss';
interface InscriptionGroupalRowProps {
	inscription: IInscription,
	canEdit: boolean,
	type: TInscriptionStatus,
	statuses: TInscriptionStatus[],
	onUndoInscription: any,
	selectAll: any,
	disableSelectAll: any,
	includeMenu: boolean,
	includeSector?: boolean,
	sectorOptions?: any[],
	onSetSector?: any
}
export function InscriptionGroupalRow(
	props: InscriptionGroupalRowProps
) {
	const [loader, showLoader, hideLoader] = useLoader();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [checked, setChecked] = useState(false);
	const [validated, setValidated] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [successTitle, setSuccessTitle] = useState<string>('');
	const [successMessage, setSuccessMessage] = useState<string>('');
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [confirmationType, setConfirmationType] = useState('');
	const [sectorOptions, setSectorOptions] = useState(props.sectorOptions);
	const [currentInscription, setCurrentInscription] = useState<IInscription>(props.inscription);
	const [inscriptionToUndo, setInscriptionToUndo] = useState<IInscription | undefined>(undefined);
	const [qrModalVisible, setQrModalVisible] = useState(false);
	const [
		,
		,
		,
		,
		,
		,
		updateInscriptionStatus,
		,
		,
		resendInscriptionEmail,
		,
		,
		,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions,
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
		updateInscriptionSector,
		getPublicAcceptedInscriptions,
		getReportBilling,
		getInscriptionsAnswers,
		getInscriptionsFeedbackAnswers
	] = useInscriptionsApi();
	const [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
		editEventNotifyInscriptions,
		getBatchesAvailability,
		checkTicketsAvailability,
		getSectorsAvailability,
		getInscriptionsBySector,
		sendEventReminder,
		sendEventFeedback,
		sendInscriptionEventFeedback
	] = useEventsApi();

	useEffect(() => {
		if (props.selectAll) {
			selectPropsInscription();
		} else {
			unselectPropsInscription();
		}
	}, [props.selectAll]);

	useEffect(() => {
		if (!!props.inscription && props.inscription.checked) {
			if (props.inscription.companions?.filter(c => c.checked === false).length === 0) {
				setChecked(true);
			} else {
				setChecked(false);
			}
		} else {
			setChecked(false);
		}
	}, [props.inscription])

	useEffect(() => {
		if (props.sectorOptions) {
			setSectorOptions([]);
			setSectorOptions(props.sectorOptions);
		}
	}, [props.sectorOptions]);

	const handleViewDetails = (e: any) => {
		e.stopPropagation();
		setExpanded(!expanded);
	};

	const selectInscription = (e?: any, value?: boolean) => {
		if (props.canEdit &&
			props.type === 'PENDING' &&
			currentInscription.inscriptionStatus !== 'PENDING_EMAIL_CONFIRMATION') {

			if (!!e) {
				e.stopPropagation();
				e.preventDefault();
			}
			const valToSet = (value === true) ? value : !checked;

			let changeCurrentInscription: boolean = false;
			let changeCompanions: boolean = false;
			if (!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0) {
				changeCurrentInscription = true;
			}

			const companions = currentInscription.companions?.map((c: IInscription) => {
				if (!!c.inscriptionStatus && props.statuses.indexOf(c.inscriptionStatus) >= 0) {
					c.checked = valToSet;
					changeCompanions = true;
				}
				return c;
			});

			let ci;
			if (changeCompanions && changeCurrentInscription) {
				ci = {
					...currentInscription,
					checked: valToSet,
					companions: companions
				};
				setCurrentInscription(ci);
			} else if (changeCompanions) {
				ci = {
					...currentInscription,
					companions: companions
				};
				setCurrentInscription(ci);
			} else {
				ci = {
					...currentInscription,
					checked: valToSet
				};
				setCurrentInscription(ci);
			}
			if (!!e) {
				props.disableSelectAll(ci);
			}
			setChecked(valToSet);
		}
	};
	const selectPropsInscription = () => {
		if (props.canEdit &&
			props.type === 'PENDING' &&
			props.inscription.inscriptionStatus !== 'PENDING_EMAIL_CONFIRMATION') {


			if (!!props.inscription.inscriptionStatus && props.statuses.indexOf(props.inscription.inscriptionStatus) >= 0) {
				props.inscription.checked = true;
			}

			if (!!props.inscription.companions) {
				for (const companion of props.inscription.companions) {
					if (!!companion.inscriptionStatus && props.statuses.includes(companion.inscriptionStatus)) {
						companion.checked = true;
					}
				}
			}
			setChecked(true);
		}
	};
	const unselectPropsInscription = () => {
		// Si el estado de inscripción existe y está en la lista de estados, desmarcar
		if (!!props.inscription.inscriptionStatus && props.statuses.indexOf(props.inscription.inscriptionStatus) >= 0) {
			props.inscription.checked = false;
		}

		// Si hay compañeros de inscripción, desmarcarlos si su estado de inscripción está en la lista de estados
		if (!!props.inscription.companions) {
			for (const companion of props.inscription.companions) {
				if (!!companion.inscriptionStatus && props.statuses.includes(companion.inscriptionStatus)) {
					companion.checked = false;
				}
			}
		}
		setChecked(false); // Asegurarse de que el estado global también se actualice para reflejar el cambio

	};

	const selectSingleInscription = (e: any, inscription: IInscription) => {
		if (
			props.canEdit &&
			props.type === 'PENDING' &&
			(inscription.inscriptionStatus === 'PENDING'
				|| inscription.inscriptionStatus === 'WAITING'
			) &&
			currentInscription.inscriptionStatus != 'PENDING_EMAIL_CONFIRMATION'
		) {
			e.stopPropagation();
			e.preventDefault();
			let ci;
			if (currentInscription._id === inscription._id) {
				ci = {
					...currentInscription,
					checked: !currentInscription.checked
				};
				setCurrentInscription(ci);
			} else {
				const c = currentInscription.companions?.map((c: IInscription) => {
					if (c._id === inscription._id) {
						c.checked = !c.checked;
					}
					return c;
				});
				if (!!c && !!currentInscription.companions) {
					ci = {
						...currentInscription,
						companions: c
					};
					setCurrentInscription(ci);
				}
			}

			// Verifica si todos los checks están seleccionados
			const allChecked: any = ci?.checked && ci?.companions?.every(c => c.checked) && ci?.companions?.every(c => (c.inscriptionStatus === 'PENDING' || c.inscriptionStatus === 'WAITING'));
			props.disableSelectAll(ci);
			setChecked(allChecked);
		}
	};

	const confirmUndo = (e: any, inscription: IInscription | undefined) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('UNDO');
		setInscriptionToUndo(inscription);
		setShowConfirmationModal(true);
	};
	const confirmResend = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('RESEND');
		setShowConfirmationModal(true);
	};

	const confirmQr = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('RESENDQR');
		setQrModalVisible(false);
		setShowConfirmationModal(true);
	};
	const openQRModal = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setQrModalVisible(true);
	};
	const onCloseConfirmationModal = async (e: any) => {
		if (e) {
			setShowConfirmationModal(false);
			switch (confirmationType) {
				case 'UNDO':
					await handleUndo();
					break;
				case 'RESEND':
					await handleResend();
					break;
				case 'RESENDQR':
					await handleResendQR();
					break;
				default:
					break;
			}
		} else {
			setShowConfirmationModal(false);
		}

	};

	const getConfirmationMessage = () => {
		switch (confirmationType) {
			case 'UNDO': {
				return t('confirmation.move-to-pending');
				// if (currentInscription.inscriptionStatus !== 'REJECTED') {
				// 	return t('confirmation.move-to-pending');
				// }
				//return t('confirmation.resend-email');
			}

			case 'RESEND':
				return t('confirmation.resend-email');
			case 'RESENDQR':
				return t('confirmation.resend-qr');
			default:
				break;
		}

	};
	const getSuccessTitle = () => {
		return confirmationType == 'RESENDQR' ? t('inscription.resend-qr-success-title') : t('inscription.resend-email-success-title');

	}
	const getSuccessMessage = (resendConfirmationEmail: boolean) => {
		const name = currentInscription?.name || currentInscription?.user?.name;
		const lastName = currentInscription?.surname || currentInscription?.user?.surname;
		const email = currentInscription?.user?.email;
		const companions = currentInscription.companions;
		const usr = (currentInscription.user?.status === 'ACTIVE' || resendConfirmationEmail) ?
			(name != null ? name : '') +
			' ' +
			(lastName != null ? lastName : '') +
			(email && email != '' ? ' (<i>' + email + '</i>)' : '') : '';
		const companionsNames = (!!companions && companions.length > 0) ?
			companions?.filter((y: any) => !y.deleted && (y.user?.status === 'ACTIVE' || resendConfirmationEmail))
				.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
				}) : null;
		const companionsNamesQR = (!!companions && companions.length > 0) ?
			companions?.filter((y: any) => !y.deleted && y.inscriptionStatus === 'ACCEPTED' && (y.user?.status === 'ACTIVE' || resendConfirmationEmail))
				.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
				}) : null;
		switch (confirmationType) {
			case 'RESENDQR':
				return t('inscription.resend-qr-success', {
					user: usr,
					companions: companionsNamesQR
				});

			default:
				return t('inscription.resend-email-success', {
					user: usr,
					companions: companionsNames
				});
		}

	};

	const dispatchUndoStatus = async (status: TInscriptionStatus) => {
		const response: any = await dispatch(
			updateInscriptionStatus({
				inscriptionStatus: status,
				id: inscriptionToUndo?._id,
			})
		);
		if (response['payload'] && response['payload']['_id']) {
			props.onUndoInscription(inscriptionToUndo, props.type);
		}
	};

	const handleUndo = async () => {
		if (!!inscriptionToUndo) {
			showLoader();
			if (inscriptionToUndo.inscriptionStatus === 'ACCEPTED') {
				await dispatchUndoStatus('PENDING');
			} else if (inscriptionToUndo.inscriptionStatus === 'DENIED') {
				const eventId: string = inscriptionToUndo.event?._id ?
					inscriptionToUndo.event?._id : inscriptionToUndo.event as string;
				const ev: any = await getEventById(eventId);
				const placesLeft = ev.maxCapacity -
					ev.inscriptions?.filter(
						(x: IInscription) =>
							x.inscriptionStatus === 'PENDING' ||
							x.inscriptionStatus === 'ACCEPTED'
					).length;

				if (placesLeft > 0) {
					await dispatchUndoStatus("PENDING");
				} else if (ev.allowsWaiting) {
					await dispatchUndoStatus("WAITING");
				} else if (!ev.allowsWaiting) {
					setErrorMessage(t("error.inscription-max-capacity"));
					setShowErrorModal(true);
				}
			}
			setInscriptionToUndo(undefined);
			hideLoader();
			// 	} else {
			// 		showLoader();
			// 		const resp: any = await dispatch(
			// 			updateInscriptionStatus({
			// 				inscriptionStatus: 'PENDING',
			// 				id: inscriptionToUndo._id,
			// 			})
			// 		);
			// 		hideLoader();
			// 		if (resp['payload'] && resp['payload']['_id']) {
			// 			setSuccessTitle(getSuccessTitle());
			// 			setSuccessMessage(getSuccessMessage(true));
			// 			setShowSuccessModal(true);
			// 		}
			// 		 else {
			// 			setInscriptionToUndo(undefined);
			// 			setShowErrorModal(true);

			// 		}

			// 	}
		}
	};
	const handleResendQR = async () => {
		showLoader();
		const response: any = await resendQREmail(currentInscription?._id);
		hideLoader();

		if (response && response['_id']) {
			setSuccessTitle(getSuccessTitle());
			setSuccessMessage(getSuccessMessage(false));
			setShowSuccessModal(true);
		} else {
			setErrorMessage(t('inscription.resend-email-error'));
			setShowErrorModal(true);
		}
	};
	const handleResend = async () => {
		showLoader();
		const response: any = await resendInscriptionEmail(currentInscription?._id);
		hideLoader();

		if (response && response['_id']) {
			setSuccessTitle(getSuccessTitle());
			setSuccessMessage(getSuccessMessage(true));
			setShowSuccessModal(true);
		} else {
			setErrorMessage(t('inscription.resend-email-error'));
			setShowErrorModal(true);
		}
	};
	const handleCloseSuccessModal = (e: any) => {
		setSuccessTitle('');
		setSuccessMessage('');
		setShowSuccessModal(false);

		if (currentInscription.inscriptionStatus == 'REJECTED') {
			props.onUndoInscription(inscriptionToUndo, props.type);
			setInscriptionToUndo(undefined);
		}
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const handleOnSelectSector = async (e: any, insc: IInscription) => {
		try {
			showLoader();
			const response: any = await dispatch(
				updateInscriptionSector({ sector: e.value, id: insc._id, inscriptionStatus: insc.inscriptionStatus })
			);
			if (!!props.onSetSector) {
				await props.onSetSector();
			}
			insc.sector = e.value;
			if (!insc.parentInscription) {
				setCurrentInscription(insc);
			}
		} catch (e) {
			console.error(e);
		}
		finally {
			hideLoader();
		}
	}
	const getBatchName = (name: string | undefined) => {
		if (name === 'FREE_BATCH') {
			return t(`event.batch.type.${name}`);
		}
		return name;
	}
	return (
		<>
			{loader}
			{showSuccessModal && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					title={successTitle}
					type="SUCCESS"
					buttonOK={t('accept')}
					message={successMessage + (currentInscription['inscriptionStatus'] == 'REJECTED'
						? ("<br> <br> " + t('inscription.undo-rejected'))
						: '')
					}
				// message={

				// }
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={errorMessage}
					type="ERROR"
					buttonOK={t('accept')}
					title={t('error.modal-title-oops')}
				/>
			)}
			{!!currentInscription && !!currentInscription.event &&
				<>
					{showConfirmationModal && (
						<CustomModal
							isShowing={showConfirmationModal}
							parentCallback={onCloseConfirmationModal}
							message={getConfirmationMessage()}
							buttonCancel={t('cancel')}
							buttonOK={t('accept')}
							type="WARNING_EDIT"
						/>
					)}
					{qrModalVisible && (
						<QRModal visible={qrModalVisible}
							inscription={currentInscription}
							onHide={() => setQrModalVisible(false)}
							resendQRFn={confirmQr}
						></QRModal>
					)}
				</>
			}
			{!!currentInscription && !!currentInscription.event &&
				<>
					<tr key={currentInscription._id}
						onClick={handleViewDetails}

						className={
							props.canEdit &&
								currentInscription['inscriptionStatus'] !=
								'PENDING_EMAIL_CONFIRMATION' &&
								currentInscription['inscriptionStatus'] != 'REJECTED' &&
								currentInscription['inscriptionStatus'] != 'ATTENDEED'
								? 'inscription-row groupal cursor-pointer align-items-center m-0'
								: 'inscription-row groupal align-items-center m-0'
						}>
						{/* Primer columna de accion */}
						<td className='text-center'>

							{props.canEdit &&
								props.type == 'PENDING' &&
								currentInscription.inscriptionStatus != 'PENDING_EMAIL_CONFIRMATION' && (
									<div onClick={selectInscription}
										className={
											(currentInscription.checked || (currentInscription.inscriptionStatus !== 'PENDING' && currentInscription.inscriptionStatus !== 'WAITING')) &&
												((!!currentInscription.companions && currentInscription.companions.filter(c => c.checked === false).length === 0) || !currentInscription.companions)
												? 'custom-checkbox cursor-pointer inscription-checkbox checked'
												: 'custom-checkbox cursor-pointer inscription-checkbox'
										}
									>
										{(currentInscription.checked || (currentInscription.inscriptionStatus !== 'PENDING' && currentInscription.inscriptionStatus !== 'WAITING')) &&
											((!!currentInscription.companions && currentInscription.companions.filter(c => c.checked === false).length === 0) || !currentInscription.companions)
											&& <IoCheckmarkSharp />}
									</div>
								)}

							{props.canEdit &&
								currentInscription.inscriptionStatus === 'PENDING_EMAIL_CONFIRMATION' && (
									<div>
										<RiMailSendLine
											className="cursor-pointer inscription-icon"
											title={t(
												'inscription.resend-confirmation'
											)}
											onClick={confirmResend}
										/>
									</div>
								)}
							{props.canEdit &&
								(currentInscription.inscriptionStatus === 'ACCEPTED' && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 ||
									(!!currentInscription.companions && currentInscription.companions.find(c => c.inscriptionStatus === 'ACCEPTED' && props.statuses.indexOf(c.inscriptionStatus) >= 0) != undefined)
								) && (
									<div>

										<IoQrCodeOutline
											className="cursor-pointer inscription-icon"
											title={t('inscription.see-qr')}
											onClick={openQRModal}
										/>
									</div>
								)}


						</td>
						{/* Nombre Apellido email */}
						<td >
							<div className="inscription-name-title">
								{currentInscription.name || currentInscription.user?.name}{' '}
								{currentInscription.surname || currentInscription.user?.surname}

								{/* <label className="inscription-companions-title m-0">{getCompanionQties(currentInscription?.companions, currentInscription?.user?.group)}</label> */}
								{/* <label className="inscription-companions-title m-0">
									{currentInscription.inscriptionStatus !== 'REJECTED' && <div dangerouslySetInnerHTML={{
										__html: getInscriptionQtyString(currentInscription, props.statuses),
									}}></div>}
									{currentInscription.inscriptionStatus === 'REJECTED' && <div dangerouslySetInnerHTML={{
										__html: getInscriptionQtyString(currentInscription, ['REJECTED']),
									}}></div>}

								</label> */}

							</div>
						</td>
						{/* Rellenado */}
						{props.includeSector && props.type != 'DENIED' && currentInscription.event.hasSectors == "yes" &&
							<td className='hide-medium'></td>
						}
						{currentInscription.event.includeDOB &&
							<td className='hide-medium'></td>
						}
						{currentInscription.event.includeGender &&
							<td className='hide-medium'></td>
						}
						{props.includeMenu && currentInscription.event.includeMenu &&
							<td className='hide-medium'></td>
						}

						{!!currentInscription?.inscriptionStatus &&
							<td className='hide-medium'>
								{currentInscription.event.eventType === 'PRIVATE_EVENT' &&<div className="d-flex m-0">
									{currentInscription.inscriptionStatus !== 'REJECTED' && <div
									className='d-flex'
									 dangerouslySetInnerHTML={{
										__html: getInscriptionQtyStringTag(currentInscription, props.statuses),
									}}></div>}
									{currentInscription.inscriptionStatus === 'REJECTED' && <div
									className='d-flex'
									 dangerouslySetInnerHTML={{
										__html: getInscriptionQtyStringTag(currentInscription, ['REJECTED']),
									}}></div>}

								</div>}
							</td>
						}
						{/* Expand icon */}
						<td>
							
							<div className={'inscriptions-link icon cursor-pointer text-right'}>
								{!expanded && <IoIosArrowDown></IoIosArrowDown>}
								{expanded && <IoIosArrowUp></IoIosArrowUp>}
							</div>

						</td>
					</tr>
					{expanded &&
						// Main inscription
						<>
							<tr key={currentInscription.user?._id} className='inscription-row align-items-center m-0'>
								{/* Action */}
								<td className='text-center' onClick={(e) => selectSingleInscription(e, currentInscription)}>
									{props.canEdit &&
										(currentInscription.inscriptionStatus === 'PENDING'
											|| currentInscription.inscriptionStatus === 'WAITING') &&
										!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 &&
										<div
											className={
												currentInscription.checked
													? 'custom-checkbox cursor-pointer inscription-checkbox checked'
													: 'custom-checkbox cursor-pointer inscription-checkbox'
											}>
											{currentInscription.checked && <IoCheckmarkSharp />}
										</div>
									}
									{props.canEdit && props.onUndoInscription && props.type != 'PENDING' && props.type != 'ATTENDEED' &&
										currentInscription.event?.eventType === 'PRIVATE_EVENT' &&
										(currentInscription.inscriptionStatus === 'ACCEPTED' || currentInscription.inscriptionStatus === 'DENIED') &&
										!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 &&
										(
											<div>
												<IoMdUndo
													className="cursor-pointer inscription-icon"
													title={t('inscription.move-to-pending')}
													onClick={(e) => confirmUndo(e, currentInscription)}
												></IoMdUndo>
											</div>
										)}

								</td>
								{/* Name */}
								<td className=''>
									<div className="inscription-name d-flex align-items-center">
										<FaCircle className={currentInscription.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!currentInscription.user ? "inactive-dot mr-1" : "d-none")} />
										{currentInscription.name || currentInscription.user?.name}{' '}
										{currentInscription.surname || currentInscription.user?.surname}
									</div>
									{!!currentInscription.event.includeIdentification && !!currentInscription.identificationNumber && !!currentInscription.identificationNumber
										&& <div className="inscription-email">
											{currentInscription.identificationType + ' ' + currentInscription.identificationNumber}
										</div>}

									{(currentInscription.email || currentInscription.user?.email) &&
										<div className="inscription-email">
											{currentInscription.email || currentInscription.user?.email}
										</div>}
									{/* Mobile */}
									<div className='hide-not-medium'>
										{props.includeSector && props.type != 'DENIED' && currentInscription.event.hasSectors == "yes" &&
											<>
												{!sectorOptions && <div className="inscription-companions">
													{currentInscription.sector?.name || t('event.sector.no-sector')}
												</div>}
												{!!sectorOptions && <div className="sector-selector small">
													<Select
														required
														label="name"
														isInvalid={validated && !currentInscription.sector?._id}
														className={validated && !currentInscription.sector?._id ? "select-control-sector invalid" : (validated && !!currentInscription.sector?._id ? "select-control-sector valid" : "select-control-sector")}
														placeholder={t('select')}
														options={sectorOptions}
														value={sectorOptions.find(x => !!currentInscription.sector?._id ? x.value === currentInscription.sector?._id : x.value === currentInscription.sector)}
														onChange={(e: any) => { handleOnSelectSector(e, currentInscription) }}
														styles={customStyles}
														isOptionDisabled={(option: any) => !option.enabled}
														isSearchable={false}
													/>
												</div>}
											</>
										}
										{/* Rango etareo y Genero */}
										{(currentInscription.event.includeDOB || currentInscription.event.includeGender) &&
											<div className="inscription-companions">
												{currentInscription.event.includeDOB && t('age-group.' + (currentInscription.group))}
												{currentInscription.event.includeDOB && currentInscription.event.includeGender && ' - '}
												{currentInscription.event.includeGender && t('gender.' + (currentInscription.gender))}

											</div>
										}

										{/* Menú */}
										{props.includeMenu && currentInscription.event.includeMenu &&
											<div className="inscription-companions">
												{
													(currentInscription.menu || currentInscription.menuCondition) &&
													<div>
														{(currentInscription.menu ? t('event.menu.' + currentInscription.menu) : '') +
															(currentInscription.menu && currentInscription.menuCondition ? ' - ' : '') +
															(currentInscription.menuCondition ? t('event.menu-condition.' + currentInscription.menuCondition) : '')
														}</div>
												}

											</div>
										}
									</div>
								</td>
								{/* Sector */}
								{props.includeSector && props.type != 'DENIED' && currentInscription.event.hasSectors == "yes" &&
									<td className='hide-medium sector-selector'>
										{!sectorOptions && <div className="inscription-companions">
											{currentInscription.sector?.name || t('event.sector.no-sector')}
										</div>}
										{!!sectorOptions && <div >
											<Select
												required
												label="name"
												isInvalid={validated && !currentInscription.sector?._id}
												className={validated && !currentInscription.sector?._id ? "select-control-sector invalid" : (validated && !!currentInscription.sector?._id ? "select-control-sector valid" : "select-control-sector")}
												placeholder={t('select')}
												options={sectorOptions}
												value={sectorOptions.find(x => !!currentInscription.sector?._id ? x.value === currentInscription.sector?._id : x.value === currentInscription.sector)}
												onChange={(e: any) => { handleOnSelectSector(e, currentInscription) }}
												styles={customStyles}
												isOptionDisabled={(option: any) => !option.enabled}
												isSearchable={false}
											/>
										</div>}
									</td>
								}
								{/* Rango etareo */}
								{currentInscription.event.includeDOB &&
									<td className="inscription-companions hide-medium">
										<div>{t('age-group.' + (currentInscription.group))}</div>

									</td>
								}
								{/* Genero */}
								{currentInscription.event.includeGender &&
									<td className="inscription-companions hide-medium">
										<div>{t('gender.' + (currentInscription.gender))}</div>


									</td>
								}
								{/* Menú */}
								{props.includeMenu && currentInscription.event.includeMenu &&
									<td className="inscription-companions hide-medium">
										{
											(currentInscription.menu || currentInscription.menuCondition) &&
											<div>
												{(currentInscription.menu ? t('event.menu.' + currentInscription.menu) : '') +
													(currentInscription.menu && currentInscription.menuCondition ? ' - ' : '') +
													(currentInscription.menuCondition ? t('event.menu-condition.' + currentInscription.menuCondition) : '')
												}</div>
										}

									</td>
								}
								{/* Status */}
								
								{!!currentInscription.inscriptionStatus &&
									<td>
										{(currentInscription.event.eventType === 'PRIVATE_EVENT' || currentInscription.inscriptionStatus == 'ATTENDEED') && <div className="p-0 m-0 d-flex align-items-center justify-content-start">
											<div className={'mr-2 status-tag ' + currentInscription.inscriptionStatus}>{t('inscription.status-tag.' + currentInscription.inscriptionStatus)}</div>
										</div >}
										<div className='inscription-email small hide-not-medium'>
											{(currentInscription.event.eventType === 'PUBLIC_EVENT' && currentInscription.inscriptionStatus != 'ATTENDEED') &&
												// <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
												<>
													{currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
														{'$ ' + currentInscription.batch?.price}
														{currentInscription.payment?.cardBrand && (
															<span className="d-none d-md-inline">
																{' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
															</span>
														)}
													</div>) : null}
													{currentInscription.payment?.cardBrand && (
														<div className="d-md-none small mt-1 inscription-companions small mt-1">
															{currentInscription.payment?.cardBrand?.toUpperCase() +
																'-' +
																currentInscription.payment.cardLast4}
														</div>
													)}
													<div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
													<div className="inscription-email small mt-1">
														{currentInscription.acceptedDT
															? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
															: ''}
													</div></>
											}
											{(currentInscription.event.eventType === 'PRIVATE_EVENT' || currentInscription.inscriptionStatus == 'ATTENDEED') &&
												<>
													{(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
														currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
															(currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
													<br></br>
													<span className="smaller">
														{(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
															currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
													</span>

												</>}
										</div>
									</td>
								}
								{!!currentInscription?.inscriptionStatus &&
									<td className="inscription-email small hide-medium">
										{(currentInscription.event.eventType === 'PUBLIC_EVENT' && currentInscription.inscriptionStatus != 'ATTENDEED') &&
											<>
												{/* <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div> */}
												{currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
													{'$ ' + currentInscription.batch?.price}
													{currentInscription.payment?.cardBrand && (
														<span className="d-none d-md-inline">
															{' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
														</span>
													)}
												</div>) : null}
												{currentInscription.payment?.cardBrand && (
													<div className="d-md-none small mt-1 inscription-companions small mt-1">
														{currentInscription.payment?.cardBrand?.toUpperCase() +
															'-' +
															currentInscription.payment.cardLast4}
													</div>
												)}
												<div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
												<div className="inscription-email small mt-1">
													{currentInscription.acceptedDT
														? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
														: ''}
												</div>
											</>
										}
										{(currentInscription.event.eventType !== 'PUBLIC_EVENT' || currentInscription.inscriptionStatus === 'ATTENDEED') &&
											<>
												{(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
													currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
														(currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
												<br></br>
												<span className="smaller">
													{(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
														currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
												</span>
											</>}


									</td>
								}
							</tr>

							{currentInscription.companions?.map((companion: IInscription, index: any) => (
								<tr key={companion._id} className='inscription-row align-items-center m-0'
								>
									{/* Action */}
									<td className='text-center' onClick={(e) => selectSingleInscription(e, companion)}>
										{props.canEdit &&
											(companion.inscriptionStatus === 'PENDING'
												|| companion.inscriptionStatus === 'WAITING') &&
											!!companion.inscriptionStatus && props.statuses.indexOf(companion.inscriptionStatus) >= 0 &&
											<div
												className={
													companion.checked
														? 'custom-checkbox cursor-pointer inscription-checkbox checked'
														: 'custom-checkbox cursor-pointer inscription-checkbox'
												}>
												{companion.checked && <IoCheckmarkSharp />}
											</div>
										}
										{props.canEdit && props.onUndoInscription && props.type != 'PENDING' && props.type != 'ATTENDEED' &&
											currentInscription.event?.eventType === 'PRIVATE_EVENT' &&
											(companion.inscriptionStatus === 'ACCEPTED' || companion.inscriptionStatus === 'DENIED') &&
											!!companion.inscriptionStatus && props.statuses.indexOf(companion.inscriptionStatus) >= 0 &&
											(
												<div>
													<IoMdUndo
														className="cursor-pointer inscription-icon"
														title={t('inscription.move-to-pending')}
														onClick={(e) => confirmUndo(e, companion)}
													></IoMdUndo>
												</div>
											)}

									</td>
									{/* Name */}
									<td className=''>
										<div className="inscription-name d-flex align-items-center">
											<FaCircle className={companion.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!companion.user ? "inactive-dot mr-1" : "d-none")} />
											{companion.name || companion.user?.name}{' '}
											{companion.surname || companion.user?.surname}
										</div>
										{!!currentInscription.event?.includeIdentification && !!companion.identificationNumber && !!companion.identificationNumber
											&& <div className="inscription-email">
												{companion.identificationType + ' ' + companion.identificationNumber}
											</div>}

										{(companion.email || companion.user?.email) &&
											<div className="inscription-email">
												{companion.email || companion.user?.email}
											</div>}
										{/* Mobile */}
										<div className='hide-not-medium'>
											{props.includeSector && props.type != 'DENIED' && currentInscription.event?.hasSectors == "yes" &&
												<>
													{!sectorOptions && <div className="inscription-companions">
														{companion.sector?.name || t('event.sector.no-sector')}
													</div>}
													{!!sectorOptions && <div className="sector-selector small">
														<Select
															required
															label="name"
															isInvalid={validated && !companion.sector?._id}
															className={validated && !companion.sector?._id ? "select-control-sector invalid" : (validated && !!companion.sector?._id ? "select-control-sector valid" : "select-control-sector")}
															placeholder={t('select')}
															options={sectorOptions}
															value={sectorOptions.find(x => !!companion.sector?._id ? x.value === companion.sector?._id : x.value === companion.sector)}
															onChange={(e: any) => { handleOnSelectSector(e, companion) }}
															styles={customStyles}
															isOptionDisabled={(option: any) => !option.enabled}
															isSearchable={false}
														/>
													</div>}
												</>
											}
											{/* Rango etareo y Genero */}
											{(currentInscription.event?.includeDOB || currentInscription.event?.includeGender) &&
												<div className="inscription-companions">
													{currentInscription.event?.includeDOB && t('age-group.' + (companion.group))}
													{currentInscription.event?.includeDOB && currentInscription.event?.includeGender && ' - '}
													{currentInscription.event?.includeGender && t('gender.' + (companion.gender))}

												</div>
											}

											{/* Menú */}
											{props.includeMenu && currentInscription.event?.includeMenu &&
												<div className="inscription-companions">
													{
														(companion.menu || companion.menuCondition) &&
														<div>
															{(companion.menu ? t('event.menu.' + companion.menu) : '') +
																(companion.menu && companion.menuCondition ? ' - ' : '') +
																(companion.menuCondition ? t('event.menu-condition.' + companion.menuCondition) : '')
															}</div>
													}

												</div>
											}
										</div>
									</td>
									{/* Sector */}
									{props.includeSector && props.type != 'DENIED' && currentInscription.event?.hasSectors == "yes" &&
										<td className='hide-medium sector-selector'>
											{!sectorOptions && <div className="inscription-companions">
												{companion.sector?.name || t('event.sector.no-sector')}
											</div>}
											{!!sectorOptions && <div>
												<Select
													required
													label="name"
													isInvalid={validated && !companion.sector?._id}
													className={validated && !companion.sector?._id ? "select-control-sector invalid" : (validated && !!companion.sector?._id ? "select-control-sector valid" : "select-control-sector")}
													placeholder={t('select')}
													options={sectorOptions}
													value={sectorOptions.find(x => !!companion.sector?._id ? x.value === companion.sector?._id : x.value === companion.sector)}
													onChange={(e: any) => { handleOnSelectSector(e, companion) }}
													styles={customStyles}
													isOptionDisabled={(option: any) => !option.enabled}
													isSearchable={false}
												/>
											</div>}
										</td>
									}
									{/* Rango etareo */}
									{currentInscription.event?.includeDOB &&
										<td className="inscription-companions hide-medium">
											<div>{t('age-group.' + (companion.group))}</div>

										</td>
									}
									{/* Genero */}
									{currentInscription.event?.includeGender &&
										<td className="inscription-companions hide-medium">
											<div>{t('gender.' + (companion.gender))}</div>


										</td>
									}
									{/* Menú */}
									{props.includeMenu && currentInscription.event?.includeMenu &&
										<td className="inscription-companions hide-medium">
											{
												(companion.menu || companion.menuCondition) &&
												<div>
													{(companion.menu ? t('event.menu.' + companion.menu) : '') +
														(companion.menu && companion.menuCondition ? ' - ' : '') +
														(companion.menuCondition ? t('event.menu-condition.' + companion.menuCondition) : '')
													}</div>
											}

										</td>
									}
									{/* Status */}
									
									{!!companion.inscriptionStatus &&
										<td>
											{(currentInscription.event?.eventType === 'PRIVATE_EVENT' || companion.inscriptionStatus == 'ATTENDEED') && <div className="p-0 m-0 d-flex align-items-center justify-content-start">
												<div className={'mr-2 status-tag ' + companion.inscriptionStatus}>{t('inscription.status-tag.' + companion.inscriptionStatus)}</div>
											</div >}
											<div className='inscription-email small hide-not-medium'>
												{(currentInscription.event?.eventType === 'PUBLIC_EVENT' && companion.inscriptionStatus != 'ATTENDEED') &&
													// <div className="inscription-email small mt-1">{getBatchName(companion.batch?.name)}</div>
													<>
														{companion.batch?.price ? (<div className="inscription-companions small mt-1">
															{'$ ' + companion.batch?.price}
															{companion.payment?.cardBrand && (
																<span className="d-none d-md-inline">
																	{' . ' + companion.payment?.cardBrand?.toUpperCase() + '-' + companion.payment.cardLast4}
																</span>
															)}
														</div>) : null}
														{companion.payment?.cardBrand && (
															<div className="d-md-none small mt-1 inscription-companions small mt-1">
																{companion.payment?.cardBrand?.toUpperCase() +
																	'-' +
																	companion.payment.cardLast4}
															</div>
														)}
														<div className="inscription-email small mt-1">{getBatchName(companion.batch?.name)}</div>
														<div className="inscription-email small mt-1">
															{companion.acceptedDT
																? DateHelper.getStringDateTimeFromDate(new Date(companion.acceptedDT))
																: ''}
														</div></>
												}
												{(currentInscription.event?.eventType === 'PRIVATE_EVENT' || companion.inscriptionStatus == 'ATTENDEED') &&
													<>
														{(companion.inscriptionStatus === 'ATTENDEED' ? (companion.attendeedBy?.name || '') + ' ' + (companion.attendeedBy?.surname || '') :
															companion.inscriptionStatus === 'ACCEPTED' ? (companion.acceptedBy?.name || '') + ' ' + (companion.acceptedBy?.surname || '') :
																(companion.deniedBy?.name || '') + ' ' + (companion.deniedBy?.surname || ''))}
														<br></br>
														<span className="smaller">
															{(companion.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(companion.attendeedDT?.toString()) :
																companion.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(companion.acceptedDT?.toString()) : DateHelper.dateAndTime(companion.deniedDT?.toString()))}
														</span>

													</>}
											</div>
										</td>
									}
									{!!companion?.inscriptionStatus &&
										<td className="inscription-email small hide-medium">
											{(currentInscription.event?.eventType === 'PUBLIC_EVENT' && companion.inscriptionStatus != 'ATTENDEED') &&
												<>
													{/* <div className="inscription-email small mt-1">{getBatchName(companion.batch?.name)}</div> */}
													{companion.batch?.price ? (<div className="inscription-companions small mt-1">
														{'$ ' + companion.batch?.price}
														{companion.payment?.cardBrand && (
															<span className="d-none d-md-inline">
																{' . ' + companion.payment?.cardBrand?.toUpperCase() + '-' + companion.payment.cardLast4}
															</span>
														)}
													</div>) : null}
													{companion.payment?.cardBrand && (
														<div className="d-md-none small mt-1 inscription-companions small mt-1">
															{companion.payment?.cardBrand?.toUpperCase() +
																'-' +
																companion.payment.cardLast4}
														</div>
													)}
													<div className="inscription-email small mt-1">{getBatchName(companion.batch?.name)}</div>
													<div className="inscription-email small mt-1">
														{companion.acceptedDT
															? DateHelper.getStringDateTimeFromDate(new Date(companion.acceptedDT))
															: ''}
													</div>
												</>
											}
											{(currentInscription.event?.eventType !== 'PUBLIC_EVENT' || companion.inscriptionStatus === 'ATTENDEED') &&
												<>
													{(companion.inscriptionStatus === 'ATTENDEED' ? (companion.attendeedBy?.name || '') + ' ' + (companion.attendeedBy?.surname || '') :
														companion.inscriptionStatus === 'ACCEPTED' ? (companion.acceptedBy?.name || '') + ' ' + (companion.acceptedBy?.surname || '') :
															(companion.deniedBy?.name || '') + ' ' + (companion.deniedBy?.surname || ''))}
													<br></br>
													<span className="smaller">
														{(companion.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(companion.attendeedDT?.toString()) :
															companion.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(companion.acceptedDT?.toString()) : DateHelper.dateAndTime(companion.deniedDT?.toString()))}
													</span>
												</>}


										</td>
									}

								</tr>

							))}

						</>

					}

				</>
			}

		</>
	);
}
