import { createAsyncThunk } from '@reduxjs/toolkit';

import { useDispatch } from 'react-redux';
import { EVENT_TYPES, INSCRIPTION_TYPES } from '../../constants';

import useUsersApi from '../hooks/useUsersApi';
import { IEvent, IOrder } from '../models/interfaces/events.interface';
const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;

export default () => {
	const dispatch = useDispatch();
	const [, , , logoutUser] = useUsersApi();

	const token = localStorage.getItem('token');
	const tokenAuth = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const createEvent = createAsyncThunk(
		'createEvent',
		async (evt: IEvent) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					await dispatch(logoutUser());
					return undefined;
				}
				const response: any = await fetch(API_URL + '/api/events',
					{
						method: 'POST',
						body: JSON.stringify(evt),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail createEvent');
				return err;
			}
		}
	);

	

	const getEventTypes = () => {
		return EVENT_TYPES;
	};

	const getInscriptionTypes = () => {
		return INSCRIPTION_TYPES;
	};
	const updateEvent = createAsyncThunk(
		'updateEvent',
		async (evt: IEvent) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					await dispatch(logoutUser());
					return undefined;
				}
				const url = evt.deletedBy != null ? '/api/events/softDeleteEvent/' : '/api/events/';
				const response: any = await fetch(API_URL + url +
					evt._id,
					{
						method: 'PATCH',
						body: JSON.stringify(evt, (k, v) => k === "imageSrc" && v === undefined ? null : v),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('Fail updateEvent');
				console.log(err);
			}
		}
	);

	const editEventNotifyInscriptions = createAsyncThunk(
		'editEventNotifyInscriptions',
		async (evt: IEvent) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					await dispatch(logoutUser());
					return undefined;
				}
				
				const response: any = await fetch(API_URL + '/api/events/editEventNotifyInscriptions/' + evt._id,
					{
						method: 'PATCH',
						body: JSON.stringify(evt, (k, v) => k === "imageSrc" && v === undefined ? null : v),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('Fail updateEventAndnotify');
				console.log(err);
			}
		}
	);


	const deleteEvent = createAsyncThunk(
		'deleteEvent',
		async ({ id }: { id: string }) => {
			try {
				const response: any = await fetch(API_URL + '/api/events/' + id
					, {
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					console.log(data);
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('Fail deleteEvent');
				console.log(err);
			}
		}
	);

	const getEventById = async (id: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/events/' + id
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log('Fail getEventById');
			console.log(error);
		}
	};
	const getBatchesAvailability = async (id: string) => {
		try {
			const now = new Date();
			const offset = now.getTimezoneOffset();
			const response: any = await fetch(API_URL + '/api/events/getBatchesAvailability/' + id +'/'+offset
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log('Fail getBatchesAvailability');
			console.log(error);
		}
	};

	const checkTicketsAvailability = createAsyncThunk(
		'checkTicketsAvailability',
		async ({ id, order }: { id: string, order: IOrder }) => {
			try {
				const now = new Date();
				const offset = now.getTimezoneOffset();
				const response: any = await fetch(API_URL + '/api/events/checkTicketsAvailability/' + id +'/' + offset,
					{
						method: 'POST',
						body: JSON.stringify(order),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail checkTicketsAvailability');
				return err;
			}
		}
	);
	const getSectorsAvailability = async (id: string) => {
		try {
			const now = new Date();
			const offset = now.getTimezoneOffset();
			const response: any = await fetch(API_URL + '/api/events/getSectorsAvailability/' + id 
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log('Fail getSectorsAvailability');
			console.log(error);
		}
	};

	const getInscriptionsBySector = async (id: string) => {
		try {
			const now = new Date();
			const offset = now.getTimezoneOffset();
			const response: any = await fetch(API_URL + '/api/events/getInscriptionsBySector/' + id 
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();


				if (!!data && data.length > 0) {

					const sorted = data.sort((a: any, b: any) => {
						if (a.sectorInfo.name.toLowerCase() < b.sectorInfo.name.toLowerCase() && a.inscriptions.length > 0) {
							return -1;
						}
						if (a.sectorInfo.name.toLowerCase() > b.sectorInfo.name.toLowerCase() && b.inscriptions.length > 0) {
							return 1;
						}
						return 0;
					}
					);
					return sorted;
				}

				return data;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log('Fail getInscriptionsBySector');
			console.log(error);
		}
	};

	const sendEventReminder = async (eventId: string) => {
		try {
			const now = new Date();
			const offset = now.getTimezoneOffset();
			const response: any = await fetch(API_URL + '/api/events/sendEventReminder/' + eventId 
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				if (response.status !== 200 ){
					return undefined;
				}
				const data = await response.json();
				if (data.sent){
					return data;
				}
				return undefined;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log('Fail sendEventReminder');
			console.log(error);
		}
	};

	const sendEventFeedback = async (eventId: string) => {
		try {
			const now = new Date();
			const offset = now.getTimezoneOffset();
			const response: any = await fetch(API_URL + '/api/events/sendEventFeedback/' + eventId 
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				if (response.status !== 200 ){
					return undefined;
				}
				const data = await response.json();
				if (data.sent){
					return data;
				}
				return undefined;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log('Fail sendEventFeedback');
			console.log(error);
		}
	};

	const sendInscriptionEventFeedback = async (eventId: string, inscriptionId: string) => {
		try {
			const now = new Date();
			const offset = now.getTimezoneOffset();
			const response: any = await fetch(API_URL + '/api/events/sendInscriptionEventFeedback/' + eventId +'/'+inscriptionId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				if (response.status !== 200 ){
					return undefined;
				}
				const data = await response.json();
				if (data.sent){
					return data;
				}
				return undefined;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log('Fail sendInscriptionEventFeedback');
			console.log(error);
		}
	};
	const getEventMailPreview = async (eventId: string, templateName: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/events/getEventMailPreview/' + eventId + '/' + templateName
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					 }
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				if (response.status !== 200 ){
					return undefined;
				}
				const data = await response.text();
				if (!!data){
					return data;
				}
				return undefined;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log(error);
		}
	};

	// event creation partial
	const createDraftEvent = createAsyncThunk(
		'createDraftEvent',
		async (evt: IEvent) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					await dispatch(logoutUser());
					return undefined;
				}
				const response: any = await fetch(API_URL + '/api/events/draft',
					{
						method: 'POST',
						body: JSON.stringify(evt),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail createEvent');
				return err;
			}
		}
	);
	const updateDraftEvent = createAsyncThunk(
		'updateDraftEvent',
		async (evt: IEvent) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					await dispatch(logoutUser());
					return undefined;
				}
				const url =  '/api/events/updateDraft/';
				const response: any = await fetch(API_URL + url +
					evt._id,
					{
						method: 'PATCH',
						body: JSON.stringify(evt, (k, v) => (k === "imageSrc" || k === "imageCardSrc") && v === undefined ? null : v),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('Fail updateEvent');
				console.log(err);
			}
		}
	);
	
	const publishEvent = createAsyncThunk(
		'publishEvent',
		async (event: any, { dispatch }) => {
			try {    
				const response = await fetch(`${API_URL}/api/events/publish/${event._id}`, {
					method: 'PATCH',
					body: JSON.stringify(event),
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
						'Content-type': 'application/json'
					}
				});
	
				if (response.status === 401) {
					await dispatch(logoutUser());
					return undefined;
				}
	
				const data = await response.json();
	
				return data;
			} catch (error) {
				console.error('Error al publicar el evento:', error);
				return undefined;
			}
		}
	);
	const getEventAvailability = async (id: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/events/getEventAvailability/' + id
				, {
					method: 'GET'
				});
			try {
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (error) {
			console.log('Fail getEventAvailability');
			console.log(error);
		}
	};
	const updatePublishedEvent = createAsyncThunk(
		'updatePublishedEvent',
		//async (evt: IEvent, message: string) => {
			async (obj: any) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					await dispatch(logoutUser());
					return undefined;
				}
				const url =  '/api/events/updatePublishedEvent/';
				const evt = obj.event;
				const _body = {
					message: obj.message,
					eventsDTO: evt//JSON.stringify(evt, (k, v) => (k === "imageSrc" || k === "imageCardSrc") && v === undefined ? null : v)
				}
				const response: any = await fetch(API_URL + url +
					evt._id,
					{
						method: 'PATCH',
						body: JSON.stringify(_body),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('Fail updateEvent');
				console.log(err);
			}
		}
	);
	return [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
		editEventNotifyInscriptions,
		getBatchesAvailability,
		checkTicketsAvailability,
		getSectorsAvailability,
		getInscriptionsBySector,
		sendEventReminder,
		sendEventFeedback,
		sendInscriptionEventFeedback,
		getEventMailPreview,
		createDraftEvent,
		updateDraftEvent,
		publishEvent,
		getEventAvailability,
		updatePublishedEvent
	] as const;
};
