import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdUndo } from 'react-icons/io';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { VscFeedback } from "react-icons/vsc";
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { useDispatch } from 'react-redux';
import { RiMailSendLine } from 'react-icons/ri';
import useLoader from '../components/loader/useLoader';
import CustomModal from '../components/modals/customModal';
import { IoQrCodeOutline } from 'react-icons/io5';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { FaCircle } from 'react-icons/fa';
import { TInscriptionStatus } from '../models/types/types';
import { DateHelper } from '../helpers/date-helper';
import Select from 'react-select';
import { customStyles } from '../helpers/inscription-helper';
import useEventsApi from '../hooks/useEventsApi';
import QRModal from '../components/modals/qrModal';

interface InscriptionSingleCardProps {
	inscription: IInscription,
	canEdit: boolean,
	type: TInscriptionStatus,
	statuses: TInscriptionStatus[],
	onUndoInscription: any,
	selectAll: any,
	disableSelectAll: any,
	includeMenu: boolean,
	includeSector?: boolean,
	sectorOptions?:any[],
	onSetSector?: any,
}
export function InscriptionSingleCard(
	props: InscriptionSingleCardProps
) {
	const [loader, showLoader, hideLoader] = useLoader();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [checked, setChecked] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [confirmationType, setConfirmationType] = useState('');
	const [sectorOptions, setSectorOptions] = useState(props.sectorOptions);
	const [validated, setValidated] = useState(false);
	const [qrModalVisible, setQrModalVisible] = useState(false);
	const [currentInscription, setCurrentInscription] = useState<IInscription>(props.inscription);
	const [inscriptionToUndo, setInscriptionToUndo] = useState<IInscription | undefined>(undefined);
	const [
		,
		,
		,
		,
		,
		,
		updateInscriptionStatus,
		,
		,
		resendInscriptionEmail,
		,
		,
		,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions, 
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
		updateInscriptionSector
	] = useInscriptionsApi();
const  [
	createEvent,
	updateEvent,
	deleteEvent,
	getEventTypes,
	getInscriptionTypes,
	getEventById,
	editEventNotifyInscriptions,
	getBatchesAvailability,
	checkTicketsAvailability,
	getSectorsAvailability,
	getInscriptionsBySector,
	sendEventReminder,
	sendEventFeedback,
	sendInscriptionEventFeedback
] = useEventsApi();
	useEffect(() => {
		if (props.selectAll) {
			if (
				props.inscription.inscriptionStatus != 'PENDING_EMAIL_CONFIRMATION'
			) {
				props.inscription.checked = true;
				setChecked(true);
			}
			
		} else {
			if (
				props.inscription.inscriptionStatus != 'PENDING_EMAIL_CONFIRMATION'
			) {
				props.inscription.checked = false;
				setChecked(false);
			}
		}
	}, [props.selectAll]);
	useEffect(() => {
		if (props.sectorOptions) {
			setSectorOptions([]);
			setSectorOptions(props.sectorOptions);
		}
	}, [props.sectorOptions]);
	const handleViewDetails = (e: any) => {
		e.stopPropagation();
		setExpanded(!expanded);
	};

	const selectInscription = (e?: any) => {
		if (
			props.canEdit &&
			props.type == 'PENDING' &&
			props.inscription['inscriptionStatus'] != 'PENDING_EMAIL_CONFIRMATION'
		) {
			e.stopPropagation();
			e.preventDefault();
			currentInscription.checked = !checked;
			props.disableSelectAll(currentInscription);
			setChecked(!checked);
		}
	};

	const confirmUndo = (e: any, inscription: IInscription | undefined) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('UNDO');
		setInscriptionToUndo(inscription);
		setShowConfirmationModal(true);
	};

	const sendFeedbackLink = async (e: any, inscription: IInscription | undefined) => {
		e.preventDefault();
		e.stopPropagation();
		const eventId = inscription?.event?._id;
		try {
			showLoader();
			if (!!eventId) {
				const email = await sendInscriptionEventFeedback(eventId, inscription._id || '');
				if (!!email) {
					
					//setSuccessMessage(i18n.t('event.success-email-feedback'));
					setShowSuccessModal(true);
				} else {
					setErrorMessage(t('event.error-email-feedback'));
					setShowErrorModal(true);
				}
			}
		}
		catch (e) {
			setErrorMessage(t('event.error-email-feeedback'));
			setShowErrorModal(true);
		}
		finally {
			hideLoader();
		}
	};
	const confirmResend = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('RESEND');
		setShowConfirmationModal(true);
	};

	const confirmQr = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('RESENDQR');
		setQrModalVisible(false);
		setShowConfirmationModal(true);
	};
	const openQRModal= (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setQrModalVisible(true);
	};
	const onCloseConfirmationModal = async (e: any) => {
		if (e) {
			setShowConfirmationModal(false);
			switch (confirmationType) {
				case 'UNDO':
					await handleUndo();
					break;
				case 'RESEND':
					await handleResend();
					break;
				case 'RESENDQR':
					await handleResendQR();
					break;
				default:
					break;
			}
		} else {
			setShowConfirmationModal(false);
		}

	};

	const getConfirmationMessage = () => {
		switch (confirmationType) {
			case 'UNDO': {
				//if (currentInscription.inscriptionStatus !== 'REJECTED') {
				return t('confirmation.move-to-pending');
				// }
				// return t('confirmation.resend-email');
			}

			case 'RESEND':
				return t('confirmation.resend-email');
			case 'RESENDQR':
				return t('confirmation.resend-qr');
			default:
				break;
		}

	};
	const getSuccessTitle = () => {
		return confirmationType == 'RESENDQR' ? t('inscription.resend-qr-success-title') : t('inscription.resend-email-success-title');

	}
	const getSuccessMessage = () => {
		const name = currentInscription?.name || currentInscription?.user?.name;
		const lastName = currentInscription?.surname || currentInscription?.user?.surname;
		const email = currentInscription?.user?.email;
		const companions = currentInscription.companions;
		const usr =
			(name != null ? name : '') +
			' ' +
			(lastName != null ? lastName : '') +
			(email && email != '' ? ' (<i>' + email + '</i>)' : '');
		const companionsNames = (!!companions && companions.length > 0) ?
			companions?.filter((y: any) => !y.deleted)
				.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
				}) : null;
		const companionsNamesQR = (!!companions && companions.length > 0) ?
			companions?.filter((y: any) => !y.deleted && y.inscriptionStatus === 'ACCEPTED')
				.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
				}) : null;
		switch (confirmationType) {
			case 'RESENDQR':
				return t('inscription.resend-qr-success', {
					user: usr,
					companions: companionsNamesQR
				});

			default:
				return t('inscription.resend-email-success', {
					user: usr,
					companions: companionsNames
				});
		}

	};

	const dispatchUndoStatus = async (status: TInscriptionStatus) => {
		const response: any = await dispatch(
			updateInscriptionStatus({
				inscriptionStatus: status,
				id: inscriptionToUndo?._id,
			})
		);
		if (response['payload'] && response['payload']['_id']) {
			props.onUndoInscription(inscriptionToUndo, props.type);
		}
	};

	const handleUndo = async () => {
		if (!!inscriptionToUndo) {
			showLoader();
			if(inscriptionToUndo.inscriptionStatus === 'ACCEPTED') {
				await dispatchUndoStatus('PENDING');
			} else if(inscriptionToUndo.inscriptionStatus === 'DENIED') {
				const eventId:string = inscriptionToUndo.event?._id ? 
											inscriptionToUndo.event?._id : inscriptionToUndo.event as string;
				const ev:any = await getEventById(eventId);
				const placesLeft = ev.maxCapacity -
					ev.inscriptions?.filter(
						(x: IInscription) =>
							x.inscriptionStatus === 'PENDING' ||
							x.inscriptionStatus === 'ACCEPTED'
					).length;

				if(placesLeft > 0) {
					await dispatchUndoStatus("PENDING");
				} else if(ev.allowsWaiting) {
					await dispatchUndoStatus("WAITING");
				} else if(!ev.allowsWaiting) {
					setErrorMessage(t("error.inscription-max-capacity"));
					setShowErrorModal(true);
				}
			}
			setInscriptionToUndo(undefined);
			hideLoader();
			// } else {
			// 	showLoader();
			// 	const resp: any = await dispatch(
			// 		updateInscriptionStatus({
			// 			inscriptionStatus: 'PENDING',
			// 			id: inscriptionToUndo._id,
			// 		})
			// 	);
			// 	hideLoader();
			// 	if (resp['payload'] && resp['payload']['_id']) {
			// 		setShowSuccessModal(true);
			// 	} else {
			// 		setShowErrorModal(true);
			// 		setInscriptionToUndo(undefined);
			// 	}
			// }
		}
	};
	const handleResendQR = async () => {
		showLoader();
		const response: any = await resendQREmail(currentInscription?._id);
		hideLoader();

		if (response && response['_id']) {
			setShowSuccessModal(true);
		} else {
			setErrorMessage(t('event.error-email-feeedback'));
			setShowErrorModal(true);
		}
	};
	const handleResend = async () => {
		showLoader();
		const response: any = await resendInscriptionEmail(currentInscription?._id);
		hideLoader();

		if (response && response['_id']) {
			setShowSuccessModal(true);
		} else {
			setErrorMessage(t('event.error-email-feeedback'));
			setShowErrorModal(true);
		}
	};
	const handleCloseSuccessModal = (e: any) => {
		setShowSuccessModal(false);

		if (currentInscription.inscriptionStatus == 'REJECTED') {
			props.onUndoInscription(inscriptionToUndo, props.type);
			setInscriptionToUndo(undefined);
		}
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const handleOnSelectSector = async (e:any, insc: IInscription) => {
		try {
			showLoader();
		
			const response: any = await dispatch(
				updateInscriptionSector({ sector: e.value, id: insc._id, inscriptionStatus: insc.inscriptionStatus })
			);
			if (!!props.onSetSector){
				await props.onSetSector();
			}
			insc.sector = e.value;
			if (!insc.parentInscription){
				setCurrentInscription(insc);
			}
		} catch (e){
			console.log(e);
		}
		finally {
			hideLoader();
		}
	}
	return (
		<>
			{loader}
			{showSuccessModal && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					title={getSuccessTitle()}
					type="SUCCESS"
					buttonOK={t('accept')}
					message={getSuccessMessage() + (currentInscription['inscriptionStatus'] == 'REJECTED'
						? ("<br> <br> " + t('inscription.undo-rejected'))
						: '')
					}
				// message={

				// }
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={errorMessage}
					type="ERROR"
					buttonOK={t('accept')}
					title={t('error.modal-title-oops')}
				/>
			)}
			{!!currentInscription && (
				<div
					key={currentInscription._id}
					className={
						props.canEdit &&
							currentInscription['inscriptionStatus'] !=
							'PENDING_EMAIL_CONFIRMATION' &&
							currentInscription['inscriptionStatus'] != 'REJECTED' &&
							currentInscription['inscriptionStatus'] != 'ATTENDEED'
							? 'inscription-box cursor-pointer row align-items-center m-0'
							: 'inscription-box row align-items-center m-0'
					}

				>

					<div className='col-md-12 p-3'>

						<div
							// onClick={(e) => selectInscription(e)}
							key={currentInscription._id}
							className="row align-items-center m-0 pr-0 pl-0 pt-2 pb-2 col-12"
						>
							<div 
							onClick={(e) => selectInscription(e)}
							className={
								props.statuses.length === 0 ? 'd-none' :
									"col-1 p-0 m-0 d-flex align-items-center justify-content-center"}>
								{!props.canEdit && props.type == 'ATTENDEED' && 
								!!currentInscription && !!currentInscription.event && !!currentInscription.event.feedbackQuestions &&
								currentInscription?.event?.feedbackQuestions?.length > 0 &&
								<div className='col-12 col-md-6 p-0 m-0'>
								<VscFeedback
									className="cursor-pointer inscription-icon"
									title={t('inscription.send-feedback')}
									onClick={(e) => sendFeedbackLink(e, currentInscription)}
								></VscFeedback> </div>}
								{props.canEdit &&
									(currentInscription.inscriptionStatus === 'PENDING' || currentInscription.inscriptionStatus === 'WAITING') &&
									!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 &&
									<div
										className={
											currentInscription.checked
												? 'custom-checkbox cursor-pointer inscription-checkbox checked'
												: 'custom-checkbox cursor-pointer inscription-checkbox'
										}>
										{currentInscription.checked && <IoCheckmarkSharp />}
									</div>
								}
								{props.canEdit && props.type != 'PENDING' && props.type != 'ATTENDEED' &&
									(!!currentInscription.inscriptionStatus && ['ACCEPTED', 'DENIED'].includes(currentInscription.inscriptionStatus)) &&
									!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 &&
									(
										<div className={currentInscription.inscriptionStatus === 'ACCEPTED' && props.statuses.includes(currentInscription.inscriptionStatus) ?
											'd-flex flex-column flex-md-row justify-content-between w-100' : 'd-flex flex-column flex-md-row justify-content-center w-100'}>
											<div className='col-12 col-md-6 p-0 m-0'>
												<IoMdUndo
													className="cursor-pointer inscription-icon"
													title={t('inscription.move-to-pending')}
													onClick={(e) => confirmUndo(e, currentInscription)}
												></IoMdUndo>
											</div>
											{props.canEdit &&
												(currentInscription.inscriptionStatus === 'ACCEPTED' && props.statuses.includes(currentInscription.inscriptionStatus)
												) && (
													<div className='col-12 col-md-6 p-0 m-0'>
														{/* <IoQrCodeOutline
															className="cursor-pointer inscription-icon"
															title={t('inscription.resend-qr')}
															onClick={confirmQr}
														/> */}
														<IoQrCodeOutline
															className="cursor-pointer inscription-icon"
															title={t('inscription.see-qr')}
															onClick={openQRModal}
														/>
													</div>
												)}
										</div>
									)}
								{props.canEdit &&
									currentInscription.inscriptionStatus === 'PENDING_EMAIL_CONFIRMATION' && (
										<div>
											<RiMailSendLine
												className="cursor-pointer inscription-icon"
												title={t(
													'inscription.resend-confirmation'
												)}
												onClick={confirmResend}
											/>
										</div>
									)}

							</div>
							<div className={props.statuses.length === 0 ?
								"col-8 col-md-8 p-0 m-0 row align-items-center" :
								"col-7 col-md-8 p-0 m-0 row align-items-center"}>
								{/* <div className={props.includeMenu ? "col-md-6" : "col-md-8"}> */}
								<div className={props.includeMenu ? (props.includeSector ? "col-md-5" : "col-md-6") : (props.includeSector ? "col-md-6" : "col-md-8")}>

									<div className="inscription-name d-flex align-items-center">
										<FaCircle className={currentInscription.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!currentInscription.user ? "inactive-dot mr-1" : "d-none")} />
										{currentInscription.name || currentInscription.user?.name}{' '}
										{currentInscription.surname || currentInscription.user?.surname}
									</div>
									{!!currentInscription.identificationNumber && !!currentInscription.identificationNumber
										&& <div className="inscription-email">
											{currentInscription.identificationType + ' ' + currentInscription.identificationNumber}
										</div>}

									{(currentInscription.email || currentInscription.user?.email) &&
										<div className="inscription-email">
											{currentInscription.email || currentInscription.user?.email}
										</div>}

								</div>
								
								<div className={props.includeMenu ?
									(props.includeSector ? "col-md-1 justify-content-start d-flex pr-1" : "col-md-2 justify-content-start d-flex")
									: (props.includeSector ? "col-md-2 justify-content-start d-flex" : "col-md-4 justify-content-start d-flex")}>
									<div className="inscription-companions d-none d-md-block" title={t('age-group.' + (currentInscription.group || currentInscription.user?.group))}>
										{props.includeMenu && props.includeSector && t('age-group-min.' + (currentInscription.group || currentInscription.user?.group))}
										{(!props.includeMenu || !props.includeSector) && t('age-group.' + (currentInscription.group || currentInscription.user?.group))}
									</div>
									<div className="inscription-companions d-block d-md-none" title={t('age-group.' + (currentInscription.group || currentInscription.user?.group))}>
										{t('age-group.' + (currentInscription.group || currentInscription.user?.group))}
									</div>
								</div>
								{props.includeSector &&

									<div className={props.includeMenu ? "col-11 col-md-3 justify-content-start d-flex p-md-0" : "col-11 col-md-4 justify-content-start d-flex "}>
										{!sectorOptions && <div className="inscription-companions">
											{currentInscription.sector?.name}
										</div> }
										{!!sectorOptions && <div className="sector-select w-100">	
										<Select
													required
													label="name"
													isInvalid={validated && !currentInscription.sector?._id}
													className={validated && !currentInscription.sector?._id ? "select-control-sector invalid" : (validated && !!currentInscription.sector?._id ? "select-control-sector valid" : "select-control-sector")}
													placeholder={t('select')}
													options={sectorOptions}
													value={sectorOptions.find(x => !!currentInscription.sector?._id ? x.value === currentInscription.sector?._id : x.value === currentInscription.sector)}
													onChange={(e: any)=> {handleOnSelectSector(e, currentInscription)}}
													styles={customStyles}
													isOptionDisabled={(option:any)=> !option.enabled}
													isSearchable={false}
												/>
										</div>}
									</div>

								}
								{props.includeMenu &&
									<>
										{(currentInscription.menu || currentInscription.menuCondition) &&
											<div className="col-12 col-md-2 justify-content-start d-flex">
												{currentInscription.menu &&
													<div className="inscription-companions">
														{t('event.menu.' + currentInscription.menu)}
													</div>}
												{currentInscription.menu && currentInscription.menuCondition &&
													<div className="inscription-companions mr-1 ml-1">
														{' - '}
													</div>}
												{currentInscription.menuCondition &&

													<div className="inscription-companions">
														{t('event.menu-condition.' + currentInscription.menuCondition)}

													</div>}
											</div>
										}

									</>}
							</div>

							<div className="col-4 col-md-3 p-0 m-0 d-md-flex align-items-center justify-content-center">
								<div className="col-12 col-md-5 p-0 m-0 d-flex justify-content-md-center">
									<div className={'status-tag ' + currentInscription.inscriptionStatus}>{t('inscription.status-tag.' + currentInscription.inscriptionStatus)}</div>

								</div>

								<div className='col-12 col-md-7 m-0 p-0 justify-content-md-center'>
									{!!currentInscription?.inscriptionStatus &&
										['ATTENDEED', 'DENIED', 'ACCEPTED'].includes(currentInscription?.inscriptionStatus) &&
										<div className="inscription-email small mt-1">
											{(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
												currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
													(currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
											<br></br>
											<span className="smaller">
												{(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
													currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
											</span>


										</div>

									}
								</div>
							</div>
						</div>

					</div>
					{showConfirmationModal && (
						<CustomModal
							isShowing={showConfirmationModal}
							parentCallback={onCloseConfirmationModal}
							message={getConfirmationMessage()}
							buttonCancel={t('cancel')}
							buttonOK={t('accept')}
							type="WARNING_EDIT"
						/>
					)}
					{qrModalVisible && (
						<QRModal visible={qrModalVisible}
						inscription={currentInscription}
						onHide={()=> setQrModalVisible(false)}
						resendQRFn={confirmQr}
						></QRModal>
					)}
				</div>
			)}
		</>
	);
}
