import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import useInscriptionsApi from '../hooks/useInscriptionsApi';
import { useHistory } from 'react-router';
import Footer from '../components/footer/footer';
import EventSummary from '../events/eventSummary';
import { IEvent } from '../models/interfaces/events.interface';
import PaymentSummary from './paymentSummary';
import InscriptionSummary from './inscriptionSummary';
import { getTicketsQties } from '../helpers/inscription-helper';
import PublicCardEvent from '../events/card-event/public-card-event';
import InscriptionSummaryNew from './inscriptionSummaryNew';
import { RiAddBoxLine } from 'react-icons/ri';
import { HiSearch } from 'react-icons/hi';

interface CheckoutSuccessProps {
    inscriptionId: string
}
const CheckoutSuccess = (props: CheckoutSuccessProps) => {
    const [
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        getInscriptionById,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        getInscriptionByIdWithBatches] = useInscriptionsApi();
    const [loader, showLoader, hideLoader] = useLoader();
    const [inscription, setInscription] = useState<IInscription>();
    const [event, setEvent] = useState<IEvent | undefined>(undefined);
    const [ticketQties, setTicketQties] = useState<any>({});
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const goToTickets = (e: any) => {
        if (!!inscription) {
            history.push('/tickets/' + inscription?.event?._id);
        }
    };
    useEffect(() => {
        handleEffect();
    }, [props.inscriptionId]);
    const handleEffect = async () => {
        try {
            showLoader();
            if (!inscription) {
                const insc: any = await getInscriptionByIdWithBatches(props.inscriptionId);
                if (!!insc) {
                    if (insc.inscriptionStatus === 'RESERVED' || insc.inscriptionStatus === 'PENDING') {
                        history.push('/inscribe-public/' + insc._id);
                    } else if (insc.inscriptionStatus !== 'ACCEPTED') {
                        history.push('/tickets/' + insc.event._id);
                    } else {
                        setInscription(insc);
                        setTicketQties(getTicketsQties(insc));
                        const ev: any = insc.event;
                        if (ev && ev['_id']) {
                            setEvent(ev);
                        }
                    }
                }
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            hideLoader();
        }
    }
    const goToLanding = () => {
        history.push('/');
    }

    return (
        <>
            {loader}

            {!!inscription && !!event && <div className="dashboard-content new-inscription p-0 m-0 pt-5">
                <div className="form-container col-xl-10">
                    <div className='row m-0 p-0 align-items-stretch'>
                        <div className='col-12 col-lg-6 d-flex flex-column align-items-center p-lg-5'>
                            <div className="d-flex row mt-2 flex-column logo-container mb-4 justify-content-center align-items-center">
                                <div className="logo white cursor-pointer" onClick={goToLanding}></div>
                            </div>
                            <div>
                                <PublicCardEvent
                                    event={event}
                                    isInscription={true}
                                    isHost={true}
                                    isAllowedToEdit={false}
                                    showOrganizer={true}
                                    includeDescription={true}
                                ></PublicCardEvent>
                            </div>
                        </div>
                        <div className='p-2 pt-4 pb-4 p-md-5 col-12 col-lg-6 tickets-selection tickets-selection-box d new-inscription-box-flex flex-column'>
                            <div className='w-100 justify-content-center d-flex p-0'>
                                <h2 className='d-flex inscription-title w-100 justify-content-center align-items-center'>{t('inscription.purchase-summary')}</h2>
                            </div>
                            <div className="col-12 m-0 p-0 row">
                                <div className='col-12 p-0 pr-md-1 pb-1 pb-md-0'>
                                    <PaymentSummary ticketQties={ticketQties} batches={event.batches} squaredCorners={true} noMargin={true} payment={inscription.payment} />
                                </div>
                                <div className='col-12 p-0 px-3 '>
                                    <InscriptionSummaryNew isPrivateFree={false} event={event} secondaryHeader hideButtons refreshInscription={()=>{}} noShadow semiTransparentBg inscription={inscription} canChangeStatus={false}></InscriptionSummaryNew>
                                </div>
                                <div className='col-12 m-0 px-3 row checkout-buttons-box'>
                                    <div className="p-0 pb-0 d-flex flex-column align-items-center justify-content-center w-100">
                                        <Button
                                            className="btn btn-primary button-primary button-ok w-100 w-md-50 m-0"
                                            onClick={goToLanding}
                                        >
                                            <HiSearch className="mr-1" />
                                            {t('event.see-other-events')}
                                        </Button>
                                        <Button
                                            className="button-outline-primary d-flex flex-row align-items-center justify-content-center w-100 w-md-50 btn btn-primary"
                                            onClick={goToTickets}
                                        >
                                            <RiAddBoxLine className="mr-1" />
                                            <span>
                                            {t('event.buy-more-tickets')}

                                            </span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-4 justify-content-center footer-container">
                        <Footer white={true} hideGala></Footer>
                    </div>
                </div>
            </div >}
        </>
    );
}

export default CheckoutSuccess;