import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useLoader from '../components/loader/useLoader';
import { TRole } from '../models/types/types';
import { DateHelper } from '../helpers/date-helper';
import { FaCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { MdEdit } from 'react-icons/md';
interface UserCommunityRowProps {
  user: any,
  organizerId: string
}
export function UserCommunityRow(props: UserCommunityRowProps): JSX.Element {
  const [loader, showLoader, hideLoader] = useLoader();
  const [roleName, setRoleName] = useState<TRole>();
  const [canEditUser, setCanEditUser] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    const _roleName = props.user.rolesOrganizers.find((x: any) => x.organizerId == props.organizerId)?.roleName || props.user.rolesOrganizers[0].roleName;
    setRoleName(_roleName);
    setCanEditUser(_roleName !== 'USER_GUEST')
  }, [props.user]);


  const goToEditUser = () => {
    if (canEditUser) {
      history.push('/user/edit/' + props.user._id);
    }
  }

  return (
    <>
      {loader}
      {!!props.user && !!roleName && (
        <tr onClick={goToEditUser} className={`user-row single ${canEditUser ? "can-edit" : ""}`}>
          <td className='image-col'>
            <div className='table-card-image m-0 '>
                <img src={!!props.user.avatar ? props.user.avatar : ('/assets/' + (!props.user.gender ? 'DEFAULT' : props.user.gender) + '.png')} className='table-card-image rounded-image m-0 ' />
                <div className="dot-container-table img"><FaCircle className={props.user.status == "ACTIVE" ? "active-dot mr-1" : "inactive-dot mr-1"} /></div>
            </div>
          </td>
          <td className="inscription-companions">
            <div className='inscription-name'>{props.user.name} {props.user.surname}</div>
            <div className='hide-not-medium inscription-companions'>
              {props.user.rolesOrganizers ? t(`role.${roleName}`) : "-"}
            </div>
            <div className='inscription-email'>{props.user.email}</div>
            {(props.user.group || props.user.dob) && <div className='hide-not-medium inscription-email'>

            {(props.user.dob ? t('age-group.' + DateHelper.getGroup(props.user.dob)) + " - " + DateHelper.calculateAge(new Date(props.user.dob), t) :
                t('age-group.' + props.user.group))}

            </div>}
            {(props.user.gender) && <div className='hide-not-medium inscription-email'>

            {(t('gender.' + props.user.gender))}

            </div>}
          </td>
          <td className="hide-medium inscription-companions">
            <div className="user-info-properties">
              {props.user.rolesOrganizers ? t(`role.${roleName}`) : "-"}
            </div>
          </td>
          <td className="hide-medium inscription-companions">
            <div className="user-info-properties">
              {props.user.dob ?
                t('age-group.' + props.user.group) + " - " + DateHelper.calculateAge(new Date(props.user.dob), t) :
                t('age-group.' + props.user.group)
              }
            </div>
          </td>
          <td className="hide-medium inscription-companions">
            <div className="user-info-properties">
              {props.user.gender ? t(`gender.${props.user.gender}`) : "-"}
            </div>
          </td>
          <td className='image-col'>
              {canEditUser && (
                  <Button
                      className="rounded-button rounded-button-ok outline"
                      title={t('user.edit')}
                  >
                      <MdEdit />
                  </Button>
              )}
          </td>
        </tr>
      )}
    </>
  );
}
