import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';


import useEventsApi from '../../../hooks/useEventsApi';

import CustomModal from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/loader/useLoader';
import { IEvent, ISector } from '../../../models/interfaces/events.interface';
import { StepProps, getSelectSingleOptions } from '../utils';
import { SHOW_SECTORS, YES_NO } from '../../../../constants';
import ErrorGlobal from '../../../components/error-global/error-global';
import SectorRow from '../../../sectors/sectorRow';
import { IoAddOutline } from 'react-icons/io5';
import SectorForm from '../../../sectors/sectorForm';
import SectorMultipleForm from '../../../sectors/sectorMultipleForm';
export function StepSectors(props: StepProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();

    const [sectors, setSectors] = useState<ISector[]>([]);
    const [hasSector, setHasSector] = useState<string | undefined>();
    const [sectorNumber, setSectorNumber] = useState<number>(0);
    const [sectorToEdit, setSectorToEdit] = useState<ISector | undefined>();
    const [sectorToEditIndex, setSectorToEditIndex] = useState<number>(-1);

    const [sectorToAdd, setSectorToAdd] = useState<ISector | undefined>();
    const [sectorToAddIndex, setSectorToAddIndex] = useState<number>(-1);

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [sectorsAvailability, setSectorsAvailability] = useState<any[]>([]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const [isDirty, setIsDirty] = useState(false);

    const dispatch = useDispatch();
    const [
        createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getSectorsAvailability,
        getInscriptionsBySector,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent
    ] = useEventsApi();


    const subStepsTotal = 1;
    const hasSectorOptions = getSelectSingleOptions(YES_NO, 'confirmation.', props.event?.status === 'DRAFT');


    const validateAll = (form: any) => {
        return (
            form.checkValidity() && checkSectors()
        );
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            try {
                showLoader();
                const _sectorsToSend = cleanSectors();
                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    hasSectors: hasSector,
                    sectors: _sectorsToSend,
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date()
                };
                let subSteps = 0;
           
                if (!!evt.steps) {
                    const stepIdx = evt.steps?.findIndex(s => s.name === 'SECTORS');
                    if (stepIdx >= 0) {
                        if (hasSector === 'yes' && !!props.event?.maxCapacity && props.event?.maxCapacity > 0) {
                            evt.steps[stepIdx].percentComplete = (getSectorsCapacity() / props.event?.maxCapacity) * 100;
                        } else if (hasSector === 'no') {
                            subSteps++;
                            evt.steps[stepIdx].percentComplete = (subSteps / subStepsTotal) * 100;
                        } else if (!hasSector || hasSector === 'none') {
                            evt.steps[stepIdx].percentComplete = 0;
                        }


                    }
                }
                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    hideLoader();
                    props.saveAndGoToStep(response.payload, 'FEEDBACK');

                } else {
                    hideLoader();
                    console.log('fallo el edit draft sectors')
                }

            }
            catch (error: any) {
                setErrorModalMessage(t('event.error-delete'));
                setShowErrorModal(true);
            } finally {
                hideLoader();
            }
        }
        if (isDirty) {
            setValidated(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    useEffect(() => {

        props.notifyIsDirty(isDirty);

    }, [isDirty]);

    const handleEvent = async () => {
        showLoader();
        if (props.loggedUser && !!props.event) {
            if (!!props.event.sectors) {
                setHasSector(props.event.hasSectors);
                setSectors(props.event.sectors);
            }
            if (SHOW_SECTORS && !!props.event._id) {
                const response: any = await getSectorsAvailability(props.event._id);
                if (!response.error && response?.sectorsAvailability) {
                    setSectorsAvailability(response.sectorsAvailability);
                }
            }

        }
        hideLoader();
    };


    const checkSectors = () => {
        const err = sectors.filter(
            (x: any) => x.hasError && !x.deleted
        );
        return (hasSector !== 'yes') ? true : (sectors.length > 0 && (!err || err?.length === 0));
    }
    const handleOnSelectHasSector = (event: any) => {
        setSectorNumber(0);
        setSectors([]);
        setSectorToEdit(undefined);
        setSectorToEditIndex(-1);
        setHasSector(event.name);
        setIsDirty(true);
    };
    const cleanSectors = () => {
        if (!!sectors && sectors.length > 0) {
            return sectors.filter(b => (!!!b.deleted));
        }
        return [];
    };
    const linkedSectors = (sectorNumber: number) => {
        return sectors.filter(b => b.sectorEndedNumber === sectorNumber && !b.deleted);
    }

    const handleAddMultipleSectors = () => {
        const bN = sectors?.length === 0 ? 1 : sectorNumber + 1
        setSectorNumber(bN);
        const sector: ISector = {
            number: bN + 1,
            name: undefined,
            capacity: undefined,
            availability: undefined
        };
        const b = [...sectors, sector];

        setIsAdding(false);
        setSectorToAdd(sector);
        setSectorToAddIndex(b.length - 1);
    }
    const handleAddSector = () => {
        const bN = sectors?.length === 0 ? 1 : sectorNumber + 1
        setSectorNumber(bN);
        addSector(bN);
    }
    const addSector = (prevNumber: number) => {
        setValidated(false);

        const sector: ISector = {
            number: prevNumber + 1,
            name: undefined,
            capacity: undefined,
            availability: undefined
        };
        const b = [...sectors, sector];

        setIsEditing(false);
        setSectorToEdit(sector);
        setSectorToEditIndex(b.length - 1);
    };
    const handleDeleteSector = (c: any) => {
        const idx = sectors?.indexOf(c);
        if (!!sectors && idx > -1) {
            const _linkedSectors = linkedSectors(c.number);
            if (!!_linkedSectors && _linkedSectors.length > 0) {
                const lb = _linkedSectors.map((sector: ISector) => {
                    return sector.name;
                });
                setErrorModalMessage(t('error.linked-sector', { sectors: lb }));
                setShowErrorModal(true);
            } else {
                sectors[idx]['deleted'] = true;
                const b = [...sectors];
                setSectors([]);
                setSectors(b);
            }
            setIsDirty(true);

        }
    };
    const handleEditSector = (b: any, i: number) => {
        setSectorToEdit(b);
        setIsEditing(true);
        setSectorToEditIndex(i);
    };
    const handleCloseEditModal = () => {
        setIsEditing(false);
        setSectorToEdit(undefined);
        setSectorToEditIndex(-1);
    }


    const handleSaveSectorModal = (modifiedSector: any, index: number) => {
        modifiedSector.availability = modifiedSector.capacity;
        const _sectors = sectors;
        _sectors[index] = modifiedSector;
        setSectors(_sectors);
        setIsEditing(false);
        setSectorToEdit(undefined);
        setSectorToEditIndex(-1);
        setIsDirty(true);
        // const sc = _sectors.reduce((acumulador, actual) => acumulador + (actual.capacity || 0), 0);

        // setMinEventMaxCapacity(sc);
    }
    const handleCloseAddModal = () => {
        setIsAdding(false);
        setSectorToAdd(undefined);
        setSectorToAddIndex(-1);
    }
    const handleSaveMultipleSectorModal = (modifiedSector: any, index: number, repeat: number) => {
        let _sectors = sectors;

        for (let i: number = 1; i <= repeat; i++) {
            let _sector: ISector = {
                ...modifiedSector,
                availability: modifiedSector.capacity,
                name: repeat > 1 ? modifiedSector.name + ' ' + i : modifiedSector.name
            };
            if (i === 1 && _sectors.length > 0 && _sectors.length > index) {

                _sectors[index] = _sector;
            } else {
                _sectors.push(_sector);
            }

        }

        setSectors(_sectors);
        setIsAdding(false);
        setSectorToAdd(undefined);
        setSectorToAddIndex(-1);
        setIsDirty(true);
        setSectorNumber(sectorNumber + _sectors.length - 1);
    }
    const getMinMaxQtyFromSectors = () => {
        const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.capacity || 0) : 0), 0);
        return sectorsQty > 1 ? sectorsQty : 1;
    }
    const getSectorsCapacity = () => {
        return sectors?.reduce((acumulador, actual) => acumulador + (!actual.deleted ? actual.capacity || 0 : 0), 0);
    };
    const getQtyAvailable = () => {
        const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.capacity || 0) : 0), 0);
        if (!!props.event?.maxCapacity) {
            return (props.event.maxCapacity - sectorsQty) >= 0 ? props.event.maxCapacity - sectorsQty : 0;
        }
        return 0;
    }
    const getMaxQtyAvailable = (index: number) => {
        const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (i !== index && !actual.deleted ? (actual.capacity || 0) : 0), 0);
        if (!!props.event?.maxCapacity) {
            return (props.event.maxCapacity - sectorsQty) >= 0 ? props.event.maxCapacity - sectorsQty : 0;
        }
        return 0;
    }
    const getMinQtyAvailable = (sector: ISector) => {
        if (!!sectorsAvailability && !!sector) {
            const mysectorAvailability: any = sectorsAvailability.find((x: any) => x.sectorId === sector._id);
            return mysectorAvailability?.occupied || 1;
        }
        return 1;
    }
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loader}
            {!!props.event &&
                <Form
                    className="form-container m-0 p-0 step-container"
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    action="/events"
                >
                    <div className="form-info-container pr-0 pt-0 pb-0">
                        <div className='d-flex flex-column m-0 mt-2 mt-md-0 p-0'>
                            <h3 className='w-100 step-information-title mb-2'>
                                {t('event.step-info.sectors.title')}
                            </h3>
                            <p className='step-information-body' dangerouslySetInnerHTML={{ __html:  t('event.step-info.sectors.text') }}></p>
                        </div>
                        <Form.Group>
                            <div className="d-flex row">


                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.include-sector')}`}
                                    </Form.Label>
                                    <Select
                                        required
                                        isInvalid={validated && !hasSector}
                                        className={validated && !hasSector ? "select-control invalid" : (validated && !!hasSector ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={hasSectorOptions}
                                        value={hasSectorOptions.find(x => x.name === hasSector)}
                                        onChange={handleOnSelectHasSector}

                                    />
                                    {validated && !hasSector && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!hasSector && `${t('error.required')}`}
                                    </Form.Control.Feedback>}
                                </div>


                            </div>

                        </Form.Group>
                        <div className={(hasSector === 'yes') ? 'form-group pt-3' : 'd-none'}>
                            <hr></hr>
                            <div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
                                <label className="group-label m-0 ml-1 mb-2 mb-md-0">
                                    {`${t('event.sectors')} - ${getSectorsCapacity()}/${props.event.maxCapacity}`}
                                </label>
                                {hasSector === 'yes' &&
                                    <div className="form-group">
                                        <Button
                                            disabled={getQtyAvailable() === 0}
                                            className="button-ok small w-xs-100 mb-2 mb-md-0"
                                            onClick={handleAddSector}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <IoAddOutline />
                                                </div>
                                                <div>
                                                    {t('event.add-sector')}
                                                </div>
                                            </div>
                                        </Button>
                                        <Button
                                            disabled={getQtyAvailable() === 0}
                                            className="button-ok small w-xs-100 ml-md-2 mb-2 mb-md-0"
                                            onClick={handleAddMultipleSectors}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <IoAddOutline />
                                                </div>
                                                <div>
                                                    {t('event.add-sectors')}
                                                </div>
                                            </div>
                                        </Button>
                                    </div>}

                            </div>

                            {validated && (!sectors || sectors.length === 0) && <Form.Control.Feedback
                                type="invalid"
                                className="global-error mb-3 mt-3"
                            >
                                <ErrorGlobal
                                    text={t('error.sectors-required')}
                                ></ErrorGlobal>
                            </Form.Control.Feedback>}
                            {sectors && sectors.length > 0 && (
                                <>
                                    {sectors.map((b, i) => (
                                        <div key={i}>
                                            {!b.deleted &&
                                                // <span>{b.number}</span>
                                                <SectorRow
                                                    i={i}
                                                    sector={b}
                                                    otherSectors={sectors.filter(x => x.number !== b.number)}
                                                    handleDeleteSector={handleDeleteSector}
                                                    handleEditSector={handleEditSector}
                                                    eventId={props.event?._id}
                                                    canEdit={true}
                                                    sectorsAvailability={sectorsAvailability}
                                                ></SectorRow>
                                            }
                                        </div>
                                    ))}
                                </>
                            )}

                            <hr></hr>
                        </div>
                    </div>
                    {/* botones */}
                    <div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                        <Button
                            disabled={!isDirty}
                            className="button-outline-primary w-100 w-md-auto text-transform-none"
                            type="submit">
                            {
                                props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                            }
                        </Button>
                    </div>
                </Form>
            }
            {sectorToEdit &&
                <SectorForm
                    isEditing={isEditing}
                    index={sectorToEditIndex}
                    sector={sectorToEdit}
                    otherSectors={sectors?.filter(x => x.number !== sectorToEdit.number && !x.deleted)}
                    maxCapacityAvailable={getMaxQtyAvailable(sectorToEditIndex)}
                    minCapacityAvailable={getMinQtyAvailable(sectorToEdit)}
                    onHideModal={handleCloseEditModal}
                    onSaveSector={handleSaveSectorModal}
                ></SectorForm>}
            {sectorToAdd &&
                <SectorMultipleForm
                    isEditing={isEditing}
                    index={sectorToAddIndex}
                    sector={sectorToAdd}
                    otherSectors={sectors?.filter(x => x.number !== sectorToAdd.number && !x.deleted)}
                    maxCapacityAvailable={getMaxQtyAvailable(sectorToAddIndex)}
                    minCapacityAvailable={getMinQtyAvailable(sectorToAdd)}
                    onHideModal={handleCloseAddModal}
                    onSaveSector={handleSaveMultipleSectorModal}
                ></SectorMultipleForm>}
            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}







        </>
    );
}

export default StepSectors;
