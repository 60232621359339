import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/esm/Button';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Select from 'react-select';
import { Redirect, NavLink } from 'react-router-dom';
import useApi from '../hooks/useApi';
import { USERS_PAGE_SIZE } from '../../constants';
import Paginator from '../components/paginator/paginator';
import useLoader from '../components/loader/useLoader';
import NoResults from '../components/no-results/noResults';
import { FaPlus } from 'react-icons/fa';
import { IoClose, IoSearch } from 'react-icons/io5';
import { Table } from 'react-bootstrap';
import UserRow from './userRow';
import './users.scss';
export function Users() {
	const [loader, showLoader, hideLoader] = useLoader();
	const [filterText, setFilterText] = useState('none');
	const [
		loggedUser,
		,
		getUserById,
		getMyUsers,
		,
		,
		,
		,
		,
		,
		,
		getAllOrganizers,
	] = useApi();


	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [userResults, setUserResults] = useState<any[] | undefined>(undefined);
	const [totalUsers, setTotalUsers] = useState(0);
	const [allOrganizers, setAllOrganizers] = useState<any[] | undefined>(undefined);
	const [page, setPage] = useState(0);
	const { t, i18n } = useTranslation();
	const [pageSize, setPageSize] = useState(USERS_PAGE_SIZE);
	const handleEffect = async () => {
		if (loggedUser && loggedUser['_id'] && !allOrganizers) {
			if (!allOrganizers) {
				showLoader();
				const org = await getAllOrganizers();
				setAllOrganizers(org);
				hideLoader();
			}
		}
	};
	const handleUsers = async () => {
		if (loggedUser && loggedUser['_id'] && allOrganizers) {
				showLoader();
				setPage(0);
				const usrs: any[] = await loadUsers(0, filterText);
				setUserResults(usrs);
				hideLoader();
		}
	};
	const pageOptions: any[] = [
		{ value: 10, name: 'TEN', label: i18n.t('pages.ten') },
		{ value: 20, name: 'TWENTY', label: i18n.t('pages.twenty') },
		{ value: 50, name: 'FIFTY', label: i18n.t('pages.fifty') },
		{ value: 0, name: 'ALL', label: i18n.t('pages.all') },
	];
	const handlePageOptionsChange = (event: any) => {
		setPageSize(event.value);
	};
	
	useEffect(() => {
		handleUsers();
	}, [allOrganizers, pageSize]);

	useEffect(() => {
		handleEffect();
	}, [loggedUser]);

	


	const loadUsers = async (pageNumber: number, filterText?: any) => {
		const loggedUserLevel =
			loggedUser['rolesOrganizers'].length == 1 &&
				loggedUser['rolesOrganizers'][0]['roleName'] == 'USER_ORGANIZER'
				? 16
				: loggedUser['rolesOrganizers']
					.filter((x: any) => x.organizerId != null)
					.sort((a: any, b: any) => {
						return b.roleLevel - a.roleLevel;
					})[0].roleLevel;
		const usrsResult: any = await getMyUsers(pageNumber, filterText, pageSize);
		const usrs: any[] = usrsResult['users'];
		setTotalUsers(usrsResult['total']);
		usrs.forEach((usr: any) => {
			usr['rolesOrganizers'].forEach((r: any) => {
				r['organizer'] = !!allOrganizers ? allOrganizers.find(
					(x: any) => x._id == r.organizerId
				)?.name : '';
			});
			usr['isOrganizer'] =
				usr['rolesOrganizers'].length == 1 &&
				usr['rolesOrganizers'][0]['roleName'] == 'USER_ORGANIZER';

			const highestRoleOrganizer = usr['isOrganizer']
				? usr['rolesOrganizers'][0]
				: usr['rolesOrganizers']
					.filter((x: any) => x.organizer != null)
					.sort((a: any, b: any) => {
						return b.roleLevel - a.roleLevel;
					})[0];

			usr['highestRoleOrganizer'] = highestRoleOrganizer;
			usr['isEditable'] =
				usr['highestRoleOrganizer'].roleLevel < loggedUserLevel;
		});
		const sortedByRole = usrs.sort(
			(a, b) =>
				b.highestRoleOrganizer.roleLevel -
				a.highestRoleOrganizer.roleLevel
		);
		return sortedByRole;
	};
	const sortedActiveUsers = () => {
		if (!!userResults) {
			return userResults.map(function (user) {
				return <UserRow user={user} key={user._id} isAdmin={loggedUser.role.roleName == 'USER_ADMIN'} />;
			});
		}
		return null;
	};
	const loadNextPage = async () => {
		showLoader();
		const usrs = await loadUsers(page + 1, filterText);

		if (usrs && usrs.length > 0) {
			setUserResults(usrs);
		}
		setPage(page + 1);
		hideLoader();
	};
	const loadPrevPage = async () => {
		showLoader();
		const usrs = await loadUsers(page - 1, filterText);

		if (usrs && usrs.length > 0) {
			setUserResults(usrs);
		}
		if (page > 0) {
			setPage(page - 1);
		}
		hideLoader();
	};

	const onfilterTextChange = (e: any) => {
		setFilterText(e.target.value);
	};
	const clickSearch = async () => {
		await performSearch();
	}
	const performSearch = async (_filter?: string) => {
		showLoader();
		setPage(0);
		const filter = _filter != undefined ? _filter : filterText != 'none' && filterText != '';
		const usrs = await loadUsers(0, filter ? filterText : null);
		if (usrs) {
			setUserResults(usrs);
		}
		hideLoader();
	};
	const handleKeyDown = async (e: any) => {
		if (e.keyCode == 13) {
			await performSearch();
		}
	};
	const clearSearchFilter = async () => {
		setFilterText('none');
		await performSearch('');
	}
	return (
		<>
			{!isLoggedUser && <Redirect to="/login" />}
			{loader}
			{isLoggedUser && loggedUser && (
				<>
					{!loggedUser['isHost'] ? (
						<div className="dashboard-content cards">
							<div className="form-container col-xl-10">
								<div className="d-flex justify-content-between align-items-center mb-3 p-0 pl-sm-2 pr-sm-2">
									<div className="d-flex justify-content-start page-title align-items-center">
										{t('users')}
									</div>
									<div className="d-flex justify-content-end">
										<NavLink to="/user/new">
											<Button className="button-primary hide-small">
												{t('user.create')}
											</Button>
											<Button className="rounded-button rounded-button-ok hide-big">
												<FaPlus />
											</Button>
										</NavLink>
									</div>

								</div>

								<div className="col-12 row m-0 p-0 align-items-center ">
									<div className="row col-12 col-xl-6 m-0 p-0 pl-md-2 pr-md-2 align-items-center search-input user-events">
										<input
											className="form-control"
											type="text"
											name="filterNameEmail"
											value={filterText != 'none' ? filterText : ''}
											placeholder={t('user.search')}
											onKeyDown={handleKeyDown}
											onChange={onfilterTextChange}
										/>
										<div className="clear-search" onClick={clearSearchFilter}>
											<IoClose />
										</div>
										<div className="input-button-search">
											<Button className="rounded-button rounded-button-ok" onClick={clickSearch} >
												<IoSearch />
											</Button>
										</div>
									</div>
									
									<div className='col-xl-3 m-0 p-0 pl-md-2 pr-md-2 mt-2 mt-xl-0'>
										<Select
											className="select-control"
											placeholder={t('select')}
											options={pageOptions}
											value={pageOptions.find(x => x.value === pageSize)}
											onChange={handlePageOptionsChange}
										/>
									</div>
								
								</div>
								
								<div className="">
									{userResults && userResults.length === 0 && (
										<NoResults
											text={i18next.t('users-not-found')}
										/>
									)}

									{!!userResults && userResults.length > 0 && (
										<div className="pb-5 pt-5">

											<Table borderless>
												<thead>
													<tr className='user-header header'>
														<th></th>
														<th>{t('user-label')}</th>
														<th className='hide-medium'>{t('user.role')}</th>
														<th className='hide-medium'>{t('event.organizer')} </th>
														<th className='hide-medium'>{t('age')} </th>
														<th className='hide-medium'>{t('user.gender')}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{sortedActiveUsers()}
												</tbody>
											</Table>

										</div>
									)}
								</div>
								{userResults && userResults.length > 0 && (
									<Paginator
										totalPages={Math.ceil(
											totalUsers / pageSize
										)}
										currentPage={page}
										onNext={loadNextPage}
										onPrevious={loadPrevPage}
									/>
								)}
							</div>
						</div>
					) : (
						<Redirect to="dashboard"></Redirect>
					)}
				</>
			)}
		</>
	);
}

export default Users;
