import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import styles from './Qrscan.module.css';
import { Button, Form } from 'react-bootstrap';
import { CustomModal } from '../app/components/modals/customModal';
import { useHistory, Redirect } from 'react-router-dom';
import useApi from '../app/hooks/useApi';
import useInscriptionsApi from '../app/hooks/useInscriptionsApi';
import useLoader from '../app/components/loader/useLoader';
import { useDispatch } from 'react-redux';
import { IoArrowBackOutline } from 'react-icons/io5';
interface QrscanProps {
	eventId: string;
//	eventName: string;
}
const Qrscan = (props: QrscanProps) => {
	const { t } = useTranslation();
	const [result, setResult] = useState('No result');
	const [error, setError] = useState<any>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	
	const [loader, showLoader, hideLoader] = useLoader();
	const [inscription, setInscription] = useState<any>(undefined);
	const [loggedUser] = useApi();
	
	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions, 
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription
	] = useInscriptionsApi();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const dispatch = useDispatch();

	
	const handleError = (err: any) => {
		console.log(err)
	}

	const confirm =async (inscriptionId: string) =>{
		try{
			
			showLoader();
			const response: any = await dispatch(
				attendeeInscription({
					id: inscriptionId,
					attendeedBy: loggedUser['_id'],
					attendeedDT: new Date(),
					isAdmin: loggedUser?.role?.roleLevel == 32
				})
			);
			if (!!response && !!response.payload){
				if (!!response.payload.error){
					if (response.payload.error.includes('inscription.status')){
						let e = t('error.inscription-status', {
							inscriptionStatus: t(
								response.payload.error
							)});
							if(!!response.payload.inscription){
							setInscription(response.payload.inscription)
							if (response.payload.inscription.event?.hasSectors && response.payload.inscription.inscriptionStatus === 'ATTENDEED'){
								const _sector = response.payload.inscription.sector?.name || t('event.sector.no-sector');
								e = t('error.inscription-status-sector', {
									inscriptionStatus: t(
										response.payload.error
									), sector: _sector});
							}
							}
							setError(e);
							setShowErrorModal(true);
					} else {
						setError(t(response.payload.error));
						setShowErrorModal(true);
					}
					
				} else if (!!response.payload.inscription){
					setInscription(response.payload.inscription);
					setShowSuccessModal(true);
				}
			}
		} catch (e) {
			
			setError(e);
			setShowErrorModal(true);
		} finally {
			
			hideLoader();
		}
	}
	const handleScan = async (result: any) => {
		if (result) {
			setIsLoading(true);
			setResult(result)
			console.log(result);
			if (props.eventId && result.includes(props.eventId)) {
				const split = result.split('/confirmAttendee/');
				console.log(split);
				const inscriptionId = split[1].split('/')[0];
				await confirm(inscriptionId);
			//	location.href = result;

			} else {
				if (result.includes('/confirmAttendee/')){
					
					setError(t('error.qr-other-event'));
				}else {
					setError(t('error.qr-invalid'));
				}
				setShowErrorModal(true);
			}
			
		}
	}
	const handleCloseErrorModal = (e: any) => {
		setError('');
		setShowErrorModal(false);
		setIsLoading(false);
	};

	const previewStyle = {
		height: '100vh',
		width: '100%'
	}
	const history = useHistory();
	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		//history.goBack();
		history.push('/events/attendance/'+ props.eventId +'/NOT_ATTENDEED');
	};
	const handleCloseSuccessModal = (e: any) => {
		
		
		setShowSuccessModal(false);
		setIsLoading(false);
	};
	return (
		<>
		 {!!!loggedUser && <Redirect to="/login" />}
		{loader}
			<div className="dashboard-content form w-100 pt-1">
				<div className='container-box'>
					<div className='container-box-content d-flex justify-content-between align-items-center'>
						<div className='d-flex '>
							<div
								className="button-back mr-2"
								onClick={(e: any) => handleOnCancel(e)}
							>
								<IoArrowBackOutline />
							</div>
							<div className='ml-4 mr-4'>
								<div className='banner-title d-flex align-items-center'><p className='one-line'>{t('admission')}</p></div>
							</div>

						</div>
					</div>
				</div>
				<div className="form-container col-12 mt-x-5">
					{!isLoading && <div className={styles.container} id="qr-plugin">
						<QrReader
							delay={500}
							style={previewStyle}
							onError={handleError}
							onScan={handleScan}
						/>
					</div>}
				</div></div>
				{showSuccessModal && (
						<CustomModal
							isShowing={showSuccessModal}
							parentCallback={handleCloseSuccessModal}
							title={t('inscription.attendee-title')}
							message={inscription.name + ' ' + inscription.surname  +(inscription.event?.sectors?.length > 0 ? ' - '+ (inscription.sector?.name || t('event.sector.no-sector')): '')}
							warningMessage={!!inscription.warningMessage ? inscription.warningMessage : null}
							type="SUCCESS"
							buttonOK={t('accept')}
						/>
					)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={error}
					title={t('error.modal-title-oops')}
					buttonOK={t('accept')}
					type="ERROR"
				/>
			)}
		</>
	);
}

export default Qrscan;