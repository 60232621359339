import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';


import useEventsApi from '../../../hooks/useEventsApi';

import CustomModal from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/loader/useLoader';
import { IEvent, IQuestion } from '../../../models/interfaces/events.interface';
import { QUESTIONS_LIMIT, YES_NO } from '../../../../constants';
import QuestionRow from '../../../questions/questionRow';
import ErrorGlobal from '../../../components/error-global/error-global';
import { IoAddOutline } from 'react-icons/io5';
import QuestionForm from '../../../questions/questionForm';
import { StepProps, getSelectSingleOptions } from '../utils';
export function StepQuestions(props: StepProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [questions, setQuestions] = useState<IQuestion[]>([]);

    const [hasQuestion, setHasQuestion] = useState<string | undefined>();

    const [questionToEdit, setQuestionToEdit] = useState<IQuestion | undefined>();

    const [questionToEditIndex, setQuestionToEditIndex] = useState<number>(-1);

    const [questionNumber, setQuestionNumber] = useState<number>(0);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const [isDirty, setIsDirty] = useState(false);

    const dispatch = useDispatch();
    const [
        createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getSectorsAvailability,
        getInscriptionsBySector,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent
    ] = useEventsApi();


    const subStepsTotal = 1;

    const hasQuestionOptions = getSelectSingleOptions(YES_NO, 'confirmation.', props.event?.status === 'DRAFT');

    const validateAll = (form: any) => {
        return (
            form.checkValidity() &&
            checkQuestions()
        );
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            try {
                showLoader();
                const _questionsToSend = getQuestions();

                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    questions: _questionsToSend,
                    hasQuestions: hasQuestion,
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date(),
                };
                let subSteps = 0;
                if (!!hasQuestion && hasQuestion !== 'none') {
                    subSteps += 1;
                }


                if (!!evt.steps) {
                    const stepIdx = evt.steps?.findIndex(s => s.name === 'QUESTIONS');
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = (subSteps / subStepsTotal) * 100;
                    }
                }
                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    hideLoader();
                    props.saveAndGoToStep(response.payload, 'SECTORS');

                } else {
                    hideLoader();
                    console.log('fallo el edit draft questions')
                }

            }
            catch (error: any) {
                setErrorModalMessage(t('event.error-delete'));
                setShowErrorModal(true);
            } finally {
                hideLoader();
            }
        }
        if (isDirty) {
            setValidated(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    const handleEvent = async () => {
        showLoader();
        if (props.loggedUser && !!props.event) {

            const ev = props.event;
            if (!!ev.questions) {
                setHasQuestion(ev.hasQuestions);
                setQuestions(ev.questions);
            }
        }
        hideLoader();
    };

    const getQuestions = () => {
        if (!!questions && questions.length > 0) {
            return questions.filter(b => (!!!b.deleted));
        }
        return [];
    }

    const handleAddQuestion = () => {
        const q = questions?.length === 0 ? 1 : questionNumber + 1
        setQuestionNumber(q);
        addQuestion(q);
    }
    const addQuestion = (prevNumber: number) => {
        setValidated(false);

        const question: IQuestion = {
            index: prevNumber + 1,
            text: undefined,
            type: undefined,
            options: undefined
        };
        const b = [...questions, question];

        setIsEditing(false);
        setQuestionToEdit(question);
        setQuestionToEditIndex(b.length - 1);
    };
    const handleDeleteQuestion = (c: any) => {
        const idx = questions?.indexOf(c);
        if (!!questions && idx > -1) {
            questions[idx]['deleted'] = true;
            const b = [...questions];
            setQuestions([]);
            setQuestions(b);
        }
        setIsDirty(true);
    };
    const getQuestionsNotDeleted = () => {
        return !!questions ? questions?.filter(x => x.deleted !== true).length : 0;
    }
    const handleEditQuestion = (b: any, i: number) => {
        setQuestionToEdit(b);
        setIsEditing(true);
        setQuestionToEditIndex(i);
    };
    const handleCloseEditQuestionModal = () => {
        setIsEditing(false);
        setQuestionToEdit(undefined);
        setQuestionToEditIndex(-1);
    }
    const handleSaveQuestionModal = (modifiedQuestion: any, index: number) => {
        const _btchs = questions;
        _btchs[index] = modifiedQuestion;
        setQuestions(_btchs);
        setIsEditing(false);
        setIsDirty(true);
        setQuestionToEdit(undefined);
        setQuestionToEditIndex(-1);
    }


    const checkQuestions = () => {
        const err = questions.filter(
            (x: any) => x.hasError && !x.deleted
        );
        return (hasQuestion !== 'yes') ? true : (questions.length > 0 && (!err || err?.length === 0));
    }

    const handleOnSelectHasQuestion = (event: any) => {
        setQuestionNumber(0);
        setQuestionToEdit(undefined);
        setQuestions([]);
        setQuestionToEditIndex(-1);
        setHasQuestion(event.name);
        setIsDirty(true);
    };

    useEffect(() => {

        props.notifyIsDirty(isDirty);

    }, [isDirty]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loader}
            {!!props.event &&
                <Form
                    className="form-container m-0 p-0 step-container"
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    action="/events"
                >
                    <div className="form-info-container pr-0 pt-0 pb-0">
                        <div className='d-flex flex-column m-0 mt-2 mt-md-0 p-0'>
                            <h3 className='w-100 step-information-title mb-2'>
                                {t('event.step-info.questions.title')}
                            </h3>
                            <p className='step-information-body' dangerouslySetInnerHTML={{ __html:  t('event.step-info.questions.text') }}></p>
                        </div>
                        <Form.Group>
                            <div className="d-flex row">
                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.include-question')}`}
                                    </Form.Label>
                                    <Select
                                        required
                                        isDisabled={!props.editable || (!!props.event?.inscriptions && props.event.inscriptions?.length > 0)}
                                        isInvalid={validated && !hasQuestion}
                                        className={validated && !hasQuestion ? "select-control invalid" : (validated && !!hasQuestion ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={hasQuestionOptions}
                                        value={hasQuestionOptions.find(x => x.name === hasQuestion)}
                                        onChange={handleOnSelectHasQuestion}
                                    />
                                    {validated && !hasQuestion && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!hasQuestion && `${t('error.required')}`}
                                    </Form.Control.Feedback>}
                                </div>


                            </div>

                        </Form.Group>
                        {/* start questions */}
                        <div className={(hasQuestion === 'yes') ? 'form-group pt-3' : 'd-none'}>
                            <hr></hr>
                            <div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
                                <label className="group-label m-0 ml-1">
                                    {`${t('event.questions')} - ${getQuestionsNotDeleted()}/${QUESTIONS_LIMIT}`}
                                </label>
                                {hasQuestion === 'yes' && 
                                    <div className="form-group">
                                        <Button
                                            disabled={!props.editable || (!!props.event.inscriptions && props.event?.inscriptions?.length > 0) || getQuestionsNotDeleted() === QUESTIONS_LIMIT}
                                            className="button-ok small"
                                            onClick={handleAddQuestion}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <IoAddOutline />
                                                </div>
                                                <div>
                                                    {t('event.add-question')}
                                                </div>
                                            </div>
                                        </Button>
                                    </div>}

                            </div>

                            {validated && (!questions || questions.length === 0) && <Form.Control.Feedback
                                type="invalid"
                                className="global-error mb-3 mt-3"
                            >
                                <ErrorGlobal
                                    text={t('error.questions-required')}
                                ></ErrorGlobal>
                            </Form.Control.Feedback>}
                            {questions && questions.length > 0 && (
                                <>
                                    {questions.map((b, i) => (
                                        <div key={i}>
                                            {!b.deleted &&
                                                <QuestionRow
                                                    i={i}
                                                    question={b}
                                                    handleDeleteQuestion={handleDeleteQuestion}
                                                    handleEditQuestion={handleEditQuestion}
                                                    canEdit={props.editable && (!props.event?.inscriptions || props.event.inscriptions?.length === 0)}
                                                ></QuestionRow>
                                            }
                                        </div>
                                    ))}
                                </>
                            )}

                            <hr></hr>
                        </div>
                        {/* end questions */}


                    </div>
                    {/* botones */}
                   {props.editable && <div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                        <Button
                            disabled={!isDirty}
                            className="button-outline-primary w-100 w-md-auto text-transform-none"
                            type="submit">
                            {
                                props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                            }
                        </Button>
                    </div> }
                </Form>
            }

            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}
            {questionToEdit &&
                <QuestionForm
                    isEditing={isEditing}
                    index={questionToEditIndex}
                    question={questionToEdit}

                    onHideModal={handleCloseEditQuestionModal}
                    onSaveQuestion={handleSaveQuestionModal}
                ></QuestionForm>}
        </>
    );
}

export default StepQuestions;
