import React, { useEffect } from 'react';
import { ISector } from '../models/interfaces/events.interface';
import { useTranslation } from 'react-i18next';
import { DateHelper } from '../helpers/date-helper';
import { Dropdown } from 'react-bootstrap';
import { IoMdMore } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';

interface SectorRowProps {
    sector: ISector;
    otherSectors?: ISector[];
    i: number;
    handleDeleteSector?: any;
    handleEditSector?: any;
    canEdit: boolean;
    eventId?: string;
    sectorsAvailability: any[];
}

export function SectorRow(props: SectorRowProps) {
    const { t, i18n } = useTranslation();
    const [occupied, setOccupied] = React.useState(0);
    let notAvailableQty = 0;
    let availableQty = props.sector.capacity || 0;
    const prevSector: ISector | undefined = props.otherSectors?.find(b => b.number === props.sector.sectorEndedNumber);

    useEffect(() => {
        if (props.sectorsAvailability && props.sectorsAvailability.length > 0 && !!props.sector._id) {
            const mysectorAvailability: any = props.sectorsAvailability.find((x: any) => x.sectorId === props.sector._id);
            setOccupied(mysectorAvailability?.occupied);
            notAvailableQty = mysectorAvailability?.occupied;
        }
    }, [occupied, props])

    if (!!props.eventId && !!props.sector._id) {
        if (!!props.sectorsAvailability) {
            // const sectorsAvailability: any[] = props.sectorsAvailability;
            // const mysectorAvailability: any = sectorsAvailability.find((x: any)=> x.sectorId === props.sector._id);
            // if (!!prevSector && previousSectorIsExpired(props.sector, props.otherSectors)){
            //     const previousSectorAvailability: any = sectorsAvailability.find((x: any)=> x.sectorId === prevSector._id);
            //     availableQty = (props.sector.capacity + previousSectorAvailability.capacity);
            //setSectorsAvailability(response.sectorsAvailability);

            // if (!!prevSector && hasPreviousSector(props.sector)){
            //     const previousSectorAvailability: any = sectorsAvailability.find((x: any)=> x.sectorId === prevSector._id);
            //     availableQty = (props.sector.capacity + previousSectorAvailability.capacity);
            // }
            notAvailableQty = occupied

        }
    }

    //     if (!!props.eventId && !!props.sector._id) {

    //         if (!!props.sectorsAvailability) {
    //             const sectorsAvailability: any[] = props.sectorsAvailability;
    //             const mysectorAvailability: any = sectorsAvailability.find((x: any)=> x.sectorId === props.sector._id);
    //             if (!!prevSector && previousSectorIsExpired(props.sector, props.otherSectors)){
    //                 const previousSectorAvailability: any = sectorsAvailability.find((x: any)=> x.sectorId === prevSector._id);
    //                 setAvailableQty(props.sector.capacity + previousSectorAvailability.capacity);
    //             } else {
    //                 setNotAvailableQty((props.sector.capacity || 0) - mysectorAvailability.capacity);
    //             }
    //             //setSectorsAvailability(response.sectorsAvailability);
    //         }
    //     }
    // }
    // useEffect(() => {
    //     handleEffect();
    // }, [props.sector]);
    const isOccuppied =()=>{
       return props.sectorsAvailability.find(s => s.sectorId === props.sector._id && s.occupied > 0);
    }
    return (
        <>
            <div key={props.i} className="batch-row mb-2">
                <div className='row m-0 p-0 ml-1 mr-1 justify-content-between align-items-center no-wrap'>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <div className='batch-title'>{props.sector.name}</div>
                        {/* <div className="card-subtitle align-items-center row p-0 m-0 mt-1">
                            {(isForSale(props.sector, props.otherSectors, props.sectorsAvailability) || isScheduled(props.sector, props.otherSectors, props.sectorsAvailability)) && <FaCircle className={isForSale(props.sector, props.otherSectors, props.sectorsAvailability) ? 'active-dot medium mr-2' : 'warning-dot medium mr-2'}></FaCircle>}
                            <div>
                                {isForSale(props.sector, props.otherSectors, props.sectorsAvailability) ? t('event.sector.for-sale') : (isScheduled(props.sector, props.otherSectors, props.sectorsAvailability) ? t('event.sector.scheduled') : '')}
                            </div>
                            <FaCircle className={'regular-dot small ml-1 mr-1'}></FaCircle>
                            {(isForSale(props.sector, props.otherSectors, props.sectorsAvailability) && !isScheduled(props.sector, props.otherSectors, props.sectorsAvailability)) && <div>{t('sales-end-on', { date: DateHelper.longDate2(props.sector.dateEnd || ''), time: props.sector.endHour })}</div>}
                            {!hasPreviousSector(props.sector) && (isScheduled(props.sector, props.otherSectors, props.sectorsAvailability)) && <div>{t('sales-start-on', { date: DateHelper.longDate2(hasPreviousSector(props.sector) ? prevSector?.dateEnd || '' : props.sector.date || ''), time: hasPreviousSector(props.sector) ? prevSector?.endHour : props.sector.startHour })} </div>}
                            {hasPreviousSector(props.sector) && isScheduled(props.sector, props.otherSectors, props.sectorsAvailability) && <div>{t('event.sector.sector-available-after', { sector: prevSector?.name })}</div>}
                            {hasEnded(props.sector) && !isOccupiedOut(props.sector, props.sectorsAvailability) && <div>{t('finalized-sales')}</div>}
                            {isOccupiedOut(props.sector, props.sectorsAvailability) && <div>{t('occupied-out')}</div>}

                        </div> */}
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <div className='batch-title'>{notAvailableQty}/{availableQty}</div>
                        {props.canEdit && <Dropdown>
                            <Dropdown.Toggle bsPrefix="sector-edit-menu" className="batch-edit-menu">
                                <IoMdMore size={28} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {props.i > 0 && !isOccuppied() && <Dropdown.Item onClick={() => props.handleDeleteSector(props.sector)}>{t('delete')}</Dropdown.Item>}
                                <Dropdown.Item onClick={() => props.handleEditSector(props.sector, props.i)}>{t('edit')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectorRow;

