
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useLoader from '../../components/loader/useLoader';
import useApi from '../../hooks/useApi';
import useEventsApi from '../../hooks/useEventsApi';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { IEvent, IStep } from '../../models/interfaces/events.interface';
import { Redirect } from 'react-router-dom';
import { TStep } from '../../models/types/types';
import StepInformation from './steps/step-INFORMATION';
import { EMPTY_STEPS, getNextUncompletedStepName, getOrganizerByLoggedUser, getRemainingTimeAndDateStatus, getTotalProgress, isHost, isPast} from './utils';
import StepImages from './steps/step-IMAGES';
import StepParticipants from './steps/step-PARTICIPANTS';
import StepStatus from './steps/stepStatus';
import StepQuestions from './steps/steps-QUESTIONS';
import StepSectors from './steps/step-SECTORS';
import StepInscriptions from './steps/step-INSCRIPTIONS';
import StepFeedback from './steps/steps-FEEDBACK';
import { IUser } from '../../models/interfaces/users.interface';
import StepSummary from './steps/step-SUMMARY';
import CustomModal from '../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import { IoMdMore } from 'react-icons/io';
import { IoArrowBackOutline } from 'react-icons/io5';
import StepReminder from './steps/steps-REMINDER';
import { Dropdown } from 'react-bootstrap';
import { SHOW_PUBLIC_EVENTS, SHOW_QUESTIONS, SHOW_SECTORS } from '../../../constants';
import QuestionsReportPDF from '../../reports/questionsReport';
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import SectorsReportPDF from '../../reports/sectorsReport';
import FeedbackQuestionsReportPDF from '../../reports/feedbackQuestionsReport';
import InscriptionsMenuReportDynamicPDF from '../../reports/menuReportDynamic';
import i18n from '../../../i18n';
import InscriptionLinkModal from '../../components/modals/inscriptionLinkModal';
import { exportAnswersToExcel, exportInscriptionsToExcel } from '../../helpers/excel-report-helper';
import { DateHelper } from '../../helpers/date-helper';
import { getEventInscriptionURL } from '../../helpers/events-helper';
import { BsTrashFill } from 'react-icons/bs';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
interface PrivateEventFormProps {
    eventId?: string,
    selectedStep?: TStep,
    editable: boolean,
    hasFinished: boolean,
    isNow?: boolean
}

export function PrivateEventForm(props: PrivateEventFormProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t } = useTranslation();
    const location = useLocation();
    // TODO REVISAR ESTO
    const [editable, setEditable] = useState<boolean>(props.editable);
    const [isPublished, setIsPublished] = useState<boolean>(false);
    const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);
    const [inscriptionLink, setInscriptionLink] = useState<string | undefined>();
    const [goToEvents, setGoToEvents] = useState<boolean>(false);
    const [hasFinished, setHasFinished] = useState<boolean>(props.hasFinished);
    const [canSendFeedbackLink, setCanSendFeedbackLink]= useState<boolean>(props.hasFinished);
   // const [editab, setHasFinished] = useState<boolean>(props.hasFinished);
    const [
        loggedUser,
        ,
        ,
        ,
        ,
        ,
        getMyHosts,
        getMyAdmissions,
        getMyPlanners,
        getOrganizer,
        ,
        getAllOrganizers
    ] = useApi();
    const [createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getSectorsAvailability,
        getInscriptionsBySector,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent,
        publishEvent] = useEventsApi();
    const [
        createInscription,
        getInscription,
        getAllInscriptions,
        getAcceptedInscriptions,
        getDeniedInscriptions,
        getPendingInscriptions,
        updateInscriptionStatus,
        updateStatusMultiple,
        confirmEmail,
        resendInscriptionEmail,
        getAdmissionInscriptions,
        generateQR,
        getInscriptionById,
        resendQREmail,
        getInscriptionByEmail,
        getInscriptionsByCriteria,
        getAttendeedInscriptions,
        sendDeniedEmail,
        getAttendeedInscriptionsFromMain,
        getReportInscriptions,
        getReportMenu,
        updateAndSendQRs,
        getReportAttendeedInscriptions,
        getReportAttendeedMenu,
        getReportPastEventInscriptions,
        getReportMenuDynamic,
        updateInscriptionAndCompanions,
        updateInscriptionAndCompanionsAndSendQR,
        getInscriptionByIdentificationNumber,
        getInscriptionByIdWithBatches,
        attendeeInscription,
        sendRejectedEmail,
        updateInscriptionSector,
        getPublicAcceptedInscriptions,
        getReportBilling,
        getInscriptionsAnswers,
        getInscriptionsFeedbackAnswers
    ] = useInscriptionsApi();
    const dispatch = useDispatch();
    const history = useHistory();
    const isLoggedUser =
        localStorage.getItem('loggedUserId') && localStorage.getItem('token');

    const [organizer, setOrganizer] = useState<IUser | undefined>(undefined);
    const [organizers, setOrganizers] = useState<IUser[] | undefined>(undefined);
    const [event, setEvent] = useState<IEvent | undefined>(undefined);
    const [selectedStep, setSelectedStep] = useState<TStep>(props.selectedStep || 'INFO');
    const [steps, setSteps] = useState<any>(EMPTY_STEPS);
    const [totalProgress, setTotalProgress] = useState<number>(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [showConfirmationReminderModal, setShowConfirmationReminderModal] = useState(false);
    const [showConfirmationFeedbackModal, setShowConfirmationFeedbackModal] = useState(false);
    const [showConfirmationDiscardChanges, setShowConfirmationDiscardChanges] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showSuccessModalPublish, setShowSuccessModalPublish] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showCopyLinkModal, setShowCopyLinkModal] = useState<boolean>(false);
    const [eventStatus, setEventStatus] = useState<string|undefined>();
    const [allStepsComplete, setAllStepsComplete]= useState<boolean>();
    const [modifiedEvent, setModifiedEvent] = useState<any>();
    const [nextLocation, setNextLocation] = useState<string|null>(null);
    const [discardChanges, setDiscradChanges] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [loggedUserIsHost, setLoggedUserIsHost] = useState<boolean>(false);
    const [loggedUserIsPlanner, setLoggedUserIsPlanner] = useState<boolean>(false);

    useEffect(() => {
        handleEffect();
    }, [loggedUser]);

    const handlePublishEvent = async () => {
        try {
            showLoader();
            if (!!event && allStepsComplete) {
                const evt: IEvent = {
                    _id: event?._id,
                    steps: event?.steps,
                    publishedBy: loggedUser._id,
                    publishedDT: new Date()
                }
                if (!!evt && !!evt.steps) {
                    const stepIdx = evt.steps.findIndex(s => s.name === 'SUMMARY');
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = 100;
                    }
                }
                const response: any = await dispatch(publishEvent(evt));
                if (!!response && response['payload']['_id']) {
                    setIsPublished(true);
                    setModifiedEvent(response.payload);

                    const _inscriptionLink = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL) +
                        getEventInscriptionURL(evt);

                    if (_inscriptionLink) {
                        setInscriptionLink(_inscriptionLink);
                        setShowSuccessModalPublish(true);
                    } else {
                        setErrorModalMessage(t('error.unexpected'));
                        setShowErrorModal(true);
                    }
                }
            }
        } catch (error) {
            console.error("Error al publicar el evento:", error);
        } finally {
            hideLoader();
        }


    };
    const handleCloseSuccessModalPublish = (e: any) => {
        if (e && inscriptionLink) {
            navigator.clipboard.writeText(inscriptionLink)
                .then(() => {
                    setShowSuccessModalPublish(false);
                    setShowLinkCopiedModal(true);
                });
        } else {
            setShowSuccessModalPublish(false);
        }

        saveAndGoToStep(modifiedEvent, 'SUMMARY');
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
    };

    const handleEffect = async () => {
        let selectedOrganizer = organizer;
        try {
            showLoader();
            if (loggedUser && isLoggedUser) {
                if (!organizer || !props.eventId) {
                    const org = getOrganizerByLoggedUser(loggedUser);
                    setOrganizer(org);
                    selectedOrganizer = org;
                }

                if (!organizers) {
                    const org = await getAllOrganizers();
                    setOrganizers(org);
                    if (org && org.length > 0) {
                        setOrganizer(org[0]);
                        selectedOrganizer = org[0];
                    }

                }
                if (!selectedOrganizer || selectedOrganizer._id === '0') {
                    history.push('/user/new');
                }

                if (!!props.eventId && !event) {
                    const ev: any = await getEventById(props.eventId);
                    if (ev && ev['_id']) {
                        setEvent(ev);
                        const rt = getRemainingTimeAndDateStatus(ev);
                        const isPast_ = rt.dateStatus === "ended";
                        setOrganizer(ev.organizer);
                        setTotalProgress(getTotalProgress(ev.steps));
                        setAllStepsComplete(ev?.steps?.slice(0, -1).every((step: IStep) => step.percentComplete === 100));
                        setIsPublished(ev.status === 'PUBLISHED');
                        setEventStatus(ev.status === "DRAFT" ? "DRAFT" : 
                                        (isPast_ ? 'ENDED' : 
                                            (rt.dateStatus === "now" ? 'NOW': 
                                                ev.status)));
                        setInscriptionLink(ev.qrUrl);
                        setHasFinished(isPast_);
                        if (isPast_){
                            setEditable(false);
                        }
                        if (ev.hasFeedbackQuestions === 'yes'){
                            setCanSendFeedbackLink(checkIfCanSendFeedbackLink(ev, isPast_));
                        }

                        const isHost_ = isHost(loggedUser, ev);
                        setLoggedUserIsHost(isHost_);
                        setLoggedUserIsPlanner(ev.planners.some((p:IUser) => p._id === loggedUser._id));
                        const isNow = rt.dateStatus === 'now';

                        setEditable(props.editable && !isHost_ && !isPast_ && !isNow);
                    }
                }

            }
        }
        catch (e) {
            console.log(e);
        } finally {

            hideLoader();
        }

    };
    const checkIfCanSendFeedbackLink = (ev: any, _hasFinished: boolean)=>{
        if (!!ev && !!ev.feedbackLimitDT && !!ev.feedbackLimitHour){
            return _hasFinished && !isPast(ev.feedbackLimitDT, ev.feedbackLimitHour) && ev.hasFeedbackQuestions === 'yes' &&
            (ev.inscriptions?.filter((x:IInscription)=> x.inscriptionStatus === 'ATTENDEED').length > 0);
        }
        return false;
    }

    useEffect(() => {
        const unblock = history.block((location: any)=>{

            if(isDirty && !discardChanges){
                setNextLocation(location.pathname);
                setShowConfirmationDiscardChanges(true);
                return false;
            }
            return true;
        });

        return function cleanup() {
            unblock();
        };
    }, [isDirty, discardChanges]);
    
    useEffect(() => {
        if (nextLocation) {
            history.push(nextLocation);
        }
    }, [showConfirmationDiscardChanges]);

    const goToStep = (stepName: TStep) => {
        if (!!event) {

            if (!!props.eventId ) {
                history.push('/events/'+ (hasFinished ? 'past/':'edit-draft/' )+ props.eventId + '/' + stepName);
            }
        }

    }

    useEffect(()=>{

        const stepName = location.pathname.split('/')[location.pathname.split('/').length - 1];
        setSelectedStep(EMPTY_STEPS.some(step=>step.name === stepName) ? stepName : EMPTY_STEPS[0].name);
        setNextLocation(null);
        setShowConfirmationDiscardChanges(false);
        setDiscradChanges(false);
        setIsDirty(false);

    }, [location])

    const saveAndGoToStep = (_event: IEvent, stepName: TStep, fromSummary?: boolean) => {
        setIsDirty(false)
        if (!!event){
            _event.inscriptions = event.inscriptions;
        }
        if (!fromSummary) {
            setEvent(_event);
            setTotalProgress(getTotalProgress(_event.steps));
            setAllStepsComplete(_event?.steps?.slice(0, -1).every((step: IStep) => step.percentComplete === 100));
        }
        const firstUncompleted = getNextUncompletedStepName(_event.steps, selectedStep);
        const _stepName = (!fromSummary && !!firstUncompleted && firstUncompleted !== selectedStep) ? firstUncompleted : stepName;

        setSelectedStep(_stepName);
        setIsPublished(_event.status === 'PUBLISHED');
       

        setInscriptionLink(_event.qrUrl);

        const rt = getRemainingTimeAndDateStatus(_event);

        const isPast_ = rt.dateStatus === "ended";

        setEventStatus(isPast_ ? 'ENDED' : (rt.dateStatus ==='now' ? 'NOW': _event.status));
        if (!!_event._id) {
            if (_event.hasFeedbackQuestions === 'yes' && !!_event.feedbackLimitDT && !!_event.feedbackLimitHour){
              setCanSendFeedbackLink(checkIfCanSendFeedbackLink(_event,isPast_));
            }
            history.push('/events/'+ (isPast_ ? 'past/':'edit-draft/' )+_event._id + '/' + _stepName);
        }
    }
    const handleCloseLinkCopiedModal = (e: any) => {
        setShowLinkCopiedModal(false);
    };
    const handleOnGoBack = (e: any) => {
        history.push(isPublished && !hasFinished ? '/events' : (hasFinished? '/past-events' : (SHOW_PUBLIC_EVENTS ? '/events/type': '/draft-events')));
    }
  
    const handleOnExport = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            const reportInscriptionsResult = !hasFinished ? await loadReportInscriptions() : await loadReportPastEventInscriptions();
            if (!!event) {
                exportInscriptionsToExcel(event, reportInscriptionsResult, hasFinished);
            }
            hideLoader();
        }
    }

    const handleOnReportSectors = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            if (!!event && !!event._id) {
                const response = await getInscriptionsBySector(event._id);
                if (!!response && response.length > 0) {
                    await SectorsReportPDF(event, response);

                } else {
                    setErrorModalMessage(t('event.error-report-sector'));
                    setShowErrorModal(true);
                }
            }
            hideLoader();
        }
    }

    const handleOnReportQuestions = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            if (!!event && !!event._id) {
                const response = await getInscriptionsAnswers(event._id);
                if (!!response && response.report.length > 0) {
                    await QuestionsReportPDF(event, response);

                } else {
                    setErrorModalMessage(t('event.error-report-question'));
                    setShowErrorModal(true);
                }
            }

            hideLoader();
        }
    }
    const loadReportInscriptions = async () => {
        if (!!event && !!event._id) {
            return await getReportInscriptions(event._id);
        }
    };
    const loadReportMenuDynamic = async (attendeed: boolean) => {
        if (!!event && !!event._id) {
            return await getReportMenuDynamic(event._id, attendeed);
        }
    };
    const loadReportPastEventInscriptions = async () => {
        if (!!event && !!event._id) {
            return await getReportPastEventInscriptions(event._id);
        }
    };
    const handleOnReportFeedbackQuestions = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            if (!!event && !!event._id) {
                const response = await getInscriptionsFeedbackAnswers(event._id);
                if (!!response && response.report.length > 0) {
                    await FeedbackQuestionsReportPDF(event, response);

                } else {
                    setErrorModalMessage(t('event.error-report-question-feedback'));
                    setShowErrorModal(true);
                }
            }

            hideLoader();
        }
    }
    const handleOnMenuReport = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            if (!!event) {
                const reportMenuDynamic = await loadReportMenuDynamic(hasFinished === true);
                await InscriptionsMenuReportDynamicPDF(event, reportMenuDynamic);
            }
            hideLoader();
        }
    }
    const handleSendReminder = () => {
        if (!!event) {
            setShowConfirmationReminderModal(true);
        }

    }
    const handleSendFeedback = () => {
        if (!!event && canSendFeedbackLink) {
            setShowConfirmationFeedbackModal(true);
        } else {
           setShowErrorModal(true);
        }

    }
    const onConfirmSendReminder = async (confirm: boolean) => {
        setShowConfirmationReminderModal(false);
        if (confirm) {
            sendReminder();
        }
    }
    const onConfirmSendFeedback = async (confirm: boolean) => {
        setShowConfirmationFeedbackModal(false);
        if (confirm) {
            sendFeedback();
        }
    }
    const sendFeedback = async () => {
        try {
            showLoader();
            if (!!event && !!event._id) {
                const email = await sendEventFeedback(event._id);
                if (!!email) {
                    setSuccessMessage(i18n.t('event.success-email-feedback'));
                    setShowSuccessModal(true);
                } else {
                    setErrorModalMessage(t('event.error-email-feedback'));
                    setShowErrorModal(true);
                }
            }
        }
        catch (e) {
            setErrorModalMessage(t('event.error-email-feeedback'));
            setShowErrorModal(true);
        }
        finally {
            hideLoader();
        }

    }
    const sendReminder = async () => {
        try {
            showLoader();
            if (!!event && !!event._id) {
                const email = await sendEventReminder(event._id);
                if (!!email) {
                    setSuccessMessage(i18n.t('event.success-email-reminder'));
                    setShowSuccessModal(true);
                } else {
                    setErrorModalMessage(t('event.error-email-reminder'));
                    setShowErrorModal(true);
                }
            }
        }
        catch (e) {
            setErrorModalMessage(t('event.error-email-reminder'));
            setShowErrorModal(true);
        }
        finally {
            hideLoader();
        }

    }
    const handleCloseDeleteModal = async (e: any) => {
        setShowDeleteModal(false);
        if (!!e) {
            if (!!event && !!event._id) {
                // await dispatch(deleteEvent({ id: eventId }));
                // history.push('/events');
                showLoader();
                const deletedObj: any = await dispatch(updateEvent({
                    _id: event._id,
                    lastUpdatedBy: loggedUser._id,
                    lastUpdatedDT: new Date(),
                    deletedBy: loggedUser._id,
                    deletedDT: new Date()
                }));
                hideLoader();

                if (deletedObj && deletedObj['payload']) {
                    if (deletedObj['payload']['removed'] == true || deletedObj['payload']['removed'] == 'true') {
                        if (deletedObj['payload']['inscriptions'] == true || deletedObj['payload']['inscriptions'] == 'true') {
                            setSuccessMessage(i18n.t('event.success-notifications-with-inscriptions'));
                            setGoToEvents(true);
                            setShowSuccessModal(true);
                        } else {
                            setGoToEvents(true);
                            setSuccessMessage(i18n.t('event.success-delete'));
                            setShowSuccessModal(true);
                        }
                    } else {
                        setErrorModalMessage(t('event.error-delete'));
                        setShowErrorModal(true);
                    }
                }
            }
        }

    };
    const handleCloseSuccessModal = (e: any) => {
        if (!!e) {
            if (goToEvents) {
                if (event?.status === 'PUBLISHED') {
                    history.push('/events');
                } else {
                    history.push('/draft-events');
                }
            }
        }
        setShowSuccessModal(false);
    };

    const handleOnExportFeedbackQuestions = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            if (!!event && !!event._id) {
                const response = await getInscriptionsFeedbackAnswers(event._id);
                if (response && response.message && response.statusCode == 418) {
                    setErrorModalMessage(t(response.message));
                    setShowErrorModal(true);
                } else {
                    if (!!response && response.report.length > 0) {
                        exportAnswersToExcel(response, event, 'FEEDBACK');
                    } else {
                        setErrorModalMessage(t('event.error-report-question-feedback'));
                        setShowErrorModal(true);
                    }
                }
            }
            hideLoader();
        }
    }

    const handleOnExportQuestions = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            if (!!event && !!event._id) {
                const response = await getInscriptionsAnswers(event._id);
                if (response && response.message && response.statusCode == 418) {
                    setErrorModalMessage(t(response.message));
                    setShowErrorModal(true);
                } else {
                    if (!!response && response.report.length > 0) {
                        exportAnswersToExcel(response, event, 'QUESTIONS');
                    } else {
                        setErrorModalMessage(t('event.error-report-question'));
                        setShowErrorModal(true);
                    }
                }
            }
            hideLoader();
        }
    }

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    const getLastUpdated = (event: any) : string =>{
        if (!event || !event.lastUpdatedDT){
            return '';
        }
        const result = DateHelper.timeAgo(event.lastUpdatedDT);
        if (!result){
            return '';
        }
        return i18n.t('last-update')+ ' '+ i18n.t( result.word, {'value': result.val});// + ' ' +i18n.t('by')+ ' '+ event.lastUpdatedBy?.name + ''+(event.lastUpdatedBy?.surname || '');
    }

    const goToInscriptionTab = (e: any, inscriptionStatus: string) => {
		e.stopPropagation();
		e.preventDefault();
        if(!event || !event.hosts) return;

        if (!loggedUserIsHost && (!editable || eventStatus == 'NOW')) {
            history.push(
                '/events/attendance/' +
                props.eventId +
                '/NOT_ATTENDEED'
                //'/' + inscriptionStatus
            );
        } else {
            if (
                (loggedUserIsHost || editable) &&
                eventStatus != 'NOW' &&
                eventStatus != 'ENDED'
            ) {
                if (event.eventType === 'PRIVATE_EVENT') {
                    history.push('/event-manager/' + props.eventId+ '/' + inscriptionStatus);
                } else {
                    history.push('/event-manager/' + props.eventId + '/' + 'ACCEPTED');
                }
            } else {
                    history.push('/event-manager/' + props.eventId);
            }
        }
	}

    const getDeleteMessage = (event: any) => {
		return event.status === 'DRAFT' ? t('event.delete-message-draft') : (event.inscriptions && event.inscriptions.length > 0 ? t('event.delete-message-with-inscriptions') : t('event.delete-message'))
	}

    return (
        <>
        {
            (isDirty && showConfirmationDiscardChanges) && (
                <CustomModal 
                    message={t('event.unsaved-changes')}
                        buttonOK={t('Continue')} 
                    buttonCancel={t('cancel')} 
                    isShowing={showConfirmationDiscardChanges} 
                    type='WARNING_EDIT' 
                    parentCallback={(proceed: boolean)=>{

                        setShowConfirmationDiscardChanges(false);

                        if(proceed) {
                            setDiscradChanges(true);
                        }else{
                            setNextLocation(null);
                        }
                    }}/>
            )
        }
            {loader}
            {(!isLoggedUser) && <Redirect to="/login" />}
            {event && event.deletedBy && <Redirect to="/events" />}

            {isLoggedUser &&
                !!loggedUser && 
                <div className="dashboard-content form event-steps p-0" >
                    <div className='d-flex flex-column col-12 align-items-center justify-content-start p-0'>

                        {
                            <div className='container-box' >
                                <div className='container-box-content d-flex justify-content-between align-items-center'>
                                <div className='d-flex '>
                                    <div
                                        className="button-back mr-2 "
                                        onClick={(e: any) => handleOnGoBack(e)}
                                    >
                                        <IoArrowBackOutline />
                                    </div>
                                    <div className='ml-4 mr-4'>
                                        <div className='banner-title d-flex align-items-center'><p className='one-line'>{!!event ? event.name : ''}</p>{!!eventStatus && <span className={'status-tag event ml-2 '+eventStatus}>{t('event.status.'+eventStatus)}</span>}</div>
                                        <div className='banner-subtitle'>{!!event && event.lastUpdatedDT ? getLastUpdated(event) : ''}</div>
                                    </div>

                                </div>
                                <div className='d-flex row align-items-center justify-content-end'>
                                    {!!event && editable && !isPublished && <Button className='d-none d-md-flex rounded-button rounded-button-ok outline delete mr-md-2'
                                    onClick={(e: any) => setShowDeleteModal(true)}>
                                        <BsTrashFill />
                                    </Button>}
                                    {!isPublished && !!event && eventStatus === "DRAFT" && <div className="d-none d-md-flex justify-content-center justify-content-md-end align-items-center">
                                        <Button
                                            disabled={!allStepsComplete || isDirty} onClick={handlePublishEvent}
                                            className="button button-primary medium w-100"
                                        >
                                            {t('event.publish')}
                                        </Button>
                                    </div>}
                                    {!!event && (isPublished || eventStatus ==="ENDED") &&
                                        <Dropdown>
                                            <Dropdown.Toggle bsPrefix="event-edit-menu" className="only-icon black">
                                                <IoMdMore size={28} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>

                                                {!hasFinished && eventStatus !== "NOW" && <Dropdown.Item onClick={(e: any) => setShowCopyLinkModal(true)}>{t('event.inscription-link')}</Dropdown.Item>}
                                                {!hasFinished && eventStatus !== "DRAFT" && (loggedUserIsPlanner || loggedUserIsHost || editable) && <Dropdown.Item onClick={(e) => goToInscriptionTab(e, 'PENDING')}>{t('event.manage-inscriptions')}</Dropdown.Item>}
                                                {event.includeMenu && <Dropdown.Item onClick={(e: any) => handleOnMenuReport()}>{t('event.edit-print-menu')}</Dropdown.Item>}
                                                {event.hasSectors === 'yes' && SHOW_SECTORS && <Dropdown.Item onClick={(e: any) => handleOnReportSectors()}>{t('event.sector.print')}</Dropdown.Item>}
                                                <Dropdown.Item onClick={(e: any) => handleOnExport()}>{t('event.export-list')}</Dropdown.Item>
                                                {((event.hasQuestions === 'yes' || (!!event.questions?.length && event.questions?.length > 0)) && SHOW_QUESTIONS) && <Dropdown.Item onClick={(e: any) => handleOnExportQuestions()}>{t('event.question.export-answers')}</Dropdown.Item>}
                                               
                                                {!!event.inscriptions && !hasFinished && event.inscriptions.filter((x: any) => x.inscriptionStatus === 'ACCEPTED').length > 0 && <Dropdown.Item onClick={(e: any) => handleSendReminder()}>{t('event.send-reminder')}</Dropdown.Item>}
                                                {(event.hasFeedbackQuestions === 'yes' || (!!event.feedbackQuestions?.length && event.feedbackQuestions?.length > 0)) && hasFinished && SHOW_QUESTIONS && <Dropdown.Item onClick={(e: any) => handleOnExportFeedbackQuestions()}>{t('event.question.export-feedback')}</Dropdown.Item>}
                                                { event.hasFeedbackQuestions === 'yes' && canSendFeedbackLink && <Dropdown.Item onClick={(e: any) => handleSendFeedback()}>{t('event.send-feedback-link')}</Dropdown.Item>}
                                                {editable && <Dropdown.Item onClick={(e: any) => setShowDeleteModal(true)}>{t('event.delete')}</Dropdown.Item>}

                                            </Dropdown.Menu>
                                        </Dropdown>}


                                </div>
                                </div>

                            </div>
                        }
                         {(totalProgress !== 100 && event?.status === 'DRAFT') &&
                            <div className='w-100 m-0 progress-bar-container'>
                                <div style={{ width: totalProgress + '%', height: '4px' }} className='progress-bar'></div>
                            </div>
                        }
                        <div id="published-banner"></div>
                        <div className="d-flex row pl-0 pr-0 pb-5 col-12 event-content d-flex justify-content-start align-items-start">
                            {/* menu for desktop */}
                            {selectedStep !== "SUMMARY" && <div className="d-none d-md-block steps-menu">
                                {!!steps && steps.map((step: any, i: number) => (
                                    <StepStatus
                                        key={i}
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={step}
                                        callback={goToStep}
                                        desktop
                                    ></StepStatus>))
                                }
                            </div>}
                            <div className={`step-body col col-xl-8 ${selectedStep}`}>
                                {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-info">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[0]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'INFO' &&
                                    <StepInformation
                                        selected={selectedStep === 'INFO'}
                                        percentComplete={event?.steps?.find(x => x.name === "INFO")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={setIsDirty}
                                    ></StepInformation>}
                                 {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-participants">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[1]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'PARTICIPANTS' &&
                                    <StepParticipants
                                        selected={selectedStep === 'PARTICIPANTS'}
                                        percentComplete={event?.steps?.find(x => x.name === "IMAGES")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={setIsDirty}
                                    ></StepParticipants>
                                }
                                {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-inscriptions">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[2]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'INSCRIPTIONS' &&
                                    <StepInscriptions
                                        selected={selectedStep === 'INSCRIPTIONS'}
                                        percentComplete={event?.steps?.find(x => x.name === "INSCRIPTIONS")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={setIsDirty}
                                    ></StepInscriptions>
                                }
                                 {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-images">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[3]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'IMAGES' && !!event &&
                                    <StepImages
                                        selected={selectedStep === 'IMAGES'}
                                        percentComplete={event?.steps?.find(x => x.name === "IMAGES")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={setIsDirty}
                                    ></StepImages>
                                }
                                {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-questions">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[4]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'QUESTIONS' &&
                                    <StepQuestions
                                        selected={selectedStep === 'QUESTIONS'}
                                        percentComplete={event?.steps?.find(x => x.name === "QUESTIONS")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={setIsDirty}
                                    ></StepQuestions>
                                }
                                {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-sectors">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[5]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'SECTORS' &&
                                    <StepSectors
                                        selected={selectedStep === 'SECTORS'}
                                        percentComplete={event?.steps?.find(x => x.name === "SECTORS")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={setIsDirty}
                                    ></StepSectors>
                                }
                                {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-feedback">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[6]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'FEEDBACK' &&
                                    <StepFeedback
                                        selected={selectedStep === 'FEEDBACK'}
                                        percentComplete={event?.steps?.find(x => x.name === "FEEDBACK")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={setIsDirty}
                                    ></StepFeedback>
                                }
                                {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-reminder">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[7]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'REMINDER' &&
                                    <StepReminder
                                        selected={selectedStep === 'REMINDER'}
                                        percentComplete={event?.steps?.find(x => x.name === "REMINDER")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={setIsDirty}
                                    />
                                }
                                {selectedStep !== "SUMMARY" && <div className='d-md-none' id="step-summary">
                                    <StepStatus
                                        event={event}
                                        selectedStep={selectedStep}
                                        step={steps[8]}
                                        callback={goToStep}
                                    ></StepStatus>
                                </div>}
                                {selectedStep === 'SUMMARY' &&
                                    <StepSummary
                                        selected={selectedStep === 'SUMMARY'}
                                        percentComplete={event?.steps?.find(x => x.name === "SUMMARY")?.percentComplete || 0}
                                        loggedUser={loggedUser}
                                        event={event}
                                        setEvent={setEvent}
                                        organizer={organizer}
                                        organizers={organizers}
                                        saveAndGoToStep={saveAndGoToStep}
                                        editable={editable}
                                        hasFinished={hasFinished}
                                        notifyIsDirty={null}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }
            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}
            {showLinkCopiedModal && (
                <CustomModal
                    isShowing={showLinkCopiedModal}
                    parentCallback={handleCloseLinkCopiedModal}
                    message={t('link-copied-success-message')}
                    title={t('link-copied-success-title')}
                    type="SUCCESS"
                    buttonOK={t('accept')}
                />
            )}
            {showConfirmationReminderModal && (
                <CustomModal
                    buttonCancel={t('confirmation.no')}
                    buttonOK={t('confirmation.yes')}
                    isShowing={showConfirmationReminderModal}
                    title={t('confirm')}
                    message={t('event.send-reminder-confirmation')}
                    parentCallback={onConfirmSendReminder}
                    type="WARNING_EDIT"
                ></CustomModal>
            )}
            {showConfirmationFeedbackModal && (
                <CustomModal
                    buttonCancel={t('confirmation.no')}
                    buttonOK={t('confirmation.yes')}
                    isShowing={showConfirmationFeedbackModal}
                    title={t('confirm')}
                    message={t('event.send-feedback-confirmation')}
                    parentCallback={onConfirmSendFeedback}
                    type="WARNING_EDIT"
                ></CustomModal>
            )}
            {!!event && showDeleteModal && (
                <CustomModal
                    isShowing={showDeleteModal}
                    parentCallback={handleCloseDeleteModal}
                    message={getDeleteMessage(event)}
                    title={t('event.delete-title')}
                    buttonCancel={t('cancel')}
                    buttonOK={t('delete')}
                    type="WARNING_DELETE"
                />
            )}
            {showSuccessModal && (
                <CustomModal
                    isShowing={showSuccessModal}
                    parentCallback={handleCloseSuccessModal}
                    title={t('success.modal-title')}
                    message={successMessage}
                    type="SUCCESS"
                    buttonOK={t('accept')}
                />
            )}
            {showCopyLinkModal && event &&
                <InscriptionLinkModal
                    event={event}
                    onHide={(e: boolean) => { setShowCopyLinkModal(false); setShowLinkCopiedModal(e); }}
                    visible={showCopyLinkModal}
                ></InscriptionLinkModal>}
            {showSuccessModalPublish && (
                    <CustomModal
                        title={t('success.event-publish')}
                        message={t('success.event-create-copy-link')}
                        isShowing={showSuccessModalPublish}
                        parentCallback={handleCloseSuccessModalPublish}
                        type={'SUCCESS'}
                        buttonOK={t('copy-inscription-link')}
                        buttonCancel={t('close')}
                    />
                )}
        </>
    );
}

export default PrivateEventForm;