import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../components/modals/customModal';
import { Dropzone } from '../../components/dropzone/dropzone';
import useUsersApi from '../../hooks/useUsersApi';
import useApi from '../../hooks/useApi';
import { IoIosSave, IoIosArrowBack } from 'react-icons/io';
import { DNI_MAX_LENGTH, genderOptions, IDENTIFICATION_TYPES, NAME_MAX_LENGTH, SHOW_PUBLIC_EVENTS, SURNAME_MAX_LENGTH } from '../../../constants';
import useLoader from '../../components/loader/useLoader';
import { convertToBase64 } from '../../helpers/image-base64';
import { TGender, TGroup, TIdentificationType } from '../../models/types/types';
import { IUser } from '../../models/interfaces/users.interface';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Validator } from '../../helpers/validators';
import { DateHelper } from '../../helpers/date-helper';
import CustomDatePicker from '../../components/custom-date-picker/custom-date-picker';
import useImageUpload from '../../hooks/useImageUpload';
import { CLOUDINARY_CLOUD_NAME, UPLOAD_TYPES } from '../../../constants';
import { IoArrowBackOutline } from 'react-icons/io5';
export function NewUser() {
	const lang: string = localStorage.getItem("i18nextLng") || 'en';
	const [loader, showLoader, hideLoader] = useLoader();
	const { t, i18n } = useTranslation();

	const [, createUser, updateUser] = useUsersApi();
	const [
		loggedUser,
		,
		,
		,
		,
		getCreatableRoles,
		,
		,
		,
		getOrganizer,
		,
		getAllOrganizers,
	] = useApi();
	const [
		uploadImageCloud
	] = useImageUpload();
	const [roles, setRoles] = useState<any[] | undefined>(undefined);
	const [organizers, setOrganizers] = useState<any[] | undefined>(undefined);
	const [organizer, setOrganizer] = useState<any>(undefined);
	const [name, setName] = useState(undefined);
	const [email, setEmail] = useState(undefined);
	const [isInvalidEmail, setIsInvalidEmail] = useState(false);
	const EMAIL_REGEX = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
	const [surname, setSurname] = useState<any>(undefined);
	const [role, setRole] = useState<any>(undefined);
	const [gender, setGender] = useState<TGender | undefined>(undefined);
	const [dob, setDOB] = useState<Date>();
	const [avatar, setAvatar] = useState<any>(undefined);
	const [generatedId, setId] = useState<any>('');
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [strError, setStrError] = useState('');
	const [showPersonInfo, setShowPersonInfo] = useState(false);
	const [showOrganizerInfo, setShowOrganizerInfo] = useState(false);
	const [existingRolesOrganizers, setExistingRolesOrganizers] = useState([]);
	const [existingUserId, setExistingUserId] = useState<any>(undefined);
	const [identificationNumber, setIdentificationNumber] = useState<string | undefined>(undefined);
	const [identificationType, setIdentificationType] = useState<TIdentificationType | undefined>(undefined);
	const [mpAccessToken, setMpAccessToken] = useState<string | undefined>(undefined);
	const [mpPublicKey, setMpPublicKey] = useState<string | undefined>(undefined);

	const [validated, setValidated] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');

	const today = new Date();
	let minDate = new Date();
	minDate.setFullYear(today.getFullYear() - 110);
	const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const getOptions = (arr: any[]) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label: x['name'] + (x['surname'] ? ' ' + x['surname'] : "") + ' (' + x['email'] + ')',
					value: x['_id'],
				};
				options.push(opt);
			});
		}
		return options;
	};
	const rolesOptions = getSelectSingleOptions(roles, 'role.');
	const _genderOptions = getSelectSingleOptions(genderOptions, 'gender.');
	const identificationTypesOptions = getSelectSingleOptions(IDENTIFICATION_TYPES, 'identification-type.');
	useEffect(() => {
		handleEffect();
	}, [loggedUser]);

	const handleEffect = async () => {
		showLoader();
		if (loggedUser && isLoggedUser && !roles && !organizers) {
			if (!roles) {
				showLoader();
				const rs = await getCreatableRoles();
				setRoles(rs);
				if (rs && rs.length > 0 && !role) {
					const r = rs[0];
					setRole(r);
					let isOrganizer = r && r.level == 16;
					setShowPersonInfo(r && !isOrganizer);
					setShowOrganizerInfo(r && isOrganizer);
					if (isOrganizer) {
						setSurname(null);
					}
					hideLoader();
				}
			}

			if (!organizers) {
				showLoader();
				const org = await getAllOrganizers();
				if (org && org.length > 0) {
					setOrganizers(org);
					setOrganizer(org[0]); //todo
					hideLoader();
				}
			}
		}
		hideLoader();
	};
	const handleOnSelectOrganizer = (event: any) => {
		const r = !!organizers ? organizers.find((x) => x._id == event.value) : null;
		setOrganizer(r);
	};
	const handleOnSelectRole = (event: any) => {
		const r = !!roles ? roles.find((x) => x._id == event.value) : null;
		setRole(r);
		let isOrganizer = r && r.level == 16;
		setShowPersonInfo(r && !isOrganizer);
		setShowOrganizerInfo(r && isOrganizer);
		if (isOrganizer) {
			setSurname(null);
			setDOB(undefined);
			setGender(undefined);
		}
	};
	const handleOnCreateName = (event: any) => {
		setName(event.target.value);
	};

	const handleOnCreateSurname = (event: any) => {
		setSurname(event.target.value);
	};

	const handleOnCreateMpAccessToken = (event: any) => {
		setMpAccessToken(event.target.value);
	};

	const handleOnCreateMpPublicKey = (event: any) => {
		setMpPublicKey(event.target.value);
	};

	const handleOnCreateEmail = (event: any) => {
		setEmail(event.target.value);
		checkIsInvalidEmail(event.target.value);
	};

	const checkIsInvalidEmail = (pEmail: any) => {
		setIsInvalidEmail(!EMAIL_REGEX.test(pEmail))
	};
	const handleOnCreateGender = (event: any) => {
		const g = genderOptions.find((x) => x.name == event.name);
		if (g && g.name) {
			setGender(g.name);
		} else {
			setGender(undefined);
		}
	};
	const handleOnCreateDOB = (_date: Date) => {
		setDOB(_date);
	};
	const handleOnCreateIdentificationNumber = (event: any) => {
		setIdentificationNumber(event.target.value);
	};
	const handleOnIdentificationTypeChange = (e: any) => {
		const g = identificationTypesOptions.find((x: any) => x.name === e.name);
		if (!!g && g.name) {
			setIdentificationType(g.name);
		} else {
			setIdentificationType(undefined);
		}
	};
	const handleCloseModal = (e: any) => {
		if (e && strError == '' && generatedId) {
			history.push('/users');
		} else {
			setValidated(false);
			setShowErrorModal(false);
		}
	};
	const updateExistingUser = async () => {
		const _roleOrganizer: any = getRoleOrganizer();

		let rolesOrganizers: any[] = existingRolesOrganizers;
		let roleOrganizer: any = rolesOrganizers.find(
			(x: any) => x.organizerId == _roleOrganizer.organizerId
		);

		// Check if the user already exists for the current organizer
		if (!roleOrganizer || roleOrganizer.length == 0) {
			rolesOrganizers.push(_roleOrganizer);
			// If not exists for the current organizer, add GUEST to the organizer
			const updatedUser: any = await dispatch(
				updateUser({
					_id: existingUserId,
					rolesOrganizers: rolesOrganizers,
					lastUpdatedBy: loggedUser._id,
					lastUpdatedDT: new Date(),
				})
			);
			if (
				!updatedUser ||
				!updatedUser['payload'] ||
				!updatedUser['payload']['_id']
			) {
				return false;
			} else {
				return true;
			}
		}
		return true;
	};
	const handleCloseSuccessModal = () => {
		history.push('/users');
	}
	const handleCloseConfirmationModal = async (e: any) => {
		setShowConfirmationModal(false);
		if (e) {
			showLoader();
			const updatedUser = await updateExistingUser();
			if (updatedUser) {

				setShowSuccessModal(true);
			}
			hideLoader();
		}
	}
	const getRoleOrganizer = () => {
		let defaultOrganizer = getOrganizer();
		let rolesOrganizers = {
			roleId: role._id,
			roleName: role.name,
			roleLevel: role.level,
			organizer:
				defaultOrganizer != '0' && defaultOrganizer.name
					? defaultOrganizer.name
					: organizer && role.name != 'USER_ORGANIZER'
						? organizer.name
						: name,
			organizerId:
				organizer && role.name != 'USER_ORGANIZER'
					? organizer._id
					: defaultOrganizer != '0'
						? defaultOrganizer
						: null,
		};
		return rolesOrganizers;
	}
	const validateAll = (form: any) => {
		return !!form && form.checkValidity()
			&& (showPersonInfo ? !Validator.name(surname) : true)
			&& (showPersonInfo ? !Validator.name(name) : true)
	}
	const handleOnSaveChanges = async (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;
		if (validateAll(form)) {
			showLoader();
			// Para crear un usuario debajo de organizer, para que lo asocie a un organizer, de momento lo tengo que crear con un usuario organizer
			// A futuro si soy admin tengo que permitir seleccionar un organizer de los cargados en el sistema, para crear un user por debajo de el (planner, admision, host)
			const rolesOrganizers = getRoleOrganizer();
			let uploadedImageUrl = undefined;
			if (avatar) {
				const uploadedImage = await uploadImageCloud(avatar, UPLOAD_TYPES[1].name);
				if (uploadedImage && uploadedImage.data) {
					uploadedImageUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage.data.public_id}.jpg`; //Aca podria ser sino public_id y solo guardar esos ids
				}
			}
			console.log("uploaded " + uploadedImageUrl)

			const group: TGroup | undefined = dob
				? DateHelper.getGroup(dob)
				: role.name != 'USER_ORGANIZER'
					? 'ADULT'
					: undefined;

			const usr: IUser = {
				name: name,
				surname: surname,
				email: email,
				//role: role,
				rolesOrganizers: [rolesOrganizers],
				gender: gender,
				dob: DateHelper.saveDateWithoutTZ(dob),
				group: group,
				status: 'INACTIVE',
				avatar: uploadedImageUrl,
				createdBy: loggedUser._id,
				createdDT: new Date(),
				lastUpdatedBy: loggedUser._id,
				lastUpdatedDT: new Date(),
				identificationNumber: identificationNumber,
				identificationType: identificationType,
				mpAccessToken: mpAccessToken,
				mpPublicKey: mpPublicKey
			};
			try {
				const response: any = await dispatch(createUser(usr));

				if (response['payload'] && response['payload']['_id']) {
					setStrError('');
					setExistingUserId(null)
					setId(response['payload']['_id']);
					hideLoader();
					setShowSuccessModal(true);
				} else {
					hideLoader();
					// Ya existe el usuario, pero para otro Organizer
					if (response['payload'] && response['payload']['statusCode'] == 418 && response['payload']['message'] && response['payload']['message'].length > 0) {
						if (role.name != 'USER_ORGANIZER') {
							setStrError('');
							setId(null);
							setExistingRolesOrganizers(response['payload']['message']);
							setExistingUserId(response['payload']['error']);
							setShowConfirmationModal(true);
						} else {
							setStrError(t('error.user.alreadyRegistered'));
							setShowErrorModal(true);
						}

					} else {
						if (response['payload'] && response['payload']['message']) {
							setStrError(
								t('error.' + response['payload']['message'])
							);
							setShowErrorModal(true);
						} else if (
							response['error'] &&
							response['error']['message']
						) {
							if (response['error']['message'].indexOf('409') >= 0) {
								setStrError(t('error.user.alreadyRegistered'));
								setShowErrorModal(true);
							} else {
								setStrError(t('error.unexpected'));
								setShowErrorModal(true);
							}
						}
					}

				}
			} catch (e) {
				hideLoader();
				setStrError(t('error.unexpected'));
				setShowErrorModal(true);
			}
		}
		setValidated(true);
	};

	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		history.goBack();
	};


	const handleChangeDropzone = async (e: any) => {
		if (e != null && e.length > 0) {
			showLoader();
			//const base64: any = await convertToBase64(e[0]);
			setAvatar(e[0]);

			hideLoader();
		} else {
			setAvatar(null);

		}
	};
	return (
		<>
			{loader}
			{(!isLoggedUser) && <Redirect to="/login" />}
			{roles && (
				<div className="dashboard-content form">
					<div className='container-box'>
						<div className='container-box-content d-flex justify-content-between align-items-center'>
							<div className='d-flex '>
								<div
									className="button-back mr-2"
									onClick={(e: any) => handleOnCancel(e)}
								>
									<IoArrowBackOutline />
								</div>
								<div className='ml-4 mr-4'>
									<div className='banner-title d-flex align-items-center'>
										<p className='one-line'>
											{`${t('user.create')}`}
										</p>
									</div>
								</div>
							</div>
							<div className='d-flex row align-items-center justify-content-end'>
								<Button
									className="rounded-button rounded-button-ok outline btn btn-primary"
									type="submit"
									onClick={handleOnSaveChanges}
								>
									<IoIosSave></IoIosSave>
								</Button>
							</div>
						</div>
					</div>
					<Form
						noValidate
						validated={validated}
						className="form-container col-xl-8 mt-x-5"
						onSubmit={handleOnSaveChanges}
					>
						<div className="form-info-container d-flex row pb-5">
							<div className="col-md-4 order-md-2">
								<Dropzone
									className="dropzone"
									onChangeStatus={handleChangeDropzone}
									setErrorModalMessage={setStrError}
									setShowErrorModal={setShowErrorModal}
								/>
							</div>
							<div className="col-md-8 order-md-1">
								{/* Role */}
								<Form.Group controlId="formRole">
									<Form.Label className="input-label">{`${t(
										'user.role'
									)}*`}</Form.Label>
									<Select
										className={validated && !!role ? "select-control valid" : "select-control"}
										placeholder={t('select')}
										options={rolesOptions}
										value={rolesOptions.find(x => x._id === role)}
										onChange={handleOnSelectRole}
									/>

								</Form.Group>
								{organizers &&
									organizers.length > 1 &&
									showPersonInfo && (
										<Form.Group controlId="formOrganizers">
											<Form.Label className="input-label">{`${t(
												'role.USER_ORGANIZER'
											)}`}</Form.Label>
											<Select
												className={
													(validated && !!organizer)
														? 'select-control valid'
														: 'select-control'
												}
												placeholder={t('select')}
												options={getOptions(organizers)}
												value={getOptions(organizers)?.find(x => x.value == organizer._id)}
												onChange={handleOnSelectOrganizer}

											/>
										</Form.Group>
									)}
								{/* Name */}
								<Form.Group controlId="formName">
									<Form.Label className="input-label">{`${t(
										'user.name'
									)}*`}</Form.Label>
									<Form.Control
										type="text"
										name="name"
										placeholder={t('user.name')}
										onChange={handleOnCreateName}
										required
										isInvalid={validated && (showPersonInfo ? Validator.name(name) : false)}
										maxLength={NAME_MAX_LENGTH}
									/>
									{validated && <Form.Control.Feedback type="invalid">
										{!name && `${t('error.required')}`}
										{!!name && `${t('error.invalid')}`}
									</Form.Control.Feedback>}
								</Form.Group>

								{/* LastName */}
								{showPersonInfo && (
									<Form.Group controlId="formSurname">
										<Form.Label className="input-label">{`${t(
											'user.lastname'
										)}*`}</Form.Label>
										<Form.Control
											required={showPersonInfo}
											type="text"
											name="surname"
											placeholder={t('user.lastname')}
											onChange={handleOnCreateSurname}
											maxLength={SURNAME_MAX_LENGTH}
											isInvalid={validated && Validator.name(surname)}
										/>
										{validated && <Form.Control.Feedback type="invalid">
											{!surname && `${t('error.required')}`}
											{!!surname && `${t('error.invalid')}`}
										</Form.Control.Feedback>}
									</Form.Group>
								)}

								{/* email */}
								<Form.Group>
									<Form.Label className="input-label">{`${t(
										'user.email'
									)}*`}</Form.Label>
									<Form.Control
										type="email"
										name="email"
										required
										placeholder={t('user.email')}
										onChange={handleOnCreateEmail}
										isInvalid={isInvalidEmail}
									/>
									<Form.Control.Feedback type="invalid">
										{email && `${t('error.email-format')}`}
										{!email &&
											`${t('error.email-required')}`}
									</Form.Control.Feedback>
								</Form.Group>
								{showPersonInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">{`${t(
													'identification-type-label'
												)}`}</Form.Label>

												<Select
													isInvalid={validated && !identificationType}
													className={validated && !!identificationType ? "select-control valid" : "select-control"}
													placeholder={t('select')}
													options={identificationTypesOptions}
													onChange={handleOnIdentificationTypeChange}
												/>
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('identification-number')}`}
												</Form.Label>
												<Form.Control
													autoComplete='none'
													maxLength={DNI_MAX_LENGTH}
													isValid= {validated && !!identificationNumber && !Validator.identificationNumber(identificationNumber)}
													isInvalid={validated && !!identificationNumber && Validator.identificationNumber(identificationNumber)}
													type="text"
													name="identificationNumber"
													placeholder={t('identification-placeholder')}
													onChange={handleOnCreateIdentificationNumber}
												/>
												{validated && <Form.Control.Feedback type="invalid">
													{!!identificationNumber && Validator.identificationNumber(identificationNumber) && `${t('error.invalid')}`}
												</Form.Control.Feedback>}
											</div>
										</div>
									</Form.Group>
								)}
								{/* Gender an DOB */}
								{showPersonInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">{`${t(
													'user.gender'
												)}`}</Form.Label>

												<Select
													isInvalid={validated && !gender}
													className={validated && !!gender ? "select-control valid" : "select-control"}
													placeholder={t('select')}
													options={_genderOptions}
													onChange={handleOnCreateGender}
												/>
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('user.dob')}`}
												</Form.Label>
												{/* <DatePicker
													locale={lang}
													dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
													required
													className='form-control date'
													selected={dob}
													maxDate={today}
													minDate={minDate}
													onChange={handleOnCreateDOB}
												></DatePicker> */}
												<CustomDatePicker
													isInvalid={validated && !dob}
													isValid={validated && !!dob}
													maxDate={today}
													minDate={minDate}
													selected={dob}
													optional={true}
													onCustomDateInputChange={handleOnCreateDOB}></CustomDatePicker>
											</div>
										</div>
									</Form.Group>
								)}

								{/* Mercado Pago keys */}
								{SHOW_PUBLIC_EVENTS && showOrganizerInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-12">
												<Form.Label className="input-label">{`${t(
													'user.mpAccessToken'
												)}`}</Form.Label>

												<Form.Control

													type="text"
													name="mpAccessToken"
													placeholder={t('user.mpAccessToken')}
													onChange={handleOnCreateMpAccessToken}
												/>
											</div>
											<div className="col-md-12">
												<Form.Label className="input-label">
													{`${t('user.mpPublicKey')}`}
												</Form.Label>
												<Form.Control
													type="text"
													name="mpPublicKey"
													placeholder={t('user.mpPublicKey')}
													onChange={handleOnCreateMpPublicKey}
												/>
											</div>
										</div>
									</Form.Group>
								)}
							</div>
						</div>
					</Form>
					{showConfirmationModal && (
						<CustomModal
							buttonCancel={t('cancel')}
							buttonOK={t('confirm')}
							isShowing={showConfirmationModal}
							title={t('user.existent')}
							message={t('user.link', { email: email })}
							parentCallback={handleCloseConfirmationModal}
							type="WARNING_EDIT"
						></CustomModal>
					)}
					{showErrorModal && (
						<CustomModal
							title={t('error.modal-title-oops')}
							message={
								strError != ''
									? strError

									: t('error.user-create')
							}
							isShowing={showErrorModal}
							parentCallback={handleCloseModal}
							type={'ERROR'}
							buttonOK={t('accept')}
						/>
					)}
					{showSuccessModal && (
						<CustomModal
							title={
								generatedId
									? t('success.user-create')
									: t('success.user-update')
							}
							isShowing={showSuccessModal}
							parentCallback={handleCloseSuccessModal}
							type={'SUCCESS'}
							buttonOK={t('accept')}
						/>
					)}
				</div>
			)}
		</>
	);
}

export default NewUser;
