import React, { useState, useEffect, useMemo } from 'react';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { TEventType } from '../../models/types/types';
import { SHOW_NEW_VIEW } from '../../../constants';
import { useHistory } from 'react-router-dom';
export function SelectTypeEvent() {
	const history = useHistory();
	const [eventType, setEventType] = useState<TEventType>();

	const { t, i18n } = useTranslation();
	const goTo = (isPublic: boolean) => {
		const url = isPublic ? (SHOW_NEW_VIEW ? '/events/public/new-draft' : "/events/public/new") : (SHOW_NEW_VIEW ? '/events/private/new-draft' : "/events/private/new");
		history.push(url);
	}
	return (
		<>
			<div className="dashboard-content form">
				<div className="form-container col-xl-8">
					<div className="d-flex justify-content-between align-items-center mb-3">
						<div className="d-flex justify-content-start page-title align-items-center">
							{`${t('event.type.SELECT')}`}
						</div>
					</div>
					<div className="form-info-container d-flex pb-5">
						<div className='d-flex m-0 p-0 flex-column'>
							<div className='event-type-card d-flex flex-column flex-md-row align-items-center justify-content-between cursor-pointer' onClick={() => goTo(false)}>
								<div className='col'>
									<div className='event-type-title'>{`${t('event.type.PRIVATE_EVENT')}`}</div>
									<div className='event-type-text' dangerouslySetInnerHTML={{ __html: `${t('event.type.DESC_PRIVATE')}` }}>
									</div>
								</div>
								<div className=''>
									<Button className="button-primary" onClick={() => goTo(false)}>
										{t('event.create-private')}
									</Button>
								</div>
							</div>
							<div className='mt-3 event-type-card d-flex flex-column flex-md-row align-items-center justify-content-between cursor-pointer' onClick={() => goTo(true)}>
								<div className='col'>
									<div className='event-type-title'>{`${t('event.type.PUBLIC_EVENT')}`}</div>
									<div className='event-type-text' dangerouslySetInnerHTML={{ __html: `${t('event.type.DESC_PUBLIC')}` }}>
									</div>
								</div>
								<div className=''>
									<Button className="button-primary" onClick={() => goTo(true)}>
										{t('event.create-public')}
									</Button>
								</div>
							</div>
						</div>
						{/* <Row>
					<Col>
							<Link to={SHOW_NEW_VIEW ? '/events/private/new-draft': "/events/private/new"}>
								<Card style={{ height: '100%', width: '18rem' , overflow:'auto'}} border="danger">
									<Card.Body className='event-type-card'>
									<div className='mb-3'>
										<Card.Title>{`${t('event.type.PRIVATE_EVENT')}`}</Card.Title>
										<Card.Text dangerouslySetInnerHTML={{__html: `${t('event.type.DESC_PRIVATE')}`}}>
										
										</Card.Text>
										</div>
										<Button className="button-ok hide-small align">
											{`${t('event.create-private')}`}
										</Button> 	
									</Card.Body>
								</Card>
							</Link>
						</Col>
						<Col>
							
							<Link to={SHOW_NEW_VIEW ? '/events/public/new-draft': "/events/public/new"}>
								<Card style={{ height: '100%', width: '18rem' , overflow: 'auto' }} border="success">
									<Card.Body className='event-type-card'>
										<div className='mb-3'>
										<Card.Title>{`${t('event.type.PUBLIC_EVENT')}`}</Card.Title>
										<Card.Text dangerouslySetInnerHTML={{__html: `${t('event.type.DESC_PUBLIC')}`}}>
										</Card.Text>
										</div>
										<Button className="button-ok hide-small">
											{`${t('event.create-public')}`}
										</Button> 
									</Card.Body>
								</Card>
							</Link>
						</Col>
						
					</Row> */}
					</div>
				</div>
			</div>
		</>
	);
}

export default SelectTypeEvent;
