import { createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IUser } from '../models/interfaces/users.interface';
const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;


export default () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const token = localStorage.getItem('token');
	const tokenAuth = {
		headers: { Authorization: `Bearer ${token}` },
	};


	const deleteUser = createAsyncThunk(
		'deleteUser',
		async ({ id }: { id: string }) => {
			try {
				// This endpoint Checks if the user is related to an event before delete
				// const r = await gulanogula.delete(
				// 	`users/userToDelete/${id}`,
				// 	tokenAuth
				// );
				// return r.data;
				const response: any = await fetch(`${API_URL}/api/users/userToDelete/${id}`
					, {
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (error) {
				console.log('Fail deleteUser');
				console.log(error);

			}
		}
	);

	const deleteUserByOrganizer = createAsyncThunk(
		'deleteUserByOrganizer',
		async ({ id, loggedUserId }: { id: string, loggedUserId: string }) => {
			try {
				const response: any = await fetch(`${API_URL}/api/users/userToDeleteByOrganizer/${id}/${loggedUserId}`
					, {
						method: 'PATCH',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (error) {
				console.log('Fail deleteUserByOrganizer');
				console.log(error);

			}
		}
	);

	const createUser = createAsyncThunk(
		'createUser',
		async (usr: IUser) => {
			try {
				const response: any = await fetch(API_URL + '/api/users/',
					{
						method: 'POST',
						body: JSON.stringify(usr),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail createUser');
				throw err;
			}
		}
	);

	const updateUser = createAsyncThunk(
		'userUpdate',
		async (userDTO: IUser) => {
			try {
				const response: any = await fetch(API_URL + '/api/users/' + userDTO._id,
					{
						method: 'PATCH',
						body: JSON.stringify(userDTO, (k, v) => k === "avatar" && v === undefined ? null : v),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail updateUser');
				console.log(err);
			}
		}
	);

	const logoutUser = createAsyncThunk('userLogout', async () => {
		try {
			localStorage.removeItem('token');
			localStorage.removeItem('loggedUserId');
			sessionStorage.clear();
			const response: any = await fetch(API_URL + '/api/auth/logout'
				, {
					method: 'POST',
					headers: { 'Content-type': 'application/json' },
				});
			history.push('/');
		} catch (err) {
			console.log('Fail logoutUser');
			console.log(err);
		}
	});

	const getUserByEmail = async (email: string | undefined) => {
		try {
			if (!!email) {
				const emailToSend = email.toLocaleLowerCase();
				const response: any = await fetch(API_URL + '/api/users/' + emailToSend
					, { method: 'GET' });
				try {
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getUserByEmail');
			console.log(err);
		}
	};

	const getRoleByName = async (roleName: string) => {
		try {
			// const response: any = await gulanogula.get('roles/getByName/' + roleName);
			// return response.data;
			const response: any = await fetch(API_URL + '/api/roles/getByName/' + roleName
				, { method: 'GET' });
			try {
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}

		} catch (error) {
			console.log('Fail getRoleByName');
			console.log(error);
		}
	};

	const updateUserPassword = createAsyncThunk(
		'updateUserPassword',
		async ({
			id,
			newPassword,
			status
		}: {
			id: string;
			newPassword: string;
			status: string;
		}) => {
			try {

				const response: any = await fetch(`${API_URL}/api/users/updateUserPassword/${id}`,
					{
						method: 'PATCH',
						body: JSON.stringify({
							newPassword,
							status
						}),
						headers: {
							'Content-type': 'application/json'
						},
					});
				try {
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail updateUserPassword');
				console.log(err);
			}
		}
	);

	const changeUserPassword = createAsyncThunk(
		'changeUserPassword',
		async ({
			id,
			currentPassword,
			newPassword
		}: {
			id: string;
			currentPassword: string,
			newPassword: string;
		}) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					await dispatch(logoutUser());
					return undefined;
				}

				const response: any = await fetch(`${API_URL}/api/users/changeUserPassword/${id}`,
					{
						method: 'PATCH',
						body: JSON.stringify({
							currentPassword,
							newPassword
						}),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail changeUserPassword');
				console.log(err);
			}
		}
	);

	const getSendResetPasswordEmail = async (email: string) => {
		try {
			// const response: any = await gulanogula.get(`users/sendResetPassword/${email}`);
			// return response.data;
			if (!!email) {
				const emailToSend = email.toLocaleLowerCase();

				const response: any = await fetch(`${API_URL}/api/users/sendResetPassword/${emailToSend}`
					, { method: 'GET' });
				try {
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else {
				return undefined;
			}

		} catch (error) {
			console.log('Fail getSendResetPasswordEmail');
			console.log(error);
		}
	};
	const createPublicUserPlanner = createAsyncThunk(
		'createPublicUserPlanner',
		async (usr: IUser) => {
			try {
				const response: any = await fetch(API_URL + '/api/users/createPublicUser',
					{
						method: 'POST',
						body: JSON.stringify(usr),
						headers: {
							'Content-type': 'application/json'
						},
					});
				try {
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('Fail createUserPlanner');
				throw err;
			}
		}
	);
	return [
		deleteUser,
		createUser,
		updateUser,
		logoutUser,
		getUserByEmail,
		getRoleByName,
		updateUserPassword,
		changeUserPassword,
		getSendResetPasswordEmail,
		deleteUserByOrganizer,
		createPublicUserPlanner
	] as const;
};
