import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import { DateHelper } from '../helpers/date-helper';
import { IContact } from '../models/interfaces/contacts.interface';
import { IoCheckmarkSharp } from 'react-icons/io5';
import useContactsApi from '../hooks/useContactsApi';
import CustomModal from '../components/modals/customModal';
import { useDispatch } from 'react-redux';
interface ContactRowProps {
    contact: IContact,
    onContacted: any
}
export function ContactRow(props: ContactRowProps): JSX.Element {
    const [loader, showLoader, hideLoader] = useLoader();
    const [contact, setContact] = useState<IContact>(props.contact);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);
   
    const dispatch = useDispatch();
    const [
        createContact,
        updateContact
    ] = useContactsApi();
    const { t } = useTranslation();

    const onCloseConfirmationModal = async (e: any) => {

        if (e) {
            setShowConfirmationModal(false);

            const c = { ...contact, contacted: true, }

            try {
                showLoader();
                const response: any = await dispatch(updateContact(c));
                if (!!response && !!response.payload) {
                    props.onContacted();
                }
              
            }
            catch (e) {
                console.log(e);
            } finally {
                hideLoader();
            }


        } else {
            setShowConfirmationModal(false);
        }
    };
    const setContacted = () => {
        if (!contact.contacted) {
            setShowConfirmationModal(true);
        }

    }


    return (
        <>
            {loader}
            {showConfirmationModal && (
                <CustomModal
                    isShowing={showConfirmationModal}
                    parentCallback={onCloseConfirmationModal}
                    message={t('confirmation.contact')}
                    buttonCancel={t('cancel')}
                    buttonOK={t('accept')}
                    type="WARNING_EDIT"
                />
            )}
            {!!contact && (
                <tr key={contact._id}>
                    <td> {contact.fullname}</td>
                    <td> {contact.email}</td>
                    <td> <p className={descriptionExpanded ? 'cursor-pointer' : 'cursor-pointer resumed'}
                        onClick={() => setDescriptionExpanded(!descriptionExpanded)}
                        dangerouslySetInnerHTML={{ __html: contact.comment?.replaceAll('\n', '<br>') || '' }}
                    ></p></td>
                    <td> {DateHelper.dateAndTime(contact.createdDT)}</td>

                    <td>
                        <div className='d-flex align-items-center'>
                        <div onClick={setContacted}
                            className={contact.contacted
                                ? 'custom-checkbox cursor-not-allowed inscription-checkbox checked mr-2'
                                : 'custom-checkbox cursor-pointer inscription-checkbox'
                            }
                        >
                            {contact.contacted && <IoCheckmarkSharp />}
                        </div>

                        {DateHelper.dateAndTime(contact.contactedDT)}
                        </div>
                        </td>

                </tr>
            )}
        </>
    );
}
