import { ANSWER_MAX_LENGTH, CONTACT_MESSAGE_MAX_LENGTH, FEEDBACK_ANSWER_MAX_LENGTH, QUESTION_MAX_LENGTH } from "../../constants";

export const Validator = {
    name: (c: string | undefined): boolean => {
      if (!c) { return true; }
  
      const chars = /^(?!-)[óáëøâéèîíôñüúïça-z\s]+(?:[-,.'`´′‵\s][óáëøâéèîíôñúüïça-z\s]+)*(?!-)$/.test(c.toLowerCase());
      const _name = c.trim();
      return (chars && _name.length > 1) ? false :
        true;
    },
    nameSector: (c: string | undefined, m: number | undefined): boolean => {
      if (!c) { return true; }
      if (c.length > (m || 100) ){
        return true;
      } return false;
    },
    nameQuestion: (c: string | undefined, m: number | undefined): boolean => {
      if (!c) { return true; }
      if (c.trim().length > (m || QUESTION_MAX_LENGTH) ){
        return true;
      } 
      const _name = c.trim();
      return ( _name.length > 1) ? false :
        true;
    },
    answer: (c: string | undefined, m: number | undefined): boolean => {
      if (!c) { return true; }
      if (c.trim().length > (m || ANSWER_MAX_LENGTH) ){
        return true;
      } 
      const _name = c.trim();
      return ( _name.length > 1) ? false :
        true;
    },
    feedbackAnswer: (c: string | undefined, m: number | undefined): boolean => {
      if (!c) { return true; }
      if (c.trim().length > (m || FEEDBACK_ANSWER_MAX_LENGTH) ){
        return true;
      } 
      const _name = c.trim();
      return ( _name.length > 1) ? false :
        true;
    },
    feedbackJustifyAnswer: (c: string | undefined, m: number | undefined): boolean => {
      if (!c) { return false; }
      if (c.trim().length > (m || FEEDBACK_ANSWER_MAX_LENGTH) ){
        return true;
      } 
      return false;
    },
    identificationNumber: (c: string | undefined): boolean => {
      if (!c) { return true; }
      const chars = /^[0-9]{7,8}$/.test(c);
      const _name = c.trim();
      return (chars && _name.length >= 7 && _name.length <=8)? false :
        true;
    },
    contactText: (c: string | undefined, m?: number | undefined): boolean => {
      if (!c) { return false; }
      if (c.trim().length > (m || CONTACT_MESSAGE_MAX_LENGTH) ){
        return true;
      } 
      return false;
    },
}  