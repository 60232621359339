import { createAsyncThunk } from '@reduxjs/toolkit';

import { INSCRIPTIONS_PAGE_SIZE } from '../../constants';
import QRCode from 'qrcode';
import { useDispatch } from 'react-redux';

import useUsersApi from '../hooks/useUsersApi';
import { ICompanion, IInscription, InscriptionModel } from '../models/interfaces/inscriptions.interface';
import { IEvent } from '../models/interfaces/events.interface';
const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;

export default () => {
	const [, , , logoutUser] = useUsersApi();
	const dispatch = useDispatch();
	const token = localStorage.getItem('token');
	const tokenAuth = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const createInscription = createAsyncThunk(
		'createInscription',
		async (inscription: any) => {
			try {

				const response: any = await fetch(API_URL + '/api/inscriptions/',
					{
						method: 'POST',
						body: JSON.stringify(inscription),
						headers: {
							'Content-type': 'application/json'
						},
					});
				try {
					const data = await response.json();
					return data as IInscription;
				} catch (e) {
					console.log('Could not cast inscription at createInscription');
					return undefined;
				}
			} catch (err) {
				console.log('Fail createInscription');
				throw err;
			}
		}
	);

	const createPrivateInscription = createAsyncThunk(
		'createInscription',
		async ({
			inscription, maxCapacity
		}: {
			inscription: any, maxCapacity:number
		})=> {
			try {

				const response: any = await fetch(API_URL + '/api/inscriptions/inscription/'+maxCapacity,
					{
						method: 'POST',
						body: JSON.stringify(inscription),
						headers: {
							'Content-type': 'application/json'
						},
					});
				try {
					const data = await response.json();
					return data as IInscription;
				} catch (e) {
					throw e;
				}
			} catch (err) {
				console.log('Fail createInscription');
				throw err;
			}
		}
	);

	const getInscription = async (userId: string | undefined, eventId: string | undefined) => {
		try {
			if (!!userId && !!eventId) {
				const response: any = await fetch(API_URL + '/api/inscriptions/getInscription/' + userId + '/' + eventId
					, { method: 'GET' });
				try {
					const data = await response.json();
					return data as IInscription;
				} catch (e) {
					console.log('Could not cast inscription at getInscription');
					return undefined;
				}
			}
			else {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getInscription');
			console.log(err);
		}
	};
	const getInscriptionByEmail = async (email: string | undefined, eventId: string | undefined) => {
		try {
			if (!!email && !!eventId) {
				const response: any = await fetch(API_URL + '/api/inscriptions/getInscriptionByEmail/' + email.toLowerCase() + '/' + eventId
					, { method: 'GET' });

				if (response.status === 200) {
					try {
						const data = await response.json();
						return data as IInscription;
					} catch (e) {
						console.log('Could not cast inscription at getInscriptionByEmail');
						return undefined;
					}
				}
			}
			return undefined;
		} catch (err) {
			console.log('Fail getInscriptionByEmail');
			console.log(err);
			return undefined;
		}
	};
	const getInscriptionByIdentificationNumber = async (identificationNumber: string | undefined, eventId: string | undefined) => {
		try {
			if (!!identificationNumber && !!eventId) {
				const response: any = await fetch(API_URL + '/api/inscriptions/getInscriptionByIDNumber/' + identificationNumber.toLowerCase() + '/' + eventId
					, { method: 'GET' });

				if (response.status === 200) {
					try {
						const data = await response.json();
						return data as IInscription;
					} catch (e) {
						console.log('Could not cast inscription at getInscriptionByIdentificationNumber');
						return undefined;
					}
				}
			}
			return undefined;
		} catch (err) {
			console.log('Fail getInscriptionByIdentificationNumber');
			console.log(err);
			return undefined;
		}
	};
	const getInscriptionById = async (inscriptionId: string) => {
		try {

			const response: any = await fetch(API_URL + '/api/inscriptions/' + inscriptionId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getInscriptionById');
			console.error(err);
			return undefined;
		}
	};

	const getInscriptionByIdWithBatches = async (inscriptionId: string) => {
		try {

			const response: any = await fetch(API_URL + '/api/inscriptions/getInscriptionByIdWithBatches/' + inscriptionId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getInscriptionByIdWithBatches');
			console.log(err);
		}
	};

	const getAllInscriptions = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getAllInscriptions/' + eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getAllInscriptions');
			console.log(err);
		}
	};

	const getAcceptedInscriptions = async (eventId: string, page: number, inscriptionsPageSize: number) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getAcceptedInscriptions/' +
				eventId +
				'/' +
				page +
				'/' +
				inscriptionsPageSize
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getAcceptedInscriptions');
			console.log(err);
		}
	};

	const getPublicAcceptedInscriptions =  async (eventId: string, page: number, inscriptionsPageSize: number) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getPublicAcceptedInscriptions/' +
				eventId +
				'/' +
				page +
				'/' +
				inscriptionsPageSize
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getPublicAcceptedInscriptions');
			console.log(err);
		}
	}

	const getDeniedInscriptions = async (eventId: string, page: number, inscriptionsPageSize: number) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getDeniedInscriptions/' +
				eventId +
				'/' +
				page +
				'/' +
				inscriptionsPageSize
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getDeniedInscriptions');
			console.log(err);
		}
	};

	const getPendingInscriptions = async (eventId: string, page: number, inscriptionsPageSize: number) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getPendingInscriptions/' +
				eventId +
				'/' +
				page +
				'/' +
				inscriptionsPageSize
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getPendingInscriptions');
			console.log(err);
		}
	};
	const getAttendeedInscriptions = async (eventId: string, page: number, inscriptionsPageSize: number) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getAttendeedInscriptions/' +
				eventId +
				'/' +
				page +
				'/' +
				inscriptionsPageSize
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getAttendeedInscriptions');
			console.log(err);
		}
	};

	const getAdmissionInscriptions = async (eventId: string, page: number, filterNameEmail: string, filterStatus: boolean, toAttendee: boolean) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getAdmissionInscriptions/' +
				eventId +
				'/' +
				page +
				'/' +
				INSCRIPTIONS_PAGE_SIZE +
				'/' +
				filterNameEmail +
				'/' +
				filterStatus +
				'/' +
				toAttendee
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}

		} catch (err) {
			console.log('Fail getAdmissionInscriptions');
			console.log(err);
		}
	};
	const attendeeInscription = createAsyncThunk(
		'attendeeInscription',
		async ({
			id,
			attendeedBy,
			attendeedDT,
			isAdmin
		}: {
			id?: string;
			attendeedBy?: string;
			attendeedDT?: Date;
			isAdmin?: boolean
		}) => {
			try {
				if (!id) {
					return undefined;
				}
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					console.log('logout attendeeInscription');
					await dispatch(logoutUser());
					return undefined;
				}

				const inscriptionDTO = {
					inscriptionStatus: 'ATTENDEED',
					attendeedBy,
					attendeedDT
				};
				const now = new Date();
				const offset = now.getTimezoneOffset();
				const response: any = await fetch(API_URL + '/api/inscriptions/attendee/' + id+ '/'+isAdmin+'/'+offset,
					{
						method: 'PATCH',
						body: JSON.stringify(inscriptionDTO),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						console.log('logout: attendeeInscription');
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('fail attendeeInscription');
				console.log(err);
			}
		}
	);


	const updateInscriptionStatus = createAsyncThunk(
		'updateInscriptionStatus',
		async ({
			inscriptionStatus,
			id,
			attendeedBy,
			attendeedDT
		}: {
			inscriptionStatus: string;
			id?: string;
			attendeedBy?: string;
			attendeedDT?: Date
		}) => {
			try {
				if (!id) {
					return undefined;
				}
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					console.log('logout updateInscriptionStatus');
					await dispatch(logoutUser());
					return undefined;
				}

				const inscriptionDTO = {
					inscriptionStatus,
					attendeedBy,
					attendeedDT
				};

				const response: any = await fetch(API_URL + '/api/inscriptions/' + id,
					{
						method: 'PATCH',
						body: JSON.stringify(inscriptionDTO),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('fail updateInscriptionStatus');
				console.log(err);
			}
		}
	);

	const updateStatusMultiple = createAsyncThunk(
		'updateStatusMultiple',
		async ({
			inscriptionStatus,
			ids,
			loggedUserId
		}: {
			inscriptionStatus: string;
			ids: (string | undefined)[];
			loggedUserId: string;
		}) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				// if (!localStorage.getItem('token')) {
				// 	console.log('logout updateStatusMultiple');
				// 	await dispatch(logoutUser());
				// 	return undefined;
				// }

				const response: any = await fetch(API_URL + '/api/inscriptions/updateStatusMultiple/' + inscriptionStatus + '/' + loggedUserId,
					{
						method: 'PATCH',
						body: JSON.stringify({ ids }),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('fail updateStatusMultiple');
				console.log(err);
			}
		}
	);

	const generateQRInscription = async (inscription: IInscription, eventId: string) => {
		try {
			const _url: string | undefined = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL;
			const qrUrl = _url + "/confirmAttendee/" + inscription._id + '/' + eventId;
			return QRCode.toDataURL(qrUrl, async (err: any, src: any) => {
				if (err) {
					console.group("err " + err)
				}
				inscription.qrImage = src;
				inscription.qrUrl = qrUrl;
				inscription.inscriptionStatus = 'ACCEPTED';
				return inscription;
			});
		} catch (e) {
			console.log(e);
			return null;
		}
	}
	const updateAndSendQRs = createAsyncThunk(
		'updateAndSendQRs',
		async ({
			inscriptions,
			loggedUserId,
			idsToSendQRs,
			eventId,
			eventMaxCapacity
		}: {
			inscriptions: IInscription[],
			loggedUserId: string,
			idsToSendQRs: (string | undefined)[],
			eventId: string,
			eventMaxCapacity?: number
		}) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				// if (!localStorage.getItem('token')) {
				// 	console.log('logout updateandsendqr');
				// 	await dispatch(logoutUser());
				// 	return undefined;
				// }
				// for (let inscription of inscriptions) {

				// 	generateQRInscription(inscription, eventId).then((insc) => {
				// 		inscription = insc;
				// 	}, (e) => {
				// 		console.log(e);
				// 		return undefined;
				// 	});
				//}
				// GAL-675 GENERATE QR AT BACKEND
				const response: any = await fetch(API_URL + '/api/inscriptions/acceptAndCreateAndSendQRs/' + loggedUserId,
					{
						method: 'PATCH',
						body: JSON.stringify({ inscriptions, idsToSendQRs, eventId, eventMaxCapacity }),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('fail updateAndSendQRs');
				console.log(err);
			}
		}
	);
	//emailConfirmation
	const confirmEmail = async (inscriptionId: string, email: string) => {
		try {
			const response: any = await fetch(`${API_URL}/api/users/emailConfirmation/${inscriptionId}/${email}`
				, { method: 'GET' });
			try {
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail confirmEmail');
			console.log(err);
		}
	};
	const resendInscriptionEmail = async (inscriptionId: string | undefined) => {
		try {
			if (!inscriptionId) {
				return undefined;
			}
			const response: any = await fetch(`${API_URL}/api/inscriptions/sendInscriptionConfirmationEmail/${inscriptionId}`
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				console.log('Respuesta de Resend resendInscriptionEmail');
				console.log(response);
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail resendInscriptionEmail');
			console.log(err);
		}
	};
	const resendQREmail = async (inscriptionId: string | undefined) => {
		try {
			if (!inscriptionId) {
				return undefined;
			}
			const response: any = await fetch(`${API_URL}/api/inscriptions/sendInscriptionQR/${inscriptionId}`
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (!response) {
					return undefined;
				}

				const data = await response.json();
				return data;
			} catch (e) {
				console.log('Fail resendQREmail to json');
				console.log(e);
				return undefined;
			}
		} catch (err) {
			console.log('Fail resendQREmail');
			console.log(err);
		}
	};
	const sendDeniedEmail = async (inscriptionId: string | undefined) => {
		try {
			if (!inscriptionId) {
				return undefined;
			}
			const response: any = await fetch(`${API_URL}/api/inscriptions/sendInscriptionDenied/${inscriptionId}`
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				console.log('Respuesta de sendDeniedEmail');
				console.log(response);
				if (!response) {
					return undefined;
				}
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail sendDeniedEmail');
			console.log(err);
		}
	};
	const sendRejectedEmail = async (inscriptionId: string | undefined) => {
		try {
			if (!inscriptionId) {
				return undefined;
			}
			const response: any = await fetch(`${API_URL}/api/inscriptions/sendInscriptionRejected/${inscriptionId}`
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				console.log('Respuesta de sendRejectedEmail');
				console.log(response);
				if (!response) {
					return undefined;
				}
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail sendRejectedEmail');
			console.log(err);
		}
	};
	const generateQR = createAsyncThunk(
		'generateQR',
		async ({ inscriptionId, eventId }: { inscriptionId: string | undefined, eventId: string | undefined }) => {
			try {
				if (!inscriptionId || !eventId) {
					return undefined;
				}
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					console.log('logout generateQR');
					await dispatch(logoutUser());
					return undefined;
				}
				const _url: string | undefined = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL;
				const qrUrl = _url + "/confirmAttendee/" + inscriptionId + '/' + eventId;
				QRCode.toDataURL(qrUrl, async (err: any, src: any) => {
					if (err) {
						console.group("err " + err)
					}
					const response: any = await fetch(`${API_URL}/api/inscriptions/generateQR/${inscriptionId}`,
						{
							method: 'PATCH',
							body: JSON.stringify({
								qrUrl,
								qrImage: src
							}),
							headers: {
								Authorization: `Bearer ${localStorage.getItem('token')}`,
								'Content-type': 'application/json'
							},
						});
					try {
						if (response.status == 401) {
							await dispatch(logoutUser());
						}
						const data = await response.json();
						return data;
					} catch (e) {
						return undefined;
					}
				});
			} catch (err) {
				console.log('Fail generateQR');
				console.log(err);
			}
		});
	const getInscriptionsByCriteria = async (eventId: string, status: string, nameOrEmail: string, page: number, inscriptionsPageSize: number) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getInscriptionsByCriteria/' +
				eventId +
				'/' + status +
				'/' + nameOrEmail +
				'/' + page +
				'/' + inscriptionsPageSize
				, {
					method: 'GET',

					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;

			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getInscriptionsByCriteria');
			console.log(err);
		}
	};
	// 
	const getAttendeedInscriptionsFromMain = async (inscriptionId: string, checkCompanions: boolean) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getAttendeedInscriptionsFromMain/' +
				inscriptionId +
				'/' + checkCompanions
				, {
					method: 'GET',

					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;

			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getAttendeedInscriptionsFromMain');
			console.log(err);
		}
	};
	const getReportInscriptions = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getReportInscriptions/' +
				eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getReportInscriptions');
			console.log(err);
		}
	};
	const getReportBilling = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getReportBilling/' +
				eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getReportBilling');
			console.log(err);
		}
	}
	const getReportMenu = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getReportMenu/' +
				eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getReportMenu');
			console.log(err);
		}
	};
	const getReportMenuDynamic = async (eventId: string, attendeed: boolean) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getReportMenuDynamic/' +
				eventId + '/' + attendeed
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getReportMenuDynamic');
			console.log(err);
		}
	};
	const getReportAttendeedInscriptions = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getReportAttendeedInscriptions/' +
				eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getReportAttendeedInscriptions');
			console.log(err);
		}
	};
	const getReportAttendeedMenu = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getReportAttendeedMenu/' +
				eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getReportAttendeedMenu');
			console.log(err);
		}
	};
	const getReportPastEventInscriptions = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getReportPastEventInscriptions/' +
				eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getReportPastEventInscriptions');
			console.log(err);
		}
	};
	const updateInscriptionAndCompanions = createAsyncThunk(
		'updateInscriptionAndCompanions',
		async (inscription: IInscription) => {
			try {
				const url = '/api/inscriptions/update/full/';
				const response: any = await fetch(API_URL + url +
					inscription._id,
					{
						method: 'PATCH',
						body: JSON.stringify(inscription),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('Fail updateInscription');
				console.log(err);
			}
		}
	);
	const updateInscriptionAndCompanionsAndSendQR = createAsyncThunk(
		'updateInscriptionAndCompanionsAndSendQR',
		async ({ inscription, event }: { inscription: IInscription, event: IEvent | undefined}) => {
			try {
				const url = '/api/inscriptions/update/full/QR/';
				const eventId = event?._id || '';
				const eventMaxCapacity = event?.maxCapacity;
				let inscriptions = [inscription];
				if (!!inscription?.companions && inscription.companions?.length > 0) {
					for (let i of inscription.companions ) {
						inscriptions.push(i);
					}
				}
				
				const response: any = await fetch(API_URL + url +
					inscription._id,
					{
						method: 'PATCH',
						body: JSON.stringify({ inscriptions, eventId, eventMaxCapacity }),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} catch (err) {
				console.log('Fail updateInscriptionAndCompanionsAndSendQR');
				console.log(err);
			}
		}
	);
	const updateInscriptionSector = createAsyncThunk(
		'updateInscriptionSector',
		async ({
			sector,
			id,
			inscriptionStatus
		}: {
			sector: string;
			id?: string;
			inscriptionStatus?: string;
		}) => {
			try {
				if (!id) {
					return undefined;
				}
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					console.log('logout updateInscriptionSector');
					await dispatch(logoutUser());
					return undefined;
				}

				const inscriptionDTO = {
					sector, inscriptionStatus
				};

				const response: any = await fetch(API_URL + '/api/inscriptions/' + id,
					{
						method: 'PATCH',
						body: JSON.stringify(inscriptionDTO),
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
							'Content-type': 'application/json'
						},
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			} catch (err) {
				console.log('fail updateInscriptionSector');
				console.log(err);
			}
		}
	);
	const getInscriptionsAnswers = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getInscriptionsAnswers/' +
				eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				console.log(data);
				
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getInscriptionsAnswers');
			console.log(err);
		}
	};
	const getInscriptionsFeedbackAnswers = async (eventId: string) => {
		try {
			const response: any = await fetch(API_URL + '/api/inscriptions/getInscriptionsFeedbackAnswers/' +
				eventId
				, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
			try {
				if (response.status == 418) {
					return response.json();
				}
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				const data = await response.json();
				console.log(data);
				
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getInscriptionsFeedbackAnswers');
			console.log(err);
		}
	};
	return [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions, 
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
		updateInscriptionSector,
		getPublicAcceptedInscriptions,
		getReportBilling,
		getInscriptionsAnswers,
		getInscriptionsFeedbackAnswers,
		createPrivateInscription
	] as const;
};
