import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { InputGroup, Tabs, Tab, Dropdown } from 'react-bootstrap';

import { FaRegCopy } from 'react-icons/fa';
import Select from 'react-select';

import DatePicker from 'react-datepicker';
import { IoIosSave, IoIosArrowBack, IoMdMore } from 'react-icons/io';
import useApi from '../../hooks/useApi';
import useEventsApi from '../../hooks/useEventsApi';

import CustomModal from '../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../components/loader/useLoader';
import useUsersApi from '../../hooks/useUsersApi';
import { EVENT_DESCRIPTION_MAX_LENGTH, EVENT_NAME_MAX_LENGTH, EVENT_TYPES, INSCRIPTION_TYPES, YES_NO, IMAGES_TYPES, EVENT_COMPANIONS_MIN, EVENT_COMPANIONS_MAX, SHOW_CHARTS, SHOW_SECTORS, SHOW_CONTACT_METHOD, SHOW_QUESTIONS, QUESTIONS_LIMIT, FEEDBACK_QUESTIONS_LIMIT, COMUNICATION_TYPES } from '../../../constants';
import { IEvent, IQuestion, ISector } from '../../models/interfaces/events.interface';
import { TCommunicationType, TInscriptionStatus } from '../../models/types/types';
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import InscriptionsReportPDF from '../../reports/inscriptionsReport';
import InscriptionsPastEventReportPDF from '../../reports/inscriptionsPastEventReport';
import { Places } from '../../components/place/places';
import { DateHelper } from '../../helpers/date-helper';
import InscriptionsMenuReportDynamicPDF from '../../reports/menuReportDynamic';
import CustomDatePicker from '../../components/custom-date-picker/custom-date-picker';
import { Dropzone } from '../../components/dropzone/dropzone';
import useImageUpload from '../../hooks/useImageUpload';
import { CLOUDINARY_CLOUD_NAME, UPLOAD_TYPES } from '../../../constants';
import { getEventInscriptionURL, shortenUrl } from '../../helpers/events-helper';
import { exportAnswersToExcel, exportInscriptionsToExcel } from '../../helpers/excel-report-helper';
import ErrorGlobal from '../../components/error-global/error-global';
import SectorRow from '../../sectors/sectorRow';
import { IoAddOutline } from 'react-icons/io5';
import SectorForm from '../../sectors/sectorForm';
import SectorsManager from '../../sectors/sectorsManager';
import SectorsReportPDF from '../../reports/sectorsReport';
import QuestionRow from '../../questions/questionRow';
import QuestionForm from '../../questions/questionForm';
import QuestionsReportPDF from '../../reports/questionsReport';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import FeedbackQuestionsReportPDF from '../../reports/feedbackQuestionsReport';
import QuestionsSummary from '../../questions/questionsSummary';
import { getRemainingTimeAndDateStatus } from '../form/utils';
import InscriptionsManager from '../../inscriptions/manager/inscriptionsManager';

interface EditEventProps {
	pEventId: string, isEditable: boolean, details: boolean, status?: TInscriptionStatus, preventEdit?: boolean
}
export function EditEvents(props: EditEventProps) {
	const lang: string = localStorage.getItem("i18nextLng") || 'en';
	//const [key, setKey] = useState<any>(props.details ? 'info' : 'invitations');
	const [key, setKey] = useState<any>('invitations');
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [loader, showLoader, hideLoader] = useLoader();
	const { t, i18n } = useTranslation();
	const [eventId, setEventId] = useState<string | undefined>(undefined);
	const [event, setEvent] = useState<any | undefined>(undefined);
	const [hasSector, setHasSector] = useState<string | undefined>();
	const [hasQuestion, setHasQuestion] = useState<string | undefined>();
	const [hasFeedbackQuestion, setHasFeedbackQuestion] = useState<string | undefined>();
	const [sectors, setSectors] = useState<ISector[]>([]);
	const [questions, setQuestions] = useState<IQuestion[]>([]);
	const [feedbackQuestions, setFeedbackQuestions] = useState<IQuestion[]>([]);
	const [sectorToEdit, setSectorToEdit] = useState<ISector | undefined>();
	const [questionToEdit, setQuestionToEdit] = useState<IQuestion | undefined>();
	const [feedbackQuestionToEdit, setFeedbackQuestionToEdit] = useState<IQuestion | undefined>();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [sectorToEditIndex, setSectorToEditIndex] = useState<number>(-1);
	const [questionToEditIndex, setQuestionToEditIndex] = useState<number>(-1);
	const [feedbackQuestionToEditIndex, setFeedbackQuestionToEditIndex] = useState<number>(-1);
	const [sectorNumber, setSectorNumber] = useState<number>(0);
	const [questionNumber, setQuestionNumber] = useState<number>(0);
	const [feedbackQuestionNumber, setFeedbackQuestionNumber] = useState<number>(0);
	const [sectorsAvailability, setSectorsAvailability] = useState<any[]>([]);
	const [inscriptionsBySector, setInscriptionsBySector] = useState<any[]>([]);
	const [inscriptionsAnswersReport, setInscriptionsAnswersReport] = useState<any>();
	const [inscriptionsFeedbackAnswersReport, setInscriptionsFeedbackAnswersReport] = useState<any>();
	// Date
	const [savedEventDate, setSavedEventDate] = useState<Date>();
	const [eventDate, setEventDate] = useState<Date>();
	const [eventDateEnd, setEventDateEnd] = useState<Date>();
	const [eventInscriptionLimitDT, setEventInscriptionLimitDT] = useState<Date>();
	const [eventFeedbackLimitDT, setEventFeedbackLimitDT] = useState<Date>();
	const [minEventMaxCapacity, setMinEventMaxCapacity] = useState<number>(1);
	const [minEventMaxCapacityByInscriptions, setMinEventMaxCapacityByInscriptions] = useState<number>(1);
	const [minEventCompanionsLimit, setMinEventCompanionsLimit] = useState<number>(1);
	const [eventMaxCapacity, setEventMaxCapacity] = useState<number | undefined>();
	const [eventCompanionsLimit, setEventCompanionsLimit] = useState<number | undefined>();
	const [canEditDetails, setCanEditDetails] = useState<boolean>(props.isEditable && !props.preventEdit);
	// Time
	const [eventStartHour, setEventStartHour] = useState<Date>();
	const [eventEndHour, setEventEndHour] = useState<Date>();
	const [eventInscriptionLimitHour, setEventInscriptionLimitHour] = useState<Date>();
	const [isInvalidInscriptionLimitHour, setIsInvalidInscriptionLimitHour] = useState<boolean>(false);
	const [isInvalidInscriptionLimitDate, setIsInvalidInscriptionLimitDate] = useState<boolean>(false);
	const [eventFeedbackLimitHour, setEventFeedbackLimitHour] = useState<Date>();
	const [isInvalidFeedbackLimitHour, setIsInvalidFeedbackLimitHour] = useState<boolean>(false);
	const [isInvalidFeedbackLimitDate, setIsInvalidFeedbackLimitDate] = useState<boolean>(false);
	const [isInvalidEndHour, setIsInvalidEndHour] = useState<boolean>(false);
	const [eventName, setEventName] = useState<string | undefined>(undefined);
	const [includeMenu, setIncludeMenu] = useState<string>('no');
	const [includeGender, setIncludeGender] = useState<string | undefined>();
	const [includeDOB, setIncludeDOB] = useState<string | undefined>();

	const [communicationType, setCommunicationType] = useState<TCommunicationType | undefined>();

	const [includeIdentification, setIncludeIdentification] = useState<string>('no');
	const [place, setPlace] = useState(undefined);
	const [placeLink, setPlaceLink] = useState(undefined);
	const [placeInvalid, setPlaceInvalid] = useState<boolean>(true);
	const [description, setDescription] = useState<string | undefined>(undefined);
	const [eventImg, setEventImg] = useState<any>(undefined);
	const [organizer, setOrganizer] = useState(undefined);
	const [showErrorHosts, setShowErrorHosts] = useState(false);
	const [showErrorPlanners, setShowErrorPlanners] = useState(false);
	const [showErrorAdmissions, setShowErrorAdmissions] = useState(false);
	const [editable, setEditable] = useState<boolean>(props.isEditable && !props.preventEdit);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showConfirmationReminderModal, setShowConfirmationReminderModal] = useState(false);
	const [showConfirmationFeedbackModal, setShowConfirmationFeedbackModal] = useState(false);
	const [showConfirmationNotificationModal, setShowConfirmationNotificationModal] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [notifyInscriptions, setNotifyInscriptions] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [validated, setValidated] = useState(false);
	const [isHost, setIsHost] = useState(undefined);
	const [goToEvents, setGoToEvents] = useState<boolean>(true);
	const [showLinkCopiedModal, setShowLinkCopiedModal] = useState(false);
	const [hosts, setHosts] = useState<any[] | undefined>(undefined);
	const [planners, setPlanners] = useState<any[] | undefined>(undefined);
	const [admissions, setAdmissions] = useState<any[] | undefined>(undefined);
	const [isPastEvent, setIsPastEvent] = useState<boolean>(false);

	const [inscriptionLink, setInscriptionLink] = useState<any>(undefined);
	const [isDirty, setIsDirty] = useState(false);
	const [imageIsDirty, setImageIsDirty] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const today = new Date();
	let tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	let maxDate = new Date();

	maxDate.setDate(today.getDate() + 700);
	const [, , updateUser] = useUsersApi();
	const [
		loggedUser,
		,
		,
		,
		,
		,
		getMyHosts,
		getMyAdmissions,
		getMyPlanners,
	] = useApi();
	const [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
		editEventNotifyInscriptions,
		getBatchesAvailability,
		checkTicketsAvailability,
		getSectorsAvailability,
		getInscriptionsBySector,
		sendEventReminder,
		sendEventFeedback
	] = useEventsApi();

	const [
		createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions,
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
		updateInscriptionSector,
		getPublicAcceptedInscriptions,
		getReportBilling,
		getInscriptionsAnswers,
		getInscriptionsFeedbackAnswers
	] = useInscriptionsApi();
	const [
		uploadImageCloud
	] = useImageUpload();
	const [selectedTypeOfEvent, setSelectedTypeOfEvent] = useState('DEFAULT');
	const _typesOfEvents: any[] = IMAGES_TYPES;
	const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const eventTypes = getSelectSingleOptions(EVENT_TYPES, 'event.type.');
	const typesOfEvents = getSelectSingleOptions(_typesOfEvents, 'event.image.');
	const inscriptionTypes = getSelectSingleOptions(INSCRIPTION_TYPES, 'event.inscription.');
	const includeMenuOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const includeIdentificationOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const communicationTypes = getSelectSingleOptions(COMUNICATION_TYPES, 'event.comunication.');
	const includeGenderOptions = getSelectSingleOptions(YES_NO, 'confirmation.');
	const includeDOBOptions = getSelectSingleOptions(YES_NO, 'confirmation.');

	const handleOnSelectTypeOfEvent = (e: any) => {
		const r: any = typesOfEvents.find((x) => x.name == e.name);
		setSelectedTypeOfEvent(r.name);
		setIsDirty(true);
		if (r.name !== "CUSTOM") {
			setEventImg(null);
			setImageIsDirty(false);
		}
	};

	const handleNameChange = (e: any) => {
		setEventName(e.target.value);
		setIsDirty(true);
	};
	const handleIncludeMenuChange = (e: any) => {
		setIncludeMenu(e.name);
		setIsDirty(true);
	}

	const handleIncludeGenderChange = (e: any) => {
		setIncludeGender(e.name);
		setIsDirty(true);
	}

	const handleIncludeDOBChange = (e: any) => {
		setIncludeDOB(e.name);
		setIsDirty(true);
	}

	const handleOnSelectCommunicationType = (e: any) => {
		const r = !!communicationTypes ? communicationTypes.find((x: any) => x.name == e.name) : undefined;
		setCommunicationType(r.name);
		setIsDirty(true);
	}
	const handleIncludeIdentificationChange = (e: any) => {
		setIncludeIdentification(e.name);
		setIsDirty(true);
	}
	const handleDateChange = (_date: Date) => {
		if (!eventDateEnd || _date > eventDateEnd) {
			setEventDate(_date);
			setEventDateEnd(_date);
		} else {
			setEventDate(_date);
		}
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(eventInscriptionLimitHour, eventInscriptionLimitDT, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(eventInscriptionLimitDT, _date));
		setIsDirty(true);
	};
	const handleDateEndChange = (_date: Date) => {
		setEventDateEnd(_date);
		if (hasFeedbackQuestion === 'yes') {
			setIsInvalidFeedbackLimitHour(invalidTimeFeedback(eventFeedbackLimitHour, eventFeedbackLimitDT, _date));
			setIsInvalidFeedbackLimitDate(invalidDateFeedback(eventFeedbackLimitDT, _date));
		}
		setIsDirty(true);
	};

	const handleInscriptionLimitDTChange = (_date: Date) => {
		setEventInscriptionLimitDT(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(eventInscriptionLimitHour, _date, eventDate));
		setIsInvalidInscriptionLimitDate(invalidDateInscription(_date));
		setIsDirty(true);
	};

	const handleInscriptionLimitHourChange = (_date: Date) => {
		setEventInscriptionLimitHour(_date);
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(_date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
		setIsDirty(true);
	};

	const handleFeedbackLimitDTChange = (_date: Date) => {
		setEventFeedbackLimitDT(_date);
		setIsInvalidFeedbackLimitHour(invalidTimeFeedback(eventFeedbackLimitHour, _date, eventDateEnd));
		setIsInvalidFeedbackLimitDate(invalidDateFeedback(_date));
		setIsDirty(true);
	};

	const handleFeedbackLimitHourChange = (_date: Date) => {
		setEventFeedbackLimitHour(_date);
		setIsInvalidFeedbackLimitHour(invalidTimeFeedback(_date));
		setIsInvalidFeedbackLimitDate(invalidDateFeedback());
		setIsDirty(true);
	};
	const handlePlaceChange = (e: any) => {
		setPlace(e);
		setIsDirty(true);
	};

	const handleDescriptionChange = (e: any) => {
		setDescription(e.target.value);
		setIsDirty(true);
	};

	const handleStartChange = (_date: Date) => {
		setEventStartHour(_date);
		setIsInvalidEndHour(invalidTime(_date, eventEndHour));
		setIsInvalidInscriptionLimitHour(invalidTimeInscription(eventInscriptionLimitHour, eventInscriptionLimitDT, eventDate, _date));
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
		setIsDirty(true);
	};

	const handleEndChange = (_date: Date) => {
		setEventEndHour(_date);
		setIsInvalidEndHour(invalidTime(eventStartHour, _date));
		if (hasFeedbackQuestion === 'yes') {
			setIsInvalidFeedbackLimitHour(invalidTimeFeedback(eventFeedbackLimitHour, eventFeedbackLimitDT, eventDateEnd, _date));
			setIsInvalidFeedbackLimitDate(invalidDateFeedback());
		}
		setIsDirty(true);
	};

	const invalidTime = (_dateStart: Date | undefined, _dateEnd: Date | undefined) => {
		const dt = DateHelper.returnDate(eventDate);
		const dtEnd = DateHelper.returnDate(eventDateEnd);
		const endHr = DateHelper.getNumberTimeFromDate(_dateEnd || eventEndHour);
		const startHr = DateHelper.getNumberTimeFromDate(_dateStart || eventStartHour);
		return (dt == dtEnd) && (startHr > endHr);
	};
	const invalidTimeInscription = (_date?: Date, _inscriptionDate?: Date, _eventDate?: Date, _startHour?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || eventInscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || eventDate);
		const inscriptionLimitHr = DateHelper.getNumberTimeFromDate(_date || eventInscriptionLimitHour);
		const eventStartHr = DateHelper.getNumberTimeFromDate(_startHour || eventStartHour);
		return (inscriptionLimit === eventStartDate) && (inscriptionLimitHr > eventStartHr);
	}
	const invalidDateInscription = (_inscriptionDate?: Date, _eventDate?: Date) => {
		const inscriptionLimit = DateHelper.returnDate(_inscriptionDate || eventInscriptionLimitDT);
		const eventStartDate = DateHelper.returnDate(_eventDate || eventDate);
		return (inscriptionLimit > eventStartDate);
	}
	const invalidTimeFeedback = (_date?: Date, _feedbackDate?: Date, _eventDateEnd?: Date, _endHour?: Date) => {
		if (hasFeedbackQuestion !== 'yes') {
			return false;
		}
		const feedbackLimit = DateHelper.returnDate(_feedbackDate || eventFeedbackLimitDT);
		const eventEndDate = DateHelper.returnDate(_eventDateEnd || eventDateEnd);
		const feedbackLimitHr = DateHelper.getNumberTimeFromDate(_date || eventFeedbackLimitHour);
		const eventEndtHr = DateHelper.getNumberTimeFromDate(_endHour || eventEndHour);
		return (feedbackLimit === eventEndDate) && (feedbackLimitHr < eventEndtHr);
	}
	const invalidDateFeedback = (_feedbackDate?: Date, _eventDateEnd?: Date) => {
		if (hasFeedbackQuestion !== 'yes') {
			return false;
		}
		const feedbackLimit = DateHelper.returnDate(_feedbackDate || eventFeedbackLimitDT);
		const eventEndDate = DateHelper.returnDate(_eventDateEnd || eventDateEnd);
		return (feedbackLimit < eventEndDate);
	}

	const checkImageValid = () => {
		if (selectedTypeOfEvent !== "CUSTOM") return true;
		else if (selectedTypeOfEvent === "CUSTOM") return !!eventImg ? true : false;
	}

	const checkComunicationValid = () => {
		if (!communicationType) {
			return false;
		}
		return COMUNICATION_TYPES.some(type => communicationType === type.name);
	};

	const checkPlaceisValid = () => {
		if (!placeInvalid)
			return true;
		else {
			hideLoader();
			setErrorModalMessage(t('error.place-invalid'));
			setShowErrorModal(true);
			return false;
		}
	}

	const validateAll = (form: any) => {
		const hostsValid = validateParticipants(event.hosts);
		setShowErrorHosts(!hostsValid);

		const plannersValid = validateParticipants(event.planners);
		setShowErrorPlanners(!plannersValid);

		const admissionsValid = validateParticipants(event.admissions);
		setShowErrorAdmissions(!admissionsValid);

		setIsInvalidEndHour(invalidTime(eventStartHour, eventEndHour));
		setIsInvalidInscriptionLimitHour(invalidTimeInscription());
		setIsInvalidInscriptionLimitDate(invalidDateInscription());
		return (
			form.checkValidity() && !!includeIdentification &&
			checkPlaceisValid() &&
			!!includeMenu && !!event.inscriptionType &&
			((event.inscriptionType === 'GROUPAL_INSCRIPTION' && !!eventCompanionsLimit) || event.inscriptionType === 'SINGLE_INSCRIPTION') &&
			!invalidTime(eventStartHour, eventEndHour) &&
			!invalidTimeInscription() &&
			!invalidDateInscription() &&
			((hasFeedbackQuestion === 'true' && !invalidDateFeedback() && !invalidTimeFeedback()) || hasFeedbackQuestion !== 'true') &&
			!!eventDate && !!eventDateEnd && !!eventInscriptionLimitDT && !!eventInscriptionLimitHour &&
			hostsValid &&
			plannersValid &&
			admissionsValid &&
			checkImageValid() &&
			(SHOW_CONTACT_METHOD ? checkComunicationValid() : true) &&
			(SHOW_SECTORS ? checkSectors() : true)
		);
	};
	const checkSectors = () => {
		const err = sectors?.filter(
			(x: any) => x.hasError && !x.deleted
		);
		return !err || err?.length === 0;
	}
	const handleHostsChange = (opt: any) => {
		event.hosts = opt;
		const hostsValid = validateParticipants(opt);
		setShowErrorHosts(!hostsValid);
		setIsDirty(true);
	};
	const handlePlannersChange = (opt: any) => {
		event.planners = opt;
		const plannersValid = validateParticipants(opt);
		setShowErrorPlanners(!plannersValid);
		setIsDirty(true);
	};
	const handleAdmissionsChange = (opt: any) => {
		event.admissions = opt;
		const admissionsValid = validateParticipants(opt);
		setShowErrorAdmissions(!admissionsValid);
		setIsDirty(true);
	};
	const getOptions = (arr: any[] | undefined) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						x['name'] +
						(x['surname'] ? ' ' + x['surname'] : '') +
						' (' +
						x['email'] +
						')',
					value: x['_id'],
				};
				options.push(opt);
			});
		}
		return options;
	};

	const validateParticipants = (arr: any[]) => {
		return arr && arr.length > 0;
	};
	const getSelected = (selectedOptions: any[]) => {
		const ids = selectedOptions.map((x: any) => {
			if (x._id) {
				return x._id;
			}
			return x.value;
		});
		return ids;
	};

	const handleOnSelectEventType = (e: any) => {
		const r = !!eventTypes ? eventTypes.find((x: any) => x.name == e.name) : undefined;
		event.eventType = r.name;
		setIsDirty(true);
	};

	const handleOnSelectInscriptionType = (e: any) => {
		const r = !!inscriptionTypes ? inscriptionTypes.find((x: any) => x.name == e.name) : undefined;
		event.inscriptionType = r.name;
		setIsDirty(true);
	};
	const handleOnChangeMaxCapacity = (e: any) => {
		event.maxCapacity = e.target.value;
		setEventMaxCapacity(e.target.value);
		setIsDirty(true);
	};
	const handleOnChangeCompanionsLimit = (e: any) => {
		event.companionsLimit = e.target.value;
		setEventCompanionsLimit(e?.target.value);
		setIsDirty(true);
	};
	const isGuest = (rolesOrganizers: any[]) => {
		return (
			rolesOrganizers.find(
				(x: any) => x.organizerId == event.organizer._id && x.roleLevel == 0
			) != null
		);
	};


	const editHosts = async () => {
		const hostsIds = getSelected(event.hosts);
		const _selectedHosts = !!hosts ? hosts.filter(
			(x) => hostsIds.indexOf(x['_id']) >= 0
		) : [];
		let editableHosts =
			_selectedHosts &&
			_selectedHosts.filter((x) => isGuest(x.rolesOrganizers));
		if (editableHosts && editableHosts.length > 0) {
			for (const host of editableHosts) {
				let rolesOrg = host['rolesOrganizers'];
				const idx = rolesOrg.findIndex(
					(x: any) => x.organizerId == event.organizer._id
				);
				if (idx >= 0) {
					host['rolesOrganizers'][idx]['roleLevel'] = 2;
					host['rolesOrganizers'][idx]['roleName'] = 'USER_HOST';
					host['rolesOrganizers'][idx]['roleId'] =
						'5fd7a0ba6be66a3c3c9827c5';
					const updatedUsr: any = await dispatch(
						updateUser({
							_id: host['_id'],
							rolesOrganizers: host['rolesOrganizers'],
							lastUpdatedBy: loggedUser._id,
							lastUpdatedDT: new Date(),
						})
					);
					if (
						!updatedUsr ||
						!updatedUsr['payload'] ||
						!updatedUsr['payload']['_id']
					) {
						return false;
					}
				}
			}
		}
		return true;
	};
	const handleEventEdit = (e: any) => {
		setNotifyInscriptions(false);
		const form = e.currentTarget;

		if (!validateAll(form)) {
			e.preventDefault();
			e.stopPropagation();
		} else {
			e.preventDefault();
			e.stopPropagation();
			if (!!event) {
				setShowConfirmationModal(true);
			} else {
				handleSubmit(false);
			}
		}
		if (isDirty) {
			setValidated(true);
		}
	}
	/*const onConfirm = async (confirm: boolean) => {
		setShowConfirmationModal(false);
		if (confirm) {
			setShowConfirmationNotificationModal(true);
		}
	}

	const onConfirmSendNotifications = async (confirm: boolean) => {
		setShowConfirmationNotificationModal(false);
		setNotifyInscriptions(confirm);
		await handleSubmit(confirm);
	}*/

	const onConfirm = async (confirm: boolean) => {
		setShowConfirmationModal(false);
		if (confirm) {
			await handleSubmit(false);
		}
	}

	const cleanWhiteLinesOnDescription = () => {

		if (!!description) {
			var copyDesc = description;
			const patt = new RegExp(/(\n\n)/g);
			while (copyDesc.match(patt) != null) {
				copyDesc = copyDesc.replaceAll(patt, '\n');
			}
			return copyDesc;
		}
		return undefined;
	};
	const cleanSectors = () => {
		if (!!sectors && sectors.length > 0) {
			return sectors.filter(b => (!!!b.deleted));
		}
		return [];
	};
	const getQuestions = () => {
		if (!!questions && questions.length > 0) {
			return questions.filter(b => (!!!b.deleted));
		}
		return [];
	}
	const getFeedbackQuestions = () => {
		if (!!feedbackQuestions && feedbackQuestions.length > 0) {
			return feedbackQuestions.filter(b => (!!!b.deleted));
		}
		return [];
	}
	const handleSendReminder = () => {
		if (!!eventId) {
			setShowConfirmationReminderModal(true);
		}

	}
	const handleSendFeedback = () => {
		if (!!eventId) {
			setShowConfirmationFeedbackModal(true);
		}

	}

	const onConfirmSendReminder = async (confirm: boolean) => {
		setShowConfirmationReminderModal(false);
		if (confirm) {
			sendReminder();
		}
	}
	const onConfirmSendFeedback = async (confirm: boolean) => {
		setShowConfirmationFeedbackModal(false);
		if (confirm) {
			sendFeedback();
		}
	}
	const sendFeedback = async () => {
		try {
			showLoader();
			if (!!eventId) {
				const email = await sendEventFeedback(eventId);
				if (!!email) {
					setGoToEvents(false);
					setSuccessMessage(i18n.t('event.success-email-feedback'));
					setShowSuccessModal(true);
				} else {
					setErrorModalMessage(t('event.error-email-feedback'));
					setShowErrorModal(true);
				}
			}
		}
		catch (e) {
			setErrorModalMessage(t('event.error-email-feeedback'));
			setShowErrorModal(true);
		}
		finally {
			hideLoader();
		}

	}
	const sendReminder = async () => {
		try {
			showLoader();
			if (!!eventId) {
				const email = await sendEventReminder(eventId);
				if (!!email) {
					setGoToEvents(false);
					setSuccessMessage(i18n.t('event.success-email-reminder'));
					setShowSuccessModal(true);
				} else {
					setErrorModalMessage(t('event.error-email-reminder'));
					setShowErrorModal(true);
				}
			}
		}
		catch (e) {
			setErrorModalMessage(t('event.error-email-reminder'));
			setShowErrorModal(true);
		}
		finally {
			hideLoader();
		}

	}

	const handleSubmit = async (sendNotifications: boolean) => {
		try {
			showLoader();
			let uploadedImageUrl = undefined;
			if (imageIsDirty && eventImg) {
				const uploadedImage = await uploadImageCloud(eventImg, UPLOAD_TYPES[0].name);
				if (uploadedImage && uploadedImage.data) {
					uploadedImageUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage.data.public_id}.jpg`; //Aca podria ser sino public_id y solo guardar esos ids
				}
			}
			cleanWhiteLinesOnDescription();
			const _sectorsToSend = cleanSectors();
			const _questionsToSend = getQuestions();
			const _feedbackQuestionsToSend = getFeedbackQuestions();
			const evt: IEvent = {
				_id: eventId,
				name: eventName,
				description: cleanWhiteLinesOnDescription(),
				place,
				placeLink,
				startHour: DateHelper.getStringTimeFromDate(eventStartHour),
				endHour: DateHelper.getStringTimeFromDate(eventEndHour),
				date: DateHelper.saveDateWithoutTZ(eventDate),
				planners: getSelected(event.planners),
				hosts: getSelected(event.hosts),
				admissions: getSelected(event.admissions),
				maxCapacity: parseInt(event.maxCapacity),
				companionsLimit: parseInt(event.companionsLimit),
				lastUpdatedBy: loggedUser._id,
				lastUpdatedDT: new Date(),
				eventType: event.eventType,
				inscriptionType: event.inscriptionType,
				defaultImage: selectedTypeOfEvent,
				dateEnd: DateHelper.saveDateWithoutTZ(eventDateEnd),
				inscriptionLimitDT: DateHelper.saveDateWithoutTZ(eventInscriptionLimitDT),
				inscriptionLimitHour: DateHelper.getStringTimeFromDate(eventInscriptionLimitHour),
				includeMenu: includeMenu === 'yes',
				includeGender: includeGender === 'yes',
				includeDOB: includeDOB === 'yes',
				communication: communicationType,
				includeIdentification: includeIdentification === 'yes',
				sectors: _sectorsToSend,
				questions: _questionsToSend,
				feedbackQuestions: _feedbackQuestionsToSend,
				feedbackLimitDT: hasFeedbackQuestion === 'yes' ? DateHelper.saveDateWithoutTZ(eventFeedbackLimitDT) : undefined,
				feedbackLimitHour: hasFeedbackQuestion === 'yes' ? DateHelper.getStringTimeFromDate(eventFeedbackLimitHour) : undefined,
			};

			if (!!uploadedImageUrl) {
				evt.imageSrc = uploadedImageUrl;
			}
			if (!sendNotifications) {
				const response: any = await dispatch(updateEvent(evt));
				if (response['type'] == 'updateEvent/fulfilled') {
					await editHosts();
					hideLoader();
					setGoToEvents(true);
					setShowSuccessModal(true);
				} else {
					hideLoader();
					setErrorModalMessage(t('event.error-delete'));
					setShowErrorModal(true);
				}
			} else {
				const response: any = await dispatch(editEventNotifyInscriptions(evt));
				if (response['type'] == 'editEventNotifyInscriptions/fulfilled') {
					await editHosts();
					hideLoader();
					setGoToEvents(true);
					setSuccessMessage(!!event?.inscriptions && event.inscriptions.length > 0 ? i18n.t('event.success-notifications-with-inscriptions') : i18n.t('event.success-notifications-no-inscriptions'));
					setShowSuccessModal(true);
				} else {
					hideLoader();
					setErrorModalMessage(t('event.error-delete'));
					setShowErrorModal(true);
				}
			}

		}
		catch (error: any) {
			setErrorModalMessage(t('event.error-delete'));
			setShowErrorModal(true);
		} finally {
			hideLoader();
		}
	};
	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		history.goBack();
	};
	const handleCloseErrorModal = () => {
		setErrorModalMessage('');
		setShowErrorModal(false);
	}

	const handleCloseDeleteModal = async (e: any) => {
		setShowDeleteModal(false);
		if (!!e) {
			if (eventId) {
				// await dispatch(deleteEvent({ id: eventId }));
				// history.push('/events');
				showLoader();
				const deletedObj: any = await dispatch(updateEvent({
					_id: eventId,
					lastUpdatedBy: loggedUser._id,
					lastUpdatedDT: new Date(),
					deletedBy: loggedUser._id,
					deletedDT: new Date()
				}));
				hideLoader();

				if (deletedObj && deletedObj['payload']) {
					if (deletedObj['payload']['removed'] == true || deletedObj['payload']['removed'] == 'true') {
						if (deletedObj['payload']['inscriptions'] == true || deletedObj['payload']['inscriptions'] == 'true') {
							setSuccessMessage(i18n.t('event.success-notifications-with-inscriptions'));
							setGoToEvents(true);
							setShowSuccessModal(true);
						} else {
							setGoToEvents(true);
							setSuccessMessage(" ");
							setShowSuccessModal(true);
						}
					} else {
						setErrorModalMessage(t('event.error-delete'));
						setShowErrorModal(true);
					}
				}
			}
		}

	};

	const handleCloseSuccessModal = (e: any) => {
		if (!!e) {
			if (goToEvents) {
				if (successMessage != "") {
					history.push('/events');
				} else {
					//window.location.reload();
					history.push('/events');
				}
			}
		}
		setShowSuccessModal(false);
	};
	const handleCloseLinkCopiedModal = () => {
		setShowLinkCopiedModal(false);
	};
	useEffect(() => {
		handleEventId();
	}, [loggedUser]);

	useEffect(() => {
		handleKeyChange(key);
	}, [key, event]);

	const handleKeyChange = async (k: string) => {
		showLoader();
		//if (k === 'info' && !!event) {
		if (k === 'invitations' && !!event) {
			if (SHOW_SECTORS) {
				const response: any = await getSectorsAvailability(props.pEventId);
				if (!response.error && response?.sectorsAvailability) {
					setSectorsAvailability(response.sectorsAvailability);
				}
			}
			// if (!hosts) {
			// 	const h = await getMyHosts(event.organizer._id);
			// 	await setHosts(h);
			// }
			// if (!planners) {
			// 	const p = await getMyPlanners(event.organizer._id);
			// 	await setPlanners(p);
			// }
			// if (!admissions) {
			// 	const adm = await getMyAdmissions(event.organizer._id);
			// 	await setAdmissions(adm);
			// }
		}
		if (k === 'sectors' && SHOW_SECTORS) {
			const response = await getInscriptionsBySector(props.pEventId);
			setInscriptionsBySector([]);

			setInscriptionsBySector(response);
		}
		if (k === 'questions' && SHOW_QUESTIONS) {
			const response = await getInscriptionsAnswers(props.pEventId);
			setInscriptionsAnswersReport(undefined);

			setInscriptionsAnswersReport(response);
		}
		if (k === 'feedbackQuestions' && SHOW_QUESTIONS) {
			const response = await getInscriptionsFeedbackAnswers(props.pEventId);
			setInscriptionsFeedbackAnswersReport(undefined);

			setInscriptionsFeedbackAnswersReport(response);
		}
		hideLoader();
	};

	const handleEventId = async () => {
		showLoader();
		if (loggedUser && isLoggedUser && !event) {

			const ev: any = await getEventById(props.pEventId);
			if (ev && ev['_id']) {
				if (ev.eventType === 'PUBLIC_EVENT') {
					location.href = window.location.href.replace('/events/', '/events-public/');
				}
				setEvent(ev);
				setEventId(ev['_id']);
				const _inscriptionLink = (process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FRONTEND_URL_LOCAL : process.env.REACT_APP_FRONTEND_URL) +
					getEventInscriptionURL(ev);
				const shortURL = await shortenUrl(_inscriptionLink);
				setInscriptionLink(
					shortURL
				);
				setIncludeMenu(ev.includeMenu === true ? 'yes' : 'no');
				if ('includeGender' in ev) {
					setIncludeGender(ev.includeGender === true ? 'yes' : 'no');
				}
				if ('includeDOB' in ev) {
					setIncludeDOB(ev.includeDOB === true ? 'yes' : 'no');
				}
				setCommunicationType(prevCommunicationType => ev.communication || prevCommunicationType);
				setIncludeIdentification(ev.includeIdentification === true ? 'yes' : 'no');
				setSavedEventDate(DateHelper.getDateWithoutTZ(ev.date));
				setEventDate(DateHelper.getDateWithoutTZ(ev.date));
				setEventDateEnd(DateHelper.getDateWithoutTZ(ev.dateEnd));
				setEventInscriptionLimitDT(DateHelper.getDateWithoutTZ(ev.inscriptionLimitDT));
				setEventFeedbackLimitDT(DateHelper.getDateWithoutTZ(ev.feedbackLimitDT));
				if (ev.maxCapacity) {
					setEventMaxCapacity(ev.maxCapacity);
				}
				if (ev.companionsLimit) {
					setEventCompanionsLimit(ev.companionsLimit);
				}
				let sectorsCap = 0;
				if (!!ev.sectors) {
					sectorsCap = ev.sectors?.reduce((acumulador: any, actual: any) => acumulador + (actual.capacity || 0), 0);
				}
				setMinEventMaxCapacity(!!ev?.inscriptions && ev.inscriptions.length > 0 ? (ev.maxCapacity || 1) : (sectorsCap || 1));
				setMinEventMaxCapacityByInscriptions(!!ev?.inscriptions && ev.inscriptions.length > 0 ? (ev.maxCapacity || 1) : (sectorsCap || 1));
				setMinEventCompanionsLimit(!!ev?.inscriptions && ev.inscriptions.length > 0 ? (ev.companionsLimit || EVENT_COMPANIONS_MIN) : EVENT_COMPANIONS_MIN);
				setEventStartHour(DateHelper.getDateWithTime(ev.startHour));
				setEventEndHour(DateHelper.getDateWithTime(ev.endHour));
				setEventInscriptionLimitHour(DateHelper.getDateWithTime(ev.inscriptionLimitHour));
				setEventFeedbackLimitHour(DateHelper.getDateWithTime(ev.feedbackLimitHour));
				setHasSector((ev.sectors?.length > 0 && SHOW_SECTORS) ? "yes" : "no");
				setHasQuestion((ev.questions?.length > 0 && SHOW_QUESTIONS) ? "yes" : "no");
				setHasFeedbackQuestion((ev.feedbackQuestions?.length > 0 && SHOW_QUESTIONS) ? "yes" : "no");
				setFeedbackQuestions(ev.feedbackQuestions);
				setQuestions(ev.questions);
				setSectors(ev.sectors);
				if (ev.defaultImage) {
					await setSelectedTypeOfEvent(ev.defaultImage);
					if (ev.defaultImage === "CUSTOM") await setEventImg(ev.imageSrc);
				}

				const isHost_ =
					loggedUser['isHost'] ||
					(!loggedUser['isOrganizer'] &&
						ev.planners.filter((x: any) => x._id == loggedUser["_id"]).length == 0 &&
						ev.hosts.filter((x: any) => x._id == loggedUser['_id'])
							.length > 0);
				await setIsHost(isHost_);
				await setEditable(props.isEditable && !isHost_ && !props.preventEdit);
				const rt = getRemainingTimeAndDateStatus(ev);
				setIsPastEvent(rt.dateStatus === "ended");
			}
		}
		hideLoader();
	};
	const handleChangeDropzone = (e: any) => {
		if (e != null && e.length > 0) {
			setEventImg(e[0]);
			setIsDirty(true);
			setImageIsDirty(true);
		} else {
			setEventImg(null);
			setIsDirty(true);
			setImageIsDirty(true);
		}
	};
	const loadReportInscriptions = async () => {
		if (!!event && !!event._id) {
			return await getReportInscriptions(event._id);
		}
	};
	const loadReportMenu = async () => {
		if (!!event && !!event._id) {
			return await getReportMenu(event._id);
		}
	};

	const loadReportMenuDynamic = async (attendeed: boolean) => {
		if (!!event && !!event._id) {
			return await getReportMenuDynamic(event._id, attendeed);
		}
	};
	const loadReportPastEventInscriptions = async () => {
		if (!!event && !!event._id) {
			return await getReportPastEventInscriptions(event._id);
		}
	};
	const loadReporAttendeedtMenu = async () => {
		if (!!event && !!event._id) {
			return await getReportAttendeedMenu(event._id);
		}
	};
	const handleOnReport = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			const reportInscriptionsResult = props.isEditable ? await loadReportInscriptions() : await loadReportPastEventInscriptions();
			if (props.isEditable) {
				await InscriptionsReportPDF(event, reportInscriptionsResult);
			} else {
				await InscriptionsPastEventReportPDF(event, reportInscriptionsResult);
			}

			hideLoader();
		}
	}
	const handleOnReportSectors = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			const response = await getInscriptionsBySector(event._id);
			if (!!response && response.length > 0) {
				await SectorsReportPDF(event, response);

			} else {
				setErrorModalMessage(t('event.error-report-sector'));
				setShowErrorModal(true);
			}
			hideLoader();
		}
	}

	const handleOnReportQuestions = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			if (!!event && !!event._id) {
				const response = await getInscriptionsAnswers(event._id);
				if (!!response && response.report.length > 0) {
					await QuestionsReportPDF(event, response);

				} else {
					setErrorModalMessage(t('event.error-report-question'));
					setShowErrorModal(true);
				}
			}

			hideLoader();
		}
	}

	const handleOnReportFeedbackQuestions = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			if (!!event && !!event._id) {
				const response = await getInscriptionsFeedbackAnswers(event._id);
				if (!!response && response.report.length > 0) {
					await FeedbackQuestionsReportPDF(event, response);

				} else {
					setErrorModalMessage(t('event.error-report-question-feedback'));
					setShowErrorModal(true);
				}
			}

			hideLoader();
		}
	}

	const handleOnExportFeedbackQuestions = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			if (!!event && !!event._id) {
				const response = await getInscriptionsFeedbackAnswers(event._id);
				if (!!response && response.report.length > 0) {
					exportAnswersToExcel(response, event, 'FEEDBACK');
				} else {
					setErrorModalMessage(t('event.error-report-question-feedback'));
					setShowErrorModal(true);
				}
			}
			hideLoader();
		}
	}

	const handleOnExportQuestions = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			if (!!event && !!event._id) {
				const response = await getInscriptionsAnswers(event._id);
				if (!!response && response.report.length > 0) {
					exportAnswersToExcel(response, event, 'QUESTIONS');
				} else {
					setErrorModalMessage(t('event.error-report-question-feedback'));
					setShowErrorModal(true);
				}
			}
			hideLoader();
		}
	}

	const handleOnMenuReport = async () => {
		if (event?.inscriptions?.length === 0) {
			setErrorModalMessage(t('event.error-delete-report'));
			setShowErrorModal(true);
		} else {
			showLoader();
			const reportMenuDynamic = await loadReportMenuDynamic(!props.isEditable);
			await InscriptionsMenuReportDynamicPDF(event, reportMenuDynamic);

			hideLoader();
		}
	}
	const goTo = (route: string) => {
		history.push(route);
	}
	const getQtyAvailable = () => {
		const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.capacity || 0) : 0), 0);
		if (!!eventMaxCapacity) {
			return (eventMaxCapacity - sectorsQty) >= 0 ? eventMaxCapacity - sectorsQty : 0;
		}
		return 0;
	}
	const handleDeleteSector = (c: any) => {
		const idx = sectors?.indexOf(c);
		if (!!sectors && idx > -1) {

			sectors[idx]['deleted'] = true;
			const b = [...sectors];
			setSectors([]);
			setSectors(b);
		}
		const sc = sectors?.reduce((acumulador, actual) => acumulador + (!actual.deleted ? actual.capacity || 0 : 0), 0);

		if (minEventMaxCapacityByInscriptions <= sc) {
			setMinEventMaxCapacity(sc);
		} else {
			setMinEventMaxCapacity(minEventMaxCapacityByInscriptions);
		}
		setIsDirty(true);

	};
	const handleEditSector = (b: any, i: number) => {
		setSectorToEdit(b);
		setIsEditing(true);
		setSectorToEditIndex(i);
	}
	const addSector = (prevNumber: number) => {
		setValidated(false);
		const sector: ISector = {
			number: prevNumber + 1,
			name: undefined,
			capacity: undefined,
			availability: undefined
		};
		const b = [...sectors, sector];
		// setSectors([]);
		// setSectors(b);
		setIsEditing(false);
		setSectorToEdit(sector);
		setSectorToEditIndex(b.length - 1);
	};
	const handleAddSector = () => {
		const bN = sectors?.length === 0 ? 1 : sectorNumber + 1
		setSectorNumber(bN);
		addSector(bN);
	}
	const handleCloseEditModal = () => {
		setIsEditing(false);
		setSectorToEdit(undefined);
		setSectorToEditIndex(-1);
	}
	const handleSaveSectorModal = (modifiedSector: any, index: number) => {
		modifiedSector.availability = modifiedSector.capacity;
		const _btchs = sectors;
		_btchs[index] = modifiedSector;
		setSectors(_btchs);
		setIsEditing(false);
		setSectorToEdit(undefined);
		setSectorToEditIndex(-1);
		setIsDirty(true);
		const sc = _btchs.reduce((acumulador, actual) => acumulador + (!actual.deleted ? actual.capacity || 0 : 0), 0);

		if (minEventMaxCapacityByInscriptions <= sc) {
			setMinEventMaxCapacity(sc);
		} else {
			setMinEventMaxCapacity(minEventMaxCapacityByInscriptions);
		}

	}
	const getMaxQtyAvailable = (index: number) => {
		const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (i !== index && !actual.deleted ? (actual.capacity || 0) : 0), 0);
		if (!!eventMaxCapacity) {
			return (eventMaxCapacity - sectorsQty) >= 0 ? eventMaxCapacity - sectorsQty : 0;
		}
		return 0;
	}
	const getMinQtyAvailable = (sector: ISector) => {
		if (!!sectorsAvailability && !!sector) {
			const mysectorAvailability: any = sectorsAvailability.find((x: any) => x.sectorId === sector._id);
			return mysectorAvailability?.occupied || 1;
		}
		return 1;
	}
	const getSectorsCapacity = () => {
		return sectors?.reduce((acumulador, actual) => acumulador + (!actual.deleted ? actual.capacity || 0 : 0), 0);
	};
	const getMinMaxQtyFromSectors = () => {
		const sectorsQty = sectors?.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.capacity || 0) : 0), 0);
		const val = sectorsQty > 1 ? sectorsQty : 1;
		const acceptedInscriptions = event?.inscriptions?.filter((i: any) => i.inscriptionStatus === 'ACCEPTED' || i.inscriptionStatus === 'ATTENDEED');
		return !!acceptedInscriptions && acceptedInscriptions.length > 0 ? (acceptedInscriptions.length > val ? acceptedInscriptions.length : val) : val;
		//return sectorsQty > 1 ? sectorsQty : 1;
	}

	const handleCopyLink = async (e: any) => {
		e.stopPropagation();
		e.preventDefault();

		navigator.clipboard.writeText(
			inscriptionLink
		).then(() => {
			if (props.isEditable || (isHost && props.isEditable)) {
				setShowLinkCopiedModal(true);
			}
		})
	};

	const handleAddQuestion = () => {
		const q = questions?.length === 0 ? 1 : questionNumber + 1
		setQuestionNumber(q);
		addQuestion(q);
	}
	const addQuestion = (prevNumber: number) => {
		setValidated(false);

		const question: IQuestion = {
			index: prevNumber + 1,
			text: undefined,
			type: undefined,
			options: undefined
		};
		const b = [...questions, question];

		setIsEditing(false);
		setQuestionToEdit(question);
		setQuestionToEditIndex(b.length - 1);
	};
	const handleDeleteQuestion = (c: any) => {
		const idx = questions?.indexOf(c);
		if (!!questions && idx > -1) {
			questions[idx]['deleted'] = true;
			const b = [...questions];
			setQuestions([]);
			setQuestions(b);
		}
		setIsDirty(true);
	};
	const getQuestionsNotDeleted = () => {
		return !!questions ? questions?.filter(x => x.deleted !== true).length : 0;
	}
	const handleEditQuestion = (b: any, i: number) => {
		setQuestionToEdit(b);
		setIsEditing(true);
		setQuestionToEditIndex(i);
	};
	const handleCloseEditQuestionModal = () => {
		setIsEditing(false);
		setQuestionToEdit(undefined);
		setQuestionToEditIndex(-1);
	}
	const handleSaveQuestionModal = (modifiedQuestion: any, index: number) => {
		const _btchs = questions;
		_btchs[index] = modifiedQuestion;
		setQuestions(_btchs);
		setIsEditing(false);
		setIsDirty(true);
		setQuestionToEdit(undefined);
		setQuestionToEditIndex(-1);
	}

	const handleAddFeedbackQuestion = () => {
		const q = feedbackQuestions?.length === 0 ? 1 : feedbackQuestionNumber + 1
		setFeedbackQuestionNumber(q);
		addFeedbackQuestion(q);
	}
	const addFeedbackQuestion = (prevNumber: number) => {
		setValidated(false);

		const question: IQuestion = {
			index: prevNumber + 1,
			text: undefined,
			type: undefined,
			options: undefined
		};
		const b = [...feedbackQuestions, question];

		setIsEditing(false);
		setFeedbackQuestionToEdit(question);
		setFeedbackQuestionToEditIndex(b.length - 1);
	};
	const handleDeleteFeedbackQuestion = (c: any) => {
		const idx = feedbackQuestions?.indexOf(c);
		if (!!feedbackQuestions && idx > -1) {
			feedbackQuestions[idx]['deleted'] = true;
			const b = [...feedbackQuestions];
			setFeedbackQuestions([]);
			setFeedbackQuestions(b);
		}
		setIsDirty(true);
	};
	const getFeedbackQuestionsNotDeleted = () => {
		return !!feedbackQuestions ? feedbackQuestions?.filter(x => x.deleted !== true).length : 0;
	}
	const handleEditFeedbackQuestion = (b: any, i: number) => {
		setFeedbackQuestionToEdit(b);
		setIsEditing(true);
		setFeedbackQuestionToEditIndex(i);
	};
	const handleCloseEditFeedbackQuestionModal = () => {
		setIsEditing(false);
		setFeedbackQuestionToEdit(undefined);
		setFeedbackQuestionToEditIndex(-1);
	}
	const handleSaveFeedbackQuestionModal = (modifiedQuestion: any, index: number) => {
		const _btchs = feedbackQuestions;
		_btchs[index] = modifiedQuestion;
		setFeedbackQuestions(_btchs);
		setIsEditing(false);
		setIsDirty(true);
		setFeedbackQuestionToEdit(undefined);
		setFeedbackQuestionToEditIndex(-1);
	}
	const handleOnExport = async () => {
        if (event?.inscriptions?.length === 0) {
            setErrorModalMessage(t('event.error-delete-report'));
            setShowErrorModal(true);
        } else {
            showLoader();
            const reportInscriptionsResult = !isPastEvent ? await loadReportInscriptions() : await loadReportPastEventInscriptions();
            if (!!event) {
                exportInscriptionsToExcel(event, reportInscriptionsResult, isPastEvent);
            }
            hideLoader();
        }
    }
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{loader}
			{(!isLoggedUser) && <Redirect to="/login" />}
			{event && event.deletedBy && <Redirect to="/events" />}
			{showConfirmationModal && (
				<CustomModal
					buttonCancel={t('confirmation.no')}
					buttonOK={t('confirmation.yes')}
					isShowing={showConfirmationModal}
					title={t('confirm')}
					message={t('confirmation.update-event')}
					parentCallback={onConfirm}
					type="WARNING_EDIT"
				></CustomModal>
			)}
			{/* {showConfirmationNotificationModal && (
				<CustomModal
					buttonCancel={t('confirmation.no')}
					buttonOK={t('confirmation.yes')}
					isShowing={showConfirmationNotificationModal}
					title={t('confirm')}
					message={event.inscriptions && event.inscriptions.length > 0 ? t('event.notify-edit') : t('event.notify-edit-no-insc')}
					parentCallback={onConfirmSendNotifications}

					type="WARNING_EDIT"
				></CustomModal>
			)} */}
			{showConfirmationReminderModal && (
				<CustomModal
					buttonCancel={t('confirmation.no')}
					buttonOK={t('confirmation.yes')}
					isShowing={showConfirmationReminderModal}
					title={t('confirm')}
					message={t('event.send-reminder-confirmation')}
					parentCallback={onConfirmSendReminder}
					type="WARNING_EDIT"
				></CustomModal>
			)}
			{showConfirmationFeedbackModal && (
				<CustomModal
					buttonCancel={t('confirmation.no')}
					buttonOK={t('confirmation.yes')}
					isShowing={showConfirmationFeedbackModal}
					title={t('confirm')}
					message={t('event.send-feedback-confirmation')}
					parentCallback={onConfirmSendFeedback}
					type="WARNING_EDIT"
				></CustomModal>
			)}
			{isLoggedUser &&
				loggedUser &&
				!!event &&
				eventTypes &&
				inscriptionTypes &&
				isHost != undefined && (
					<div className="dashboard-content form">
						<Form
							className="form-container col-xl-8"
							onSubmit={handleEventEdit}
							noValidate
							validated={validated}
							action="/events"
						>
							<div className="d-flex justify-content-between align-items-center mb-3">
								<div className="d-flex justify-content-start page-title align-items-center">
									<div
										className="button-back mr-2"
										onClick={(e: any) => handleOnCancel(e)}
									>
										<IoIosArrowBack />
									</div>
									{editable && `${t('event.manage-inscriptions')}`}
									{!editable && `${t('event.manage-inscriptions')}`}
								</div>

								<div className="d-flex justify-content-end align-items-center">
									{/* {editable && <Button
										className="rounded-button rounded-button-delete mr-2 hide-small"
										title={t('event.delete')}
										onClick={(e: any) =>
											setShowDeleteModal(true)
										}
									>
										<IoIosTrash />
									</Button>}
									{SHOW_CHARTS && <Button className="mr-2 rounded-button rounded-button-ok hide-small" onClick={()=>goTo("/charts/event/"+props.pEventId)}>
												<BsGraphUp />
											</Button>}
									<Button
										className="rounded-button rounded-button-ok mr-2 hide-small"
										title={t('event.edit-print-list')}
										onClick={
											handleOnReport
										}
									>
										<IoIosPrint />
									</Button>

									{event.includeMenu &&
										<Button
											className="rounded-button rounded-button-ok mr-2 hide-small"
											title={t('event.edit-print-menu')}
											onClick={
												handleOnMenuReport
											}
										>
											<MdRestaurantMenu />
										</Button>} */}
									{(editable || hasFeedbackQuestion === 'yes') && <Button
										disabled={!isDirty}
										className={key != 'info' ? "d-none" : "rounded-button rounded-button-save"}
										type="submit"
									>
										<IoIosSave></IoIosSave>
									</Button>}

									<Dropdown>
										<Dropdown.Toggle bsPrefix="event-edit-menu" className="rounded-button rounded-button-ok ml-2">
											<IoMdMore size={28} />
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{!!event.qrImage &&
												<a className="dropdown-item" download={event.name?.replace(' ', '_') + '.png'} href={event.qrImage}> {t('event.download-QR')}</a>
											}
											{editable && <Dropdown.Item onClick={(e: any) => setShowDeleteModal(true)}>{t('event.delete')}</Dropdown.Item>}
											{/* <Dropdown.Item onClick={(e: any) => handleOnReport()}>{t('event.edit-print-list')}</Dropdown.Item> */}
											{event.includeMenu &&
												<Dropdown.Item onClick={(e: any) => handleOnMenuReport()} >
													{t('event.edit-print-menu')}
												</Dropdown.Item>}
											{SHOW_CHARTS && <Dropdown.Item onClick={(e: any) => goTo("/charts/event/" + props.pEventId)}>{'Charts'}</Dropdown.Item>}
											{hasSector === 'yes' && SHOW_SECTORS && <Dropdown.Item onClick={(e: any) => handleOnReportSectors()}>{t('event.sector.print')}</Dropdown.Item>}
											{/* {hasQuestion === 'yes' && SHOW_QUESTIONS && <Dropdown.Item onClick={(e: any) => handleOnReportQuestions()}>{t('event.question.print')}</Dropdown.Item>} */}
											<Dropdown.Item onClick={(e: any) => handleOnExport()}>{t('event.export-list')}</Dropdown.Item>
											{hasQuestion === 'yes' && SHOW_QUESTIONS && <Dropdown.Item onClick={(e: any) => handleOnExportQuestions()}>{t('event.question.export-answers')}</Dropdown.Item>}
											{hasFeedbackQuestion === 'yes' && !editable && SHOW_QUESTIONS && <Dropdown.Item onClick={(e: any) => handleOnReportFeedbackQuestions()}>{t('event.question.print-feedback')}</Dropdown.Item>}
											{hasFeedbackQuestion === 'yes' && !editable && SHOW_QUESTIONS && <Dropdown.Item onClick={(e: any) => handleOnExportFeedbackQuestions()}>{t('event.question.export-feedback')}</Dropdown.Item>}
											{!!event.inscriptions && event.inscriptions.filter((x: IInscription) => x.inscriptionStatus === 'ACCEPTED').length > 0 && <Dropdown.Item onClick={(e: any) => handleSendReminder()}>{t('event.send-reminder')}</Dropdown.Item>}
											{hasFeedbackQuestion === 'yes' && !editable && !!event.inscriptions && event.inscriptions.filter((x: IInscription) => x.inscriptionStatus === 'ATTENDEED').length > 0 && <Dropdown.Item onClick={(e: any) => handleSendFeedback()}>{t('event.send-feedback-link')}</Dropdown.Item>}
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>

							<div className="form-info-container">
							{/* defaultActiveKey={props.details ? 'info' : 'invitations'} */}

								<Tabs
									defaultActiveKey={'invitations'}
									id="event-tabs"
									transition={false}
									className="custom-tabs"
									activeKey={key}
									onSelect={(k) => setKey(k)}
								>
									<Tab
									tabClassName='d-none'
										className="custom-tab"
										eventKey="info"
										title={t('info')}
									>
										{key == 'info' && (
											<div className="d-flex row pb-5">
												<div className="col-md-4 order-md-2">
													{/* { event.image && event.image.preview && (
											<img className="dropzone" src={event.image.preview}/>
										)}
										*/}
													<Form.Group controlId="formImg">
														<Form.Label className="input-label">
															{t(
																'event.select-image'
															)}
														</Form.Label>
														<Select
															isDisabled={!editable}
															className={validated && !checkImageValid() ? "select-control invalid" : "select-control"}
															placeholder={t('select')}
															options={typesOfEvents}
															value={typesOfEvents.find(x => x.name === selectedTypeOfEvent)}
															onChange={handleOnSelectTypeOfEvent}
														/>

														{selectedTypeOfEvent && selectedTypeOfEvent !== 'CUSTOM' &&
															(<div className="p-4">
																<img
																	className={
																		selectedTypeOfEvent +
																		' event-image-preview'
																	}
																	src={
																		'/assets/' +
																		selectedTypeOfEvent +
																		'.png'
																	}
																/>
															</div>
															)}
													</Form.Group>
													{selectedTypeOfEvent && selectedTypeOfEvent === 'CUSTOM' &&
														(<div className='mt-4'>
															<Dropzone
																className="dropzone"
																value={eventImg}
																onChangeStatus={handleChangeDropzone}
																setErrorModalMessage={setErrorModalMessage}
																setShowErrorModal={setShowErrorModal}
															/>
														</div>
														)}
												</div>
												<div className="col-md-8 order-md-1">
													<Form.Group controlId="formOrganizers">
														<Form.Label className="input-label">{`${t(
															'role.USER_ORGANIZER'
														)}`}</Form.Label>
														<Form.Control
															name="organizer"
															type="text"
															defaultValue={
																event.organizer
																	.name
															}
															disabled
														/>
													</Form.Group>

													<Form.Group controlId="formEditEvent">
														<Form.Label className="input-label">
															{`${t(
																'event.name'
															)}`}
														</Form.Label>
														<Form.Control
															disabled={!editable}
															type="text"
															name="name"
															placeholder={t(
																'event.name'
															)}
															required
															defaultValue={
																event.name
															}
															onChange={
																handleNameChange
															}
															maxLength={EVENT_NAME_MAX_LENGTH}
														/>
														<Form.Control.Feedback type="invalid">
															{`${t(
																'error.required'
															)}`}
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group>
														<Form.Label className="input-label">
															{`${t(
																'event.inscription-link'
															)}`}
														</Form.Label>
														<InputGroup
															onClick={handleCopyLink}
														>
															<Form.Control
																id="copy-link"
																value={
																	inscriptionLink
																}
																readOnly
																type="text"
																disabled={
																	!editable
																}
															></Form.Control>
															<FaRegCopy className="copy-link" />
														</InputGroup>
													</Form.Group>
													<Form.Group>
														<Form.Label className="input-label">
															{`${t(
																'event.description'
															)}`}
														</Form.Label>
														<Form.Control
															maxLength={EVENT_DESCRIPTION_MAX_LENGTH}
															disabled={!editable}
															as="textarea"
															rows={3}
															className="textarea-gala"
															type="text"
															name="description"
															placeholder={t(
																'event.description'
															)}
															required
															defaultValue={
																event.description
															}
															onChange={
																handleDescriptionChange
															}
														/>
														<Form.Control.Feedback type="invalid">
															{`${t(
																'error.required'
															)}`}
														</Form.Control.Feedback>
													</Form.Group>
													<Form.Group>
														<Form.Label className="input-label">
															{' '}
															{`${t('place')}`}
														</Form.Label>
														<Places disabled={!editable} required={true} validated={validated} setPlace={handlePlaceChange} place={event.place}
															setPlaceLink={setPlaceLink} placeLink={event.placeLink} setPlaceInvalid={setPlaceInvalid} placeInvalid={placeInvalid}></Places>

													</Form.Group>
													<Form.Group>
														<div className="d-flex row">
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{' '}
																	{`${t(
																		'date-start'
																	)}`}
																</Form.Label>
																{/* <DatePicker
																	required
																	locale={lang}
																	dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
																	className='form-control date'
																	disabled={!editable}
																	selected={eventDate}
																	maxDate={maxDate}
																	minDate={tomorrow}
																	onChange={handleDateChange}

																></DatePicker> */}
																<CustomDatePicker
																	disabled={!editable}
																	selected={eventDate}
																	maxDate={maxDate}
																	minDate={(!!savedEventDate && savedEventDate < tomorrow) ? savedEventDate : tomorrow}
																	isInvalid={validated && !eventDate}
																	isValid={validated && !!eventDate}
																	onCustomDateInputChange={handleDateChange}></CustomDatePicker>
																{validated && !eventDate && <Form.Control.Feedback type="invalid" className="custom-error">
																	{!eventDate &&
																		`${t(
																			'error.required'
																		)}`}
																</Form.Control.Feedback>}
															</div>
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'start-time'
																	)}`}
																</Form.Label>
																<InputGroup
																	className="mb-3"
																	hasValidation
																>

																	<DatePicker
																		disabled={!editable}
																		className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
																		selected={eventStartHour}
																		onChange={handleStartChange}
																		showTimeSelect
																		showTimeSelectOnly
																		timeIntervals={15}
																		timeFormat="HH:mm"
																		dateFormat="HH:mm"
																		required
																	/>
																	{validated && !eventStartHour && <Form.Control.Feedback type="invalid" className='custom-error'>
																		{!eventStartHour &&
																			`${t(
																				'error.required'
																			)}`}
																	</Form.Control.Feedback>}
																</InputGroup>
															</div>
														</div>
													</Form.Group>

													<Form.Group>
														<div className="d-flex row">
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{' '}
																	{`${t(
																		'date-end'
																	)}`}
																</Form.Label>

																{/* <DatePicker
																	required
																	locale={lang}
																	dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
																	className='form-control date'
																	disabled={!editable}
																	selected={eventDateEnd}
																	maxDate={maxDate}
																	minDate={eventDate}
																	onChange={handleDateEndChange}
																></DatePicker> */}
																<CustomDatePicker
																	disabled={!editable}
																	selected={eventDateEnd}
																	maxDate={maxDate}
																	minDate={eventDate || tomorrow}
																	isInvalid={validated && !eventDateEnd}
																	isValid={validated && !!eventDateEnd}
																	onCustomDateInputChange={handleDateEndChange}></CustomDatePicker>
																{validated && !eventDateEnd && <Form.Control.Feedback type="invalid" className='custom-error'>
																	{!eventDateEnd &&
																		`${t(
																			'error.required'
																		)}`}
																</Form.Control.Feedback>}
															</div>

															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'end-time'
																	)}`}
																</Form.Label>
																<InputGroup
																	className="mb-3"
																	hasValidation
																>
																	<DatePicker
																		disabled={!editable}
																		className={isInvalidEndHour ? 'form-control time is-invalid' : 'form-control time'}
																		selected={eventEndHour}
																		onChange={handleEndChange}
																		showTimeSelect
																		showTimeSelectOnly
																		timeIntervals={15}
																		timeFormat="HH:mm"
																		dateFormat="HH:mm"
																		required
																	/>

																	{validated && !eventEndHour && <Form.Control.Feedback type="invalid" className='custom-error'>
																		{!eventEndHour &&
																			`${t(
																				'error.required'
																			)}`}
																	</Form.Control.Feedback>}
																</InputGroup>
															</div>
														</div>
													</Form.Group>
													<Form.Group>
														<div className="d-flex row">
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{' '}
																	{`${t(
																		'date-limit-inscription'
																	)}`}
																</Form.Label>

																<CustomDatePicker
																	disabled={!editable}
																	selected={eventInscriptionLimitDT}
																	maxDate={eventDate || tomorrow}
																	minDate={(!!eventInscriptionLimitDT && eventInscriptionLimitDT < today) ? eventInscriptionLimitDT : today}
																	isInvalid={validated && !eventInscriptionLimitDT}
																	isValid={validated && !!eventInscriptionLimitDT}
																	onCustomDateInputChange={handleInscriptionLimitDTChange}></CustomDatePicker>
																{validated && !eventInscriptionLimitDT && <Form.Control.Feedback type="invalid" className='custom-error'>
																	{!eventInscriptionLimitDT &&
																		`${t(
																			'error.required'
																		)}`}
																</Form.Control.Feedback>}
															</div>

															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'time-limit-inscription'
																	)}`}
																</Form.Label>
																<InputGroup
																	className="mb-3"
																	hasValidation
																>
																	<DatePicker
																		disabled={!editable}
																		className={isInvalidInscriptionLimitHour ? 'form-control time is-invalid' : 'form-control time'}
																		selected={eventInscriptionLimitHour}
																		onChange={handleInscriptionLimitHourChange}
																		showTimeSelect
																		showTimeSelectOnly
																		timeIntervals={15}
																		timeFormat="HH:mm"
																		dateFormat="HH:mm"
																		required
																	/>
																	{validated && !eventInscriptionLimitHour && <Form.Control.Feedback type="invalid" className='custom-error'>
																		{!eventInscriptionLimitHour &&
																			`${t(
																				'error.required'
																			)}`}
																	</Form.Control.Feedback>}

																</InputGroup>
															</div>
														</div>
													</Form.Group>
													<Form.Group>
														<div className="d-flex row align-items-end">
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'event.include-menu'
																	)}`}
																</Form.Label>
																<Select
																	isDisabled={!editable || (!!event?.inscriptions && event.inscriptions.length > 0)}
																	className={validated ? "select-control valid" : "select-control"}
																	placeholder={t('select')}
																	options={includeMenuOptions}
																	value={includeMenuOptions.find(x => x.name === includeMenu)}
																	onChange={handleIncludeMenuChange}
																/>
															</div>
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'event.include-identification'
																	)}`}
																</Form.Label>
																<Select
																	isDisabled={!editable || (!!event?.inscriptions && event.inscriptions.length > 0)}
																	className={validated ? "select-control valid" : "select-control"}
																	placeholder={t('select')}
																	options={includeIdentificationOptions}
																	value={includeIdentificationOptions.find(x => x.name === includeIdentification)}
																	onChange={handleIncludeIdentificationChange}
																/>

															</div>
														</div>
														<div className="d-flex row align-items-stretch">
															<div className={event.inscriptionType === 'GROUPAL_INSCRIPTION' ? "col-md-4" : "col-md-6"}>
																<Form.Label className="input-label">
																	{`${t(
																		'event.max-capacity'
																	)}`}
																</Form.Label>
																<Form.Control
																	required
																	min={getMinMaxQtyFromSectors()}
																	isInvalid={!!eventMaxCapacity && eventMaxCapacity < getMinMaxQtyFromSectors()}
																	disabled={
																		!editable
																	}
																	name="maxCapacity"
																	type="number"
																	defaultValue={
																		event.maxCapacity
																	}
																	onChange={
																		handleOnChangeMaxCapacity
																	}
																></Form.Control>
																<Form.Control.Feedback type="invalid">
																	{!eventMaxCapacity && `${t('error.required')}`}
																	{eventMaxCapacity && `${t(
																		'event.max-capacity-min', { min: getMinMaxQtyFromSectors() }
																	)}`}
																</Form.Control.Feedback>


															</div>
															<div className={event.inscriptionType === 'GROUPAL_INSCRIPTION' ? "col-md-4" : "col-md-6"}>
																<Form.Label className="input-label">
																	{`${t(
																		'event.inscription-type'
																	)}`}
																</Form.Label>
																<Select
																	isDisabled={!editable || (!!event?.inscriptions && event.inscriptions.length > 0)}
																	className={validated ? "select-control valid" : "select-control"}
																	placeholder={t('select')}
																	options={inscriptionTypes}
																	value={inscriptionTypes.find(x => x.name === event.inscriptionType)}
																	onChange={handleOnSelectInscriptionType}
																/>

															</div>
															<div className={event.inscriptionType === 'GROUPAL_INSCRIPTION' ? "col-md-4" : "d-none"}>
																<Form.Label className="input-label">
																	{`${t(
																		'event.companions-limit'
																	)}`}
																</Form.Label>
																<Form.Control
																	disabled={
																		!editable
																	}
																	defaultValue={event.companionsLimit}
																	required={event.inscriptionType === 'GROUPAL_INSCRIPTION'}
																	name="companionsLimit"
																	type="number"
																	max={EVENT_COMPANIONS_MAX}
																	onChange={
																		handleOnChangeCompanionsLimit
																	}
																	min={minEventCompanionsLimit}
																	isInvalid={!!eventCompanionsLimit && eventCompanionsLimit < minEventCompanionsLimit}
																></Form.Control>
																{validated && !!event.companionsLimit && <Form.Control.Feedback type="invalid">
																	{`${t(
																		'event.companions-limit-value', { min: minEventCompanionsLimit, max: EVENT_COMPANIONS_MAX }
																	)}`}
																</Form.Control.Feedback>}
																{validated && !event.companionsLimit && <Form.Control.Feedback type="invalid" className='custom-error'>
																	{!event.companionsLimit && `${t('error.required')}`}
																</Form.Control.Feedback>}
															</div>
														</div>
														{/*Contact method*/}
														<div className="d-flex row align-items-end">
															<div className="col-md-6">
																<Form.Label className="input-label">
																	{`${t(
																		'event.contact'
																	)}`}
																</Form.Label>
																<Select
																	isDisabled={!editable || (!!event?.inscriptions && event.inscriptions.length > 0)}
																	className={validated ? "select-control valid" : "select-control"}
																	placeholder={t('select')}
																	options={communicationTypes}
																	value={communicationTypes.find(x => x.name === communicationType)}
																	onChange={handleOnSelectCommunicationType}
																/>
															</div>
														</div>
													</Form.Group>

													{/*Include gender and dob */}

													<div className="d-flex row">
														<div className="col-md-6">
															<Form.Label className="input-label">
																{`${t('event.include-gender')}`}
															</Form.Label>
															<Select
																isDisabled={!editable || (!!event?.inscriptions && event.inscriptions.length > 0)}
																isInvalid={validated && !includeGender}
																className={validated && !includeGender ? "select-control invalid" : (validated && !!includeGender ? "select-control valid" : "select-control")}
																placeholder={t('select')}
																options={includeGenderOptions}
																value={includeGenderOptions.find(x => x.name === includeGender)}
																onChange={handleIncludeGenderChange}
															/>
															{validated && !includeGender && <Form.Control.Feedback type="invalid" className='custom-error'>
																{!includeGender && `${t('error.required')}`}
															</Form.Control.Feedback>}
														</div>

														<div className="col-md-6">
															<Form.Label className="input-label">
																{`${t('event.include-dob')}`}
															</Form.Label>
															<Select
																isDisabled={!editable || (!!event?.inscriptions && event.inscriptions.length > 0)}
																isInvalid={validated && !includeDOB}
																className={validated && !includeDOB ? "select-control invalid" : (validated && !!includeDOB ? "select-control valid" : "select-control")}
																placeholder={t('select')}
																options={includeDOBOptions}
																value={includeDOBOptions.find(x => x.name === includeDOB)}
																onChange={handleIncludeDOBChange}
															/>
															{validated && !includeDOB && <Form.Control.Feedback type="invalid" className='custom-error'>
																{!includeDOB && `${t('error.required')}`}
															</Form.Control.Feedback>}
														</div>
													</div>
													{/* sectors */}
													<div className={(hasSector === 'yes') ? 'form-group pt-3' : 'd-none'}>
														<hr></hr>
														<div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
															<label className="group-label m-0 ml-1">
																{`${t('event.sectors')} - ${getSectorsCapacity()}/${eventMaxCapacity}`}
															</label>
															{hasSector === 'yes' &&
																<div className="form-group">
																	<Button
																		disabled={getQtyAvailable() === 0 || !editable}
																		className="button-ok small"
																		onClick={handleAddSector}
																	>
																		<div className="d-flex align-items-center justify-content-center">
																			<div>
																				<IoAddOutline />
																			</div>
																			<div>
																				{t('event.add-sector')}
																			</div>
																		</div>
																	</Button>
																</div>}

														</div>

														{validated && (!sectors || sectors?.length === 0) && <Form.Control.Feedback
															type="invalid"
															className="global-error mb-3 mt-3"
														>
															<ErrorGlobal
																text={t('error.sectors-required')}
															></ErrorGlobal>
														</Form.Control.Feedback>}
														{/* !!sectorsAvailability &&  */}
														{!!sectors && sectors.length > 0 && !!sectorsAvailability && (
															<>
																{sectors.map((b, i) => (
																	<div key={i}>

																		{!b.deleted &&
																			<SectorRow
																				i={i}
																				sector={b}
																				otherSectors={sectors.filter(x => x.number !== b.number)}
																				handleDeleteSector={handleDeleteSector}
																				handleEditSector={handleEditSector}
																				eventId={event._id}
																				canEdit={editable}
																				sectorsAvailability={sectorsAvailability}
																			></SectorRow>
																		}
																	</div>
																))}
															</>
														)}
													</div>
													{/* end sectors */}

													{/* start questions */}
													<div className={(hasQuestion === 'yes') ? 'form-group pt-3' : 'd-none'}>
														<hr></hr>
														<div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
															<label className="group-label m-0 ml-1">
																{`${t('event.questions')} - ${getQuestionsNotDeleted()}/${QUESTIONS_LIMIT}`}
															</label>
															{hasQuestion === 'yes' &&
																<div className="form-group">
																	<Button
																		disabled={event.inscriptions?.length > 0 || getQuestionsNotDeleted() === QUESTIONS_LIMIT}
																		className="button-ok small"
																		onClick={handleAddQuestion}
																	>
																		<div className="d-flex align-items-center justify-content-center">
																			<div>
																				<IoAddOutline />
																			</div>
																			<div>
																				{t('event.add-question')}
																			</div>
																		</div>
																	</Button>
																</div>}

														</div>

														{validated && (!questions || questions.length === 0) && <Form.Control.Feedback
															type="invalid"
															className="global-error mb-3 mt-3"
														>
															<ErrorGlobal
																text={t('error.questions-required')}
															></ErrorGlobal>
														</Form.Control.Feedback>}
														{questions && questions.length > 0 && (
															<>
																{questions.map((b, i) => (
																	<div key={i}>
																		{!b.deleted &&
																			<QuestionRow
																				i={i}
																				question={b}
																				handleDeleteQuestion={handleDeleteQuestion}
																				handleEditQuestion={handleEditQuestion}
																				canEdit={event.inscriptions?.length === 0}
																			></QuestionRow>
																		}
																	</div>
																))}
															</>
														)}

														<hr></hr>
													</div>
													{/* end questions */}


													<div>
														<label className="input-label form-label">{`${t(
															'event.hosts'
														)}`}</label>

														<Select
															className={
																showErrorHosts
																	? 'select-control invalid'
																	: 'select-control'
															}
															isDisabled={
																!editable
															}
															placeholder={t(
																'select'
															)}
															options={getOptions(
																hosts
															)}
															defaultValue={event.hosts.map(
																(o: any) => ({
																	label:
																		o[
																		'name'
																		] +
																		(o[
																			'surname'
																		]
																			? ' ' +
																			o[
																			'surname'
																			]
																			: '') +
																		' (' +
																		o[
																		'email'
																		] +
																		')',
																	value:
																		o._id,
																})
															)}
															onChange={
																handleHostsChange
															}
															isMulti
															required
														/>
														{showErrorHosts && (
															<Form.Control.Feedback
																type="invalid"
																className="custom-error"
															>
																{`${t(
																	'error.hosts-required'
																)}`}
															</Form.Control.Feedback>
														)}
													</div>
													<div>
														<label className="input-label form-label">{`${t(
															'event.planners'
														)}`}</label>

														<Select
															className={
																showErrorPlanners
																	? 'select-control invalid'
																	: 'select-control'
															}
															isDisabled={
																!editable
															}
															placeholder={t(
																'select'
															)}
															options={getOptions(
																planners
															)}
															defaultValue={event.planners.map(
																(o: any) => ({
																	label:
																		o[
																		'name'
																		] +
																		(o[
																			'surname'
																		]
																			? ' ' +
																			o[
																			'surname'
																			]
																			: '') +
																		' (' +
																		o[
																		'email'
																		] +
																		')',
																	value:
																		o._id,
																})
															)}
															onChange={
																handlePlannersChange
															}
															isMulti
															required
														/>
														{showErrorPlanners && (
															<Form.Control.Feedback
																type="invalid"
																className="custom-error"
															>
																{`${t(
																	'error.planners-required'
																)}`}
															</Form.Control.Feedback>
														)}
													</div>
													<div>
														<label className="input-label form-label">{`${t(
															'event.admissions'
														)}`}</label>
														<Select
															className={
																showErrorAdmissions
																	? 'select-control invalid'
																	: 'select-control'
															}
															isDisabled={
																!editable
															}
															placeholder={t(
																'select'
															)}
															options={getOptions(
																admissions
															)}
															defaultValue={event.admissions.map(
																(o: any) => ({
																	label:
																		o[
																		'name'
																		] +
																		(o[
																			'surname'
																		]
																			? ' ' +
																			o[
																			'surname'
																			]
																			: '') +
																		' (' +
																		o[
																		'email'
																		] +
																		')',
																	value:
																		o._id,
																})
															)}
															onChange={
																handleAdmissionsChange
															}
															isMulti
															required
														/>
														{showErrorAdmissions && (
															<Form.Control.Feedback
																type="invalid"
																className="custom-error"
															>
																{`${t(
																	'error.admissions-required'
																)}`}
															</Form.Control.Feedback>
														)}
													</div>

													{/* start feedbackquestions */}
													<div className={(hasFeedbackQuestion === 'yes') ? 'form-group pt-3' : 'd-none'}>


														<div className='row justify-content-center align-items-center m-0 p-0 mb-2 mt-4'>
															<label className="event-separator-title m-0">
																{t('inscription.feedback')}
															</label>
														</div>
														<Form.Group>
															<div className="d-flex row">
																<div className="col-md-6">
																	<Form.Label className="input-label">
																		{' '}
																		{`${t(
																			'date-limit-feedback'
																		)}`}
																	</Form.Label>

																	<CustomDatePicker
																		disabled={false}
																		selected={eventFeedbackLimitDT}
																		maxDate={maxDate}
																		minDate={eventDateEnd || tomorrow}
																		isInvalid={validated && !eventFeedbackLimitDT && hasFeedbackQuestion === 'yes'}
																		isValid={(hasFeedbackQuestion === 'yes' && validated && !!eventFeedbackLimitDT) || hasFeedbackQuestion !== 'yes'}
																		onCustomDateInputChange={handleFeedbackLimitDTChange}></CustomDatePicker>
																	{validated && hasFeedbackQuestion === 'yes' && !eventFeedbackLimitDT && <Form.Control.Feedback type="invalid" className='custom-error'>
																		{!eventFeedbackLimitDT &&
																			`${t(
																				'error.required'
																			)}`}
																	</Form.Control.Feedback>}
																</div>

																<div className="col-md-6">
																	<Form.Label className="input-label">
																		{`${t(
																			'time-limit-feedback'
																		)}`}
																	</Form.Label>
																	<InputGroup
																		className="mb-3"
																		hasValidation
																	>
																		<DatePicker
																			disabled={false}
																			className={hasFeedbackQuestion === 'yes' && isInvalidFeedbackLimitHour ? 'form-control time is-invalid' : 'form-control time'}
																			selected={eventFeedbackLimitHour}
																			onChange={handleFeedbackLimitHourChange}
																			showTimeSelect
																			showTimeSelectOnly
																			timeIntervals={15}
																			timeFormat="HH:mm"
																			dateFormat="HH:mm"
																			required={hasFeedbackQuestion === 'yes'}
																		/>
																		{validated && hasFeedbackQuestion === 'yes' && !eventFeedbackLimitHour && <Form.Control.Feedback type="invalid" className='custom-error'>
																			{!eventFeedbackLimitHour &&
																				`${t(
																					'error.required'
																				)}`}
																		</Form.Control.Feedback>}

																	</InputGroup>
																</div>
															</div>
														</Form.Group>


														<div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
															<label className="group-label m-0 ml-1">
																{`${t('event.feedback-questions')} - ${getFeedbackQuestionsNotDeleted()}/${FEEDBACK_QUESTIONS_LIMIT}`}
															</label>
															{hasFeedbackQuestion === 'yes' &&
																<div className="form-group">
																	<Button
																		disabled={getFeedbackQuestionsNotDeleted() === FEEDBACK_QUESTIONS_LIMIT}
																		className="button-ok small"
																		onClick={handleAddFeedbackQuestion}
																	>
																		<div className="d-flex align-items-center justify-content-center">
																			<div>
																				<IoAddOutline />
																			</div>
																			<div>
																				{t('event.add-feedback-question')}
																			</div>
																		</div>
																	</Button>
																</div>}

														</div>

														{validated && hasFeedbackQuestion === 'yes' && (!feedbackQuestions || feedbackQuestions.length === 0) && <Form.Control.Feedback
															type="invalid"
															className="global-error mb-3 mt-3"
														>
															<ErrorGlobal
																text={t('error.questions-required')}
															></ErrorGlobal>
														</Form.Control.Feedback>}
														{feedbackQuestions && feedbackQuestions.length > 0 && (
															<>
																{feedbackQuestions.map((b, i) => (
																	<div key={i}>
																		{!b.deleted &&
																			<QuestionRow
																				i={i}
																				question={b}
																				handleDeleteQuestion={handleDeleteFeedbackQuestion}
																				handleEditQuestion={handleEditFeedbackQuestion}
																				canEdit={true}
																			></QuestionRow>
																		}
																	</div>
																))}
															</>
														)}

														<hr></hr>
													</div>
													{/* end feedback questions */}
												</div>
											</div>
										)}
									</Tab>
									<Tab
										className="custom-tab"
										eventKey="invitations"
										title={t('invites')}
									>
										{key == 'invitations'
											&& (
												<InscriptionsManager
												event={event}
													includeMenu={event.includeMenu}
													eventId={event._id}
													canEdit={props.isEditable}
													inscriptionType={event.inscriptionType}
													allInscriptions={event.inscriptions}
													statusTab={props.status}
													eventType={event.eventType}
													eventMaxCapacity={event.maxCapacity}
													includeSector={hasSector === 'yes' && SHOW_SECTORS}
													isPublic={event.eventType === 'PUBLIC_EVENT'}
												/>
											)}
									</Tab>
									{hasSector === 'yes' && SHOW_SECTORS && <Tab
										className="custom-tab"
										eventKey="sectors"
										title={t('event.sectors')}
									>
										{key == 'sectors' && !!inscriptionsBySector && inscriptionsBySector.length > 0
											&& (
												<SectorsManager eventId={event._id}
													inscriptionsBySector={inscriptionsBySector}
													includeMenu={includeMenu === 'yes'}></SectorsManager>

											)}
									</Tab>}
									{hasQuestion === 'yes' && SHOW_QUESTIONS && <Tab
										className="custom-tab"
										eventKey="questions"
										title={t('event.questions')}
									>
										{key == 'questions' && !!event.questions && event.questions.length > 0 && !!inscriptionsAnswersReport
											&& (
												<QuestionsSummary eventId={event._id}
													event={event}
													questions={event.questions}
													data={inscriptionsAnswersReport}
													includeMenu={includeMenu === 'yes'}></QuestionsSummary>

											)}
									</Tab>}
									{/* TODO feedback tab */}
									{hasFeedbackQuestion === 'yes' && !editable && SHOW_QUESTIONS && <Tab
										className="custom-tab"
										eventKey="feedbackQuestions"
										title={t('event.feedback-questions')}
									>
										{key == 'feedbackQuestions' && !!event.feedbackQuestions && event.feedbackQuestions.length > 0 && !!inscriptionsFeedbackAnswersReport
											&& (
												<QuestionsSummary eventId={event._id}
													questions={event.feedbackQuestions}
													event={event}
													data={inscriptionsFeedbackAnswersReport}
													includeMenu={false}></QuestionsSummary>

											)}
									</Tab>}
								</Tabs>
							</div>
						</Form>



						{showDeleteModal && (
							<CustomModal
								isShowing={showDeleteModal}
								parentCallback={handleCloseDeleteModal}
								message={event.inscriptions && event.inscriptions.length > 0 ? t('event.delete-message-with-inscriptions') : t('event.delete-message')}
								title={t('event.delete-title')}
								buttonCancel={t('cancel')}
								buttonOK={t('delete')}
								type="WARNING_DELETE"
							/>
						)}
						{showSuccessModal && !goToEvents && (
							<CustomModal
								isShowing={showSuccessModal}
								parentCallback={handleCloseSuccessModal}
								title={successMessage}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}
						{showSuccessModal && goToEvents === true && (
							<CustomModal
								isShowing={showSuccessModal}
								parentCallback={handleCloseSuccessModal}
								message={successMessage}
								title={(successMessage != '' && !notifyInscriptions) ? t('event.success-delete') : t('success.event-update')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}
						{showLinkCopiedModal && (
							<CustomModal
								isShowing={showLinkCopiedModal}
								parentCallback={handleCloseLinkCopiedModal}
								message={t('link-copied-success-message')}
								title={t('link-copied-success-title')}
								type="SUCCESS"
								buttonOK={t('accept')}
							/>
						)}

						{showErrorModal && (
							<CustomModal
								isShowing={showErrorModal}
								parentCallback={handleCloseErrorModal}
								message={errorModalMessage}
								title={t('error.modal-title-oops')}
								buttonOK={t('accept')}
								type="ERROR"
							/>
						)}
						{sectorToEdit &&
							<SectorForm
								isEditing={isEditing}
								index={sectorToEditIndex}
								sector={sectorToEdit}
								otherSectors={sectors?.filter(x => x.number !== sectorToEdit.number && !x.deleted)}
								maxCapacityAvailable={getMaxQtyAvailable(sectorToEditIndex)}
								minCapacityAvailable={getMinQtyAvailable(sectorToEdit)}
								onHideModal={handleCloseEditModal}
								onSaveSector={handleSaveSectorModal}
							></SectorForm>}
						{questionToEdit &&
							<QuestionForm
								isEditing={isEditing}
								index={questionToEditIndex}
								question={questionToEdit}

								onHideModal={handleCloseEditQuestionModal}
								onSaveQuestion={handleSaveQuestionModal}
							></QuestionForm>}
						{feedbackQuestionToEdit &&
							<QuestionForm
								isEditing={isEditing}
								index={feedbackQuestionToEditIndex}
								question={feedbackQuestionToEdit}
								isFeedback={true}
								onHideModal={handleCloseEditFeedbackQuestionModal}
								onSaveQuestion={handleSaveFeedbackQuestionModal}
							></QuestionForm>}
					</div>
				)}
		</>
	);
}

export default EditEvents;
