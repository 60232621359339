import i18next from 'i18next';
import { IEvent } from '../models/interfaces/events.interface';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getAverage } from '../helpers/events-helper';
import { IInscription } from '../models/interfaces/inscriptions.interface';
import { DateHelper } from './date-helper';

const getText = (a: string) => {
    return a == 'yes' ? i18next.t('confirmation.' + a) : (a == 'no' ? i18next.t('confirmation.' + a) : a);
}

const getSummaryText = (summary: any, question: string) => {
    const sum: string[] = [];
    const average = i18next.t('average')
    if (!summary) {
        return '';
    }
    sum.push(question);
    if (summary.type !== 'SCALE') {
        for (const option of summary.options) {
            sum.push(getText(option.text));
            sum.push(option.sum.toString());
        }
    } 
    else {
        sum.push(average);
        sum.push(getAverage(summary.options).toString());
    }
    return sum;
}

const getInscriptionData = (event: IEvent, inscription: IInscription) => {
    const user: any = { };
    user[i18next.t('user.lastname')] = inscription.surname || '';
    user[i18next.t('user.name')] = inscription.name || '';
    user[i18next.t('user.email')] = inscription.email || '';
    
    user[i18next.t('user.inscriptionStatus')] = !!inscription.inscriptionStatus ? i18next.t('inscription.status.' + inscription.inscriptionStatus) : '';

    switch(inscription.inscriptionStatus){

        case "ACCEPTED": {
            user[i18next.t('accepted-by')] = (!!(inscription.acceptedBy?.name) ? inscription.acceptedBy.name : '' + 
                                            !!(inscription.acceptedBy?.surname) ? ' ' + inscription.acceptedBy?.surname : '');
            user[i18next.t('accepted-date')] = DateHelper.dateAndTime(inscription.acceptedDT?.toString() ||  '');
            break;
        }

        case "ATTENDEED": {
            user[i18next.t('attendeed-by')] = (!!(inscription.attendeedBy?.name) ? inscription.attendeedBy.name : '' + 
                                                !!(inscription.attendeedBy?.surname) ? ' ' + inscription.attendeedBy?.surname : '');
            user[i18next.t('attendeed-date')] = DateHelper.dateAndTime(inscription.attendeedDT?.toString() ||  '');
            break;
        }

        case "DENIED": {
            user[i18next.t('denied-by')] = (!!(inscription.deniedBy?.name) ? inscription.deniedBy.name : '' + 
                                            !!(inscription.deniedBy?.surname) ? ' ' + inscription.deniedBy?.surname : '');
            user[i18next.t('denied-date')] = DateHelper.dateAndTime(inscription.deniedDT?.toString() || '');
            break;
        }

        default: break;
        
    }

    if (event.includeGender) {
        user[i18next.t('user.gender')] = !!inscription.gender ? i18next.t('gender.' + inscription.gender) : '';
    }
    if (event.includeDOB || event.eventType === 'PUBLIC_EVENT') {
        user[i18next.t('user.age-group')] = !!inscription.group ? i18next.t('age-group.' + inscription.group) : '';
        user[i18next.t('user.dob')] = DateHelper.onlyDateFromString(inscription.dob?.toString() || '');
    }

    if (event.includeIdentification) {
        user[i18next.t('identification-type-label')] = !!inscription.identificationType ? i18next.t('identification-type.' + inscription.identificationType) : '';
        user[i18next.t('identification-number')] = inscription.identificationNumber || '';
    }
    
    if (event.includeMenu) {
        user[i18next.t('event.menu-label')] = !!inscription.menu ? i18next.t('event.menu.' + inscription.menu) : '';
        user[i18next.t('event.menu-condition-label')] = !!inscription.menuCondition ? i18next.t('event.menu-condition.' + inscription.menuCondition) : '';
        user[i18next.t('event.menu-condition-label-other')] = inscription.menuOther || '';
    }
    if (event.hasSectors =='yes'){
        user[i18next.t('event.sector-label')] = !!inscription.sector?.name ? inscription.sector.name : i18next.t('event.sector.no-sector');

    }
    return user;
};

export const exportAnswersToExcel = (data: any, event: IEvent, title: string) => {
    const excelData: any = [];
    const arrayData: any[] = [];
    data.summary.map((sum: any, i: number) => {
        data.questions.map((question: any) => {
            if (question._id === sum.questionId) {
                getSummaryText(sum, question.text) !== '' && arrayData.push(getSummaryText(sum, question.text));
            }
        })
    })

    let baseQuestion: string = '';

    if (arrayData.length > 0) {
        baseQuestion = arrayData[0][0];
    }

    for (let i = 0; i < arrayData.length; i++) {
        const currentQuestion = arrayData[i][0];
        const nextQuestions: any = {};
        for (let j = 1; j < arrayData[i].length; j += 2) {
            const firstQuestion: any = {};
            if (i > 0) {
                nextQuestions[baseQuestion] = currentQuestion || '';
                nextQuestions[''] = '';
            }
            else {
                firstQuestion[baseQuestion] = arrayData[i][j] || '';
                firstQuestion[''] = arrayData[i][j + 1] || '';
                excelData.push(firstQuestion)
            }
        }
        if (i > 0) {
            excelData.push(nextQuestions)
        }

        if (i > 0) {
            for (let j = 1; j < arrayData[i].length; j += 2) {
                const nextQuestions: any = {}
                nextQuestions[baseQuestion] = arrayData[i][j]
                nextQuestions[''] = arrayData[i][j + 1]
                excelData.push(nextQuestions);
            }
        }
    }

    const formattedData: any[] = [];
    const userResponses: { [key: string]: any } = {};
    let wscols: any[] = [];


    data.report.forEach((entry: any) => {
        let user: any = getInscriptionData(event, entry);
        formattedData.push(user);
        userResponses[entry.user] = user;
    });


    data.report.forEach((entry: any) => {
        const user = userResponses[entry.user];
        entry.answers.forEach((answer: any) => {
            if (answer.questionType === 'SCALE') {
                user[answer.questionText] = answer.answerText.split(' ').splice(2).join(' ') || '-';
                let scaleNumber = answer.answerText.split(' ')[0];
                user['1-10'] = scaleNumber;
            }
            else {
                user[answer.questionText] = getText(answer.answerText);
            }
        });
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    const workbook = XLSX.utils.book_new();
    if (worksheet) {
        for (let cell in worksheet) {
            if (cell.length === 2 && cell.endsWith("1")) {
                wscols.push({ wch: 30 });
                worksheet[cell].s = {
                    font: {
                        bold: true,
                    },
                    fill: {
                        fgColor: { rgb: "FFCC00" },
                    }
                };
            }
        }
    }

    worksheet['!cols'] = wscols;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Respuestas");

    const worksheetSummary = XLSX.utils.json_to_sheet(excelData);

    const merge = [];

    for (let i = 0; i < excelData.length; i++) {
        if (i === 0) {
            merge.push({ s: { r: i, c: 0 }, e: { r: i, c: 1 } })
            worksheetSummary[`A${i+1}`].s = {
                font: {
                    bold: true,
                },
                fill: {
                    fgColor: { rgb: "FFCC00" },
                }
            };
        }
        else {
            const obj = excelData[i];
            if (obj[""].length === 0) {
                merge.push({ s: { r: i+1, c: 0 }, e: { r: i+1, c: 1 } })
                worksheetSummary[`A${i+2}`].s = {
                    font: {
                        bold: true,
                    },
                    fill: {
                        fgColor: { rgb: "FFCC00" },
                    }
                };
            }
        }
    }

    worksheetSummary["!merges"] = merge;

    XLSX.utils.book_append_sheet(workbook, worksheetSummary, "Resumen");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, `${title}-${event.name}.xlsx`);
};

const generateWorkSheetInscriptions = (event: IEvent,
                            inscriptions: IInscription[]) => {
    const formattedData: any[] = [];
    let wscols: any[] = [];

    !!inscriptions ? inscriptions.forEach((entry: IInscription) => {
        formattedData.push(getInscriptionData(event, entry));
    }) : null;

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    if (worksheet) {
        for (let cell in worksheet) {
            if (cell.length === 2 && cell.endsWith("1")) {
                wscols.push({ wch: 30 });
                worksheet[cell].s = {
                    font: {
                        bold: true,
                    },
                    fill: {
                        fgColor: { rgb: "FFCC00" },
                    }
                };
            }
        }
    }

    worksheet['!cols'] = wscols;

    return worksheet;
}

export const exportInscriptionsToExcel = (event: IEvent, reportInscriptionsResult: any, hasFinished: boolean) => {
    const inscriptions: IInscription[] = reportInscriptionsResult?.inscriptions;
    const otherInscriptions: IInscription[] = reportInscriptionsResult?.otherInscriptions;
    const rejectedInscriptions: IInscription[] = reportInscriptionsResult?.rejectedInscriptions;

    const workbook = XLSX.utils.book_new();
    
    if(!!inscriptions && !!(inscriptions.length !== 0)) XLSX.utils.book_append_sheet(
        workbook, 
        generateWorkSheetInscriptions(event, inscriptions), 
        !!hasFinished ? 
            !!(inscriptions.length > 1) ? i18next.t('inscription.status-tag.ATTENDEEDS') : i18next.t('inscription.status-tag.ATTENDEED') :
            i18next.t('inscription.inscriptions'));

    if(!!otherInscriptions && !!(otherInscriptions.length !== 0)) XLSX.utils.book_append_sheet(
        workbook, 
        generateWorkSheetInscriptions(event, otherInscriptions), 
        !!(otherInscriptions.length > 1) ? i18next.t('inscription.status-tag.NOT_ATTENDEEDS') : i18next.t('inscription.status-tag.NOT_ATTENDEED'));

    if(!!rejectedInscriptions && !!(rejectedInscriptions.length !== 0)) XLSX.utils.book_append_sheet(
        workbook, 
        generateWorkSheetInscriptions(event, rejectedInscriptions), 
        !!(rejectedInscriptions.length > 1) ? i18next.t('inscription.status-tag.REJECTEDS') : i18next.t('inscription.status-tag.REJECTED'));


    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, `${!!hasFinished ? "ATTENDANCE" : "LIST"}-${event.name}.xlsx`);
};

export const exportCommunityUsersToExcel = (loggedUser: any, data: any, filters: any, t:any) => {
    const workbook = XLSX.utils.book_new();

    const formattedData = data.users.map((u:any) => {
        const user: any = { };
        user[t('user.lastname')] = u.surname || '';
        user[t('user.name')] = u.name || '';
        user[t('user.email')] = u.email || '';
        user[t('user.age-group')] = u.group ? i18next.t('age-group.' + u.group) : '';
        user[t('age')] = u.dob ? DateHelper.calculateAge(new Date(u.dob), t) : '';
        user[t('user.role')] = t('role.' + (u?.rolesOrganizers[0].roleName)) || '';
        user[t('user.gender')] = u.gender ? t('gender.' + u.gender) : '';
        return user;
    });

    const worksheetData = XLSX.utils.json_to_sheet(formattedData);
    worksheetData['!cols'] = [
        { wch: 15 },
        { wch: 15 },
        { wch: 35 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
    ];
    XLSX.utils.book_append_sheet(workbook, worksheetData, loggedUser.name);

    const filter:any = {};
    filter[t('user.gender')] = filters.gender !== '' ? t('gender.' + filters.gender) : '';
    filter[t('user.age-group')] = filters.groups.lenght !== 0 ? t('age-group.' + filters.groups[0]) : '';
    filter[t('user.role')] = `${filters.roles.map((role:any) => `${t('role.' + role)}`).join([', '])}`;
    filter[t('community-filters.text-filter')] = filters.text;
    const worksheetFilter = XLSX.utils.json_to_sheet([filter]);
    worksheetFilter['!cols'] = [
        { wch: 15 },
        { wch: 15 },
        { wch: 30 },
        { wch: 45 },
    ]
    XLSX.utils.book_append_sheet(workbook, worksheetFilter, t('filters'));

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, 'LIST-COMMUNITY-REPORT.xlsx');
}