import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import useLoader from '../../components/loader/useLoader';
import i18n from '../../../i18n';
import { DateHelper } from '../../helpers/date-helper';
import { getEventCardImageURL, getEventInscriptionURL } from '../../helpers/events-helper';
import { getRemainingTimeAndDateStatus } from '../form/utils';
interface PublicCardEventProps {
	event: any;
	isAllowedToEdit: boolean;
	isHost: boolean;
	isReadOnly?: boolean;
	isPast?: boolean;
	preventClick?: boolean;
	isEditing?: boolean;
	includeDescription?: boolean;
	showOrganizer?: boolean;
	showHosts?: boolean;
	isInscription?: boolean;
	preview?:boolean;
	isSoldOut?: boolean;
	finalizedSales?: boolean;
	isLogged?: boolean;

}

export function PublicCardEvent(props: PublicCardEventProps) {
	const [loader, showLoader, hideLoader] = useLoader();
	
	const [dateStatus, setDateStatus] = useState('');
	const [remainingTime, setRemainingTime] = useState(undefined);

	const [bgImg, setBgImg] = useState<string>('/assets/DEFAULT.png');
	const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);
	const [hosts, setHosts] = useState<string | undefined>(undefined);
	const [inscriptionMessage, setInscriptionMessage] = useState('');
	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		if (!remainingTime) {
			const rm: any = getRemainingTime();
			setRemainingTime(rm);
		}
	}, [remainingTime]);
	
	useEffect(() => {

		
		setBgImg(getEventCardImageURL(props.event));
		const hosts = props.event['hosts'].map((h: any, i: number) => {
			return (
				(i === 0 ? ' ' : ', ') +
				h.name +
				(h.surname ? ' ' + h.surname + '' : '')
			);
		});
		setHosts(hosts);
		setInscriptionMessage(
			t('inscription.subtitle-host-not-invited', {
				hostName: hosts,
			})
		);


	}, [props.event]);
	
	const handleCardClick = (e: any) => {
		if (props.event && props.event._id && !props.isSoldOut && !props.finalizedSales) {

			history.push(getEventInscriptionURL(props.event));

			return;
		}
	};
	
	

	const getRemainingTime = () => {
		const rt = getRemainingTimeAndDateStatus(props.event);
		if (!!rt.dateStatus) {
			setDateStatus(rt.dateStatus);
		}
		if (rt.text != 'undefined') {
			if (!!rt.days) {

				return i18n
					.t(rt.text, { days: rt.days })
					.toLocaleUpperCase();
			}
			switch(rt.text) {
				case 'ended':
					const endDate = DateHelper.getDateWithTimeFromDateAndTime(props.event.endHour, props.event.dateEnd);
					const result = DateHelper.timeAgo(endDate);
					return i18n
						.t(result.word, { value: result.val })
						.toLocaleUpperCase();
				case 'tomorrow':
					return i18n
						.t('tomorrow')
						.toLocaleUpperCase();
				case 'today':
					return i18n
						.t('today')
						.toLocaleUpperCase();
			}
			
		}
		if (props.event.deletedBy != null) {
			return i18n.t('canceled').toUpperCase();
		}

	};

	

	return (
		<>
			{loader}
			<div className={"event-card-container" + ((props.isSoldOut || props.finalizedSales) ? " draft" : "")}>
				<div className={`event-card p-0 ${!!!props.isInscription ? "event-border" : ""} ${(props.isSoldOut || props.finalizedSales) ? ' cursor-not-allowed' : ' cursor-pointer'}`} onClick={handleCardClick} >
					<div className='col-12 p-0 m-0 flex-column' >
						<div className={'card-image event w-100 m-0 '}
							style={{ backgroundImage: `url(${bgImg})` }}
						>

							{/* {props.event.status === 'DRAFT' && !props.preventClick && <div className='card-tag draft'>{t('draft')}</div>} */}
							{/* {props.isSoldOut && <div className='card-tag sold-out'>{t('sold-out')}</div>}
							{props.finalizedSales && !props.isSoldOut && <div className='card-tag finalized-sales'>{t('finalized-sales')}</div>}
							 */}
							{!props.preventClick && <div className="d-flex m-0 pt-3 justify-content-between align-items-center">
								<div>
									<div className={dateStatus + ' card-remaining-label'}>
										<span> {remainingTime}</span>
									</div>
								</div>

								

							</div>}
						</div>
						<div className='card-event-info'>
							<div className="d-flex flex-column p-0 pr-4 pt-3 pl-4 justify-content-between pb-1">
								<div>
								{!!!props.isInscription
									&&<div className='d-flex justify-content-end'>
									{!props.isSoldOut && !props.finalizedSales && !props.event.hasPayment && <span className='status-tag event PUBLISHED'>{t('free')}</span>}
									{!props.isSoldOut && !props.finalizedSales && props.event.hasPayment && <span className='status-tag event ENDED'>{t('from')+ ' '+props.event.batches[0]?.currency+'$ '+ props.event.batches[0]?.price}</span>}
									{props.isSoldOut && <span className='status-tag event DRAFT'>{t('sold-out')}</span>}
									{props.finalizedSales && !props.isSoldOut && <span className='status-tag event DRAFT'>{t('finalized-sales')}</span>}
									</div>}
									<div className={`event-title mb-2 mt-2 pb-2 ${!!!props.isInscription ? "grid-text" : ""}`}>
										{props.event.name}
										{/* <hr className="m-0" /> */}
									</div>

									{props.includeDescription && <div>
										<div className="mt-2 event-summary-description text-left" >
											<p className={descriptionExpanded ? 'cursor-pointer' : 'cursor-pointer resumed'}
												onClick={() => setDescriptionExpanded(!descriptionExpanded)}
												dangerouslySetInnerHTML={{ __html: props.event?.description?.replaceAll('\n', '<br>') || '' }}></p></div>
									</div>}

									{props.isInscription && <div className={'dotted-hr mt-3 mb-3' + (props.preview ? ' gray': '')}></div>}
									<div className="event-card-info mb-2">
										<span className="event-card-label">
											{t('start')}{': '}
										</span>
										<br></br>
										<span className="event-card-info ">
											{DateHelper.dateAndDayLong(props.event.date)}
										</span>{' '}
										| {`${props.event.startHour}hs`}
									</div>
									<div className="event-card-info mb-2">
										<span className="event-card-label ">
											{t('end')}{': '}
										</span>
										<br></br>
										<span className="event-card-info ">
											{DateHelper.dateAndDayLong(props.event.dateEnd)}
										</span>{' '}
										| {`${props.event.endHour}hs`}
									</div>

									<div className="event-card-info mb-2">
										<span className="event-card-label ">
											{t('place')}{': '}
										</span>
										<br></br>
										{
											!!props.event?.placeLink &&
											<a href={props.event.placeLink}
												target="_blank"
												className={`event-card-info ${!!!props.isInscription ? "grid-text" : ""}`}>
												{props.event.place}
											</a>
										}
										{
											!props.event?.placeLink &&
											<span className={`event-card-info ${!!!props.isInscription ? "grid-text" : ""}`}>
												{props.event?.place}
											</span>
										}
									</div>

									{props.showOrganizer && <div className="event-card-info mt-3 mb-2">
										<div className='d-flex row m-0 align-items-center justify-content-end'>

											<div className='text-right mr-3'>
												<span className="event-card-label text-right ">
													{t('event.organize')}{': '}
												</span>
												<br></br>
												<span className="event-card-info text-right">
													{props.event.organizer.name}
												</span>
											</div>
											<div className=''>
												<img className="event-organizer-avatar" src={props.event.organizer?.avatar} />
											</div>
										</div>
									</div>
									}
								</div>

							</div>
						</div>
						
					</div>
					
				</div>
				
			</div>
		</>
	);
}

export default PublicCardEvent;
