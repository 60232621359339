import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';


import useEventsApi from '../../../hooks/useEventsApi';

import CustomModal from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/loader/useLoader';
import { IMAGES_TYPES } from '../../../../constants';
import { IEvent } from '../../../models/interfaces/events.interface';
import useImageUpload from '../../../hooks/useImageUpload';
import { CLOUDINARY_CLOUD_NAME, UPLOAD_TYPES } from '../../../../constants';
import { StepProps, getSelectSingleOptions } from '../utils';
import CardDropzone from '../../../components/dropzone/card-dropzone';
import EventSummaryDropzone from '../../../components/dropzone/event-summary-dropzone';
import EventSummaryBanner from '../../summary/eventSummaryBanner';

export function StepImages(props: StepProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();

    const [eventImg, setEventImg] = useState<any>(undefined);
    const [cardImg, setCardImg] = useState<any>(undefined);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const [isDirty, setIsDirty] = useState(false);
    const [imageIsDirty, setImageIsDirty] = useState(false);
    const [cardImageIsDirty, setCardImageIsDirty] = useState(false);
    const [isPublished, setIsPublished] = useState<boolean>(props.event?.status === 'PUBLISHED');
    const dispatch = useDispatch();
    const [
        createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getSectorsAvailability,
        getInscriptionsBySector,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent
    ] = useEventsApi();

    const [
        uploadImageCloud
    ] = useImageUpload();

    const [selectedTypeOfEvent, setSelectedTypeOfEvent] = useState('DEFAULT');
    const _typesOfEvents: any[] = IMAGES_TYPES;
    const subStepsTotal = 2;

    const typesOfEvents = getSelectSingleOptions(_typesOfEvents, 'event.image.');

    const handleOnSelectTypeOfEvent = (e: any) => {
        const r: any = typesOfEvents.find((x) => x.name == e.name);
        setSelectedTypeOfEvent(r.name);
        setIsDirty(true);
        if (r.name !== "CUSTOM") {
            setEventImg(null);
            setImageIsDirty(false);
        }
    };

    const checkImageValid = () => {
        if (selectedTypeOfEvent !== "CUSTOM") return true;
        else if (selectedTypeOfEvent === "CUSTOM") return !!eventImg ? true : false;
    }


    const validateAll = (form: any) => {
        return (
            form.checkValidity()
        );
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            try {
                showLoader();
                let uploadedImageUrl = undefined;
                if (imageIsDirty && !!eventImg) {
                    const uploadedImage = await uploadImageCloud(eventImg, UPLOAD_TYPES[0].name);
                    if (uploadedImage && uploadedImage.data) {
                        uploadedImageUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage.data.public_id}.jpg`; //Aca podria ser sino public_id y solo guardar esos ids
                    }
                }

                let uploadedImageCardUrl = undefined;
                if (cardImageIsDirty && !!cardImg) {
                    const uploadedImage2 = await uploadImageCloud(cardImg, UPLOAD_TYPES[0].name);
                    if (uploadedImage2 && uploadedImage2.data) {
                        uploadedImageCardUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage2.data.public_id}.jpg`; //Aca podria ser sino public_id y solo guardar esos ids
                    }
                }

                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    defaultImage: selectedTypeOfEvent,
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date()
                };
                let subSteps = 0;
                if (selectedTypeOfEvent === 'CUSTOM') {
                    if (!!uploadedImageUrl) {
                        evt.imageSrc = uploadedImageUrl;
                        subSteps += 1;
                    } else {
                        if (imageIsDirty && uploadedImageUrl === undefined) {
                            evt.imageSrc = undefined;
                            subSteps = 0;
                        } else {
                            if (!imageIsDirty && !!eventImg) {
                                subSteps += 1;
                            }
                        }
                    }
                } else {
                    subSteps += 1;
                }

                if (!!uploadedImageCardUrl) {
                    evt.imageCardSrc = uploadedImageCardUrl;
                    subSteps += 1;
                }
                else if (cardImageIsDirty && uploadedImageCardUrl === undefined) {
                    evt.imageCardSrc = undefined;
                } else if (!cardImageIsDirty && !!cardImg) {
                    subSteps += 1;
                }

                if (!!evt.steps) {
                    const stepIdx = evt.steps?.findIndex(s => s.name === 'IMAGES');
                    if (stepIdx >= 0) {
                        evt.steps[stepIdx].percentComplete = (subSteps / subStepsTotal) * 100;
                    }
                }
                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    hideLoader();
                    props.saveAndGoToStep(response.payload, 'QUESTIONS');

                } else {
                    hideLoader();
                    console.log('fallo el edit draft images')
                }

            }
            catch (error: any) {
                setErrorModalMessage(t('event.error-delete'));
                setShowErrorModal(true);
            } finally {
                hideLoader();
            }
        }
        if (isDirty) {
            setValidated(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    useEffect(() => {

        props.notifyIsDirty(isDirty);

    }, [isDirty]);

    const handleEvent = async () => {
        showLoader();
        if (props.loggedUser && !!props.event) {
            setIsPublished(props.event?.status === 'PUBLISHED');
            if (!!props.event.defaultImage) {
                setSelectedTypeOfEvent(props.event.defaultImage);
                if (props.event.defaultImage === "CUSTOM") {
                    setEventImg(props.event.imageSrc);
                }
            }
            if (!!props.event.imageCardSrc) {
                setCardImg(props.event.imageCardSrc);
            }
        }
        hideLoader();
    };
    const handleChangeDropzone = (e: any) => {
        if (e != null && e.length > 0) {
            setEventImg(e[0]);

        } else {
            setEventImg(null);

        }
        setIsDirty(true);
        setImageIsDirty(true);
    };
    const handleChangeCardDropzone = (e: any) => {
        if (e != null && e.length > 0) {
            setCardImg(e[0]);

        } else {
            setCardImg(null);

        }
        setIsDirty(true);
        setCardImageIsDirty(true);
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loader}

            <Form
                className="form-container m-0 p-0 step-container"
                onSubmit={handleSubmit}
                noValidate
                validated={validated}
                action="/events"
            >


                <div className="form-info-container pr-0 pt-0 pb-0 ">

                   { false && <div className="d-flex row pb-0 m-0">
                        <div className="w-100">

                            <Form.Group controlId="formImg">
                                <Form.Label className="input-label mt-0">
                                    {t(
                                        'event.select-event-image'
                                    )}
                                </Form.Label>
                                {/* isDisabled={!editable} */}
                                <Select

                                    className={validated && !checkImageValid() ? "select-control invalid" : "select-control"}
                                    placeholder={t('select')}
                                    options={typesOfEvents}
                                    value={typesOfEvents.find(x => x.name === selectedTypeOfEvent)}
                                    onChange={handleOnSelectTypeOfEvent}
                                />

                                {selectedTypeOfEvent && selectedTypeOfEvent !== 'CUSTOM' &&
                                    (

                                        <div className='mt-4'>  <EventSummaryBanner event={props.event} draftImage={selectedTypeOfEvent}></EventSummaryBanner></div>

                                    )}
                            </Form.Group>
                            {selectedTypeOfEvent && selectedTypeOfEvent === 'CUSTOM' &&
                                (<div className='mt-4'>
                                    <EventSummaryDropzone
                                        className="dropzone"
                                        value={eventImg}
                                        onChangeStatus={handleChangeDropzone}
                                        setErrorModalMessage={setErrorModalMessage}
                                        setShowErrorModal={setShowErrorModal}
                                        event={props.event}
                                    />
                                    {
                                        selectedTypeOfEvent === "CUSTOM" && !eventImg && isPublished &&
                                        <div className="alert alert-danger" role="alert">
                                            {t(
                                                'error.error-image-required'
                                            )}
                                        </div>
                                    }

                                </div>
                                )}
                        </div>

                    </div>}

                    <div className="d-flex row m-0">
                        <div className=" ">

                            {/* <Form.Group controlId="formImg">
                                <Form.Label className="input-label">
                                    {t(
                                        'event.select-card-image'
                                    )}
                                </Form.Label>

                            </Form.Group> */}
                            <p className='step-information-body' dangerouslySetInnerHTML={{ __html: t('event.select-card-image') }}></p>
                            {
                                (<div className='mt-4'>
                                    <CardDropzone
                                        squared={false}
                                        className="dropzone"
                                        value={cardImg}
                                        onChangeStatus={handleChangeCardDropzone}
                                        setErrorModalMessage={setErrorModalMessage}
                                        setShowErrorModal={setShowErrorModal}
                                        event={props.event}
                                    />
                                    {
                                        !cardImg && isPublished &&
                                        <div className="alert alert-danger" role="alert">
                                            {t(
                                                'error.error-image-required'
                                            )}
                                        </div>
                                    }

                                </div>
                                )}
                        </div>


                    </div>


                </div>
                {/* botones */}
                <div className="d-flex justify-content-center justify-content-md-end mt-2 align-items-center mb-3">
                    <Button
                        disabled={isPublished ? ((!cardImg || (!eventImg && selectedTypeOfEvent === "CUSTOM")) || !isDirty) : !isDirty}
                        className="button-outline-primary w-100 w-md-auto text-transform-none"
                        type="submit">
                        {
                            props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                        }
                    </Button>
                </div>
            </Form>






            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}



        </>
    );
}

export default StepImages;
