import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Select from 'react-select';


import useEventsApi from '../../../hooks/useEventsApi';

import CustomModal from '../../../components/modals/customModal';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/loader/useLoader';
import { IEvent, IBatch } from '../../../models/interfaces/events.interface';
import { StepProps, getSelectSingleOptions } from '../utils';
import { ONLY_FREE, YES_NO } from '../../../../constants';
import ErrorGlobal from '../../../components/error-global/error-global';
import { IoAddOutline } from 'react-icons/io5';
import { DateHelper } from '../../../helpers/date-helper';
import BatchRow from '../../batchRow';
import BatchForm from '../../batchForm';
export function StepBatches(props: StepProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const { t, i18n } = useTranslation();
    const [isFree, setIsFree] = useState<string | undefined>();
    const [timeToBuy, setTimeToBuy] = useState<number>();
    const [minAge, setMinAge] = useState<number>();
    const [batches, setBatches] = useState<IBatch[]>([]);

    const [batchNumber, setBatchNumber] = useState<number>(0);
    const [batchToEdit, setBatchToEdit] = useState<IBatch | undefined>();
    const [batchToEditIndex, setBatchToEditIndex] = useState<number>(-1);

    const [batchToAdd, setBatchToAdd] = useState<IBatch | undefined>();
    const [batchToAddIndex, setBatchToAddIndex] = useState<number>(-1);
    const [maxCapacity, setMaxCapacity] = useState<number>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [batchesAvailability, setBatchesAvailability] = useState<any[]>([]);
    const [date, setDate] = useState<Date>();
    const [startsAt, setStart] = useState<Date>();
    const [dateEnd, setDateEnd] = useState<Date>();
    const [inscriptionLimitDT, setInscriptionLimitDT] = useState<Date>();
    const [inscriptionLimitHour, setInscriptionLimitHour] = useState<Date>();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const [isDirty, setIsDirty] = useState(false);

    const dispatch = useDispatch();
    const [
        createEvent,
        updateEvent,
        deleteEvent,
        getEventTypes,
        getInscriptionTypes,
        getEventById,
        editEventNotifyInscriptions,
        getBatchesAvailability,
        checkTicketsAvailability,
        getBatchsAvailability,
        getInscriptionsByBatch,
        sendEventReminder,
        sendEventFeedback,
        sendInscriptionEventFeedback,
        getEventMailPreview,
        createDraftEvent,
        updateDraftEvent
    ] = useEventsApi();


    const subStepsTotal = 3;
    const isFreeOptions = getSelectSingleOptions(YES_NO, 'event.isFree.');
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let maxDate = new Date();

    maxDate.setDate(today.getDate() + 700);

    const validateAll = (form: any) => {
        return (
            form.checkValidity() && checkBatches()
        );
    };
    const getBatchesCapacity = () => {
        return batches?.reduce((acumulador, actual) => acumulador + (!actual.deleted ? actual.qty || 0 : 0), 0);
    };
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;
        if (validateAll(form)) {
            try {
                showLoader();
                const _batchesToSend = cleanBatches();
                const evt: IEvent = {
                    _id: props.event?._id,
                    steps: props.event?.steps,
                    hasPayment: isFree === 'no',
                    batches: _batchesToSend,
                    lastUpdatedBy: props.loggedUser._id,
                    lastUpdatedDT: new Date(),
                    timeToBuy: timeToBuy,
                    minAge: minAge
                };
                let subSteps = 0;

                if (!!evt.steps) {
                    const stepIdx = evt.steps?.findIndex(s => s.name === 'BATCHES');
                    if (!!isFree) {
                        subSteps += 1;
                    }
                    if (!!timeToBuy) {
                        subSteps += 1;
                    }
                    if (!!minAge && minAge >= 0) {
                        subSteps += 1;
                    }
                    if (stepIdx >= 0) {
                        if (isFree === 'no' && !!props.event?.maxCapacity && props.event?.maxCapacity > 0) {

                            const bc = getBatchesCapacity();
                            const completion = bc <= props.event?.maxCapacity ? (bc /props.event.maxCapacity) : 50;
                            evt.steps[stepIdx].percentComplete = (((subSteps / (subStepsTotal))*50) + (completion * 50));// (getBatchesCapacity() / props.event?.maxCapacity) * 100;
                        } else {
                            evt.steps[stepIdx].percentComplete = (subSteps / subStepsTotal) * 100;
                        }


                    }
                }
                const response: any = await dispatch(updateDraftEvent(evt));
                if (response['payload']['_id']) {
                    hideLoader();
                    props.saveAndGoToStep(response.payload, 'QUESTIONS');

                } else {
                    hideLoader();
                    console.log('fallo el edit draft batches')
                }

            }
            catch (error: any) {
                setErrorModalMessage(t('event.error-delete'));
                setShowErrorModal(true);
            } finally {
                hideLoader();
            }
        }
        if (isDirty) {
            setValidated(true);
        }
    };

    const handleCloseErrorModal = () => {
        setErrorModalMessage('');
        setShowErrorModal(false);
    }

    useEffect(() => {
        document.getElementById('published-banner')?.scrollIntoView({ behavior: "smooth" });
        handleEvent();
    }, [props.event]);

    useEffect(() => {

        props.notifyIsDirty(isDirty);

    }, [isDirty]);

    const handleEvent = async () => {
        showLoader();
        if (props.loggedUser && !!props.event) {
            // if (!!props.event.batches) {
            //    // setHasBatch(props.event.hasPayment ? 'yes' : 'no');
            //     setIsFree(props.event.hasPayment ? 'no' : 'yes');
            //     setBatches(props.event.batches);
            // }
            setDate(DateHelper.getDateWithoutTZ(props.event.date));
            setStart(DateHelper.getDateWithTime(props.event.startHour));


            if (!!props.event.batches) {
                setIsFree(ONLY_FREE ? 'yes' : (props.event?.hasPayment === true ? 'no' : (props.event?.hasPayment === false ? 'yes' : 'none')));
                setBatches(props.event.batches);
                const _b = [...props.event.batches];
                if (_b && _b.length > 0) {
                    const sorted = _b.sort((a: IBatch, b: IBatch) => {
                        return (
                            (b.number || 0) -
                            (a.number || 0)
                        );
                    });
                    setBatchNumber(sorted[0]?.number || 0);
                }
            }
            setInscriptionLimitDT(DateHelper.getDateWithoutTZ(props.event.inscriptionLimitDT));
            setInscriptionLimitHour(DateHelper.getDateWithTime(props.event.inscriptionLimitHour));

            if (props.event.maxCapacity) {
                setMaxCapacity(props.event.maxCapacity);
            }

            if (!!props.event._id && props.event.hasPayment === true) {
                const response: any = await getBatchesAvailability(props.event._id);
                if (!response.error && response?.batchesAvailability) {
                    setBatchesAvailability(response.batchesAvailability);
                }
            }
            if (!!props.event.timeToBuy) {
                setTimeToBuy(props.event.timeToBuy);
            }
            if (!!props.event.minAge) {
                setMinAge(props.event.minAge);
            }

        }
        hideLoader();
    };

    ///arrabca
    // const getMinMaxQtyFromBatches = () => {
    //     const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.qty || 0) : 0), 0);
    //     return batchesQty > 1 ? batchesQty : 1;
    // }
    const getMaxQtyAvailable = (index: number) => {
        const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (i !== index && !actual.deleted ? (actual.qty || 0) : 0), 0);
        if (!!maxCapacity) {
            return (maxCapacity - batchesQty) >= 0 ? maxCapacity - batchesQty : 0;
        }
        return 0;
    }
    const getQtyAvailable = () => {
        const batchesQty = batches.reduce((acumulador, actual, i) => acumulador + (!actual.deleted ? (actual.qty || 0) : 0), 0);
        if (!!maxCapacity) {
            return (maxCapacity - batchesQty) >= 0 ? maxCapacity - batchesQty : 0;
        }
        return 0;
    }
    const linkedBatches = (batchNumber: number) => {
        return batches.filter(b => b.batchEndedNumber === batchNumber && !b.deleted);
    }
    const handleDeleteBatch = (c: any) => {
        const idx = batches?.indexOf(c);
        if (!!batches && idx > -1) {
            const _linkedBatches = linkedBatches(c.number);
            if (_linkedBatches.length > 0) {
                const lb = _linkedBatches.map((batch: IBatch) => {
                    return batch.name;
                });
                setErrorModalMessage(t('error.linked-batch', { batches: lb }));
                setShowErrorModal(true);
            } else {
                batches[idx]['deleted'] = true;
                const b = [...batches];
                setBatches([]);
                setBatches(b);
            }

        }
    };
    const handleEditBatch = (b: any, i: number) => {
        setBatchToEdit(b);
        setIsEditing(true);
        setBatchToEditIndex(i);
    }
    const addBatch = (prevNumber: number) => {
        setValidated(false);
        let d;

        if (!!date) {
            d = new Date(date);
            d.setDate(date.getDate() - 1);
        }
        const batch: IBatch = {
            number: prevNumber + 1,
            name: t('general-ticket'),
            date: today,
            dateEnd: d || tomorrow,
            startHour: '00:00',
            endHour: '22:00',
            price: undefined,
            currency: undefined,
            qty: undefined,
            visible: true,
            enabled: true
        };
        const b = [...batches, batch];
        // setBatches([]);
        // setBatches(b);
        setIsEditing(false);
        setBatchToEdit(batch);
        setBatchToEditIndex(b.length - 1);
    };

    const handleAddBatch = () => {
        const bN = batches?.length === 0 ? 1 : batchNumber + 1
        setBatchNumber(bN);
        addBatch(bN);
    }
    const handleSaveBatchModal = (modifiedBatch: any, index: number) => {
        if (!modifiedBatch._id) {
            const batchAvailability = {
                batchId: modifiedBatch._id,
                batchNumber: modifiedBatch.number,
                parentBatchNumber: modifiedBatch.batchEndedNumber,
                enabled: modifiedBatch.enabled,
                qty: modifiedBatch.qty,
                sold: 0
            };
            setBatchesAvailability([...batchesAvailability, batchAvailability]);
        } else {
            const batchAv = batchesAvailability.find(x => x.batchId === modifiedBatch._id);
            batchAv.qty = modifiedBatch.qty - batchAv.sold;
            const ba = batchesAvailability;
            const i = batchesAvailability.findIndex(x => x.batchId === modifiedBatch._id);
            ba[i] = batchAv;
            setBatchesAvailability(ba);

        }
        const _btchs = batches;
        _btchs[index] = modifiedBatch;
        setBatches(_btchs);
        setIsEditing(false);
        setBatchToEdit(undefined);
        setBatchToEditIndex(-1);
        setIsDirty(true);

    }
    const checkBatches = () => {
        const err = batches.filter(
            (x: any) => x.hasError && !x.deleted
        );
        return !err || err?.length === 0;
    }
    const handleIsFree = async (e: any) => {
        setIsFree(e.name);
        setIsDirty(true);
        setBatches([]);
        // //	event.hasPayment = e.name === 'no';
        // 	/* manage cleaning fields*/
        // 	if (e.name === 'yes') {
        // 		setBatches([]);
        // 	}
        // if (name) {
        // 	handleAddBatch();
        // 	setInscriptionLimitDT(tomorrow);
        // 	setInscriptionLimitHour(undefined);
        // }
    };
   
    const handleOnChangeTimeToBuy = (event: any) => {
        setTimeToBuy(event.target.value);
        setIsDirty(true);
    };
    const handleOnChangeMinAge = (event: any) => {
        setMinAge(event.target.value);
        setIsDirty(true);
    };
    const disableEditing = (b: IBatch, availability: any) => {
        const mybatchAvailability: any = availability.find((x: any) => x.batchId === b._id);
        return !!mybatchAvailability && mybatchAvailability.sold > 0;
    }
    const cleanBatches = () => {

        if (isFree === 'yes') {
            const now = new Date();
            const batch: IBatch = {
                number: 1,
                name: 'FREE_BATCH',
                batchLimit: 1,
                date: DateHelper.saveDateWithoutTZ(now),
                dateEnd: DateHelper.saveDateWithoutTZ(inscriptionLimitDT),
                startHour: DateHelper.getStringTimeFromDate(now),
                endHour: DateHelper.getStringTimeFromDate(inscriptionLimitHour),
                price: 0,
                currency: 'ARS',
                qty: maxCapacity,
                visible: true,
                enabled: true,
            };
            return [batch];
        } else if (!!batches && batches.length > 0) {
            return batches.filter(b => (!!!b.deleted));
        }
        return [];
    };
    const handleCloseEditModal = () => {
        setIsEditing(false);
        setBatchToEdit(undefined);
        setBatchToEditIndex(-1);
    }


    const getMinQtyAvailable = (batch: IBatch) => {
        if (!!batchesAvailability && !!batch) {
            const mybatchAvailability: any = batchesAvailability.find((x: any) => x.batchId === batch._id);
            return mybatchAvailability?.occupied || 1;
        }
        return 1;
    }
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {loader}
            {!!props.event &&
                <Form
                    className="form-container m-0 p-0 step-container"
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                    action="/events"
                >
                    <div className="form-info-container pr-0 pt-0 pb-0">
                        <div className='d-flex flex-column m-0 mt-2 mt-md-0 p-0'>
                            <h3 className='w-100 step-information-title mb-2'>
                                {t('event.step-info.batches.title')}
                            </h3>
                            <p className='step-information-body' dangerouslySetInnerHTML={{ __html: t('event.step-info.batches.text') }}></p>
                        </div>
                        <Form.Group>
                            <div className="d-flex row">
                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t(
                                            'event.time-to-buy'
                                        )}`}
                                    </Form.Label>
                                    <Form.Control
                                        disabled={!props.editable}
                                        name="timeToBuy"
                                        defaultValue={props.event?.timeToBuy}
                                        type="number"
                                        min="1"
                                        onChange={
                                            handleOnChangeTimeToBuy
                                        }

                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {`${t('error.required')}`}
                                    </Form.Control.Feedback>
                                </div>
                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.min-age')}`}
                                    </Form.Label>
                                    <Form.Control
                                        disabled={!props.editable || (!!props.event?.inscriptions && props.event.inscriptions.length > 0)}
                                        name="minAge"
                                        type="number"
                                        defaultValue={props.event?.minAge}
                                        min="0"
                                        onChange={
                                            handleOnChangeMinAge
                                        }

                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {`${t('error.required')}`}
                                    </Form.Control.Feedback>
                                </div>


                            </div>
                        </Form.Group>
                        <Form.Group>
                            <div className="d-flex row">


                                <div className="col-md-6">
                                    <Form.Label className="input-label">
                                        {`${t('event.is-free')}`}
                                    </Form.Label>
                                    <Select
                                        required
                                        isDisabled={ONLY_FREE || !props.editable || props.event.status != 'DRAFT'|| (!!props.event?.inscriptions && props.event.inscriptions.length > 0)}

                                        isInvalid={validated && !isFree}
                                        className={validated && !isFree ? "select-control invalid" : (validated && !!isFree ? "select-control valid" : "select-control")}
                                        placeholder={t('select')}
                                        options={isFreeOptions}
                                        value={isFreeOptions.find(x => x.name === isFree)}
                                        onChange={handleIsFree}

                                    />
                                    {validated && !isFree && <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {!isFree && `${t('error.required')}`}
                                    </Form.Control.Feedback>}
                                </div>


                            </div>

                        </Form.Group>
                        <div className={(isFree === 'no') ? 'form-group pt-3' : 'd-none'}>
                            <hr></hr>
                            <div className='row justify-content-between align-items-center m-0 p-0 mb-3'>
                                <label className="group-label m-0 ml-1 mb-2 mb-md-0">
                                    {`${t('event.batches')} - ${getBatchesCapacity()}/${props.event.maxCapacity}`}
                                </label>
                                {isFree === 'no' &&
                                    <div className="form-group">
                                        <Button
                                            disabled={getQtyAvailable() === 0}
                                            className="button-ok small w-xs-100 mb-2 mb-md-0"
                                            onClick={handleAddBatch}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <IoAddOutline />
                                                </div>
                                                <div>
                                                    {t('event.add-batche')}
                                                </div>
                                            </div>
                                        </Button>

                                    </div>}

                            </div>

                            {validated && (!batches || batches.length === 0) && <Form.Control.Feedback
                                type="invalid"
                                className="global-error mb-3 mt-3"
                            >
                                <ErrorGlobal
                                    text={t('error.batches-required')}
                                ></ErrorGlobal>
                            </Form.Control.Feedback>}
                            {batches && batches.length > 0 && !!batchesAvailability && (
                                <>
                                    {batches.map((b, i) => (
                                        <div key={i}>
                                            {!b.deleted &&
                                                // <span>{b.number}</span>
                                                <BatchRow
                                                    i={i}
                                                    batch={b}
                                                    otherBatches={batches.filter(x => x.number !== b.number)}
                                                    handleDeleteBatch={handleDeleteBatch}
                                                    handleEditBatch={handleEditBatch}
                                                    eventId={props.event?._id}
                                                    canEdit={true}
                                                    batchesAvailability={batchesAvailability}
                                                ></BatchRow>
                                            }
                                        </div>
                                    ))}
                                </>
                            )}

                            <hr></hr>
                        </div>
                    </div>
                    {/* botones */}
                    <div className="d-flex justify-content-center justify-content-md-end mt-4 align-items-center mb-3 ">
                        <Button
                            disabled={!isDirty}
                            className="button-outline-primary w-100 w-md-auto text-transform-none"
                            type="submit">
                            {
                                props.event?.status === 'DRAFT' ? t('save-continue') : t('save')
                            }
                        </Button>
                    </div>
                </Form>
            }
            {batchToEdit &&
                <BatchForm
                    isEditing={isEditing}
                    index={batchToEditIndex}
                    batch={batchToEdit}
                    disableEdit={disableEditing(batchToEdit, batchesAvailability)}
                    otherBatches={batches?.filter(x => x.number !== batchToEdit.number && !x.deleted)}
                    maxQtyAvailable={getMaxQtyAvailable(batchToEditIndex)}
                    minQtyAvailable={getMinQtyAvailable(batchToEdit)}
                    eventDate={date || today}
                    eventStartHour={startsAt}
                    onHideModal={handleCloseEditModal}
                    onSaveBatch={handleSaveBatchModal}
                ></BatchForm>}



            {showErrorModal && (
                <CustomModal
                    isShowing={showErrorModal}
                    parentCallback={handleCloseErrorModal}
                    message={errorModalMessage}
                    title={t('error.modal-title-oops')}
                    buttonOK={t('accept')}
                    type="ERROR"
                />
            )}







        </>
    );
}

export default StepBatches;
