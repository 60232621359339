import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdUndo } from 'react-icons/io';
import { IoCheckmarkSharp, IoClose } from 'react-icons/io5';
import { VscFeedback } from "react-icons/vsc";
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import { useDispatch } from 'react-redux';
import useLoader from '../../components/loader/useLoader';
import CustomModal from '../../components/modals/customModal';
import { IoQrCodeOutline } from 'react-icons/io5';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import { FaCircle, FaLess } from 'react-icons/fa';
import { TInscriptionStatus } from '../../models/types/types';
import { DateHelper } from '../../helpers/date-helper';
import Select from 'react-select';
import { customStyles } from '../../helpers/inscription-helper';
import useEventsApi from '../../hooks/useEventsApi';
import QRModal from '../../components/modals/qrModal';
import './inscriptions.scss';
interface InscriptionSingleRowProps {
	inscription: IInscription,
	canEdit: boolean,
	type: TInscriptionStatus,
	statuses: TInscriptionStatus[],
	onUndoInscription: any,
	selectAll: any,
	disableSelectAll: any,
	includeMenu: boolean,
	includeSector?: boolean,
	sectorOptions?: any[],
	onSetSector?: any,
}
export function InscriptionSingleRow(
	props: InscriptionSingleRowProps
) {
	const [loader, showLoader, hideLoader] = useLoader();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [checked, setChecked] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [confirmationType, setConfirmationType] = useState('');
	const [sectorOptions, setSectorOptions] = useState(props.sectorOptions);
	const [validated, setValidated] = useState(false);
	const [qrModalVisible, setQrModalVisible] = useState(false);
	const [currentInscription, setCurrentInscription] = useState<IInscription>(props.inscription);
	const [inscriptionToUndo, setInscriptionToUndo] = useState<IInscription | undefined>(undefined);
	const [
		,
		,
		,
		,
		,
		,
		updateInscriptionStatus,
		,
		,
		resendInscriptionEmail,
		,
		,
		,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
		getReportInscriptions,
		getReportMenu,
		updateAndSendQRs,
		getReportAttendeedInscriptions,
		getReportAttendeedMenu,
		getReportPastEventInscriptions,
		getReportMenuDynamic,
		updateInscriptionAndCompanions,
		updateInscriptionAndCompanionsAndSendQR,
		getInscriptionByIdentificationNumber,
		getInscriptionByIdWithBatches,
		attendeeInscription,
		sendRejectedEmail,
		updateInscriptionSector
	] = useInscriptionsApi();
	const [
		createEvent,
		updateEvent,
		deleteEvent,
		getEventTypes,
		getInscriptionTypes,
		getEventById,
		editEventNotifyInscriptions,
		getBatchesAvailability,
		checkTicketsAvailability,
		getSectorsAvailability,
		getInscriptionsBySector,
		sendEventReminder,
		sendEventFeedback,
		sendInscriptionEventFeedback
	] = useEventsApi();
	useEffect(() => {
		if (props.selectAll) {
			if (
				props.inscription.inscriptionStatus != 'PENDING_EMAIL_CONFIRMATION'
			) {
				props.inscription.checked = true;
				setChecked(true);
			}

		} else {
			if (
				props.inscription.inscriptionStatus != 'PENDING_EMAIL_CONFIRMATION'
			) {
				props.inscription.checked = false;
				setChecked(false);
			}
		}
	}, [props.selectAll]);
	useEffect(() => {
		if (props.sectorOptions) {
			setSectorOptions([]);
			setSectorOptions(props.sectorOptions);
		}
	}, [props.sectorOptions]);
	const handleViewDetails = (e: any) => {
		e.stopPropagation();
		setExpanded(!expanded);
	};

	const selectInscription = (e?: any) => {
		if (
			props.canEdit &&
			props.type == 'PENDING' &&
			props.inscription['inscriptionStatus'] != 'PENDING_EMAIL_CONFIRMATION'
		) {
			e.stopPropagation();
			e.preventDefault();
			currentInscription.checked = !checked;
			props.disableSelectAll(currentInscription);
			setChecked(!checked);
		}
	};

	const confirmUndo = (e: any, inscription: IInscription | undefined) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('UNDO');
		setInscriptionToUndo(inscription);
		setShowConfirmationModal(true);
	};

	const sendFeedbackLink = async (e: any, inscription: IInscription | undefined) => {
		e.preventDefault();
		e.stopPropagation();
		const eventId = inscription?.event?._id;
		try {
			showLoader();
			if (!!eventId) {
				const email = await sendInscriptionEventFeedback(eventId, inscription._id || '');
				if (!!email) {

					//setSuccessMessage(i18n.t('event.success-email-feedback'));
					setShowSuccessModal(true);
				} else {
					setErrorMessage(t('event.error-email-feedback'));
					setShowErrorModal(true);
				}
			}
		}
		catch (e) {
			setErrorMessage(t('event.error-email-feeedback'));
			setShowErrorModal(true);
		}
		finally {
			hideLoader();
		}
	};
	const confirmResend = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('RESEND');
		setShowConfirmationModal(true);
	};

	const confirmQr = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setConfirmationType('RESENDQR');
		setQrModalVisible(false);
		setShowConfirmationModal(true);
	};
	const openQRModal = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setQrModalVisible(true);
	};
	const onCloseConfirmationModal = async (e: any) => {
		if (e) {
			setShowConfirmationModal(false);
			switch (confirmationType) {
				case 'UNDO':
					await handleUndo();
					break;
				case 'RESEND':
					await handleResend();
					break;
				case 'RESENDQR':
					await handleResendQR();
					break;
				default:
					break;
			}
		} else {
			setShowConfirmationModal(false);
		}

	};

	const getConfirmationMessage = () => {
		switch (confirmationType) {
			case 'UNDO': {
				//if (currentInscription.inscriptionStatus !== 'REJECTED') {
				return t('confirmation.move-to-pending');
				// }
				// return t('confirmation.resend-email');
			}

			case 'RESEND':
				return t('confirmation.resend-email');
			case 'RESENDQR':
				return t('confirmation.resend-qr');
			default:
				break;
		}

	};
	const getSuccessTitle = () => {
		return confirmationType == 'RESENDQR' ? t('inscription.resend-qr-success-title') : t('inscription.resend-email-success-title');

	}
	const getSuccessMessage = () => {
		const name = currentInscription?.name || currentInscription?.user?.name;
		const lastName = currentInscription?.surname || currentInscription?.user?.surname;
		const email = currentInscription?.user?.email;
		const companions = currentInscription.companions;
		const usr =
			(name != null ? name : '') +
			' ' +
			(lastName != null ? lastName : '') +
			(email && email != '' ? ' (<i>' + email + '</i>)' : '');
		const companionsNames = (!!companions && companions.length > 0) ?
			companions?.filter((y: any) => !y.deleted)
				.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
				}) : null;
		const companionsNamesQR = (!!companions && companions.length > 0) ?
			companions?.filter((y: any) => !y.deleted && y.inscriptionStatus === 'ACCEPTED')
				.map((x: any) => {
					return (
						'<br>' +
						x.name +
						' ' +
						x.surname +
						(x.email && x.email != ''
							? ' (<i>' + x.email + '</i>)'
							: '')
					);
				}) : null;
		switch (confirmationType) {
			case 'RESENDQR':
				return t('inscription.resend-qr-success', {
					user: usr,
					companions: companionsNamesQR
				});

			default:
				return t('inscription.resend-email-success', {
					user: usr,
					companions: companionsNames
				});
		}

	};

	const dispatchUndoStatus = async (status: TInscriptionStatus) => {
		const response: any = await dispatch(
			updateInscriptionStatus({
				inscriptionStatus: status,
				id: inscriptionToUndo?._id,
			})
		);
		if (response['payload'] && response['payload']['_id']) {
			props.onUndoInscription(inscriptionToUndo, props.type);
		}
	};

	const handleUndo = async () => {
		if (!!inscriptionToUndo) {
			showLoader();
			if (inscriptionToUndo.inscriptionStatus === 'ACCEPTED') {
				await dispatchUndoStatus('PENDING');
			} else if (inscriptionToUndo.inscriptionStatus === 'DENIED') {
				const eventId: string = inscriptionToUndo.event?._id ?
					inscriptionToUndo.event?._id : inscriptionToUndo.event as string;
				const ev: any = await getEventById(eventId);
				const placesLeft = ev.maxCapacity -
					ev.inscriptions?.filter(
						(x: IInscription) =>
							x.inscriptionStatus === 'PENDING' ||
							x.inscriptionStatus === 'ACCEPTED'
					).length;

				if (placesLeft > 0) {
					await dispatchUndoStatus("PENDING");
				} else if (ev.allowsWaiting) {
					await dispatchUndoStatus("WAITING");
				} else if (!ev.allowsWaiting) {
					setErrorMessage(t("error.inscription-max-capacity"));
					setShowErrorModal(true);
				}
			}
			setInscriptionToUndo(undefined);
			hideLoader();
			// } else {
			// 	showLoader();
			// 	const resp: any = await dispatch(
			// 		updateInscriptionStatus({
			// 			inscriptionStatus: 'PENDING',
			// 			id: inscriptionToUndo._id,
			// 		})
			// 	);
			// 	hideLoader();
			// 	if (resp['payload'] && resp['payload']['_id']) {
			// 		setShowSuccessModal(true);
			// 	} else {
			// 		setShowErrorModal(true);
			// 		setInscriptionToUndo(undefined);
			// 	}
			// }
		}
	};
	const handleResendQR = async () => {
		showLoader();
		const response: any = await resendQREmail(currentInscription?._id);
		hideLoader();

		if (response && response['_id']) {
			setShowSuccessModal(true);
		} else {
			setErrorMessage(t('event.error-email-feeedback'));
			setShowErrorModal(true);
		}
	};
	const handleResend = async () => {
		showLoader();
		const response: any = await resendInscriptionEmail(currentInscription?._id);
		hideLoader();

		if (response && response['_id']) {
			setShowSuccessModal(true);
		} else {
			setErrorMessage(t('event.error-email-feeedback'));
			setShowErrorModal(true);
		}
	};
	const handleCloseSuccessModal = (e: any) => {
		setShowSuccessModal(false);

		if (currentInscription.inscriptionStatus == 'REJECTED') {
			props.onUndoInscription(inscriptionToUndo, props.type);
			setInscriptionToUndo(undefined);
		}
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
	};
	const handleOnSelectSector = async (e: any, insc: IInscription) => {
		try {
			showLoader();

			const response: any = await dispatch(
				updateInscriptionSector({ sector: e.value, id: insc._id, inscriptionStatus: insc.inscriptionStatus })
			);
			if (!!props.onSetSector) {
				await props.onSetSector();
			}
			insc.sector = e.value;
			if (!insc.parentInscription) {
				setCurrentInscription(insc);
			}
		} catch (e) {
			console.log(e);
		}
		finally {
			hideLoader();
		}
	}
	const getBatchName = (name: string | undefined) => {
		if (name === 'FREE_BATCH') {
			return t(`event.batch.type.${name}`);
		}
		return name;
	}
	return (
		<>
			{loader}
			{showSuccessModal && (
				<CustomModal
					isShowing={showSuccessModal}
					parentCallback={handleCloseSuccessModal}
					title={getSuccessTitle()}
					type="SUCCESS"
					buttonOK={t('accept')}
					message={getSuccessMessage() + (currentInscription['inscriptionStatus'] == 'REJECTED'
						? ("<br> <br> " + t('inscription.undo-rejected'))
						: '')
					}
				// message={

				// }
				/>
			)}
			{showErrorModal && (
				<CustomModal
					isShowing={showErrorModal}
					parentCallback={handleCloseErrorModal}
					message={errorMessage}
					type="ERROR"
					buttonOK={t('accept')}
					title={t('error.modal-title-oops')}
				/>
			)}
			{!!currentInscription && !!currentInscription.event &&
				<>
					{showConfirmationModal && (
						<CustomModal
							isShowing={showConfirmationModal}
							parentCallback={onCloseConfirmationModal}
							message={getConfirmationMessage()}
							buttonCancel={t('cancel')}
							buttonOK={t('accept')}
							type="WARNING_EDIT"
						/>
					)}
					{qrModalVisible && (
						<QRModal visible={qrModalVisible}
							inscription={currentInscription}
							onHide={() => setQrModalVisible(false)}
							resendQRFn={confirmQr}
						></QRModal>
					)}
				</>
			}
			{!!currentInscription && !!currentInscription.event && (
				<tr key={currentInscription._id} className={
					props.canEdit &&
						currentInscription['inscriptionStatus'] !=
						'PENDING_EMAIL_CONFIRMATION' &&
						currentInscription['inscriptionStatus'] != 'REJECTED' &&
						currentInscription['inscriptionStatus'] != 'ATTENDEED'
						? 'inscription-row cursor-pointer m-0 single'
						: 'inscription-row m-0 single'
				}>

					{/* Primer columna de accion */}
					{props.statuses?.length === 0 && props.type == 'ATTENDEED' &&
						<td className='text-center'>
							<div
								className={
									currentInscription.inscriptionStatus == 'ATTENDEED'
										? 'custom-checkbox inscription-checkbox checkbox-rounded successful'
										: 'd-none'
								}
							>
								{<IoCheckmarkSharp />}
							</div>
						</td>}
					{props.statuses?.length === 0 && props.type == 'DENIED' &&
						<td className='text-center'>
							<div
								className={
									(currentInscription.inscriptionStatus == 'DENIED' || currentInscription.inscriptionStatus == 'REJECTED')
										? 'custom-checkbox inscription-checkbox checkbox-rounded rejected'
										: 'd-none'
								}
							>
								{<IoClose />}
							</div>
						</td>}
					{props.statuses?.length > 0 &&
						<td className='text-center'
							onClick={(e) => selectInscription(e)}>
							{!props.canEdit && props.type == 'ATTENDEED' &&
								!!currentInscription.event.feedbackQuestions &&
								currentInscription?.event?.feedbackQuestions?.length > 0 &&
								<div className='col-12 col-md-6 p-0 m-0'>
									<VscFeedback
										className="cursor-pointer inscription-icon"
										title={t('inscription.send-feedback')}
										onClick={(e) => sendFeedbackLink(e, currentInscription)}
									></VscFeedback> </div>}
							{props.canEdit &&
								(currentInscription.inscriptionStatus === 'PENDING' || currentInscription.inscriptionStatus === 'WAITING') &&
								!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 &&
								<div
									className={
										currentInscription.checked
											? 'custom-checkbox cursor-pointer inscription-checkbox checked'
											: 'custom-checkbox cursor-pointer inscription-checkbox'
									}>
									{currentInscription.checked && <IoCheckmarkSharp />}
								</div>
							}
							{props.canEdit && props.type != 'PENDING' && props.type != 'ATTENDEED' &&
								(!!currentInscription.inscriptionStatus && ['ACCEPTED', 'DENIED'].includes(currentInscription.inscriptionStatus)) &&
								!!currentInscription.inscriptionStatus && props.statuses.indexOf(currentInscription.inscriptionStatus) >= 0 &&
								(
									<div className={currentInscription.inscriptionStatus === 'ACCEPTED' && props.statuses.includes(currentInscription.inscriptionStatus) ?
										'd-flex flex-column flex-md-row justify-content-between w-100' : 'd-flex flex-column flex-md-row justify-content-center w-100'}>
										{currentInscription.event?.eventType === 'PRIVATE_EVENT' && <div className='col-12 col-md-6 p-0 m-0'>
											<IoMdUndo
												className="cursor-pointer inscription-icon"
												title={t('inscription.move-to-pending')}
												onClick={(e) => confirmUndo(e, currentInscription)}
											></IoMdUndo>
										</div>}
										{props.canEdit &&
											(currentInscription.inscriptionStatus === 'ACCEPTED' && props.statuses.includes(currentInscription.inscriptionStatus)
											) && (
												<div className='col-12 col-md-6 p-0 m-0'>
													<IoQrCodeOutline
														className="cursor-pointer inscription-icon"
														title={t('inscription.see-qr')}
														onClick={openQRModal}
													/>
												</div>
											)}
									</div>
								)}


						</td>
					}
					{/* Nombre Apellido email */}
					<td className=''>
						<div className="inscription-name d-flex align-items-center">
							<FaCircle className={currentInscription.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!currentInscription.user ? "inactive-dot mr-1" : "d-none")} />
							{currentInscription.name || currentInscription.user?.name}{' '}
							{currentInscription.surname || currentInscription.user?.surname}
						</div>
						{!!currentInscription.event.includeIdentification && !!currentInscription.identificationNumber && !!currentInscription.identificationNumber
							&& <div className="inscription-email">
								{currentInscription.identificationType + ' ' + currentInscription.identificationNumber}
							</div>}

						{(currentInscription.email || currentInscription.user?.email) &&
							<div className="inscription-email">
								{currentInscription.email || currentInscription.user?.email}
							</div>}
						{/* Mobile */}
						<div className='hide-not-medium'>
							{props.includeSector && props.type != 'DENIED' && currentInscription.event.hasSectors == "yes" &&
								<>
									{!sectorOptions && <div className="inscription-companions">
										{currentInscription.sector?.name || t('event.sector.no-sector')}
									</div>}
									{!!sectorOptions && <div className="sector-selector small">
										<Select
											required
											label="name"
											isInvalid={validated && !currentInscription.sector?._id}
											className={validated && !currentInscription.sector?._id ? "select-control-sector invalid" : (validated && !!currentInscription.sector?._id ? "select-control-sector valid" : "select-control-sector")}
											placeholder={t('select')}
											options={sectorOptions}
											value={sectorOptions.find(x => !!currentInscription.sector?._id ? x.value === currentInscription.sector?._id : x.value === currentInscription.sector)}
											onChange={(e: any) => { handleOnSelectSector(e, currentInscription) }}
											styles={customStyles}
											isOptionDisabled={(option: any) => !option.enabled}
											isSearchable={false}
										/>
									</div>}
								</>
							}
							{/* Rango etareo y Genero */}
							{(currentInscription.event.includeDOB || currentInscription.event.includeGender) &&
								<div className="inscription-companions">
									{currentInscription.event.includeDOB && t('age-group.' + (currentInscription.group))}
									{currentInscription.event.includeDOB && currentInscription.event.includeGender && ' - '}
									{currentInscription.event.includeGender && t('gender.' + (currentInscription.gender))}

								</div>
							}

							{/* Menú */}
							{props.includeMenu && currentInscription.event.includeMenu &&
								<div className="inscription-companions">
									{
										(currentInscription.menu || currentInscription.menuCondition) &&
										<div>
											{(currentInscription.menu ? t('event.menu.' + currentInscription.menu) : '') +
												(currentInscription.menu && currentInscription.menuCondition ? ' - ' : '') +
												(currentInscription.menuCondition ? t('event.menu-condition.' + currentInscription.menuCondition) : '')
											}</div>
									}

								</div>
							}
						</div>
					</td>
					{/* Sector */}
					{props.includeSector && props.type != 'DENIED' && currentInscription.event.hasSectors == "yes" &&
						<td className='hide-medium sector-selector'>
							{!sectorOptions && <div className="inscription-companions">
								{currentInscription.sector?.name || t('event.sector.no-sector')}
							</div>}
							{!!sectorOptions && <div >
								<Select
									required
									label="name"
									isInvalid={validated && !currentInscription.sector?._id}
									className={validated && !currentInscription.sector?._id ? "select-control-sector invalid" : (validated && !!currentInscription.sector?._id ? "select-control-sector valid" : "select-control-sector")}
									placeholder={t('select')}
									options={sectorOptions}
									value={sectorOptions.find(x => !!currentInscription.sector?._id ? x.value === currentInscription.sector?._id : x.value === currentInscription.sector)}
									onChange={(e: any) => { handleOnSelectSector(e, currentInscription) }}
									styles={customStyles}
									isOptionDisabled={(option: any) => !option.enabled}
									isSearchable={false}
								/>
							</div>}
						</td>
					}
					{/* Rango etareo */}
					{currentInscription.event.includeDOB &&
						<td className="inscription-companions hide-medium">
							<div>{t('age-group.' + (currentInscription.group))}</div>

						</td>
					}
					{/* Genero */}
					{currentInscription.event.includeGender &&
						<td className="inscription-companions hide-medium">
							<div>{t('gender.' + (currentInscription.gender))}</div>


						</td>
					}
					{/* Menú */}
					{props.includeMenu && currentInscription.event.includeMenu &&
						<td className="inscription-companions hide-medium">
							{
								(currentInscription.menu || currentInscription.menuCondition) &&
								<div>
									{(currentInscription.menu ? t('event.menu.' + currentInscription.menu) : '') +
										(currentInscription.menu && currentInscription.menuCondition ? ' - ' : '') +
										(currentInscription.menuCondition ? t('event.menu-condition.' + currentInscription.menuCondition) : '')
									}</div>
							}

						</td>
					}
					{/* Status */}
					{!!currentInscription.inscriptionStatus &&
						<td className='status-col'>
							{(currentInscription.event.eventType === 'PRIVATE_EVENT' || currentInscription.inscriptionStatus == 'ATTENDEED') && <div className="p-0 m-0 d-flex align-items-center justify-content-start">
								<div className={'mr-2 status-tag ' + currentInscription.inscriptionStatus}>{t('inscription.status-tag.' + currentInscription.inscriptionStatus)}</div>
							</div >}
							<div className='inscription-email small hide-not-medium'>
								{(currentInscription.event.eventType === 'PUBLIC_EVENT' && currentInscription.inscriptionStatus != 'ATTENDEED') &&
									// <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
									<>
										{currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
											{'$ ' + currentInscription.batch?.price}
											{currentInscription.payment?.cardBrand && (
												<span className="d-none d-md-inline">
													{' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
												</span>
											)}
										</div>) : null}
										{currentInscription.payment?.cardBrand && (
											<div className="d-md-none small mt-1 inscription-companions small mt-1">
												{currentInscription.payment?.cardBrand?.toUpperCase() +
													'-' +
													currentInscription.payment.cardLast4}
											</div>
										)}
										<div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
										<div className="inscription-email small mt-1">
											{currentInscription.acceptedDT
												? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
												: ''}
										</div></>
								}
								{(currentInscription.event.eventType === 'PRIVATE_EVENT' || currentInscription.inscriptionStatus == 'ATTENDEED') &&
									<>
										{(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
											currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
												(currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
										<br></br>
										<span className="smaller">
											{(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
												currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
										</span>

									</>}
							</div>
						</td>
					}
					{!!currentInscription?.inscriptionStatus &&
						<td className="inscription-email small hide-medium">
							{(currentInscription.event.eventType === 'PUBLIC_EVENT' && currentInscription.inscriptionStatus != 'ATTENDEED') &&
								<>
									{/* <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div> */}
									{currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
										{'$ ' + currentInscription.batch?.price}
										{currentInscription.payment?.cardBrand && (
											<span className="d-none d-md-inline">
												{' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
											</span>
										)}
									</div>) : null}
									{currentInscription.payment?.cardBrand && (
										<div className="d-md-none small mt-1 inscription-companions small mt-1">
											{currentInscription.payment?.cardBrand?.toUpperCase() +
												'-' +
												currentInscription.payment.cardLast4}
										</div>
									)}
									<div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
									<div className="inscription-email small mt-1">
										{currentInscription.acceptedDT
											? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
											: ''}
									</div>
								</>
							}
							{(currentInscription.event.eventType !== 'PUBLIC_EVENT' || currentInscription.inscriptionStatus === 'ATTENDEED') &&
								<>
									{(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
										currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
											(currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
									<br></br>
									<span className="smaller">
										{(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
											currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
									</span>
								</>}


						</td>
					}

				</tr>
			)}
		</>
	);
}
