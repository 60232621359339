import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IoCheckmarkSharp } from 'react-icons/io5';
import useInscriptionsApi from '../../hooks/useInscriptionsApi';
import { useDispatch } from 'react-redux';
import useLoader from '../../components/loader/useLoader';
import CustomModal from '../../components/modals/customModal';
import { getInscriptionQtyString } from '../../helpers/inscription-helper';
import { IInscription } from '../../models/interfaces/inscriptions.interface';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { TInscriptionStatus } from '../../models/types/types';
import { DateHelper } from '../../helpers/date-helper';
interface InscriptionAdmissionGroupalRowProps {
	inscription: IInscription,
	onAtendee: any,
	loggedUserId: string,
	canAttendee: boolean,
	isFinished: boolean,
	statuses: TInscriptionStatus[],
	includeSector: boolean
}
export function InscriptionAdmissionGroupalRow(
	props: InscriptionAdmissionGroupalRowProps
) {
	const [loader, showLoader, hideLoader] = useLoader();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const [checked, setChecked] = useState(false);
	const [currentInscription, setCurrentInscription] = useState<IInscription>(props.inscription);
	const [inscriptionToAccept, setInscriptionToAccept] = useState<IInscription | undefined>(undefined);
	const [includeCompanions, setIncludeCompanions] = useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [previousStatus, setPreviousStatus] = useState<any>(
		props.inscription.inscriptionStatus
	);
	const [createInscription,
		getInscription,
		getAllInscriptions,
		getAcceptedInscriptions,
		getDeniedInscriptions,
		getPendingInscriptions,
		updateInscriptionStatus,
		updateStatusMultiple,
		confirmEmail,
		resendInscriptionEmail,
		getAdmissionInscriptions,
		generateQR,
		getInscriptionById,
		resendQREmail,
		getInscriptionByEmail,
		getInscriptionsByCriteria,
		getAttendeedInscriptions,
		sendDeniedEmail,
		getAttendeedInscriptionsFromMain,
	] = useInscriptionsApi();

	const statusesToAttendee: TInscriptionStatus[] = ['ACCEPTED', 'PENDING', 'PENDING_EMAIL_CONFIRMATION'];
	const statusesToNotAttendee: TInscriptionStatus[] = ['ATTENDEED', 'DENIED', 'REJECTED'];
	const deniedStatuses: TInscriptionStatus[] = ['DENIED', 'REJECTED'];
	const handleViewDetails = (e: any) => {
		e.stopPropagation();
		setExpanded(!expanded);
	};

	const selectInscription = (e: any) => {
		if (currentInscription.inscriptionStatus !== 'ATTENDEED' ||
			(currentInscription.companions?.find(c => c.inscriptionStatus !== 'ATTENDEED') !== undefined)) {
			if (props.canAttendee) {
				e.stopPropagation();
				e.preventDefault();
				setInscriptionToAccept(currentInscription);
				setIncludeCompanions(true);
				setShowConfirmationModal(true);
				setChecked(!checked);
				//}
			} else {
				setShowErrorModal(true);
			}
		}
	};
	const selectSingleInscription = (e: any, inscription: IInscription) => {
		if (inscription.inscriptionStatus !== 'ATTENDEED' &&
			!!inscription.inscriptionStatus && deniedStatuses.indexOf(inscription.inscriptionStatus) < 0 &&
			props.statuses.indexOf(inscription.inscriptionStatus) >= 0) {
			if (props.canAttendee) {
				e.stopPropagation();
				e.preventDefault();
				setInscriptionToAccept(inscription);
				setIncludeCompanions(false);
				setShowConfirmationModal(true);
			} else {
				setShowErrorModal(true);
			}
		}
	};

	const handleUndo = () => {
		console.log(previousStatus);
	};
	const handleCloseSuccessModal = (e: any) => {
		setShowSuccessModal(false);
	};
	const handleCloseErrorModal = (e: any) => {
		setShowErrorModal(false);
		if (!!errorMessage) {
			setErrorMessage(undefined);
			props.onAtendee();
		}

	};
	const handleAtendee = async (e: any) => {
		if (!e) {
			setChecked(false);
			setShowConfirmationModal(false);
			setInscriptionToAccept(undefined);
			setIncludeCompanions(false);
		} else {
			setShowConfirmationModal(false);
			showLoader();
			try {
				if (!!inscriptionToAccept) {

					let ids = includeCompanions && !!inscriptionToAccept.companions && inscriptionToAccept.companions.length > 0 ?
						inscriptionToAccept.companions.filter((y: IInscription) => !!y.inscriptionStatus && statusesToAttendee.indexOf(y.inscriptionStatus) >= 0)
							.map((x: IInscription) => {
								return x._id;
							}) : [];

					if (!!inscriptionToAccept?.inscriptionStatus && statusesToAttendee.indexOf(inscriptionToAccept?.inscriptionStatus) >= 0) {
						ids.push(inscriptionToAccept._id);
					}
					if (!!inscriptionToAccept._id) {
						const alreadyAttendeed = await getAttendeedInscriptionsFromMain(inscriptionToAccept._id, includeCompanions);
						if (!!alreadyAttendeed) {
							let attendeedNames: string = '';
							for (const attendeedInscription of alreadyAttendeed) {
								if (ids.includes(attendeedInscription._id)) {
									attendeedNames += (attendeedNames !== '' ? '<br>' : '') + (attendeedInscription.name || attendeedInscription.user?.name) + ' ' +
										(attendeedInscription.surname || attendeedInscription.user?.surname);

								}
							}
							if (attendeedNames != '') {
								setErrorMessage(t('error.inscription-already-attendeed', { names: attendeedNames }));
								setShowErrorModal(true);
								return;
							}
						}
						const response: any = await dispatch(
							updateStatusMultiple({
								inscriptionStatus: 'ATTENDEED',
								ids: ids,
								loggedUserId: props.loggedUserId,
							})
						);
						if (response) {
							props.onAtendee(inscriptionToAccept, ids);
						}
					}

				}
			} catch (e) {
				console.log(e);
			} finally {
				hideLoader();
				setInscriptionToAccept(undefined);
				setChecked(false);
				setIncludeCompanions(false);
			}
		}
	};
	const getConfirmationMessage = () => {
		if (!inscriptionToAccept) {
			return '';
		}
		let companionsNames: string[] = [];
		if (includeCompanions) {
			companionsNames = !!inscriptionToAccept.companions ? inscriptionToAccept?.companions
				.filter((y: IInscription) => !!y.inscriptionStatus && statusesToAttendee.indexOf(y.inscriptionStatus) >= 0)
				.map((x: IInscription) => {
					return ' ' + (x.name || x.user?.name) + ' ' + (x.surname || x.user?.surname);
				}) : [];
		}
		let userName;
		if (!!inscriptionToAccept?.inscriptionStatus && statusesToAttendee.indexOf(inscriptionToAccept?.inscriptionStatus) >= 0) {
			userName = (inscriptionToAccept.name || inscriptionToAccept.user?.name) + ' ' + (inscriptionToAccept.surname || inscriptionToAccept.user?.surname);
		}
		if (!!companionsNames && companionsNames.length > 0) {
			if (!!userName) {
				return !!inscriptionToAccept ? i18n.t('confirmation.inscription-atendee-groupal', {
					user: userName,
					companionNames: companionsNames,
				}) : '';
			} else {
				return !!inscriptionToAccept ? i18n.t('confirmation.inscription-atendee', {
					user: companionsNames
				}) : '';
			}
		} else {
			return !!inscriptionToAccept ? i18n.t('confirmation.inscription-atendee', {
				user: (inscriptionToAccept.name || inscriptionToAccept.user?.name) + ' ' + (inscriptionToAccept.surname || inscriptionToAccept.user?.surname),
			}) : '';
		}
	};
	const getBatchName = (name: string | undefined) => {
		if (name === 'FREE_BATCH') {
			return t(`event.batch.type.${name}`);
		}
		return name;
	}
	return (
		<>
			{loader}
			{showConfirmationModal && (
				<CustomModal
					buttonCancel={t('cancel')}
					buttonOK={t('confirm')}
					isShowing={showConfirmationModal}
					message={getConfirmationMessage()}
					parentCallback={handleAtendee}
					type="WARNING_EDIT"
				></CustomModal>
			)}
			{showErrorModal && (
				<CustomModal
					buttonOK={t('accept')}
					isShowing={showErrorModal}
					title={t('error.modal-title-oops')}
					message={errorMessage ? errorMessage :
						(props.isFinished
							? t('error.event-ended')
							: t('error.event-not-started'))
					}
					parentCallback={handleCloseErrorModal}
					type="ERROR"
				></CustomModal>
			)}

			{!!currentInscription && !!currentInscription.event &&
				<>
					<tr key={currentInscription._id}
						onClick={handleViewDetails}
						className='inscription-row groupal'>
						{/* Primer columna de accion */}
						{/* Action */}
						<td className='cursor-pointer text-center'>
							{props.canAttendee && props.statuses.indexOf('REJECTED') < 0
								&& props.statuses.indexOf('ATTENDEED') < 0
								&& (
									<div onClick={selectInscription}
										className={
											checked
												? 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded checked'
												: 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded'
										}
									>
										{<IoCheckmarkSharp />}
									</div>
								)}

						</td>
						{/* Nombre Apellido email */}
						<td >
							<div className="inscription-name-title">
								{currentInscription.name || currentInscription.user?.name}{' '}
								{currentInscription.surname || currentInscription.user?.surname}{': '}

								{/* <label className="inscription-companions-title m-0">{getCompanionQties(currentInscription?.companions, currentInscription?.user?.group)}</label> */}
								<label className="inscription-companions-title m-0">
									{currentInscription.inscriptionStatus !== 'REJECTED' && <div dangerouslySetInnerHTML={{
										__html: getInscriptionQtyString(currentInscription, props.statuses),
									}}></div>}
									{currentInscription.inscriptionStatus === 'REJECTED' && <div dangerouslySetInnerHTML={{
										__html: getInscriptionQtyString(currentInscription, ['REJECTED']),
									}}></div>}

								</label>

							</div>
						</td>
						{/* Rellenado */}
						{props.includeSector && currentInscription.event.hasSectors == "yes" &&
							<td className='hide-medium'></td>
						}
						{currentInscription.event.includeDOB &&
							<td className='hide-medium'></td>
						}
						{currentInscription.event.includeGender &&
							<td className='hide-medium'></td>
						}
						{/* {currentInscription.event.includeMenu &&
							<td className='hide-medium'></td>
						} */}

						{!!currentInscription?.inscriptionStatus &&
							<td className='hide-medium'></td>
						}
						{/* Expand icon */}
						<td>
							<div className={'inscriptions-link icon cursor-pointer text-right'}>
								{!expanded && <IoIosArrowDown></IoIosArrowDown>}
								{expanded && <IoIosArrowUp></IoIosArrowUp>}
							</div>

						</td>
					</tr>
					{expanded &&
						// Main inscription
						<>
							<tr key={currentInscription.user?._id} className='inscription-row align-items-center m-0'
								onClick={(e) => selectSingleInscription(e, currentInscription)}
							>
								{/* Action */}
								<td className='text-center' >

									{props.canAttendee &&
										!props.statuses.includes('ATTENDEED') &&
										!!currentInscription.inscriptionStatus && !['REJECTED', 'DENIED'].includes(currentInscription.inscriptionStatus) &&
										props.statuses.includes(currentInscription.inscriptionStatus) &&
										<div
											className={
												currentInscription.inscriptionStatus == 'ATTENDEED'
													? 'custom-checkbox inscription-checkbox checkbox-rounded successful'
													: (checked || currentInscription._id === inscriptionToAccept?._id)
														? 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded checked'
														: 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded'
											}
										>
											{<IoCheckmarkSharp />}
										</div>
									}

								</td>
								{/* Name */}
								<td className=''>
									<div className="inscription-name d-flex align-items-center">
										<FaCircle className={currentInscription.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!currentInscription.user ? "inactive-dot mr-1" : "d-none")} />
										{currentInscription.name || currentInscription.user?.name}{' '}
										{currentInscription.surname || currentInscription.user?.surname}
									</div>
									{!!currentInscription.event.includeIdentification && !!currentInscription.identificationNumber && !!currentInscription.identificationNumber
										&& <div className="inscription-email">
											{currentInscription.identificationType + ' ' + currentInscription.identificationNumber}
										</div>}

									{(currentInscription.email || currentInscription.user?.email) &&
										<div className="inscription-email">
											{currentInscription.email || currentInscription.user?.email}
										</div>}
									{/* Mobile */}
									<div className='hide-not-medium'>
										{props.includeSector && currentInscription.event.hasSectors == "yes" &&

											<div className="inscription-companions">
												{currentInscription.sector?.name || t('event.sector.no-sector')}

											</div>

										}
										{/* Rango etareo y Genero */}
										{(currentInscription.event.includeDOB || currentInscription.event.includeGender) &&
											<div className="inscription-companions">
												{currentInscription.event.includeDOB && t('age-group.' + (currentInscription.group))}
												{currentInscription.event.includeDOB && currentInscription.event.includeGender && ' - '}
												{currentInscription.event.includeGender && t('gender.' + (currentInscription.gender))}

											</div>
										}

										{/* Menú
										{currentInscription.event.includeMenu &&
											<div className="inscription-companions">
												{
													(currentInscription.menu || currentInscription.menuCondition) &&
													<div>
														{(currentInscription.menu ? t('event.menu.' + currentInscription.menu) : '') +
															(currentInscription.menu && currentInscription.menuCondition ? ' - ' : '') +
															(currentInscription.menuCondition ? t('event.menu-condition.' + currentInscription.menuCondition) : '')
														}</div>
												}

											</div>
										} */}
									</div>
								</td>
								{/* Sector */}
								{/* Sector */}
								{currentInscription.event?.hasSectors == "yes" &&
									<td className='hide-medium'>
										<div className="inscription-companions">
											{currentInscription.sector?.name || t('event.sector.no-sector')}

										</div>
									</td>
								}
								{/* Rango etareo */}
								{currentInscription.event.includeDOB &&
									<td className="inscription-companions hide-medium">
										<div>{t('age-group.' + (currentInscription.group))}</div>

									</td>
								}
								{/* Genero */}
								{currentInscription.event.includeGender &&
									<td className="inscription-companions hide-medium">
										<div>{t('gender.' + (currentInscription.gender))}</div>


									</td>
								}
								{/* Menú
								{props.includeMenu && currentInscription.event.includeMenu &&
									<td className="inscription-companions hide-medium">
										{
											(currentInscription.menu || currentInscription.menuCondition) &&
											<div>
												{(currentInscription.menu ? t('event.menu.' + currentInscription.menu) : '') +
													(currentInscription.menu && currentInscription.menuCondition ? ' - ' : '') +
													(currentInscription.menuCondition ? t('event.menu-condition.' + currentInscription.menuCondition) : '')
												}</div>
										}

									</td>
								} */}
								{/* Status */}
								{!!currentInscription.inscriptionStatus &&
									<td>
										{(currentInscription.event.eventType === 'PRIVATE_EVENT' || currentInscription.inscriptionStatus == 'ATTENDEED') && <div className="p-0 m-0 d-flex align-items-center justify-content-start justify-content-md-end">
											<div className={'mr-2 status-tag ' + currentInscription.inscriptionStatus}>{t('inscription.status-tag.' + currentInscription.inscriptionStatus)}</div>
										</div >}
										<div className='inscription-email small hide-not-medium'>
											{(currentInscription.event.eventType === 'PUBLIC_EVENT' && currentInscription.inscriptionStatus != 'ATTENDEED') &&
												// <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
												<>
													{currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
														{'$ ' + currentInscription.batch?.price}
														{currentInscription.payment?.cardBrand && (
															<span className="d-none d-md-inline">
																{' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
															</span>
														)}
													</div>) : null}
													{currentInscription.payment?.cardBrand && (
														<div className="d-md-none small mt-1 inscription-companions small mt-1">
															{currentInscription.payment?.cardBrand?.toUpperCase() +
																'-' +
																currentInscription.payment.cardLast4}
														</div>
													)}
													<div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
													<div className="inscription-email small mt-1">
														{currentInscription.acceptedDT
															? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
															: ''}
													</div></>
											}
											{(currentInscription.event.eventType === 'PRIVATE_EVENT' || currentInscription.inscriptionStatus == 'ATTENDEED') &&
												<>
													{(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
														currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
															(currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
													<br></br>
													<span className="smaller">
														{(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
															currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
													</span>

												</>}
										</div>
									</td>
								}
								{!!currentInscription?.inscriptionStatus &&
									<td className="inscription-email small hide-medium">
										{(currentInscription.event.eventType === 'PUBLIC_EVENT' && currentInscription.inscriptionStatus != 'ATTENDEED') &&
											<>
												{/* <div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div> */}
												{currentInscription.batch?.price ? (<div className="inscription-companions small mt-1">
													{'$ ' + currentInscription.batch?.price}
													{currentInscription.payment?.cardBrand && (
														<span className="d-none d-md-inline">
															{' . ' + currentInscription.payment?.cardBrand?.toUpperCase() + '-' + currentInscription.payment.cardLast4}
														</span>
													)}
												</div>) : null}
												{currentInscription.payment?.cardBrand && (
													<div className="d-md-none small mt-1 inscription-companions small mt-1">
														{currentInscription.payment?.cardBrand?.toUpperCase() +
															'-' +
															currentInscription.payment.cardLast4}
													</div>
												)}
												<div className="inscription-email small mt-1">{getBatchName(currentInscription.batch?.name)}</div>
												<div className="inscription-email small mt-1">
													{currentInscription.acceptedDT
														? DateHelper.getStringDateTimeFromDate(new Date(currentInscription.acceptedDT))
														: ''}
												</div>
											</>
										}
										{(currentInscription.event.eventType !== 'PUBLIC_EVENT' || currentInscription.inscriptionStatus === 'ATTENDEED') &&
											<>
												{(currentInscription.inscriptionStatus === 'ATTENDEED' ? (currentInscription.attendeedBy?.name || '') + ' ' + (currentInscription.attendeedBy?.surname || '') :
													currentInscription.inscriptionStatus === 'ACCEPTED' ? (currentInscription.acceptedBy?.name || '') + ' ' + (currentInscription.acceptedBy?.surname || '') :
														(currentInscription.deniedBy?.name || '') + ' ' + (currentInscription.deniedBy?.surname || ''))}
												<br></br>
												<span className="smaller">
													{(currentInscription.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(currentInscription.attendeedDT?.toString()) :
														currentInscription.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(currentInscription.acceptedDT?.toString()) : DateHelper.dateAndTime(currentInscription.deniedDT?.toString()))}
												</span>
											</>}


									</td>
								}
							</tr>

							{currentInscription.companions?.map((companion: IInscription, index: any) => (
								<tr key={companion.user?._id} className='inscription-row align-items-center m-0'
									onClick={(e) => selectSingleInscription(e, companion)}
								>
									{/* Action */}
									<td className='text-center' >

										{props.canAttendee &&
											!props.statuses.includes('ATTENDEED') &&
											!!companion.inscriptionStatus && !['REJECTED', 'DENIED'].includes(companion.inscriptionStatus) &&
											props.statuses.includes(companion.inscriptionStatus) &&
											<div
												className={
													companion.inscriptionStatus == 'ATTENDEED'
														? 'custom-checkbox inscription-checkbox checkbox-rounded successful'
														: (checked || companion._id === inscriptionToAccept?._id)
															? 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded checked'
															: 'custom-checkbox cursor-pointer inscription-checkbox checkbox-rounded'
												}
											>
												{<IoCheckmarkSharp />}
											</div>
										}

									</td>
									{/* Name */}
									<td className=''>
										<div className="inscription-name d-flex align-items-center">
											<FaCircle className={companion.user?.status == "ACTIVE" ? "active-dot mr-1" : (!!companion.user ? "inactive-dot mr-1" : "d-none")} />
											{companion.name || companion.user?.name}{' '}
											{companion.surname || companion.user?.surname}
										</div>
										{!!currentInscription.event?.includeIdentification && !!companion.identificationNumber && !!companion.identificationNumber
											&& <div className="inscription-email">
												{companion.identificationType + ' ' + companion.identificationNumber}
											</div>}

										{(companion.email || companion.user?.email) &&
											<div className="inscription-email">
												{companion.email || companion.user?.email}
											</div>}
										{/* Mobile */}
										<div className='hide-not-medium'>
											{props.includeSector && currentInscription.event?.hasSectors == "yes" &&

												<div className="inscription-companions">
													{companion.sector?.name || t('event.sector.no-sector')}

												</div>

											}
											{/* Rango etareo y Genero */}
											{(currentInscription.event?.includeDOB || currentInscription.event?.includeGender) &&
												<div className="inscription-companions">
													{currentInscription.event?.includeDOB && t('age-group.' + (companion.group))}
													{currentInscription.event?.includeDOB && currentInscription.event?.includeGender && ' - '}
													{currentInscription.event?.includeGender && t('gender.' + (companion.gender))}

												</div>
											}

											{/* Menú
                                        {currentInscription.event?.includeMenu &&
                                            <div className="inscription-companions">
                                                {
                                                    (companion.menu || companion.menuCondition) &&
                                                    <div>
                                                        {(companion.menu ? t('event.menu.' + companion.menu) : '') +
                                                            (companion.menu && companion.menuCondition ? ' - ' : '') +
                                                            (companion.menuCondition ? t('event.menu-condition.' + companion.menuCondition) : '')
                                                        }</div>
                                                }

                                            </div>
                                        } */}
										</div>
									</td>

									{/* Sector */}
									{currentInscription.event?.hasSectors == "yes" &&
										<td className='hide-medium'>
											<div className="inscription-companions">
												{companion.sector?.name || t('event.sector.no-sector')}

											</div>
										</td>
									}
									{/* Rango etareo */}
									{currentInscription.event?.includeDOB &&
										<td className="inscription-companions hide-medium">
											<div>{t('age-group.' + (companion.group))}</div>

										</td>
									}
									{/* Genero */}
									{currentInscription.event?.includeGender &&
										<td className="inscription-companions hide-medium">
											<div>{t('gender.' + (companion.gender))}</div>


										</td>
									}
									{/* Menú
                                {props.includeMenu && currentInscription.event?.includeMenu &&
                                    <td className="inscription-companions hide-medium">
                                        {
                                            (companion.menu || companion.menuCondition) &&
                                            <div>
                                                {(companion.menu ? t('event.menu.' + companion.menu) : '') +
                                                    (companion.menu && companion.menuCondition ? ' - ' : '') +
                                                    (companion.menuCondition ? t('event.menu-condition.' + companion.menuCondition) : '')
                                                }</div>
                                        }

                                    </td>
                                } */}
									{/* Status */}
									{!!companion.inscriptionStatus &&
										<td>
											{(currentInscription.event?.eventType === 'PRIVATE_EVENT' || companion.inscriptionStatus == 'ATTENDEED') && <div className="p-0 m-0 d-flex align-items-center justify-content-start justify-content-md-end">
												<div className={'mr-2 status-tag ' + companion.inscriptionStatus}>{t('inscription.status-tag.' + companion.inscriptionStatus)}</div>
											</div >}
											<div className='inscription-email small hide-not-medium'>
												{(currentInscription.event?.eventType === 'PUBLIC_EVENT' && companion.inscriptionStatus != 'ATTENDEED') &&
													// <div className="inscription-email small mt-1">{getBatchName(companion.batch?.name)}</div>
													<>
														{companion.batch?.price ? (<div className="inscription-companions small mt-1">
															{'$ ' + companion.batch?.price}
															{companion.payment?.cardBrand && (
																<span className="d-none d-md-inline">
																	{' . ' + companion.payment?.cardBrand?.toUpperCase() + '-' + companion.payment.cardLast4}
																</span>
															)}
														</div>) : null}
														{companion.payment?.cardBrand && (
															<div className="d-md-none small mt-1 inscription-companions small mt-1">
																{companion.payment?.cardBrand?.toUpperCase() +
																	'-' +
																	companion.payment.cardLast4}
															</div>
														)}
														<div className="inscription-email small mt-1">{getBatchName(companion.batch?.name)}</div>
														<div className="inscription-email small mt-1">
															{companion.acceptedDT
																? DateHelper.getStringDateTimeFromDate(new Date(companion.acceptedDT))
																: ''}
														</div></>
												}
												{(currentInscription.event?.eventType === 'PRIVATE_EVENT' || companion.inscriptionStatus == 'ATTENDEED') &&
													<>
														{(companion.inscriptionStatus === 'ATTENDEED' ? (companion.attendeedBy?.name || '') + ' ' + (companion.attendeedBy?.surname || '') :
															companion.inscriptionStatus === 'ACCEPTED' ? (companion.acceptedBy?.name || '') + ' ' + (companion.acceptedBy?.surname || '') :
																(companion.deniedBy?.name || '') + ' ' + (companion.deniedBy?.surname || ''))}
														<br></br>
														<span className="smaller">
															{(companion.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(companion.attendeedDT?.toString()) :
																companion.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(companion.acceptedDT?.toString()) : DateHelper.dateAndTime(companion.deniedDT?.toString()))}
														</span>

													</>}
											</div>
										</td>
									}
									{!!companion?.inscriptionStatus &&
										<td className="inscription-email small hide-medium">
											{(currentInscription.event?.eventType === 'PUBLIC_EVENT' && companion.inscriptionStatus != 'ATTENDEED') &&
												<>
													{/* <div className="inscription-email small mt-1">{getBatchName(companion.batch?.name)}</div> */}
													{companion.batch?.price ? (<div className="inscription-companions small mt-1">
														{'$ ' + companion.batch?.price}
														{companion.payment?.cardBrand && (
															<span className="d-none d-md-inline">
																{' . ' + companion.payment?.cardBrand?.toUpperCase() + '-' + companion.payment.cardLast4}
															</span>
														)}
													</div>) : null}
													{companion.payment?.cardBrand && (
														<div className="d-md-none small mt-1 inscription-companions small mt-1">
															{companion.payment?.cardBrand?.toUpperCase() +
																'-' +
																companion.payment.cardLast4}
														</div>
													)}
													<div className="inscription-email small mt-1">{getBatchName(companion.batch?.name)}</div>
													<div className="inscription-email small mt-1">
														{companion.acceptedDT
															? DateHelper.getStringDateTimeFromDate(new Date(companion.acceptedDT))
															: ''}
													</div>
												</>
											}
											{(currentInscription.event?.eventType !== 'PUBLIC_EVENT' || companion.inscriptionStatus === 'ATTENDEED') &&
												<>
													{(companion.inscriptionStatus === 'ATTENDEED' ? (companion.attendeedBy?.name || '') + ' ' + (companion.attendeedBy?.surname || '') :
														companion.inscriptionStatus === 'ACCEPTED' ? (companion.acceptedBy?.name || '') + ' ' + (companion.acceptedBy?.surname || '') :
															(companion.deniedBy?.name || '') + ' ' + (companion.deniedBy?.surname || ''))}
													<br></br>
													<span className="smaller">
														{(companion.inscriptionStatus === 'ATTENDEED' ? DateHelper.dateAndTime(companion.attendeedDT?.toString()) :
															companion.inscriptionStatus === 'ACCEPTED' ? DateHelper.dateAndTime(companion.acceptedDT?.toString()) : DateHelper.dateAndTime(companion.deniedDT?.toString()))}
													</span>
												</>}


										</td>
									}
								</tr>


							))}

						</>

					}

				</>
			}
		</>
	);
}
