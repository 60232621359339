import { useHistory, Redirect } from 'react-router-dom';

import useApi from '../hooks/useApi';
import { IoIosArrowBack, IoIosPrint } from 'react-icons/io';
import { MdFilterList } from 'react-icons/md';
import { Form, Button, Accordion, Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLoader from '../components/loader/useLoader';
import NoResults from '../components/no-results/noResults';
import i18next from 'i18next';
import React, { useState, useEffect } from 'react';
import Paginator from '../components/paginator/paginator';
import { USERS_PAGE_SIZE } from '../../constants';
import { UserCommunityCard } from './userCommunityCard';
import { genderOptions } from '../../constants';
import { ageGroupOptions } from '../../constants';
import Select from 'react-select';
import CommunityUsersReportPDF from '../reports/communityUsersReport';
import CustomModal from '../components/modals/customModal';
import { IoArrowDown } from 'react-icons/io5';
import { exportCommunityUsersToExcel } from '../helpers/excel-report-helper';
import { UserCommunityRow } from './userCommunityRow';

export function Community() {
	const [users, setUsers] = useState([]);
	const [usersPage, setUsersPage] = useState(0);
	const [usersTotal, setUsersTotal] = useState(0);

	const [loader, showLoader, hideLoader] = useLoader();
	const [
		loggedUser,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		,
		getAllRoles,
		,
		getCommunityFilteredUsers,
	] = useApi();
	const [roles, setRoles] = useState([]);

	const [textFilter, setTextFilter] = useState('');
	const [genderFilter, setGenderFilter] = useState('');
	const [selectedGroups, setSelectedGroups] = useState<{ id: number, value: string, key: string }[]>([]);
	const [selectedRoles, setSelectedRoles] = useState<{ id: number, value: string, key: string }[]>([]);
	const [showErrorReportModal, setShowErrorReportModal] = useState(false);

	const [openFilters, setOpenFilters] = useState(false);

	const { t, i18n } = useTranslation();

	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');

	useEffect(() => {
		handleDataLoad();
	}, [loggedUser]);

	const handleDataLoad = async () => {
		if (loggedUser && users.length < 1) {
			showLoader();
			const usrs = await loadUsers(0);
			setUsers(usrs);
			hideLoader();
		}

		if (roles && roles.length === 0) {
			showLoader();
			const rs = await getAllRoles();
			if (rs) setRoles(rs.filter((r: any) => r.level < loggedUser?.role.roleLevel));
			hideLoader();
		}
	};

	const loadUsers = async (pageNumber: number, reset?: boolean) => {
		const _users: any = reset ?
			await getCommunityFilteredUsers(pageNumber, "", "", [], []) :
			await getCommunityFilteredUsers(pageNumber, textFilter, genderFilter, selectedRoles.map((r) => r.value), selectedGroups.map((r) => r.value));
		if (!!_users) {
			setUsersTotal(_users['total']);
			return _users['users'];
		}
		return [];
	};

	const handlePrev = async () => {
		showLoader();
		const pending = await loadUsers(usersPage - 1);
		if (pending && pending.length > 0) {
			setUsers([]);
			setUsers(pending);
		}
		setUsersPage(usersPage - 1);
		hideLoader();
	};
	const handleNext = async () => {
		showLoader();
		const pending = await loadUsers(usersPage + 1);
		if (pending && pending.length > 0) {
			setUsers([]);
			setUsers(pending);
		}
		setUsersPage(usersPage + 1);
		hideLoader();
	};

	const handleTextFilterChange = (e: any) => {
		setTextFilter(e.target.value);
	};

	const handleGenderFilterChange = (e: any) => {
		const g = genderOptions.find((x: any) => x.name === e.name);
		if (!!g && g.name) {
			setGenderFilter(g.name);
		} else {
			setGenderFilter('');
		}
	};

	const handleApplyFilters = async () => {
		if (loggedUser) {
			showLoader();
			const filteredUsrs = await loadUsers(0);
			setUsers(filteredUsrs);
			setUsersPage(0);
			hideLoader();
		}
	};

	const handleResetFilters = async () => {
		if (loggedUser) {
			showLoader();
			setTextFilter('');
			setGenderFilter('');
			setSelectedRoles([]);
			setSelectedGroups([]);
			const usrs = await loadUsers(0, true);
			setUsers(usrs);
			setUsersPage(0);
			hideLoader();
		}
	};

	const handleKeyDown = async (e: any) => {
		if (e.keyCode == 13) {
			await handleApplyFilters();
		}
	};

	const handleGroupsChange = (opt: any) => {
		setSelectedGroups(opt);
	};

	const handleRolesChange = (opt: any) => {
		setSelectedRoles(opt);
	};

	const handleOnReport = async () => {
		if (users?.length === 0) {
			setShowErrorReportModal(true);
		} else {
			showLoader();
			const filters = {
				text: textFilter,
				gender: genderFilter,
				roles: selectedRoles.map((r) => r.value),
				groups: selectedGroups.map((g) => g.value)
			}
			const reportCommunityResult = await getCommunityFilteredUsers(0, filters.text, filters.gender, filters.roles, filters.groups, 0);			
			//await CommunityUsersReportPDF(loggedUser, reportCommunityResult, filters, getRoleOptions(roles));
			exportCommunityUsersToExcel(loggedUser, reportCommunityResult, filters, t);
			hideLoader();
		}
	}

	const handleCloseErrorReportModal = () => {
		setShowErrorReportModal(false);
	}

	const getRoleOptions = (arr: any[]) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label: `${t('role.' + x.name)}`,
					value: x.name,
					key: x['_id'],
				};
				options.push(opt);
			});
		}
		return options;
	};

	const getGroupOptions = (arr: any[]) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label: `${t(
						'inscription.add-' + x.name
					)}`,
					value: x.name,
					key: x['_id'],
				};
				options.push(opt);
			});
		}
		return options;
	};

	const history = useHistory();
	const getOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [{ name: '0', value: 0, label: i18n.t('select') }];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const _genderOptions = getOptions(genderOptions, 'gender.');
	return (
		<>
			{loader}
			{!isLoggedUser && <Redirect to="/login" />}
			{isLoggedUser && loggedUser && (
				<>
					{loggedUser['canAccessCommunity'] ? (
						<div className="dashboard-content form">
							<div className='container-box'>
								<div className='container-box-content d-flex justify-content-between align-items-center'>
									<div className='d-flex '>
										<div className=''>
											<div className='banner-title d-flex align-items-center'>
												<p className='one-line'>
													{`${t('community')}`}
												</p>
											</div>
										</div>
									</div>
									<div className='d-flex align-items-center justify-content-end'>
										<Button
											className="rounded-button rounded-button-ok outline btn btn-primary"
											title={t('event.export-list')}
											onClick={(e: any) =>
												handleOnReport()
											}
										>
											<IoArrowDown />
										</Button>
									</div>
								</div>
							</div>
							<div className="form-container col-md-10 mt-x-5">
								<div className="justify-content-between align-items-center mb-3">
									<Card className="mt-3">
										<Card.Header
											onClick={() => setOpenFilters(!openFilters)}
											className="cursor-pointer cm-header"
										>
											<div className="d-flex justify-content-start align-items-center community-filter-title">
												<MdFilterList className="mr-2" />
												{`${t('filters')}`}
											</div>
										</Card.Header>
										{openFilters ?
											<Card.Body>
												<Form.Group>
													<div className="d-flex row">
														<div className="col-md-6">
															{/* Name, Email or Surname */}
															<Form.Group controlId="formTextFilter">
																<Form.Label className="input-label">
																	{`${t('community-filters.text-filter')}`}
																</Form.Label>
																<Form.Control
																	type="text"
																	name="name"
																	placeholder={`${t('community-filters.text-filter')}`}
																	value={textFilter}
																	onKeyDown={handleKeyDown}
																	onChange={handleTextFilterChange}
																	className="form-control-filters"
																/>
															</Form.Group>
														</div>
														<div className="col-md-6">
															{/* Gender */}
															<Form.Group controlId="formGender">
																<Form.Label className="input-label">
																	{`${t('user.gender')}`}
																</Form.Label>
																<Select
																	className="select-control"
																	placeholder={t('select')}
																	options={_genderOptions}

																	onChange={handleGenderFilterChange}
																/>
				
															</Form.Group>
														</div>
														<div className="col-md-6">
															{/* Role */}
															<Form.Group controlId="formRole">
																<label className="input-label form-label">{`${t(
																	'user.role'
																)}`}</label>
																<Select
																	className="select-control"
																	placeholder={t('select')}
																	options={getRoleOptions(roles)}
																	value={selectedRoles}
																	onChange={handleRolesChange}
																	isMulti
																/>
															</Form.Group>
														</div>
														<div className="col-md-6">
															{/* Age */}
															<Form.Group controlId="formGroup">
																<label className="input-label form-label">{`${t(
																	'age'
																)}`}</label>
																<Select
																	className="select-control"
																	placeholder={t('select')}
																	options={getGroupOptions(ageGroupOptions)}
																	value={selectedGroups}
																	onChange={handleGroupsChange}
																	isMulti
																/>
															</Form.Group>
														</div>
													</div>
													<div className="d-flex row justify-content-end col-12 m-0 p-0">
														<div className=" d-flex justify-content-end mt-4">
															<Button
																className="button-ok small"
																onClick={handleResetFilters}
															>
																{t(
																	'community-filters.reset'
																)}
															</Button>
														</div>
														<div className="ml-3 d-flex justify-content-end mt-4">
															<Button
																className="button-ok small"
																type="submit"
																onClick={handleApplyFilters}
															>
																{t('apply')}
															</Button>
														</div>
													</div>
												</Form.Group>
											</Card.Body>
											: null}
									</Card>
									<div className="pb-5 pt-5">
										<Table borderless>
											<thead>
												<tr className='user-header header'>
													<th></th>
													<th>{t('user-label')}</th>
													<th className='hide-medium'>{t('user.role')} </th>
													<th className='hide-medium'>{t('age')} </th>
													<th className='hide-medium'>{t('user.gender')}</th>
													<th></th>
												</tr>
											</thead>
											<tbody className="user-info-body">
												{users &&
													users?.map((user, i) => (
														<UserCommunityRow
															user={user}
															key={i}
															organizerId={loggedUser._id}
														/>
													))}
											</tbody>
										</Table>
										{users && users.length == 0 && (
											<div className="w-100 align-items-center">
												<NoResults
													text={i18next.t(
														'users-not-found'
													)} />
											</div>
										)}
										{users &&
											users.length > 0 && (
												<Paginator
													currentPage={usersPage}
													totalPages={Math.ceil(
														usersTotal /
														USERS_PAGE_SIZE
													)}
													onPrevious={handlePrev}
													onNext={handleNext}
												></Paginator>
											)}
									</div>
								</div>
							</div>
							{showErrorReportModal && (
								<CustomModal
									isShowing={showErrorReportModal}
									parentCallback={handleCloseErrorReportModal}
									message={t('event.error-delete-report')}
									title={t('error.modal-title-oops')}
									buttonOK={t('accept')}
									type="ERROR"
								/>
							)}
						</div>
					) : (
						<Redirect to="dashboard"></Redirect>
					)}
				</>
			)}
		</>
	);
}

export default Community;