import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import useApi from '../../hooks/useApi';
import useUsersApi from '../../hooks/useUsersApi';
import Select from 'react-select';
import { IoIosSave, IoIosArrowBack } from 'react-icons/io';

import Dropzone from '../../components/dropzone/dropzone';
import CustomModal from '../../components/modals/customModal';
import { useHistory, Redirect } from 'react-router';
import { DNI_MAX_LENGTH, genderOptions, IDENTIFICATION_TYPES, NAME_MAX_LENGTH, SHOW_PUBLIC_EVENTS, SURNAME_MAX_LENGTH } from '../../../constants';
import { useTranslation } from 'react-i18next';
import useLoader from '../../components/loader/useLoader';
import { Nav } from 'react-bootstrap';
import { TGender, TIdentificationType } from '../../models/types/types';
import { Validator } from '../../helpers/validators';
import { DateHelper } from '../../helpers/date-helper';
import CustomDatePicker from '../../components/custom-date-picker/custom-date-picker';
import useImageUpload from '../../hooks/useImageUpload';
import { CLOUDINARY_CLOUD_NAME, UPLOAD_TYPES } from '../../../constants';
import { AuthContext } from '../../globalStates';
import { IoArrowBackOutline } from 'react-icons/io5';
import { IUser } from '../../models/interfaces/users.interface';
export function MyProfile() {
	const lang: string = localStorage.getItem("i18nextLng") || 'en';
	const [loader, showLoader, hideLoader] = useLoader();
	const [name, setName] = useState<any>(undefined);
	const [surname, setSurname] = useState<any>(undefined);
	const [avatar, setAvatar] = useState<any>(undefined);
	const [loggedUser] = useApi();
	const [authState, setAuthState] = useContext(AuthContext);
	const [
		uploadImageCloud
	] = useImageUpload();
	const [, , updateUser] = useUsersApi();
	const [validated, setValidated] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [imageIsDirty, setImageIsDirty] = useState(false);
	const [gender, setGender] = useState<TGender | undefined>(undefined);
	const [dob, setDOB] = useState<Date>();
	const [identificationNumber, setIdentificationNumber] = useState<string | undefined>(undefined);
	const [identificationType, setIdentificationType] = useState<TIdentificationType | undefined>(undefined);
	const [mpAccessToken, setMpAccessToken] = useState<string | undefined>(undefined);
	const [mpPublicKey, setMpPublicKey] = useState<string | undefined>(undefined);
	const [
		showConfirmationDiscardModal,
		setShowConfirmationDiscardModal,
	] = useState(false);
	const [showConfirmationSaveModal, setShowConfirmationSaveModal] = useState(
		false
	);
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorModalMessage, setErrorModalMessage] = useState('');
	const [showPersonInfo, setShowPersonInfo] = useState(false);
	const [showOrganizerInfo, setShowOrganizerInfo] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();
	const { t, i18n } = useTranslation();

	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');

	let today = new Date();

	let minDate = new Date();
	minDate.setFullYear(today.getFullYear() - 110);

	const handleEffect = async () => {
		showLoader();
		if (loggedUser && isLoggedUser) {
			if (loggedUser && loggedUser['_id'] && loggedUser['role']) {
				setGender(loggedUser.gender);
				if (!!loggedUser.dob) {
					setDOB(DateHelper.getDateWithoutTZ(loggedUser.dob));
				}
				if (!!loggedUser.name) {
					setName(loggedUser.name);
				}
				if (!!loggedUser.surname) {
					setSurname(loggedUser.surname);
				}
				if (!!loggedUser.identificationNumber) {
					setIdentificationNumber(loggedUser.identificationNumber);
				}
				if (!!loggedUser.identificationType) {
					setIdentificationType(loggedUser.identificationType);
				}
				if (!!loggedUser.mpAccessToken){
					setMpAccessToken(loggedUser.mpAccessToken)
				}
				if(!!loggedUser.mpPublicKey){
					setMpPublicKey(loggedUser.mpPublicKey);
				}
				setShowPersonInfo(loggedUser['role'].roleName != 'USER_ORGANIZER' || loggedUser.isPersonOrganizer);
				setShowOrganizerInfo(
					loggedUser['role'].roleName == 'USER_ORGANIZER'
				);
				setAvatar(loggedUser['avatar']);
				hideLoader();
			}
		}
	};

	useEffect(() => {
		handleEffect();
	}, [loggedUser]);

	const handleOnNameChange = (event: any) => {
		setName(event.target.value);
		setIsDirty(true);
	};

	const handleOnSurnameChange = (event: any) => {
		setSurname(event.target.value);
		setIsDirty(true);
	};

	const handleOnGenderChange = (event: any) => {
		const g = genderOptions.find((x) => x.name == event.name);
		if (g && g.name) {
			setGender(g.name);
		} else {
			setGender(undefined);
		}
		setIsDirty(true);
	};
	const handleDOBChange = (_date: Date) => {
		setDOB(_date);
		setIsDirty(true);
	};
	const handleOnCreateIdentificationNumber = (event: any) => {
		setIdentificationNumber(event.target.value);
		setIsDirty(true);
	};
	const handleOnIdentificationTypeChange = (e: any) => {
		const g = identificationTypesOptions.find((x: any) => x.name === e.name);
		if (!!g && g.name) {
			setIdentificationType(g.name);
		} else {
			setIdentificationType(undefined);
		}
		setIsDirty(true);
	};

	const handleOnMpAccessTokenChange = (event: any) => {
		setMpAccessToken(event.target.value);
		setIsDirty(true);
	};

	const handleOnMpPublicKeyChange = (event: any) => {
		setMpPublicKey(event.target.value);
		setIsDirty(true);
	};

	const handleOnCancel = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (isDirty) {
			setShowConfirmationDiscardModal(true);
		} else {
			history.goBack();
		}
	};
	const handleCloseDiscardModal = async (e: any) => {
		if (e) {
			history.goBack();
		}
		setShowConfirmationDiscardModal(false);
	};

	const handleCloseSuccessModal = (e: any) => {
		if (e) {
			history.push('/dashboard');
		}
		setShowSuccessModal(false);
	};

	const handleCloseSaveModal = async (e: any) => {
		setShowConfirmationSaveModal(false);
		if (e) {
			try {
				showLoader();
				performUpdate();
			}
			catch (e){
				console.log(e);
			} finally{
				hideLoader();
			}
			
		}
	};

	const performUpdate = async () => {
		const group: any = dob ? DateHelper.getGroup(dob) : (!showOrganizerInfo ? 'ADULT' : null);
		let uploadedImageUrl = undefined;
		if (imageIsDirty && avatar) {
			const uploadedImage = await uploadImageCloud(avatar, UPLOAD_TYPES[1].name);
			if (uploadedImage && uploadedImage.data) {
				uploadedImageUrl = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/${uploadedImage.data.public_id}.jpg`;
			}
		}
		const usr: IUser = {
			name,
			surname,
			_id: loggedUser._id,
			gender,
			dob: DateHelper.saveDateWithoutTZ(dob),
			group,
			lastUpdatedBy: loggedUser._id,
			lastUpdatedDT: new Date(),
			identificationNumber,
			identificationType,
			mpAccessToken,
			mpPublicKey
		};
		if (imageIsDirty){
			usr.avatar = uploadedImageUrl;
		}

		const response: any = await dispatch(
			updateUser(usr)
		);
		if (response) {
			setAuthState(null);
			setShowSuccessModal(true);
		}
	};
	const validateAll = (form: any) => {
		return !!form && form.checkValidity()
			&& (showPersonInfo ? !Validator.name(surname) : true)
			&& (showPersonInfo ? !Validator.name(name) : true)
	}
	const handleSubmit = async (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (isDirty) {
			const form = event.currentTarget;
			if (validateAll(form)) {
				setShowConfirmationSaveModal(true);
			}
			setValidated(true);
		}
	};

	const handleChangeDropzone = async (e: any) => {
		if (e != null) {
			showLoader();
			//const base64 = await convertToBase64(e[0]);
			setAvatar(e[0]);
			setIsDirty(true);
			setImageIsDirty(true);
			hideLoader();
		} else {
			setAvatar(null);
			setIsDirty(true);
			setImageIsDirty(true);
		}
	};
	const handleCloseErrorModal = () => {
		setErrorModalMessage('');
		setShowErrorModal(false);
	}
	const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
		const options: any[] = [];
		if (arr && arr.length > 0) {
			arr.forEach((x) => {
				let opt = {
					label:
						i18n.t(key + x.name),
					value: x['_id'],
					name: x.name
				};
				options.push(opt);
			});
		}
		return options;
	};
	const _genderOptions = getSelectSingleOptions(genderOptions, 'gender.');
	const identificationTypesOptions = getSelectSingleOptions(IDENTIFICATION_TYPES, 'identification-type.');

	return (
		<>
			{loader}
			{!isLoggedUser && <Redirect to="/login" />}
			{loggedUser && (
				<div className="dashboard-content form">
					<div className='container-box'>
						<div className='container-box-content d-flex justify-content-between align-items-center'>
							<div className='d-flex '>
								<div
									className="button-back mr-2"
									onClick={(e: any) => handleOnCancel(e)}
								>
									<IoArrowBackOutline />
								</div>
								<div className='ml-4 mr-4'>
									<div className='banner-title d-flex align-items-center'><p className='one-line'>{t('my-profile')}</p></div>
								</div>
							</div>
							<div className='d-flex row align-items-center justify-content-end'>
								<Button
									className="rounded-button rounded-button-ok btn btn-primary outline"
									type="submit"
									disabled={!isDirty}
									onClick={handleSubmit}
								>
									<IoIosSave></IoIosSave>
								</Button>
							</div>
						</div>
					</div>
					<Form
						noValidate
						validated={validated}
						className="form-container col-xl-8 mt-x-5"
						onSubmit={handleSubmit}
					>
						<div className="d-flex row pb-5 form-info-container">
							<div className=" col-md-4 order-md-2">
								<Dropzone
									className="dropzone"
									value={avatar}
									onChangeStatus={handleChangeDropzone}
									setErrorModalMessage={setErrorModalMessage}
									setShowErrorModal={setShowErrorModal}
								/>
							</div>
							<div className="col-md-8 order-md-1">
								<Form.Group controlId="formName">
									<Form.Label className="input-label">
										{`${t('user.role')}*`}
									</Form.Label>
									<Form.Control
										type="text"
										name="name"
										placeholder={t('user.role')}
										defaultValue={`${t(
											'role.' + loggedUser.role.roleName
										)}`}
										disabled
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label className="input-label">
										{`${t('user.email')}*`}
									</Form.Label>
									<Form.Control
										className="edit-user-input"
										type="email"
										name="email"
										placeholder={t('user.email')}
										defaultValue={loggedUser.email}
										required
										disabled
									/>
								</Form.Group>
								<Form.Group controlId="formName">
									<Form.Label className="input-label">
										{`${t('user.name')}*`}
									</Form.Label>
									<Form.Control
										type="text"
										name="name"
										placeholder={t('user.name')}
										defaultValue={loggedUser.name}
										onChange={handleOnNameChange}
										required
										isInvalid={validated && (showPersonInfo ? Validator.name(name) : false)}
										maxLength={NAME_MAX_LENGTH}
									/>
									{validated && <Form.Control.Feedback type="invalid">
										{!name && `${t('error.required')}`}
										{!!name && `${t('error.invalid')}`}
									</Form.Control.Feedback>}
								</Form.Group>
								{/* LastName */}

								{showPersonInfo && (
									<Form.Group controlId="formSurname">
										<Form.Label className="input-label">
											{`${t('user.lastname')}*`}
										</Form.Label>
										<Form.Control
											placeholder={t('user.lastname')}
											type="text"
											name="surname"
											defaultValue={loggedUser.surname}
											onChange={handleOnSurnameChange}
											required
											maxLength={SURNAME_MAX_LENGTH}
											isInvalid={validated && Validator.name(surname)}
										/>
										{validated && <Form.Control.Feedback type="invalid">
											{!surname && `${t('error.required')}`}
											{!!surname && `${t('error.invalid')}`}
										</Form.Control.Feedback>}
									</Form.Group>
								)}
								{showPersonInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">{`${t(
													'identification-type-label'
												)}`}</Form.Label>

												<Select
													isInvalid={false}
													isValid={validated && !!identificationType}
													className={validated && !!identificationType ? "select-control valid" : "select-control"}
													placeholder={t('select')}
													options={identificationTypesOptions}
													value={identificationTypesOptions.filter(x => x.name === identificationType)}
													onChange={handleOnIdentificationTypeChange}
												/>
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('identification-number')}`}
												</Form.Label>
												<Form.Control
													defaultValue={loggedUser.identificationNumber}
													autoComplete='none'
													maxLength={DNI_MAX_LENGTH}
													isValid= {validated && !!identificationNumber && !Validator.identificationNumber(identificationNumber)}
													isInvalid={validated && !!identificationNumber && Validator.identificationNumber(identificationNumber)}
													type="text"
													name="identificationNumber"
													placeholder={t('identification-placeholder')}
													onChange={handleOnCreateIdentificationNumber}
												/>
												{validated && <Form.Control.Feedback type="invalid">
													{!!identificationNumber && Validator.identificationNumber(identificationNumber) && `${t('error.invalid')}`}
												</Form.Control.Feedback>}
											</div>
										</div>
									</Form.Group>
								)}
								{/* Gender an DOB */}
								{showPersonInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-6">
												<Form.Label className="input-label">{`${t(
													'user.gender'
												)}`}</Form.Label>
												<Select
													isInvalid={validated && !gender}
													className={validated && !!gender ? "select-control valid" : "select-control"}
													placeholder={t('select')}
													options={_genderOptions}
													value={_genderOptions.filter(x => x.name === gender)}
													onChange={handleOnGenderChange}
												/>
											</div>
											<div className="col-md-6">
												<Form.Label className="input-label">
													{`${t('user.dob')}`}
												</Form.Label>

												{/* <DatePicker
													locale={lang}
													dateFormat={(!!lang && lang.includes('en')) ? "MM/dd/yyyy" : "dd/MM/yyyy"}
													required
													className='form-control date'
													selected={dob}
													maxDate={today}
													minDate={minDate}
													onChange={handleDOBChange}
												></DatePicker> */}
												<CustomDatePicker
													isInvalid={false}
													isValid={validated && !!dob}
													maxDate={today}
													minDate={minDate}
													selected={dob}
													optional={true}
													onCustomDateInputChange={handleDOBChange}></CustomDatePicker>
											</div>
										</div>
									</Form.Group>
								)}
								{/* Mercado Pago keys */}
								{/* SHOW_PUBLIC_EVENTS && showOrganizerInfo && (
									<Form.Group>
										<div className="d-flex row">
											<div className="col-md-12">
												<Form.Label className="input-label">{`${t(
													'user.mpAccessToken'
												)}`}</Form.Label>

												<Form.Control
													defaultValue={loggedUser.mpAccessToken}
													type="text"
													name="mpAccessToken"
													placeholder={t('user.mpAccessToken')}
													onChange={handleOnMpAccessTokenChange}
												/>
											</div>
											<div className="col-md-12">
												<Form.Label className="input-label">
													{`${t('user.mpPublicKey')}`}
												</Form.Label>
												<Form.Control
													type="text"
													name="mpPublicKey"
													defaultValue={loggedUser.mpPublicKey}
													placeholder={t('user.mpPublicKey')}
													onChange={handleOnMpPublicKeyChange}
												/>
											</div>
										</div>
									</Form.Group>
								) */}
								{/* ChangePassword */}
								<Form.Group controlId="formChangePassword">
									<Form.Label className="input-label">
										{`${t('user.password')}`}
									</Form.Label>
									<Nav className="justify-content-left ml-0">
										<Nav.Link href="/changePassword" className="inscriptions-link pl-0">
											{`${t('user.change-password')}`}
										</Nav.Link>
									</Nav>
								</Form.Group>
							</div>
						</div>
					</Form>
					{showConfirmationSaveModal && (
						<CustomModal
							isShowing={showConfirmationSaveModal}
							parentCallback={handleCloseSaveModal}
							message={t('confirmation.update-my-user')}
							type="WARNING_EDIT"
							buttonCancel={t('confirmation.no')}
							buttonOK={t('confirmation.yes')}
						/>
					)}
					{showConfirmationDiscardModal && (
						<CustomModal
							isShowing={showConfirmationDiscardModal}
							parentCallback={handleCloseDiscardModal}
							message={t('confirmation.no-update-my-user')}
							type="WARNING_EDIT"
							buttonCancel={t('confirmation.no')}
							buttonOK={t('confirmation.yes')}
						/>
					)}
					{showSuccessModal && (
						<CustomModal
							isShowing={showSuccessModal}
							parentCallback={handleCloseSuccessModal}
							title={t('success.my-user-update')}
							type="SUCCESS"
							buttonOK={t('accept')}
						/>
					)}
					{showErrorModal && (
						<CustomModal
							message={errorModalMessage}
							title={t('error.modal-title-oops')}
							isShowing={showErrorModal}
							parentCallback={handleCloseErrorModal}
							type='ERROR'
							buttonOK={t('accept')}
						/>
					)}
				</div>
			)}
		</>
	);
}

export default MyProfile;
