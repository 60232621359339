import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useApi from '../hooks/useApi';
import useLoader from '../components/loader/useLoader';
import Paginator from '../components/paginator/paginator';
import { EVENTS_PAGE_SIZE, ONLY_FREE, SHOW_PUBLIC_EVENTS } from '../../constants';
import NoResults from '../components/no-results/noResults';
import { IoClose, IoSearch } from 'react-icons/io5';
import { CardEventComplete } from './card-event/card-event-complete';
import CardEvent from './card-event/card-event';
export function PastEvents() {
	const [filterText, setFilterText] = useState('none');
	const [loggedUser,
		getLoggedUser,
		getUserById,
		getMyUsers,
		getUsers,
		getCreatableRoles,
		getMyHosts,
		getMyAdmissions,
		getMyPlanners,
		getOrganizer,
		getMyEvents,
		getAllOrganizers,
		getAllVisibleRoles,
		getAllRoles,
		uploadImage,
		getCommunityFilteredUsers,
		getMyPastEvents] = useApi();
	const isLoggedUser =
		localStorage.getItem('loggedUserId') && localStorage.getItem('token');
	const [loader, showLoader, hideLoader] = useLoader();
	const [eventResults, setEventResults] = useState<any[] | undefined>(undefined);
	const [page, setPage] = useState<number>(0);
	const [totalEvents, setTotalEvents] = useState<number>(0);
	const { t } = useTranslation();
	const [filters, setFilters] = useState<any| undefined>({
		eventType: SHOW_PUBLIC_EVENTS ? undefined : 'PRIVATE_EVENT',
		hasPayment: undefined
	});
	const sortedActiveEvents = () => {
		const sortedEvents = eventResults;/* !!eventResults ? eventResults.sort((a, b) => {
			return (
				DateHelper.daysFromToday(b.date) -
				DateHelper.daysFromToday(a.date)
			);
		}) : undefined ; */
		
		const activeEvents = sortedEvents;
		
		if (activeEvents && activeEvents.length == 0) {
			return (
				<div className="ml-3 event-subtitle">
					{t('event.no-events')}
				</div>
			);
		}
		return !!activeEvents ? activeEvents.map(function (event) {
			const isHost =
				loggedUser['isHost'] ||
				(event.hosts.filter((x: any) => x._id == loggedUser['_id']).length >
					0 &&
					event.planners.filter((x: any) => x._id == loggedUser["_id"]).length == 0 &&
					event.organizer._id != loggedUser['_id'] &&
					!loggedUser['isOrganizer']);
			
			return (
				// <CardEventComplete
				// 	event={event}
				// 	key={event._id}
				// 	isAllowedToEdit={false}
				// 	isHost={isHost}
				// 	isPast={true}
				// />
				<CardEvent
					event={event}
					key={event._id}
					isAllowedToEdit={false}
					isHost={isHost}
					isPast={true}
					showOrganizer={!!loggedUser ? (event.organizer?._id !== loggedUser._id) : true}
				/>
			);
		}) : undefined;
	};

	// 
	useEffect(() => {
		onFilterChange();
	}, [filters, loggedUser]);
	const loadEvents = async (page: any, filterText?: string) => {
		const response: any = await getMyPastEvents(filterText, page,filters.eventType, filters.hasPayment);
		setTotalEvents(response.total);
		return response.events;
	};
	const loadNextPage = async () => {
		showLoader();
		const evts = await loadEvents(page + 1, filterText);

		if (evts && evts.length > 0) {
			setEventResults(evts);
		}
		setPage(page + 1);
		hideLoader();
	};
	const loadPrevPage = async () => {
		showLoader();
		const evts = await loadEvents(page - 1, filterText);

		if (evts && evts.length > 0) {
			setEventResults(evts);
		}
		if (page > 0) {
			setPage(page - 1);
		}
		hideLoader();
	};
	const onfilterTextChange = (e: any) => {
		setFilterText(e.target.value);
	};
	const clickSearch = async()=>{
		await performSearch();
	}
	const performSearch = async (_filter?: string) => {
		showLoader();
		setPage(0);
		const filter = _filter != undefined? _filter : filterText != 'none' && filterText != '';
		const response : any = await getMyPastEvents(filter ? filterText : null, null, filters.eventType, filters.hasPayment);
		const evts = response.events;
		setTotalEvents(response.total);
		if (evts) {
			setEventResults(evts);
		}
		hideLoader();
	};
	const handleKeyDown = async (e: any) => {
		if (e.keyCode == 13) {
			await performSearch();
		}
	};
	const clearSearchFilter = async () => {
		setFilterText('none');
		await performSearch('');
	}
	const onFilterChange = async () => {
		showLoader();
		setPage(0);
		const response : any = await getMyPastEvents(filterText, null, filters.eventType, filters.hasPayment);
		const evts = response.events;
		setTotalEvents(response.total);
		if (evts) {
			await setEventResults(evts);
		}
		hideLoader();
	}
	const onEventTypeFilterChange = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		if (e && e.target.id) {
			const value = e.target.id === filters.eventType ? undefined : e.target.id;
			if (value === "PRIVATE_EVENT") {
				setFilters({
					...filters,
					eventType: value,
					hasPayment: undefined
				});
			} else {
				setFilters({
					...filters,
					eventType: value,
				});
			};
		}
	};
	const onHasPaymentFilterChange = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		if (e && e.target.id) {
			const booleanValue = e.target.id === 'PAID_EVENT' ? 'true' : 'false';
			const value = booleanValue === filters.hasPayment ? undefined : booleanValue;
			setFilters({
				...filters,
				hasPayment: value,
			});
		}
	};
	return (
		<>
			{!isLoggedUser && <Redirect to="/login" />}
			{loader}
			{isLoggedUser && loggedUser && eventResults && (
				<div className="dashboard-content cards">
					<div className="form-container col-xl-11">
						<div className="d-flex justify-content-between align-items-center mb-3 p-0 pl-sm-2 pr-sm-2">
							<div className="d-flex justify-content-start page-title align-items-center">
								{t('past-events')}
							</div>
							<div className="d-flex justify-content-end">
								
							</div>
						</div>
						
						<div className='row justify-content-center justify-content-xl-start m-0'>	
							<div className="d-flex col-12 col-xl-6 row m-0 p-0 align-items-center ">
								<div className="row col-12 m-0 p-0 pl-md-2 pr-md-2 align-items-center search-input user-events">
									<input
										className="form-control"
										type="text"
										name="filterNameEmail"
										value={filterText != 'none' ? filterText : ''}
										placeholder={t('event.search')}
										onKeyDown={handleKeyDown}
										onChange={onfilterTextChange}
									/>
									<div className="clear-search" onClick={clearSearchFilter}>
										<IoClose />
									</div>
									<div className="input-button-search">
										<Button className="rounded-button rounded-button-ok" onClick={clickSearch} >
											<IoSearch />
										</Button>
									</div>
								</div>
							</div>
							{SHOW_PUBLIC_EVENTS && <div className="row m-0 p-0 mt-2 mt-xl-0">
								<div className="d-flex col m-0 p-1 ml-2 align-items-center justify-content-center">
									<div onClick={onEventTypeFilterChange} className="d-flex">
										<div className={"filter-button left d-flex align-items-center justify-content-center " + (filters.eventType === "PUBLIC_EVENT" ? "selected " : "")} id='PUBLIC_EVENT'> 
											{t('event.filters.' + "public")}
										</div>
										<div className={"filter-button right d-flex align-items-center justify-content-center " + (filters.eventType === "PRIVATE_EVENT" ? "selected " : "")} id="PRIVATE_EVENT"> 
											{t('event.filters.' + "private")}
										</div>
									</div>
								</div>
								{!ONLY_FREE && <div className="d-flex col m-0 p-1 ml-2 align-items-center justify-content-center">
									<div onClick={onHasPaymentFilterChange} className="d-flex">
										<div className={"filter-button left d-flex align-items-center justify-content-center " + (filters.hasPayment === "false" ? "selected " : "") + (filters.eventType === "PRIVATE_EVENT" ? "disabled " : "")} id="FREE_EVENT"> 
											{t('event.filters.' + "free")}
										</div>
										<div className={"filter-button right d-flex align-items-center justify-content-center " + (filters.hasPayment === "true" ? "selected " : "") + (filters.eventType === "PRIVATE_EVENT" ? "disabled " : "")} id="PAID_EVENT"> 
											{t('event.filters.' + "paid")}
										</div>
									</div>
								</div>}
							</div>}
			
						</div>

						<div className="">
							{eventResults && eventResults.length == 0 && (
								<div className="align-items-center">
									<NoResults text={i18next.t('events-not-found')} />
								</div>
							)}

							{eventResults && eventResults.length > 0 && (
								<div className="pb-5 pt-2">
									{/* <div className="event-subtitle">
										{t('event.active')}
									</div> */}
									{/* d-flex row m-0 justify-content-center justify-content-md-start */}
									<div className="cards-event-container pr-2 pl-2">
										{sortedActiveEvents()}
									</div>
								</div>
							)}
						</div>
						{eventResults && eventResults.length > 0 && (
							<Paginator
								totalPages={Math.ceil(
									totalEvents / EVENTS_PAGE_SIZE
								)}
								currentPage={page}
								onNext={loadNextPage}
								onPrevious={loadPrevPage}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default PastEvents;
