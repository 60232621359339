import { useEffect, useContext } from 'react';

import { AuthContext } from '../globalStates';
import { USERS_PAGE_SIZE, EVENTS_PAGE_SIZE, ageGroupOptions, SHOW_PUBLIC_EVENTS, ONLY_FREE } from '../../constants';
import useLoader from '../components/loader/useLoader';
import useUsersApi from '../hooks/useUsersApi';
import { useDispatch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
const API_URL: string | undefined = process.env.NODE_ENV === 'development' ? '' : process.env.REACT_APP_BACKEND_URL;
export default () => {
	const [, , , logoutUser] = useUsersApi();
	//const [loggedUser, setLoggedUser] = useState(undefined);
	const [loader, showLoading, hideLoading] = useLoader();

	const [loggedUser, setLoggedUser] = useContext(AuthContext);
	const token = localStorage.getItem('token');
	const dispatch = useDispatch();
	const tokenAuth = {
		headers: {
			Authorization: `Bearer ${token}`,
		}
	};

	const handleEffect = async () => {
		if (!loggedUser) {
			if (localStorage.getItem('loggedUserId')) {
				await getLoggedUser();
			}
		}
	};
	useEffect(() => {
		handleEffect();
	}, [loggedUser]);

	const getLoggedUser = async () => {
		try {
			showLoading();
			if (!loggedUser) {
				const userLoggedId = localStorage.getItem('loggedUserId');

				const response: any = await fetch(API_URL + '/api/users/' + userLoggedId, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},

				});
				const userData = await response.json();

				const userLogged = userData;
				const rolesOrganizers: any[] = userData.rolesOrganizers;
				const adminRoles = rolesOrganizers.filter(
					(x) =>
						x.roleName == 'USER_ADMIN' ||
						x.roleName == 'USER_ORGANIZER' ||
						x.roleName == 'USER_PLANNER'
				);
				if (adminRoles.length > 0) {
					const organizerIds = adminRoles.map((x) => {
						return x.organizerId;
					});
					userLogged['organizers'] = organizerIds;
					userLogged['role'] = adminRoles[0];
					userLogged['isHost'] = false;
					userLogged['isOrganizer'] =
						adminRoles.filter((x) => x.roleName == 'USER_ORGANIZER')
							.length > 0;
					userLogged['canAccessCommunity'] =
						userLogged['role'].roleLevel >= 16;
					setLoggedUser(userLogged);
				} else {
					const admissionRole = rolesOrganizers.filter(
						(x) => x.roleName == 'USER_ADMISSION'
					);

					if (admissionRole.length > 0) {
						const organizerIds = admissionRole.map((x) => {
							return x.organizerId;
						});

						userLogged['isHost'] = false;
						userLogged['isOrganizer'] = false;

						userLogged['organizers'] = organizerIds;
						userLogged['role'] = admissionRole[0];
						userLogged['canAccessCommunity'] = false;
						setLoggedUser(userLogged);
					} else {
						const hostRole = rolesOrganizers.filter(
							(x) => x.roleName == 'USER_HOST'
						);
						const organizerIds = hostRole.map((x) => {
							return x.organizerId;
						});
						userLogged['organizers'] = organizerIds;
						userLogged['role'] = hostRole[0];
						userLogged['isHost'] = true;
						userLogged['isOrganizer'] = false;
						userLogged['canAccessCommunity'] = false;
						setLoggedUser(userLogged);
					}
				}
			} else {
				return loggedUser;
			}
		} catch (error) {
			console.log('Fail getLoggedUser')
			console.log(error);
		}
	};

	// Unused endpoint
	const getUsers = async () => {
		try {
			const response: any = await fetch(API_URL + '/api/users/', {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				}
			});
			try {
				const data = await response.json();
				return data;
			} catch (e) {
				return undefined;
			}
		} catch (err) {
			console.log('Fail getUsers')
			console.log(err);
		}
	};

	const getUserById = async (id: string) => {
		try {
			if (loggedUser && loggedUser['role']) {
				const loggedUserId = loggedUser['_id'];
				if (loggedUser['role'].roleName == 'USER_PLANNER') {
					const response: any = await fetch(API_URL + '/api/users/findByIdAndOrganizers/' +
						id +
						'/' +
						loggedUserId, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
					const _user = await response.json();
					if (_user.length > 0) {
						const user = _user[0];
						let roles = _user[0].rolesOrganizers;

						roles = _user[0].rolesOrganizers.filter(
							(x: any) =>
								loggedUser['organizers'].indexOf(
									x.organizerId
								) >= 0
						);

						user['role'] = roles[0];

						return user;
					}
				} else {
					const organizerId = getOrganizer();
					const response: any = await fetch(API_URL + '/api/users/findByIdAndOrganizer/' +
						id +
						'/' +
						organizerId, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},

					});
					const _user = await response.json();
					if (_user.length > 0) {
						const user = _user[0];
						let roles = _user[0].rolesOrganizers;
						if (organizerId != '0') {
							roles = _user[0].rolesOrganizers.filter(
								(x: any) => x.organizerId == organizerId
							);
						}

						user['role'] = roles[0];
						return user;
					}
				}
			}
			//	return undefined;
		} catch (error) {
			console.log('Fail getUserById')
			console.log(error);
		}
	};

	// Tested OK
	const getCreatableRoles = async () => {
		try {
			if (loggedUser && loggedUser['role']) {
				const response: any = await fetch(API_URL + '/api/roles/creatable/' + loggedUser['role'].roleLevel, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					}
				});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());

					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			}
			return undefined;
		} catch (error) {
			console.log('Fail getCreatableRoles');
			console.log(error);
		}
	};

	const filterUsers = (myUsers: [], loggedUserId: string) => {
		if (myUsers && myUsers.length > 0) {
			// exclude logged user
			const myFilteredUsers = myUsers.filter(
				(x) => x['_id'] != loggedUserId
			);
			return myFilteredUsers;
		} else {
			return myUsers;
		}
	};
	const getMyUsers = async (page: number, filterText?: string, pageSize?: number) => {
		try {
			const ps = (pageSize? pageSize :USERS_PAGE_SIZE);
			const _textFilter = filterText && filterText != '' ? filterText : 'none';
			if (loggedUser && loggedUser['role']) {
				const loggedUserId = loggedUser['_id'];
				if (loggedUser['role'].roleName == 'USER_PLANNER') {
					const response: any = await fetch(API_URL + '/api/users/myAdminUsers/' +
						loggedUserId +
						'/' +
						_textFilter +
						'/' +
						page +
						'/' +
						ps
						, {
							method: 'GET',
							headers: {
								Authorization: `Bearer ${localStorage.getItem('token')}`,
							}
						});
					try {
						if (response.status == 401) {
							await dispatch(logoutUser());
						}
						const data = await response.json();
						return filterUsers(data, loggedUserId);
					} catch (e) {
						return [];
					}

				} else {
					const organizerId = getOrganizer();
					const response: any = await fetch(API_URL + '/api/users/myUsers/' +
						organizerId +
						'/' +
						_textFilter +
						'/' +
						page +
						'/' +
						ps
						, {
							method: 'GET',
							headers: {
								Authorization: `Bearer ${localStorage.getItem('token')}`,
							}
						});
					try {
						if (response.status == 401) {
							await dispatch(logoutUser());
						}
						const data = await response.json();
						return filterUsers(data, loggedUserId);
					} catch (e) {
						return [];
					}
				}
			}
			return [];
		} catch (err) {
			console.log('Fail getMyUsers')
			console.log(err);
		}
	};
	const getAllOrganizers = async () => {
		try {
			if (
				loggedUser &&
				loggedUser['role'] &&
				loggedUser['role']['roleName'] == 'USER_ADMIN'
			) {
				const response: any = await fetch(API_URL + '/api/users/organizers/getAll/'
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else if (
				loggedUser &&
				loggedUser['role'] &&
				loggedUser['role']['roleName'] == 'USER_PLANNER'
			) {
				const response: any = await fetch(API_URL + '/api/users/myOrganizers/' + loggedUser['_id']
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else if (
				loggedUser &&
				loggedUser['role'] &&
				loggedUser['role']['roleName'] == 'USER_ORGANIZER'
			) {
				const response: any = await fetch(API_URL + '/api/users/' + loggedUser['_id']
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return [data];
				} catch (e) {
					return undefined;
				}
			}
			return undefined;
		} catch (error) {
			console.log('Fail getAllOrganizers');
			console.log(error);
		}
	};

	const getMyHosts = async (organizerId?: string) => {
		try {
			if (organizerId && organizerId != '0') {

				const response: any = await fetch(API_URL + '/api/users/hosts/' + organizerId
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else {
				if (loggedUser && loggedUser['role']) {
					const _organizerId = getOrganizer();
					const response: any = await fetch(API_URL + '/api/users/hosts/' + _organizerId
						, {
							method: 'GET',
							headers: {
								Authorization: `Bearer ${localStorage.getItem('token')}`,
							}
						});
					try {
						if (response.status == 401) {
							await dispatch(logoutUser());
						}
						const data = await response.json();
						return data;
					} catch (e) {
						return undefined;
					}
				}
				return undefined;
			}
		} catch (error) {
			console.log('Fail getMyHosts');
			console.log(error);
		}
	};
	const getMyPlanners = async (organizerId?: string) => {
		try {
			if (organizerId && organizerId != '0') {
				const response: any = await fetch(API_URL + '/api/users/planners/' + organizerId
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else {
				if (loggedUser && loggedUser['role']) {
					const _organizerId = getOrganizer();
					const response: any = await fetch(API_URL + '/api/users/planners/' + _organizerId
						, {
							method: 'GET',
							headers: {
								Authorization: `Bearer ${localStorage.getItem('token')}`,
							}
						});
					try {
						const data = await response.json();
						return data;
					} catch (e) {
						return undefined;
					}
				}
				return undefined;
			}
		} catch (error) {
			console.log('Fail getMyPlanners');
			console.log(error);
		}
	};
	const getMyAdmissions = async (organizerId?: string) => {
		try {
			if (organizerId && organizerId != '0') {
				const response: any = await fetch(API_URL + '/api/users/admissions/' + organizerId
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else {
				if (loggedUser && loggedUser['role']) {
					const _organizerId = getOrganizer();
					const response: any = await fetch(API_URL + '/api/users/admissions/' + _organizerId
						, {
							method: 'GET',
							headers: {
								Authorization: `Bearer ${localStorage.getItem('token')}`,
							}
						});
					try {
						if (response.status == 401) {
							await dispatch(logoutUser());
						}
						const data = await response.json();
						return data;
					} catch (e) {
						return undefined;
					}
				}
				return undefined;
			}
		} catch (error) {
			console.log('Fail getMyAdmissions')
			console.log(error);
		}
	};
	const getEvents = async (text?: string, page?: number, eventType?: string, hasPayment?: string, all: boolean = false, isDraft: boolean = false) => {
		const filterText = text && text != '' ? text : 'none';
		const filterEventType = eventType ?? 'none';
		const filterHasPayment = ONLY_FREE ? 'false' : (hasPayment ?? 'none');
		const _page = page ? page : 0;
		const now = new Date();
				const offset = now.getTimezoneOffset();
		try {
			if (loggedUser['isHost']) {
				const response: any = await fetch(API_URL + '/api/events/myUserEventsHost/' + 
				loggedUser['_id'] + '/' + 
				filterText + '/' + 
				_page + '/' + (all ? 0 : EVENTS_PAGE_SIZE)  + '/'+ offset + '/' + filterEventType + '/' + filterHasPayment 
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else if (loggedUser['role'].roleName == 'USER_PLANNER') {
				const response: any = await fetch(API_URL + '/api/events/myAdminEvents/' + 
				loggedUser['_id'] + '/' + filterText + '/' + _page + '/' + (all ? 0 : EVENTS_PAGE_SIZE) + '/'+ 
				offset + '/' + filterEventType + '/' + filterHasPayment+ '/' +isDraft
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else if (loggedUser['role'].roleName == 'USER_ADMISSION') {
				const response: any = await fetch(API_URL + '/api/events/myAdmissionEvents/' + loggedUser['_id'] + '/' + filterText + '/' + _page + '/' + (all ? 0 : EVENTS_PAGE_SIZE) + '/'+offset
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			}
			else {
				const organizerId = getOrganizer();
				const response: any = await fetch(API_URL + '/api/events/myEvents/' + organizerId + '/' + filterText + '/' + _page + '/' + (all ? 0 : EVENTS_PAGE_SIZE)   +'/' + offset + '/' + filterEventType + '/' + filterHasPayment +'/'+isDraft
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			}
		}
		catch (error) {
			console.log('Fail getEvents');
			console.log(error);
		}
	};
	const getPublicEvents = async (text?: string, page?: number, all: boolean = false) => {
		const filterText = text && text != '' ? text : 'none';
		const _page = page ? page : 0;
		const now = new Date();
				const offset = now.getTimezoneOffset();
		try {
			const response: any = await fetch(API_URL + '/api/events/publicEvents/' + filterText + '/' + _page + '/' + (all ? 0 : EVENTS_PAGE_SIZE)   +'/' + offset + `/${ONLY_FREE}`
					, {
						method: 'GET',
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			
		}
		catch (error) {
			console.log('Fail getPublicEvents');
			console.log(error);
		}
	};
	const getPastEvents = async (text?: string, page?: number,  eventType?: string, hasPayment?: string, all: boolean = false) => {
		const filterText = text && text != '' ? text : 'none';
		const filterEventType = eventType ?? 'none';
		const filterHasPayment = ONLY_FREE? 'false': (hasPayment ?? 'none');
		const _page = page ? page : 0;
		const now = new Date();
				const offset = now.getTimezoneOffset();
		try {
			if (loggedUser['isHost']) {
				const response: any = await fetch(API_URL + '/api/events/myUserPastEventsHost/' + loggedUser['_id'] + '/' + filterText + '/' + _page + '/' + (all ? 0 : EVENTS_PAGE_SIZE) +'/' + offset+ '/' + filterEventType + '/' + filterHasPayment
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else if (loggedUser['role'].roleName == 'USER_PLANNER') {
				const response: any = await fetch(API_URL + '/api/events/myAdminPastEvents/' + loggedUser['_id'] + '/' + filterText + '/' + _page + '/' + (all ? 0 : EVENTS_PAGE_SIZE)  +'/' + offset+ '/' + filterEventType + '/' + filterHasPayment
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			} else if (loggedUser['role'].roleName == 'USER_ADMISSION') {
				const response: any = await fetch(API_URL + '/api/events/myAdmissionPastEvents/' + loggedUser['_id'] + '/' + filterText + '/' + _page + '/' + (all ? 0 : EVENTS_PAGE_SIZE)  +'/' + offset+ '/' + filterEventType + '/' + filterHasPayment
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			}
			else {
				const organizerId = getOrganizer();
				const response: any = await fetch(API_URL + '/api/events/myPastEvents/' + organizerId + '/' + filterText + '/' + _page + '/' + (all ? 0 : EVENTS_PAGE_SIZE)  +'/' + offset+ '/' + filterEventType + '/' + filterHasPayment
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			}
		}
		catch (error) {
			console.log('Fail getPastEvents');
			console.log(error);
		}
	};
	const getMyEvents = async (text?: any, page?: any, eventType?: string, hasPayment?: string, all: boolean = false, isDraft: boolean = false) => {
		try {

			if (!SHOW_PUBLIC_EVENTS){
				eventType='PRIVATE_EVENT';
			}
			if (loggedUser && loggedUser['role']) {
				const result: any = await getEvents(text ? text : '', page, eventType, hasPayment, all, isDraft);
				let events = result.events;
				if (events && events.length > 0) {
					for (let e of events) {
						const allInscriptions: any[] = e.inscriptions;
						if (!!allInscriptions) {
						e['ACCEPTED'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ACCEPTED'
						).length;
						e['confirmed'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ACCEPTED'
						).length;
						e['PENDING'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'PENDING' || x['inscriptionStatus'] =='PENDING_EMAIL_CONFIRMATION'
						).length;
						e['pending'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'PENDING'
						).length;
						e['REJECTED'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'DENIED' || x['inscriptionStatus'] == 'REJECTED'
						).length;
						e['denied'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'DENIED'
						).length;
						e['attendeed'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ATTENDEED'
						).length;
						e['ATTENDEED'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ATTENDEED'
						).length;
						e['notAttendeed'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ACCEPTED' ||
								x['inscriptionStatus'] == 'PENDING' ||
								x['inscriptionStatus'] == 'PENDING_EMAIL_CONFIRMATION'
						).length;
						e['NOT_ATTENDEED'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ACCEPTED' ||
								x['inscriptionStatus'] == 'PENDING' ||
								x['inscriptionStatus'] == 'PENDING_EMAIL_CONFIRMATION'
						).length;
						}
					}
					return { total: result.total, events: events };
				}
				else {
					return { total: 0, events: [] };
				}
			}
			return undefined;
		} catch (err) {
			console.log('Fail getMyEvents')
			console.log(err);
		}
	};
	const getMyPastEvents = async (text?: any, page?: any, eventType?: string, hasPayment?: string, all: boolean = false) => {
		try {

			if (loggedUser && loggedUser['role']) {
				const result: any = await getPastEvents(text ? text : '', page, eventType, hasPayment, all);
				let events = result.events;
				if (events && events.length > 0) {
					for (let e of events) {
						const allInscriptions: any[] = e.inscriptions;
						if (!!allInscriptions){
						e['ACCEPTED'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ACCEPTED'
						).length;
						e['confirmed'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ACCEPTED'
						).length;
						e['PENDING'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'PENDING' || x['inscriptionStatus'] =='PENDING_EMAIL_CONFIRMATION'
						).length;
						e['pending'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'PENDING'
						).length;
						e['REJECTED'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'DENIED' || x['inscriptionStatus'] == 'REJECTED'
						).length;
						e['denied'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'DENIED'
						).length;
						e['attendeed'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ATTENDEED'
						).length;
						e['ATTENDEED'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ATTENDEED'
						).length;
						e['notAttendeed'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ACCEPTED' ||
								x['inscriptionStatus'] == 'PENDING' ||
								x['inscriptionStatus'] == 'PENDING_EMAIL_CONFIRMATION'
						).length;
						e['NOT_ATTENDEED'] = allInscriptions.filter(
							(x) => x['inscriptionStatus'] == 'ACCEPTED' ||
								x['inscriptionStatus'] == 'PENDING' ||
								x['inscriptionStatus'] == 'PENDING_EMAIL_CONFIRMATION'
						).length;
						}
					}
					return { total: result.total, events: events };
				}
				else {
					return { total: 0, events: [] };
				}
			}
			return undefined;
		} catch (err) {
			console.log('Fail getMyPastEvents')
			console.log(err);
		}
	};
	//Helpers
	const getOrganizer = () => {
		let organizer;

		if (loggedUser && loggedUser['role']) {
			if (loggedUser['role']['roleName'] == 'USER_ORGANIZER') {
				organizer = loggedUser['_id'];
			} else if (loggedUser['role']['roleLevel'] < 16) {
				// Cuando soy planner, tomo mi organizer de cabecera
				// Permitir seleccionar uno en el caso qu tenga varios
				// TODO: o asignar todos los que tengo??
				organizer = loggedUser['organizers'][0];
			} else {
				return '0';
			}
		}
		return organizer;
	};

	const getAllVisibleRoles = async () => {
		try {
			if (loggedUser && loggedUser['role']) {
				const response: any = await fetch(API_URL + '/api/roles/visible/' + loggedUser['role'].roleLevel
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			}
			return undefined;
		} catch (error) {
			console.log('Fail getAllVisibleRoles');
			console.log(error);
		}
	};
	const getAllRoles = async () => {
		try {
			if (loggedUser && loggedUser['role']) {
				const response: any = await fetch(API_URL + '/api/roles'
					, {
						method: 'GET',
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}

			}
			return undefined;
		} catch (error) {
			console.log('Fail getAllRoles');
			console.log(error);
		}
	};
	const uploadImagex = createAsyncThunk(
		'uploadImage',
		async (img: File) => {
			try {
				//TODO REMOVE THIS WHEN CERTIFICATE IS ON
				if (!localStorage.getItem('token')) {
					await dispatch(logoutUser());
					return undefined;
				}
				var formData = new FormData()
				formData.append('image', img);
				const response: any = await fetch(API_URL + '/api/imageuploader/upload',
					{
						method: 'POST',
						body: formData,
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`
						}
					});
				try {
					if (response.status == 401) {
						await dispatch(logoutUser());
					}
					console.log(response)
					const data = await response.json();

					// if (data && data.filename) {
					// 	var formDataDist = new FormData();
					// 	var img_ = new File([img], data.filename)
					// 	formDataDist.append('image', img_);

					// 	const responseDist = await fetch(API_URL + '/api/imageuploader/uploadDist',
					// 		{
					// 			method: 'POST',
					// 			body: formDataDist,

					// 			headers: {
					// 				Authorization: `Bearer ${localStorage.getItem('token')}`
					// 			}
					// 		});
					// 	if (responseDist.status == 401) {
					// 		await dispatch(logoutUser());
					// 	}
					// 	const dataDist = await responseDist.json();
					// 	console.log(dataDist)
					// 	return dataDist;
					// }
					return data;
				} catch (e) {
					console.log(e)
					return undefined;
				}

			} catch (err) {
				console.log('Fail uploadImage');
				return err;
			}
		}
	);
	const uploadImage = async (img: File) => {
		try {

			//TODO REMOVE THIS WHEN CERTIFICATE IS ON
			if (!localStorage.getItem('token')) {
				await dispatch(logoutUser());
				return undefined;
			}

			var formData = new FormData();

			formData.append('image', img);

			const response: any = await fetch(API_URL + '/api/imageuploader/upload',
				{
					method: 'POST',
					body: formData,
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`
					}
				});

			try {
				if (response.status == 401) {
					await dispatch(logoutUser());
				}
				console.log(response);
				const data = await response.json();

				if (data && data.filename) {
					var formDataDist = new FormData();
					var img_ = new File([img], data.filename)
					formDataDist.append('image', img_);

					const responseDist = await fetch(API_URL + '/api/imageuploader/uploadDist',
						{
							method: 'POST',
							body: formDataDist,

							headers: {
								Authorization: `Bearer ${localStorage.getItem('token')}`
							}
						});
					if (responseDist.status == 401) {
						await dispatch(logoutUser());
					}
					const dataDist = await responseDist.json();
					console.log(dataDist)
					return dataDist;
				}
				return data;
			} catch (e) {
				console.log(e);
				return undefined;
			}

		} catch (err) {
			console.log('Fail uploadImage');
			return err;
		}
	}
		;
	const getCommunityFilteredUsers = async (
		page: number,
		text?: string,
		gender?: string,
		roles?: any[],
		groups?: any[],
		pageSize?: number,
	) => {
		const filterText = text && text !== '' ? text : 'none';
		const genderText = gender && gender !== '' && gender !== '0' ? gender : 'none';
		const roleArray = roles && roles.length > 0 ? roles : 'none';
		const groupArray = groups && groups.length > 0 ? groups : 'none';
		const perPage = typeof pageSize === 'number' && pageSize >= 0 ? pageSize : USERS_PAGE_SIZE;

		try {
			if (loggedUser && loggedUser['role']) {
				const loggedUserId = loggedUser['_id'];
				if (loggedUser['role'].roleName == 'USER_PLANNER') {
					const response = await fetch(API_URL +
						'/api/users/communityFilteredUsers/' +
						loggedUserId + '/' +
						filterText + '/' +
						genderText + '/' +
						roleArray + '/' +
						groupArray + '/' +
						page + '/' + perPage,
						{
							method: 'GET',
							headers: {
								Authorization: `Bearer ${localStorage.getItem(
									'token'
								)}`,
							},
						}
					);
					try {
						if (response.status == 401) {
							await dispatch(logoutUser());
						}
						const data = await response.json();
						return filterUsers(data, loggedUserId);
					} catch (e) {
						return [];
					}
				} else {
					const organizerId = getOrganizer();
					const response = await fetch(API_URL +
						'/api/users/communityFilteredUsers/' +
						organizerId + '/' +
						filterText + '/' +
						genderText + '/' +
						roleArray + '/' +
						groupArray + '/' +
						page + '/' + perPage,
						{
							method: 'GET',
							headers: {
								Authorization: `Bearer ${localStorage.getItem(
									'token'
								)}`,
							},
						}
					);
					try {
						if (response.status == 401) {
							await dispatch(logoutUser());
						}
						const data = await response.json();
						return filterUsers(data, loggedUserId);
					} catch (e) {
						return [];
					}
				}
			}
			return [];
		} catch (err) {
			console.log('Fail getCommunityFilteredUsers');
			console.log(err);
		}
	};
	const getCreatablePublicRoles = async () => {
		try {
			
				const response: any = await fetch(API_URL + '/api/roles/creatable-public/public', {
					method: 'GET',
					
				});
				try {
					
					const data = await response.json();
					return data;
				} catch (e) {
					return undefined;
				}
			
			return undefined;
		} catch (error) {
			console.log('Fail getCreatablePublicRoles');
			console.log(error);
		}
	};
	return [
		loggedUser,
		getLoggedUser,
		getUserById,
		getMyUsers,
		getUsers,
		getCreatableRoles,
		getMyHosts,
		getMyAdmissions,
		getMyPlanners,
		getOrganizer,
		getMyEvents,
		getAllOrganizers,
		getAllVisibleRoles,
		getAllRoles,
		uploadImage,
		getCommunityFilteredUsers,
		getMyPastEvents,
		getPublicEvents,
		getCreatablePublicRoles
	] as const;
};
