import { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OPTION_MULTIPLE_LIMIT, OPTION_SINGLE_LIMIT, QUESTION_MAX_LENGTH, QUESTION_TYPES, } from '../../constants';
import i18n from '../../i18n';
import useLoader from '../components/loader/useLoader';
import { Validator } from '../helpers/validators';
import { IQuestion, IQuestionOption } from '../models/interfaces/events.interface';
import { TQuestionType } from '../models/types/types';
import Select from 'react-select';
import { IoAddOutline } from 'react-icons/io5';
import ErrorGlobal from '../components/error-global/error-global';
import { BsTrashFill } from 'react-icons/bs';
interface QuestionFormProps {
    question: IQuestion,
    index: number,
    disableEdit?: boolean
    onHideModal: any;
    onSaveQuestion: any;
    isEditing: boolean;
    isFeedback?: boolean;
}
export function QuestionForm(props: QuestionFormProps) {
    const [loader, showLoader, hideLoader] = useLoader();
    const [validated, setValidated] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [text, setText] = useState<string | undefined>(props.question.text);
    const [type, setType] = useState<TQuestionType | undefined>(props.question.type);
    const [questionToSave, setQuestionToSave] = useState<IQuestion>(props.question);
    const [optionNumber, setOptionNumber] = useState<number>(props.question.options?.length || 0);
    const [options, setOptions] = useState<IQuestionOption[]>();

    const { t } = useTranslation();

    const getSelectSingleOptions = (arr: any[] | undefined, key: string) => {
        const options: any[] = [];
        if (arr && arr.length > 0) {
            arr.forEach((x) => {
                let opt = {
                    label:
                        i18n.t(key + x.name),
                    value: x['_id'],
                    name: x.name
                };
                if(x.name === 'SCALE'){
                    if (props.isFeedback){
                        options.push(opt);
                    }
                } else {
                     options.push(opt);
                }
               
            });
        }
        return options;
    };
    const questionTypes = getSelectSingleOptions(QUESTION_TYPES, 'event.question.types.');


    const hasError = () => {
        const hasErr =
            (!text || !type ||
                ((type === 'OPTION_SINGLE' || type === 'OPTION_MULTIPLE') && (hasErrorOptions())));
        return hasErr;
    };

    const hasErrorOptions = () => {
        if (!options) return true;
        const notDeleted = options.filter(x => !x.deleted);
        return !notDeleted || notDeleted.length < 2 || (notDeleted.find(x => Validator.nameQuestion(x.text, QUESTION_MAX_LENGTH)) !== undefined);
    }

    const validateAll = (form: any) => {
        setValidated(true);
        return (!!form.checkValidity() && !hasError());
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        if (validateAll(form)) {
            showLoader();
            if (!!props.onSaveQuestion) {
                const _questionToSave = getQuestionToSave();
                props.onSaveQuestion(_questionToSave, props.index);
            }
            hideLoader();
        }
        //   setValidated(true);
    };

    const getQuestionToSave = () => {
        const q: IQuestion = {
            ...questionToSave,
            text: text,
            type: type,
            options: !!options ? options.filter(x=> !x.deleted) : options
        };
        return q;
    }

    const handleOnCreateNameQuestion = (event: any) => {
        setText(event.target.value);
        setDirty(true);
    }

    const handleCloseModal = () => {
        if (props.onHideModal) {
            props.onHideModal();
        }
    }

    useEffect(() => {
       
        if (!!props.question.options && props.question.options.length > 0 && props.question.type !== 'YES_NO') {
            let optionsCopy: IQuestionOption[] = [];
            for (const option of props.question.options) {
                optionsCopy.push({
                    text: option.text,
                    deleted: option.deleted,
                    index: option.index
                });
            }
            setOptions(optionsCopy);
            let sortedbyIndex = optionsCopy.sort((a, b)=> {
                return a.index -b.index;
            })
            setOptionNumber(sortedbyIndex[sortedbyIndex.length-1].index);
        }

    }, [props.question]);

    const setScaleOptions = (options: number) => {
        let opts = []
        for (let i = 1; i <= options; i ++){
            const opt = { index: i , text: undefined };
            opts.push(opt);
        }
        setOptions(opts);
        setOptionNumber(options);
    }
    const handleOnTypeChange = (_type: any) => {
        if (_type.name === 'OPTION_SINGLE' || _type.name === 'OPTION_MULTIPLE') {
                setOptions([{
                    index: 1,
                    text: undefined,
                }, {
                    index: 2,
                    text: undefined,
                }]);
                setOptionNumber(2);
        } else if(_type.name === 'SCALE'){
            setOptions([]);
            setScaleOptions(10);
        } else {
            setOptions([]);
            setOptionNumber(0);
        }

        setType(_type.name);
        setDirty(true);
    };

    const getNumber = (option: IQuestionOption) => {
        if(!!options){
            const i = options.filter(x=> !x.deleted).findIndex(x => x.index === option.index);
            return i+1;
        }
        return '';
    }
    const handleOnCreateOption = (e: any, option: IQuestionOption) => {
        option.text = e.target.value;
        if (!!options) {
            let opts = [...options];
            const i = opts.findIndex(x => x.index === option.index);
            opts[i].text = e.target.value;
            setOptions(opts);
        }

        setDirty(true);
    };

    const deleteOption = (option: IQuestionOption) => {
        if (!!options) {
            let opts = [...options];
            const i = opts.findIndex(x => x.index === option.index);
            opts[i].deleted = true;
            setOptions([])
            setOptions(opts);
        }

        setDirty(true);
    }
    const handleAddOption = () => {
        const q = options?.length === 0 ? 1 : optionNumber + 1
        setOptionNumber(q);
        addOption(q);
    }
    const addOption = (prevNumber: number) => {
        setValidated(false);

        const option: IQuestionOption = {
            index: prevNumber + 1,
            text: undefined,
        };

        const b = !options ? [option] : [...options, option];
        console.log(b);
        setOptions(b);
    };
    return (
        <>
            <Modal show={!!props.question}
                onHide={handleCloseModal}
                backdrop="static"
                animation={false}
                keyboard={false}
                centered>
                {loader}
                <Form
                    noValidate
                    validated={validated}
                    className="form-container m-0 p-0"
                    onSubmit={handleSubmit}
                >
                     <div className='ml-3 modal-title mb-3 '>{t('event.add-question')}</div>
                        <div className='ml-3 modal-title mb-3 '> <hr></hr></div>
                    <Form.Group >
                        <div className="col-12 m-0 p-0 row">
                            <div className="col-md-12"
                                id={'question-' + props.question.index + '-name'}>
                                <Form.Label className="input-label">
                                    {`${t('event.question.name')}`}
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="text"
                                    autoComplete="off"
                                    defaultValue={props.question.text}
                                    placeholder={t('event.question.text')}
                                    onChange={handleOnCreateNameQuestion}
                                    required
                                    disabled={props.disableEdit}
                                    maxLength={QUESTION_MAX_LENGTH}
                                    isInvalid={validated && Validator.nameQuestion(text, QUESTION_MAX_LENGTH)}
                                />
                                {validated && Validator.nameQuestion(text, QUESTION_MAX_LENGTH) && <Form.Control.Feedback type="invalid">
                                    {!text && `${t('error.required')}`}
                                    {!!text && `${t('error.invalid')}`}
                                </Form.Control.Feedback>}
                            </div>
                            <div className="col-md-12"
                                id={'question-' + props.question.index + '-type'}>
                                <Form.Label className="input-label">
                                    {`${t('event.question.type')}`}
                                </Form.Label>
                                <Select
                                    className={(validated && !!type) ? "select-control valid" : (validated && !type ? "select-control invalid" : "select-control")}
                                    placeholder={t('select')}
                                    options={questionTypes}
                                    value={questionTypes.find(x => x.name === type)}
                                    onChange={handleOnTypeChange}
                                    required
                                    isDisabled={props.disableEdit}
                                    isInvalid={validated && !type}
                                />
                                {validated && !type &&
                                    <Form.Control.Feedback type="invalid" className='custom-error'>
                                        {`${t('error.required')}`}
                                    </Form.Control.Feedback>}

                            </div>
                            {/* cargar opciones para scale */}
                            {(type === 'SCALE')
                                && <div className="col-md-12 mt-4"
                                    key={'question-scale-' + props.question.index + '-options'}
                                    id={'question-scale-' + props.question.index + '-options'}>

                                    {!!options && options.length > 0 && (
                                        <>
                                            {options.map((option, i) => (
                                                <>
                                               { (i== 0 || i == options.length-1) && 
                                                <div key={'option-scale-' + i}>
                                                    {
                                                        !option.deleted &&
                                                        <>
                                                            <div className='d-flex row m-0 p-0 align-items-center mb-2'>
                                                                <div className='col-1 input-label'> { getNumber(option)}</div>
                                                                <div className='col-11  m-0 p-0'>
                                                               
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="text"
                                                                        autoComplete="off"
                                                                        defaultValue={option.text}
                                                                        placeholder={t('event.question.optional-tag')}
                                                                        onChange={(e) => { handleOnCreateOption(e, option) }}
                                                                       
                                                                        disabled={props.disableEdit}
                                                                        maxLength={QUESTION_MAX_LENGTH}
                                                                        isInvalid={validated && Validator.nameQuestion(option.text, QUESTION_MAX_LENGTH)}
                                                                    />
                                                                    {validated && Validator.nameQuestion(option.text, QUESTION_MAX_LENGTH) && <Form.Control.Feedback type="invalid">
                                                                       
                                                                        {!!option.text && `${t('error.invalid')}`}
                                                                    </Form.Control.Feedback>}
                                                                </div>
                                                              
                                                            </div>
                                                        </>
                                                    }
                                                </div>
} </>
                                            ))}
                                        </>
                                    )}
                                </div>}
                            {
                                !!options && validated &&
                                ((type === 'SCALE') && options.length < 2
                                )
                                &&
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="global-error m-3"
                                >
                                    <ErrorGlobal
                                        text={t('error.question-options-required')}
                                    ></ErrorGlobal>
                                </Form.Control.Feedback>
                            }
                            {/* cargar opciones para single y multiple */}
                            {(type === 'OPTION_SINGLE' || type === 'OPTION_MULTIPLE')
                                && <div className="col-md-12 mt-4"
                                    id={'question-' + props.question.index + '-options'}>

                                    {!!options && options.length > 0 && (
                                        <>
                                            {options.map((option, i) => (
                                                <div key={'option-' + i}>
                                                    {
                                                        !option.deleted &&
                                                        <>
                                                            <Form.Label className="input-label">
                                                                {`${t('event.question.option')} ` + getNumber(option)}
                                                            </Form.Label>
                                                            <div className='d-flex row m-0 p-0 align-items-center'>
                                                                <div className='col-11  m-0 p-0'>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="text"
                                                                        autoComplete="off"
                                                                        defaultValue={option.text}
                                                                        placeholder={t('event.question.option-text')}
                                                                        onChange={(e) => { handleOnCreateOption(e, option) }}
                                                                        required
                                                                        disabled={props.disableEdit}
                                                                        maxLength={QUESTION_MAX_LENGTH}
                                                                        isInvalid={validated && Validator.nameQuestion(option.text, QUESTION_MAX_LENGTH)}
                                                                    />
                                                                    {validated && Validator.nameQuestion(option.text, QUESTION_MAX_LENGTH) && <Form.Control.Feedback type="invalid">
                                                                        {!option.text && `${t('error.required')}`}
                                                                        {!!option.text && `${t('error.invalid')}`}
                                                                    </Form.Control.Feedback>}
                                                                </div>
                                                                {i >= 2 &&
                                                                    <div className='col-1  m-0 p-0'><Button
                                                                        className="icon-button-delete ml-2"
                                                                        onClick={(e: any) =>
                                                                            deleteOption(option)
                                                                        }
                                                                    >
                                                                        <BsTrashFill />
                                                                    </Button></div>}
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            ))}
                                        </>
                                    )}
                                    <div className="form-group d-flex justify-content-end mt-3">
                                        <Button
                                            disabled={!!options && options.filter(x=> !x.deleted).length === (type === 'OPTION_SINGLE' ? OPTION_SINGLE_LIMIT : OPTION_MULTIPLE_LIMIT)}
                                            className="button-ok small"
                                            onClick={handleAddOption}
                                        >
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div>
                                                    <IoAddOutline />
                                                </div>
                                                <div>
                                                    {t('event.add-question-option')}
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                </div>}
                            {
                                !!options && validated &&
                                ((type === 'OPTION_SINGLE' || type === 'OPTION_MULTIPLE') && options.length < 2
                                )
                                &&
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="global-error m-3"
                                >
                                    <ErrorGlobal
                                        text={t('error.question-options-required')}
                                    ></ErrorGlobal>
                                </Form.Control.Feedback>
                            }
                        </div>
                    </Form.Group>

                    <div className='d-flex row align-items-center justify-content-end m-0 mt-5 w-100'>
                        <Button className='button-cancel button-cancel-modal m-2' onClick={handleCloseModal}>{t('cancel')}</Button>
                        <Button className='button-ok button-ok-modal m-2' type="submit" disabled={!dirty}>{t('save')}</Button>

                    </div>

                </Form>
            </Modal>
        </>

    );
}

export default QuestionForm;
